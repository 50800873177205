import { styled as mStyled } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslatedMessage } from '../hooks/use-translated-message';
import { FOOTER_PAGES, Path, getLocaledAbsolutePath } from '../constants';
import { MenuItemObj } from '../types';
import { Box } from '@mui/material';
import { useIntl } from 'react-intl';

const FooterWrapper = mStyled('footer')(({ theme }) => ({
    padding: '2rem 0 2rem 0'
    // background: theme.custom.background.main
}));

const Copyright = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    return (
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2, mb: 2, fontWeight: 500 }}>
            {`${message('General.CopyRight.Year')} `}
            <Link color="inherit" href={getLocaledAbsolutePath(Path.MAIN, locale)}>
                {message('General.DictName')}
            </Link>
            {'. '}
            {message('General.CopyRight.Rights')}
            {'.'}

        </Typography>
    );
}

const FooterNavigation = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    return <Box sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        typography: 'body2',
    }}>
        {FOOTER_PAGES.map((page: MenuItemObj) => (
            <Link key={page.id} href={getLocaledAbsolutePath(page.path ?? "", locale)} sx={{ mr: 2 }}>{message(page.labelKey)}</Link>
        ))}
    </Box>
}

const Footer = () => (
    <FooterWrapper>
        <FooterNavigation />
        <Copyright />
    </FooterWrapper>
);

export default Footer