import { useState, useEffect } from 'react';
import { fetchData } from '../utils/fetch-data'; // Assuming fetchData is in the same directory
import { AxiosRequestConfig } from 'axios';
import { useUserInfoContext } from './use-user-info-context';
import { StatusCode, UserStatus } from '../types';
import useNavigate from './use-navigate';
import { getFromLocalStore } from '../utils/local-storage';
import { LocalStorageKey } from '../constants';
import { useTranslatedMessage } from './use-translated-message';
import { useMessageContext } from './use-message-context';

/**
 * Custom React hook to fetch data using Axios and the fetchData utility function.
 * 
 * @param url - The URL to fetch data from.
 * @param options - An object containing various options for the request.
 * @param skipAuth - Whether skipping authenication requirement for an api call
 * @param skip - Whether skipping triggering the api call
 * @returns An object containing response data, loading and error states.
 */
const useAxiosFetch = <T = any>(
    url: string,
    options: AxiosRequestConfig = {},
    // skipAuth works same way as forceRedirect in the opposite direction
    skipAuth?: boolean,
    skip?: boolean
): { data: T | null; status: StatusCode | null; loading: boolean; error: Error | null } => {
    type Response = { result?: T, status?: StatusCode } | null;
    const { authToken, clearUserCache } = useUserInfoContext();
    const [data, setData] = useState<T | null>(null);
    const [status, setStatus] = useState<StatusCode | null>(null);
    const [, setResponse] = useState<Response>(null);
    const [loading, setLoading] = useState<boolean | undefined>();
    const [error, setError] = useState<Error | null>(null);
    const { navigateToLogin } = useNavigate();
    const userStatus = getFromLocalStore(LocalStorageKey.USER_STATUS);
    const message = useTranslatedMessage();
    const { showToastMessage } = useMessageContext();

    // Assuming user is authenticated when loading, since it ultimately gets real value
    // This prevents frequent userStatus change upon page refresh
    // This also helps determine if a redirection is needed
    const isAuthenticatedFromCache = userStatus === UserStatus.ONLINE;

    useEffect(() => {
        if (skip) return;
        // Avoid endless loading due to rerender caused by change in loading status 
        // Trigger fetch only at first rendering
        if (loading !== undefined) return;
        // Skip fetch if requiring authentication but not authenticated
        if (!skipAuth && !isAuthenticatedFromCache) return;
        const fetchDataAsync = async () => {
            try {
                setLoading(true);
                setError(null);

                // Whatever skipAuth is will be passed as forceRedirect in fetchData
                // If unauthorized, will be force-redirected to Login when skipAuth is false
                const responseData = await fetchData<Response>(url, {
                    ...options
                }, !skipAuth);
                setResponse(responseData);
                setData(responseData?.result ?? null);
                setStatus(responseData?.status ?? null);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err);
                } else {
                    setError(new Error('An unknown error occurred'));
                }
            } finally {
                setLoading(false);
            }
        };

        fetchDataAsync();
    }, [url, options, loading, skip, authToken, skipAuth, isAuthenticatedFromCache]);

    // This might already have been protected by AuthWrapper and fetchData
    // This happens on FE without triggering api call
    if (!skipAuth && !isAuthenticatedFromCache) {
        showToastMessage({ message: message('Form.Msg.FailureAuth'), type: 'error' });
        navigateToLogin();
        clearUserCache();
    }

    return { data, status, loading: Boolean(loading), error };
};

export default useAxiosFetch;
