import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { styled as mStyled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import TableComponent from '../TableComponent';
import { type ColumnData } from '../../../types';
import { type ObjData } from '../../../types';
import { Color } from '../../../constants';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';

const StyledContainer = styled.div`

`;

const StyledToolbar = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const StyledButtonGroup = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Search = mStyled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: 'auto',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const SearchIconWrapper = mStyled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = mStyled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '22ch',
            },
        },
    },
}));

interface SearchComponentProps {
    onChange: (value: string) => void;
}

const SearchComponent = ({ onChange }: SearchComponentProps) => {
    const [value, setValue] = useState<string>('');
    const message = useTranslatedMessage();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const currValue = event.target.value;
        setValue(currValue);
        onChange(currValue);
    }

    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={message('Form.PlaceHolder.Search')}
                inputProps={{ 'aria-label': message('Form.PlaceHolder.Search') }}
                value={value}
                onChange={handleOnChange}
            />
        </Search>
    );
}

interface SearchableTableProps {
    columns: ColumnData[];
    rows: ObjData[];
    searchKeys: string[];
    onRowClick: (rowIndex: number) => void;
    defaultOrderBy?: {
        columnId: string;
        direction: 'asc' | 'desc';
    }
}

const SearchableTable = (props: SearchableTableProps) => {
    const { defaultOrderBy, rows, columns, searchKeys, onRowClick } = props;
    const [keyword, setKeyword] = useState<string>('');
    const [editing, setEditing] = useState<boolean>(false);
    const [selected, setSelected] = useState<readonly number[]>([]);
    const message = useTranslatedMessage();

    const filteredRows = !Boolean(keyword) ? rows : rows.filter((row) => {
        return searchKeys.some((key) => (row[key] as string).includes(keyword));
    });

    const numSelected = selected.length;

    const handleRowClick = (event: React.MouseEvent<unknown>, rowIndex: number) => {
        onRowClick(rowIndex);
    }

    return (<StyledContainer>
        <StyledToolbar>
            <StyledButtonGroup>
                {/* Temporarily disable edit buttton, hold until functionality is in full swing */}
                <Button disabled sx={{ marginLeft: '0', paddingLeft: '0', minWidth: 40, justifyContent: 'left', color: Color.PRIMARY_FONT_DARK_GREY, fontWeight: 700 }} onClick={() => setEditing(!editing)}>{editing ? message('Form.Button.Cancel') : message('Form.Button.Edit')}</Button>
                <SearchComponent onChange={(value) => setKeyword(value)} />
            </StyledButtonGroup>
            <StyledButtonGroup>
                {editing && numSelected > 0 ?
                    (
                        <>
                            <Typography
                                sx={{ flex: '1 1 100%', whiteSpace: 'nowrap' }}
                                color="inherit"
                                component="div"
                            >
                                {message('Form.Field.SelectedRowNumber', { num: numSelected })}
                            </Typography>
                            <Button sx={{ marginLeft: '10px' }} variant='contained' color='error' fullWidth startIcon={<DeleteIcon />}>删除</Button>
                        </>
                    ) : null
                }
            </StyledButtonGroup>
        </StyledToolbar>
        <TableComponent columns={columns} rows={filteredRows} selected={selected} setSelected={setSelected} editing={editing} handleRowClick={handleRowClick} defaultOrderBy={defaultOrderBy} />
    </StyledContainer>)
}

export default SearchableTable; 