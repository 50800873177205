import { useEffect, useRef, useState } from "react";
import styled from '@emotion/styled/macro';
import { useTranslatedMessage } from "../../../hooks/use-translated-message";
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import AddressElement from "./AddressElement";
import { useParams } from "react-router-dom";
import { useDictOrderAPI } from "../../../hooks/use-dict-order-api";
import Template from "../Template";
import { Address, AddressType, FormType, StatusCode, type DictOrderFull, type DictOrderWithUserInfo, type FormError } from "../../../types";
import HorizontalField from "../../HorizontalField";
import LoadingButton from "../../LoadingButton";
import Accordion from '../../Accordion';
import { getSubscriptionLabelKeyById, getSubscriptionDurationNameByMonthNum, getIndustryLabelById, getLocaledAbsolutePath, Path, getIndustryTitleById } from '../../../constants';
import { useIntl } from "react-intl";
import { useUserInfoContext } from "../../../hooks/use-user-info-context";
import PayDialog from "./PayDialog";
import PhoneField from '../../PhoneField';
import { getRoundedPriceAmount, getRoundedPriceLabel } from "../../../utils/calculate-price";
import { DEFAULT_LOCALE } from "../../../translations";
import EmailField, { StandardReadonlyEmailField } from "../../auth/EmailField";
import { updateFormError, validateOrderFormFields, validateFieldValue, validateAddressField } from "../../../utils/validate-field-value";
import { useMessageContext } from "../../../hooks/use-message-context";
import { useUserAPI } from "../../../hooks/use-user-api";
import { CountryType, CountryCode } from '../../../constants/country';
import CountrySelect, { StandardCountrySelect } from "../../CountrySelect";
import FormTextField from "../../FormTextField";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { getPaymentSuccessPath } from "../../../utils/url-path";

const StyledBox = styled(Box)`
    padding: 8px 16px;
`;

const StyledField = styled(HorizontalField)`
    .MuiGrid-container {
        margin: 16px 8px !important;
    }
`;

const isOrderChanged = (orderInfo: DictOrderWithUserInfo, orderFull: DictOrderFull) => {
    let isSame = true;
    for (const key of Object.keys(orderInfo)) {
        if (orderInfo[key] !== orderFull[key]) {
            isSame = false;
        }
    }

    return !isSame;
}

const Checkout = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const { placeOrder, updateOrder } = useDictOrderAPI();
    const isRenewRef = useRef<boolean | undefined>();
    const [duplicateDictIds, setDuplicateDictIds] = useState<string[]>([]);
    const [showRenewDialog, setShowRenewDialog] = useState(false);
    const { getDefaultAddress } = useUserAPI();
    const { user } = useUserInfoContext();
    const { showToastMessage } = useMessageContext();
    const [fullName, setFullName] = useState<string>('');
    const [country, setCountry] = useState<CountryCode | null>(user?.country as CountryCode ?? null);
    const [defaultAddress, setDefaultAddress] = useState<Address | null>();
    const [addrObj, setAddrObj] = useState<StripeAddressElementChangeEvent>();
    const [orderPlacing, setOrderPlacing] = useState(false);
    // orderFull is fetched after order is placed, and attached to orderFullRef
    // it is not set to a React state, so that dialog can be triggered without a rerender
    const orderFullRef = useRef<DictOrderFull | null>(null);
    const [formError, setFormError] = useState<FormError>({});
    const [isAutoRenew, setIsAutoRenew] = useState(false);
    const [checkoutOpen, setCheckoutOpen] = useState(false);
    const params = useParams();
    const formRef = useRef<HTMLFormElement>(null);
    const productId = params?.productId ?? '';
    const subscriptionType = params?.subscriptionType ?? '';
    const activeMonth = params?.activeMonth ?? '';
    const baseCharge = getRoundedPriceAmount(+(params?.basePrice ?? ''));
    // TODO: When tax is needed, add the following code.
    // const baseChargeLabel = getRoundedPriceLabel(baseCharge);
    // const taxCharge = getRoundedPriceAmount(baseCharge * 0, 2);
    const taxCharge = 0;
    // const taxChargeLabel = getRoundedTaxLabel(taxCharge);
    const totalCharge = getRoundedPriceAmount(baseCharge + taxCharge);
    const totalChargeLabel = getRoundedPriceLabel(totalCharge);

    const dictTypes = (params?.dictTypes ?? '').split(',');
    const dictTypesLabel = dictTypes.map((dictType) => getIndustryLabelById(+dictType, locale)).join(", ");

    const accordionItems = [{
        id: "basicInfo",
        label: message('Order.BasicInfo')
    }, {
        id: "contactInfo",
        label: message('Order.ContactInfo'),
        icon: <Tooltip title={message('Order.ContactInfoTooltip')} placement={"top"}>
            <InfoIcon sx={{ fontSize: '1rem', ml: 0.5 }} />
        </Tooltip>

    }];

    // Handles two separate logic, both depending on the same variables:
    // 1. sync default address from api
    // 2. sync user's country to CountrySelect
    useEffect(() => {
        getDefaultAddress(user?.id).then((address: Address | null) => {
            // In our registration flow, it is impossible that country with default address has a different country
            // However, if that happens, do not sync default address
            if (address?.country === user?.country) {
                setDefaultAddress(address);
            }
        });

        if (user?.country) {
            setCountry(user?.country as CountryCode ?? null);
        }

    }, [user, getDefaultAddress])

    // We need to closely monitor country change in both selects so that user outside of US is always using CountrySelect
    // Both country selects need to be synced due to their tight interaction
    // country => defaultAddress.country sync is handled at handleOnCountryChange
    // here we handle defaultAddress.country => country sync
    useEffect(() => {
        if (!defaultAddress) return;
        if (country === defaultAddress.country) return;
        setCountry(defaultAddress.country as CountryCode ?? null);

    }, [country, defaultAddress]);

    const stripeAddressInfo = addrObj?.value;
    const stripeAddress = stripeAddressInfo?.address;
    const isUS = country === 'US';

    // Sync validation status as address change
    useEffect(() => {
        // TODO: For address outside of US and CN, the current validation might fail in requiring wrong fields
        const result = isUS ? validateAddressField(addrObj?.value ?? {}, message) : { status: StatusCode.SUCCESS, message: "" };
        setFormError((currFormError) => updateFormError(currFormError, "address", result.message))
    }, [addrObj, message, isUS])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        // TODO: Seems like the Confirm button is not responding to the loading status update
        setOrderPlacing(true);

        const formData = new FormData(event.currentTarget);
        const mobilephoneTyped = (formData.get('mobilephone') || '').toString();

        const orderInfo: DictOrderWithUserInfo = {
            dictTypeIds: dictTypes,
            dictTypes,
            productId,
            subscriptionTypeId: subscriptionType,
            subscriptionType,
            activeMonth,
            activeMonthNum: activeMonth,
            baseCharge,
            taxCharge,
            totalCharge,
            currencyCode: 2,
            ...isUS ? {
                invoiceTitle: stripeAddressInfo?.name ?? '',
                billingAddressLine1: stripeAddress?.line1 ?? '',
                billingAddressLine2: stripeAddress?.line2 ?? '',
                billingAddressLine3: '',
                billingAddressDistrict: '',
                billingAddressCity: stripeAddress?.city ?? '',
                billingAddressState: stripeAddress?.state ?? '',
                billingAddressCountry: stripeAddress?.country ?? '',
                billingAddressZipcode: stripeAddress?.postal_code ?? '',
                userPhone: mobilephoneTyped,
                userName: stripeAddressInfo?.name ?? '',
            } : {
                invoiceTitle: '',
                billingAddressLine1: '',
                billingAddressLine2: '',
                billingAddressLine3: '',
                billingAddressDistrict: '',
                billingAddressCity: '',
                billingAddressState: '',
                // prioritize user selection
                billingAddressCountry: (formData.get('country') as string || country) || '',
                billingAddressZipcode: '',
                userPhone: mobilephoneTyped,
                userName: (formData.get('name') || '').toString(),
            },
            // For logged in user, the Email component is readonly and formData is not accesible
            userEmail: user ? user.email : (formData.get('email') || '').toString(),
            userPhone: mobilephoneTyped,
            userId: +(user?.id ?? ''),
            stripeUserId: user?.stripeId ?? '',
            isTrial: false,
            isAutoRenew,
            locale,
            isRenew: isRenewRef.current
        };

        const currFormError = validateOrderFormFields(orderInfo, addrObj?.value ?? {}, message);

        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            showToastMessage({ message: message('Form.Msg.PlsChangeOrderInfo'), type: 'warning' });
            setOrderPlacing(false);
            return;
        }

        try {
            // To avoid duplicate order if user closes the payment dialog and pay again.
            // However, user might have changed information after closing the dialog and opens again
            // Need to update order information after payment confirmation
            if (!orderFullRef.current) {
                const { success, duplicates = [] } = await placeOrder(orderInfo, setOrderPlacing, (orderFull: DictOrderFull) => {
                    orderFullRef.current = orderFull;
                });
                if (duplicates.length > 0) {
                    setDuplicateDictIds(duplicates);
                    setShowRenewDialog(true);
                    setOrderPlacing(false);
                    return;
                }
                if (!success) {
                    setOrderPlacing(false);
                    return;
                }
            } else {
                if (isOrderChanged(orderInfo, orderFullRef.current)) {
                    // TODO: Update order with the latest user input
                    // update the latest user selection to ref value that is used by PayDialog
                    orderFullRef.current = {
                        ...orderFullRef.current,
                        ...orderInfo
                    };
                    const isUpdated = await updateOrder(orderFullRef.current, setOrderPlacing);
                    if (!isUpdated) {
                        setOrderPlacing(false);
                        return;
                    }
                }
            }

            // We need to wait for the next tick to trigger payment dialog open,
            // since order information will be updated in the callback of placeOrder
            setTimeout(() => {
                setCheckoutOpen(true);
            }, 500);
        } catch (e) {

        }

        // When order fails or when order is not placed because of duplicate operation, loading indicator needs to be ultimately reset
        setOrderPlacing(false);
    };

    const handleNameChange = (e: any) => {
        const name = e.target.value;
        setFullName(name);
        setDefaultAddress((currAddr) => ({
            ...currAddr,
            fullName
        } as Address));
    }

    const handleOnCountryChange = (selectedCountry: CountryType | null) => {
        const currCountryCode = selectedCountry?.code as CountryCode;
        setCountry(currCountryCode ?? null);

        setDefaultAddress((currAddr) => ({
            ...currAddr,
            country: currCountryCode
        } as Address));
    }

    // Now only the name field trigger a form change event.
    // CountrySelect onChange event is handled separately
    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const status = validateFieldValue({ name, value, formType: FormType.ORDER, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message))
    }

    const onAddressChange = (obj: StripeAddressElementChangeEvent) => {
        setAddrObj(obj);
    }

    const DynamicEmailField = Boolean(user) ? StandardReadonlyEmailField : EmailField;
    // When user does not have a country set or when user is not logged in, use CountrySelect, otherwise readonly
    const DynamicCountrySelect = Boolean(user?.country) ? StandardCountrySelect : CountrySelect;

    const addressError = formError?.["address"] ?? "";

    return (
        <Template title={message('Product.Checkout')}>
            <Box ref={formRef} component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate sx={{ mt: 1 }}>
                <Accordion isSingleExpand={false} items={accordionItems} selectedIds={["basicInfo", "contactInfo", "paymentInfo"]}>
                    <StyledBox>
                        <StyledField
                            id={"account"}
                            label={message('Form.Account.SubscriptionType')}
                            value={message(getSubscriptionLabelKeyById(subscriptionType))}
                        />
                        <StyledField
                            id={"dictTypes"}
                            label={message('Order.SelectedDictTypes')}
                            value={dictTypesLabel}
                        />
                        <StyledField
                            id={"activeMonth"}
                            label={message('Order.ValidPeriod')}
                            value={message(getSubscriptionDurationNameByMonthNum(+activeMonth))}
                        />
                        {/* // TODO: There is no tax currently */}
                        {/* <StyledField
                            id={"base-price"}
                            label={message('Order.Price.BasePrice')}
                            value={baseChargeLabel}
                        />
                        <StyledField
                            id={"taxCharge"}
                            label={message('Order.Price.TaxCharge')}
                            value={taxChargeLabel}
                        /> */}
                        <StyledField
                            id={"totalCharge"}
                            label={message('Order.Price.TotalPrice')}
                            value={totalChargeLabel}
                        />
                        <StyledField
                            id={"isAutoRenew"}
                            label={message('Form.Account.AutoRenew')}
                        >
                            <RadioGroup
                                row
                                aria-labelledby="isAutoRenew"
                                name="isAutoRenew"
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setIsAutoRenew(value === "yes");
                                }}
                                defaultValue={'no'}
                            >
                                <FormControlLabel
                                    value={"no"}
                                    control={<Radio size='small' />}
                                    label={message('General.No')}
                                />
                                <FormControlLabel
                                    value={"yes"}
                                    control={<Radio size='small' />}
                                    label={message('General.Yes')}
                                />
                            </RadioGroup>
                        </StyledField>
                    </StyledBox>
                    <StyledBox>
                        {!isUS && <HorizontalField sx={{ mb: 1.5 }} keepStacked={!Boolean(user)} label={message('Form.Profile.Name')}>
                            <FormTextField
                                name="name"
                                noLabel
                                label=""
                                autoComplete='name'
                                autoFocus
                                formError={formError}
                                initialValue={user?.name}
                                value={fullName}
                                onChange={handleNameChange}
                            />
                        </HorizontalField>}
                        <HorizontalField sx={{ mb: 1.5 }} keepStacked={!Boolean(user)} label={message('Form.Profile.Email')}>
                            <DynamicEmailField noLabel formError={formError} readonly={Boolean(user)} initialValue={user?.email} />
                        </HorizontalField>
                        {/* We pass in allowed country for Stripe AddressElement */}
                        {/* Now always show CountrySelect since it is the only source to choose country, regardless of whether being US */}
                        {/* Allow users not logged in or without country specified during registration to select country here */}
                        <HorizontalField sx={{ mb: 1.5 }} keepStacked={!Boolean(user)} label={message('Form.Profile.Country')}>
                            <DynamicCountrySelect noLabel formError={formError} required onChange={handleOnCountryChange} readonly={Boolean(user?.country)} initialValue={user?.country as CountryCode} />
                        </HorizontalField>
                        <HorizontalField sx={{ mb: 1.5 }} keepStacked={!Boolean(user)} label={message('Form.Profile.Mobile')}>
                            <PhoneField
                                name='mobilephone'
                                formError={formError}
                                noLabel
                                country={country}
                                initialCountry={country}
                                initialValue={user?.mobilephone}
                            />
                        </HorizontalField>
                        {/* TODO: Include organization name. Add tooltip showing what kind of invoice we provide */}
                        {isUS && <AddressElement user={user} onChange={onAddressChange} defaultAddress={{ ...defaultAddress, addressType: AddressType.BILLING, autoId: 0 }} country={country} />}
                        {Boolean(addressError) ? <Box>{
                            Array.isArray(addressError)
                                ? addressError.map(errorItem => <FormHelperText sx={{ mt: 0, ml: 2 }} key={errorItem} error>{errorItem}</FormHelperText>)
                                : <FormHelperText sx={{ mt: 0, ml: 2 }} error>{addressError}</FormHelperText>
                        }</Box> : null}
                    </StyledBox>
                </Accordion>
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={Object.keys(formError).length > 0 || orderPlacing}
                    loading={orderPlacing}
                >
                    {message('Order.Button.ConfirmPurchase')}
                </LoadingButton>
            </Box>
            {/* If checkoutOpen is used to guard the dialog, multiple create intent requests
            will be triggered if user close and open dialog again */}
            {orderFullRef.current !== null && <PayDialog
                message={message}
                orderFull={{
                    ...orderFullRef.current,
                    stripeUserId: user?.stripeId ?? ''
                }}
                open={checkoutOpen}
                setOpen={setCheckoutOpen}
                paymentSuccessUrl={getPaymentSuccessPath(
                    orderFullRef.current.orderNo,
                    orderFullRef.current.orderId,
                    false,
                    isAutoRenew,
                    locale
                )}
            />}
            <Dialog
                open={showRenewDialog}
                onClose={() => setShowRenewDialog(false)}
            >
                <DialogTitle id="alert-dialog-title">
                    {message('Order.Checkout.ConfirmRenewDialogTitle')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message('Order.Checkout.ConfirmRenewDialogContent')}
                        <Box sx={{ mt: 2 }}>
                            {duplicateDictIds.map(id => getIndustryTitleById(+id, locale))}
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowRenewDialog(false)}>{message('Form.Button.Cancel')}</Button>
                    <Button onClick={() => {
                        isRenewRef.current = true;
                        setShowRenewDialog(false);
                    }} autoFocus>
                        {message('Form.Button.Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Template>
    )
}

export default Checkout;