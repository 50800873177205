import SearchableTable from './SearchableTable';
import { DataType, type ColumnData } from '../../../types';
import { useLoaderData } from 'react-router-dom';
import { type SearchHistoryData } from '../../../types';
import { Path, getLocaledAbsolutePath } from '../../../constants';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import { useIntl } from 'react-intl';

const SearchHistory = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const rows = useLoaderData() as SearchHistoryData[];
    const onRowClick = (rowIndex: number) => {
        const keyword = rows[rowIndex].keyword;
        window.open(`${getLocaledAbsolutePath(Path.SEARCH, locale)}/${keyword}`);
    }

    const columns: ColumnData[] = [
        {
            width: 200,
            label: message('Form.SearchWord'),
            id: 'keyword',
            dataType: DataType.STRING
        },
        {
            width: 100,
            label: message('Form.SearchNumber'),
            id: 'numSearch',
            dataType: DataType.NUMERIC
        },
        {
            width: 120,
            label: message('Form.AccessTime'),
            id: 'recentTime',
            dataType: DataType.DATE
        }
    ];

    const defaultOrderBy = {
        columnId: 'recentTime',
        direction: 'desc' as const
    }

    return <SearchableTable rows={rows} columns={columns} searchKeys={['keyword']} onRowClick={onRowClick} defaultOrderBy={defaultOrderBy} />
}

export default SearchHistory;
