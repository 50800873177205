import { getDictNameByIndustry, getIndustryLabelByIndustry } from "../constants";
import { TranslationMessage } from "../hooks/use-translated-message";
import { isChiWord } from "./sanitize-search-word";

export const getHomeSEO = (locale: string, industry: string) => {
    const isPro = Boolean(industry);
    const dictName = isPro ? getDictNameByIndustry(industry, locale) : "";
    const fullDictName = isPro
        ? locale === 'en'
            ? dictName + "Dictionary for Terminology Translation"
            : dictName
        : "";
    const industryLabel = getIndustryLabelByIndustry(industry, locale);
    const titleZH = `${dictName || "专业词典"}-学术词典-在线词典-在线翻译-SCIdict赛斯专业词典`;
    const titleEN = `${dictName || ""} Chinese-English Dictionary - A Dictionary for Terminology Translation - SCIDICT`;
    const descriptionForPro = isPro
        ? locale === 'en'
            ? `${fullDictName} is a product of SCIDICT targeting the domain of ${industryLabel}`
            : `${fullDictName}是SCIDICT赛斯专业词典专注于${industryLabel}领域的专业术语词典。`
        : "";
    const descriptionZH = `${descriptionForPro}SCIDICT赛斯专业词典是专为学术研究人员以及技术专家设计的中英专业术语搜索词典，致力于为细分专业领域的翻译提供参照，并同时促进各行各业人士的专业英语学习进阶。`;
    const descriptionEN = `${descriptionForPro}SCIDICT is a Chinese-English terminology dictionary designed to assist in translations in specialized fields. Its target audience includes scientific researchers, industrial professionals, professional translators, and graduate students.`;
    const keywordsForPro = isPro ? `${dictName}|` : "";
    const keywordsZH = `${keywordsForPro}专业词典|学术词典|科技词典|在线词典|在线翻译|SCIdict|赛斯专业词典`;
    const keywordsEN = `${keywordsForPro}Dictionary Pro | Chinese-English Dictionary of Science and Technology Translation | Terminology Dictionary | Chinese/English Translation | SCIDICT`;
    const title = locale === 'zh' ? titleZH : titleEN;
    const description = locale === 'zh' ? descriptionZH : descriptionEN;
    const keywords = locale === 'zh' ? keywordsZH : keywordsEN;
    return { title, description, keywords };
}

export const getSearchSEO = (keyword: string, locale: string, message: TranslationMessage) => {
    if (!keyword) return getHomeSEO(locale, "");
    const isChi = isChiWord(keyword);
    const tgtname = isChi ? message('SEO.EnglishTranslation') : message('SEO.ChineseTranslation');
    const tgtname2 = isChi ? message('SEO.EnglishEquivalent') : message('SEO.ChineseEquivalent');

    const titleZH = `${keyword}的${tgtname}、${tgtname2}-专业词典-学术词典-在线词典-在线翻译-SCIdict赛斯专业词典`;
    const titleEN = `${keyword}'s ${tgtname2}, ${tgtname} - SCIDICT - A Chinese-English Dictionary of Terminology Translation`;

    const descriptionZH = `${keyword}怎么翻译, 它的${tgtname}、${tgtname2}，${keyword}的相关专业术语翻译, 来自SCIdict赛斯专业词典的翻译, SCIdict是专为学术研究人员以及技术专家设计的中英专业术语搜索词典，致力于为细分专业领域的翻译提供参照, 并同时促进各行各业人士的专业英语学习进阶。`
    const descriptionEN = `How to translate ${keyword}? What is its ${tgtname2} or ${tgtname}? What are relevant terms of ${keyword}. Check search results at SCIDICT, a Chinese-English dictionary of terminology translations designed to assist in translations in specialized fields, with its target audience including scientific researchers, industrial professionals, professional translators, and graduate students.`;

    const keywordsZH = `${keyword},${tgtname},专业翻译,${tgtname2},专业术语,SCIdict赛斯专业词典`;
    const keywordsEN = `${tgtname} or ${tgtname2} of ${keyword}, specialized field translation, terminology translation, SCIDICT`;

    const title = locale === 'zh' ? titleZH : titleEN;
    const description = locale === 'zh' ? descriptionZH : descriptionEN;
    const keywords = locale === 'zh' ? keywordsZH : keywordsEN;

    return {
        title,
        description,
        keywords
    }
}