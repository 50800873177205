import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const Accordion = styled((props: MuiAccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface AccordionItem {
    id: string;
    label: string;
    icon?: React.ReactNode;
    linkNode?: React.ReactNode;
}

interface AccordionComponentProps {
    items: AccordionItem[];
    children: React.ReactNode[];
    selectedIds: string[];
    isSingleExpand: boolean;
}

const getIdsStr = (ids: string[]) => ids.sort().join(",");

export default function AccordionComponent({ isSingleExpand, items, children, selectedIds }: AccordionComponentProps) {
    const defaultSelectedIdsStr = getIdsStr(selectedIds);
    const [expanded, setExpanded] = React.useState<string>(defaultSelectedIdsStr);
    const expandedSet = React.useMemo(() => new Set(expanded.split(',')), [expanded]);

    // Set expanded item on load once
    React.useEffect(() => {
        if (items.length === 0) return;

        // Set expanded id to selectedId, prioritized
        if (defaultSelectedIdsStr !== expanded) {
            setExpanded(defaultSelectedIdsStr);
            return;
        }
    }, []);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            if (isSingleExpand) {
                setExpanded(newExpanded ? panel : "");
            } else {
                const currIds: string[] = expanded.split(",");
                const newIds = [...currIds];
                const index = currIds.indexOf(panel);
                if (index >= 0) {
                    newIds.splice(index, 1);
                } else {
                    newIds.push(panel);
                }
                setExpanded(getIdsStr(newIds))
            }

        };

    return (
        <div>
            {items.map((item: AccordionItem, idx: number) => (
                <Accordion key={item.id} expanded={expandedSet.has(item.id)} onChange={handleChange(item.id)}>
                    <AccordionSummary aria-controls={`${item.id}-content`} id={`${item.id}-header`}>
                        <Stack sx={{
                            justifyContent: 'space-between',
                            width: '100%',
                            alignItems: 'center'
                        }} direction='row'>
                            <Stack direction='row'>
                                <Typography>{item.label}</Typography>
                                {item.icon ?? null}
                            </Stack>
                            {item.linkNode ?? null}
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        {children[idx]}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}
