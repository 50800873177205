import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { styled as mStyled, Theme, useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBox from '@mui/material/Checkbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import { useSearchDataContext } from '../../hooks/use-search-data-context';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { parseResultData } from '../../utils/search-result-builder';
import { highlightKeyword } from '../../utils/highlight-content';
import { type Entry, StatusCode, IndustryID, TabIndustryID, NoIndustryID, EnrichedTextMatchItem } from '../../types';
import { Color, Font, getIndustryBadgeById, getIndustryLabelById } from '../../constants';
import FloatingToolbar from './FloatingToolbar';
import { TranslationMessage, useTranslatedMessage } from '../../hooks/use-translated-message';
import { useIntl } from 'react-intl';
import { Search } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import useNavigate from '../../hooks/use-navigate';
import { sanitizeSearchWordForUrl } from '../../utils/sanitize-search-word';
import { getSearchUrl } from '../../utils/get-search-url';
import { getUserDictTypeIds } from '../../utils/parse-user-data';
import { useMessageContext } from '../../hooks/use-message-context';
import RecentUpdates from './RecentUpdates';

interface WordProps {
    isPro: boolean;
    size?: string;
    noWrap?: boolean;
    highlight?: boolean;
}

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const PhraseRow = styled(Row)`
    flex-wrap: wrap;
    background: #eee;
    margin: 10px 0;
`;

// There is no chance to set horizontal paddings to ResultWrapper since it is responsive with a width percentage
const ResultWrapper = mStyled('div')(({ theme }) => ({
    ...theme.mixins.responsiveMainWidth({ theme }),
    padding: '10px 0',
    background: Color.OFFWHITE2,
    borderRadius: '18px',
    color: Color.PRIMARY_FONT_BLACK
}));

// Add margin to ResultWrapper by setting horizontal margin at ResultInner
const ResultInner = styled.div`
    margin: 0 10px;
`;

const StyledSrcTgtWordWrapper = styled(Row)`
    font-weight: 700;
    font-size: ${Font.FONT_SIZE_MEDIUM_LARGE};
    color: ${Color.PRIMARY_BLUE_MEDIUM};
`;

const StyledWord = styled.div<WordProps> `
    padding: 10px;
    font-size: ${(props) => props.size || Font.FONT_SIZE_MEDIUM};
    white-space: ${(props) => props.noWrap ? 'nowrap' : 'normal'};

    ${(props) => props.highlight ? css`
    span.primary {
        color: ${Color.PRIMARY_MATCH_FONT};
        font-weight: 700;
    }
    `: ''}

    ${(props) => props.highlight && props.isPro ? css`
    span.secondary {
        color: ${Color.SECONDARY_MATCH_FONT};
        font-weight: 500;
    }
    `: ''}


`;

const StyledSrcWord = styled(StyledWord)`
`;

const StyledTgtWord = styled(StyledWord)`
    position: relative;
    padding: 0;
    span {
        color: ${Color.PRIMARY_FONT_DARK_GREY};
        display: flex;
        align-items: center;
    }
    font-size: ${Font.FONT_SIZE_MEDIUM_LARGE};
`;

const StyledAllTgtWordsWrapper = styled.div`
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
`;

const StyledTotal = styled.div`
    padding: 10px;
    text-align: left;
    font-size: ${Font.FONT_SIZE_MEDIUM};
    font-weight: 300;
`;

const SupplementalInfo = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: ${Font.FONT_SIZE_MEDIUM};
        font-weight: 300;
    }
    
`;

const StyledEntriesWrapper = styled.div``;

const StyledPhrase = styled(StyledWord)`
    padding: 10px 10px;
`;

const StyledSrcPhrase = styled(StyledPhrase)`
    // font-weight: 700;
    color: ${Color.PRIMARY_FONT_DARK_GREY};
    margin-right: 20px;
`;

const StyledTgtPhrase = styled(StyledPhrase)`
    position: relative;
    cursor: default;
    padding: 10px 5px;
    ::after {
        content: ';'
    }
`;

const StyledTgtPhraseWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 5px;
`;

const SrcWord = (props: any) => <StyledSrcWord isPro={props.isPro} size={Font.FONT_SIZE_MEDIUM_LARGE} noWrap={true}>
    {props.children}
</StyledSrcWord>

interface TgtWordProps {
    highlight?: boolean;
    isFullMatch: boolean;
    isPro: boolean;
    content: string | JSX.Element | null;
    source: string;
}

const TgtWord = (props: TgtWordProps) => {
    const { highlight, content, source, isFullMatch, isPro } = props;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <StyledTgtWord
            isPro={isPro}
            highlight={highlight}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="tgt-word"
        >
            <Chip
                sx={{
                    margin: '0 5px 10px',
                    // Make sure chip is blue only for full matched target words
                    ...highlight && (isFullMatch
                        ? {
                            'span': {
                                color: Color.PRIMARY_MATCH_FONT,
                            },
                        }
                        : {
                            'svg': {
                                color: Color.SECONDARY_MATCH_CHECK_MARK
                            },
                            // make sure highlighted text span inside Chip has spaces on both sides
                            // 'span.secondary,span.text': { ml: 0.25, mr: 0.25 }
                            'span.space': { ml: 0.25, mr: 0.25 }
                        }
                    )
                }}
                label={<>
                    {highlight && (isPro || isFullMatch) && <CheckCircleIcon />}
                    {content}
                </>}
            />
            {isHovered && <FloatingToolbar content={`${source}   ${content}`} />}
        </StyledTgtWord>
    )
}

const SrcPhrase = (props: any) => <StyledSrcPhrase isPro={props.isPro} noWrap={true} highlight={props.highlight}>
    {props.children}
</StyledSrcPhrase>

interface TgtPhraseProps {
    children: JSX.Element;
    content: string;
    highlight?: boolean;
    isPro: boolean;
}

const TgtPhrase = (props: TgtPhraseProps) => {
    const { content, children, isPro } = props;
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <StyledTgtPhrase
                className='tgt-phrase'
                isPro={isPro}
                highlight={props.highlight}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {children}
                {isHovered && <FloatingToolbar content={content} />}
            </StyledTgtPhrase>
        </>

    );
}

interface TooltipButtonProps {
    message: TranslationMessage;
    industryId: TabIndustryID;
    srcWord: string;
    locale: string;
    handleOnClick: (industryId: string) => void;
    hasEndIcon?: boolean;
    setExpanded?: (expanded: boolean) => void;
}

const TooltipButton = ({
    message,
    industryId,
    srcWord,
    locale,
    handleOnClick,
    hasEndIcon,
    setExpanded,
}: TooltipButtonProps) => {
    if (industryId === "" || industryId === "all") return null;

    return (
        <Tooltip title={message('Search.SearchInSpecialty', { specialty: getIndustryLabelById(+industryId, locale), word: srcWord })}>
            <Button
                size='small'
                color='primary'
                variant='contained'
                sx={{ transform: 'scale(0.7)', width: '100px' }}
                startIcon={<Search />}
                onClick={() => handleOnClick(industryId)}
                endIcon={setExpanded && hasEndIcon ? <ExpandMoreIcon onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setExpanded(true);
                }} /> : null}
            >
                {getIndustryBadgeById(+industryId, locale)}
            </Button>
        </Tooltip>
    )
}

interface ProBadgeProps {
    srcWord: string;
    tabIndustryId: TabIndustryID;
    industries: IndustryID[];
    userDictTypeIds: string[];
    locale: string;
    isPro: boolean;
    isLoggedIn: boolean;
    message: TranslationMessage
}

const ProBadge = ({ userDictTypeIds, isPro, isLoggedIn, srcWord, tabIndustryId, industries, locale, message }: ProBadgeProps) => {
    const { navigateWithIndustry, navigateToProductProdict, navigateToAccount, navigateToLogin } = useNavigate();
    const searchUrl = getSearchUrl(sanitizeSearchWordForUrl(srcWord));
    const [expanded, setExpanded] = useState(false);
    const { openDialog } = useMessageContext();

    // When tabIndustryId === "", it's on the ordinary search tab. Pro users can also be on this tab
    // The single default industry visible on Badge before user interaction
    // Ideally, singleIndustryId should not be empty string
    const singleIndustryId = ([NoIndustryID.EMPTY, NoIndustryID.ALL] as TabIndustryID[]).includes(tabIndustryId) ? (industries.length > 0 ? industries[0] : NoIndustryID.EMPTY) : tabIndustryId;

    // It turns out the industry info coming from BE is for the source phrase only.
    // industry info for the target phrase is not concerned.
    // We may leverage the same button for similarity search

    // For more than 4 industries, the buttons are annoying
    const hasMoreIndustries = industries.length > 1 && industries.length < 5;
    const handleOnClick = (currIndustry: string) => {
        if (userDictTypeIds.includes(currIndustry)) {
            navigateWithIndustry(searchUrl, currIndustry);
        } else {
            const contentMsgKey = isPro ? 'Search.DictTypeNoAccessInstruction.Pro' : 'Search.DictTypeNoAccessInstruction.Guest';
            const isLoggedInNonPro = isLoggedIn && !isPro;

            openDialog({
                content: message(contentMsgKey, { dictname: getIndustryBadgeById(+currIndustry, locale) }),
                confirmButtonText: message('Form.Account.Purchase'),
                onConfirm: navigateToProductProdict,
                ...isLoggedInNonPro
                    ? {}
                    : isPro ? {
                        cancelButtonText: message('Order.Button.GoToAccount'),
                        onCancel: navigateToAccount,
                    } : {
                        cancelButtonText: message('Form.Auth.SignIn'),
                        onCancel: navigateToLogin,
                    }

            })
        }
    }

    // TODO: use animation to expand buttons
    return hasMoreIndustries && expanded
        ? <Fade in={hasMoreIndustries && expanded} timeout={1500}>
            <Box width={'100px'}>
                {industries.map((industry) => (
                    <TooltipButton
                        message={message}
                        locale={locale}
                        industryId={industry}
                        srcWord={srcWord}
                        handleOnClick={handleOnClick}
                    />
                ))}
            </Box>
        </Fade>
        : <TooltipButton
            message={message}
            locale={locale}
            industryId={singleIndustryId}
            srcWord={srcWord}
            handleOnClick={handleOnClick}
            setExpanded={setExpanded}
            hasEndIcon={hasMoreIndustries}
        />

}

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }: { theme: Theme; expand: boolean }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const SearchResult = () => {
    const isStaticEnv = process.env.REACT_APP_BUILD_ENV === 'static';
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const theme = useTheme();
    const {
        isHighlightKeyword,
        setIsHighlightKeyword,
        expandTarget,
        setExpandTarget,
        showAllTarget,
        hideWeakMatchedTarget,
        isProPlus = false,
        user,
        loading: userInfoLoading
    } = useUserInfoContext();
    const { loading, status, searchResult: data, keyword, industryId } = useSearchDataContext();
    const errorMessage = status?.status === StatusCode.FAIL ? status?.message : null;
    // Initially loading is undefined, we want to make it a loading state
    // to reduce the visual latency after initiating a search
    // When there's error, loading status can be stuck at undefined
    // TODO: Add test for these edge cases
    if ((loading || (loading === undefined && !errorMessage)) && Boolean(keyword)) {
        return <LinearProgress />;
    }

    if (userInfoLoading) return null;

    // Render useful error message to the end user in the cases below:
    // Extra long input keyword
    // Empty input keyword
    // Gibberish input keyword
    if (errorMessage) {
        return <SrcWord isPro={isProPlus}>
            {errorMessage}
        </SrcWord>
    }

    const hasNoData = !data;

    // For null data, rendering 0 results rather than nothing is more meaningful to user
    // if (hasNoData) {
    //     return null;
    // }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsHighlightKeyword(event.target.checked);
    };

    const resultData = errorMessage || hasNoData ? {
        total: 0,
        srcWords: [keyword],
        tgtWords: [],
        minVisibleTgtWords: [],
        entries: [],
    } : parseResultData({
        data,
        isPro: isProPlus,
        showAllTarget: Boolean(showAllTarget),
        hideWeakMatchTarget: Boolean(hideWeakMatchedTarget)
    });

    const { total, srcWords, tgtWords, minVisibleTgtWords, entries } = resultData;
    const srcWord = srcWords.join(' ');

    const visibleTgtWords: EnrichedTextMatchItem[] = expandTarget ? tgtWords : minVisibleTgtWords;
    const hasMoreTgtWords = tgtWords.length > minVisibleTgtWords.length;

    return (
        <div className="searchbody">
            <ResultWrapper>
                <ResultInner>
                    <StyledSrcTgtWordWrapper>
                        <SrcWord>
                            {srcWord}
                        </SrcWord>
                        <StyledAllTgtWordsWrapper className='tgt-words-wrapper'>
                            {visibleTgtWords.map((tgt) => {
                                const matched = expandTarget ? tgt.isMatched : true;
                                return (<TgtWord key={tgt.text} source={srcWord} content={tgt.html ?? tgt.text} highlight={matched} isFullMatch={tgt.matchScore === 1} isPro={isProPlus} />)
                            })}
                            {hasMoreTgtWords && <ExpandMore expand={Boolean(expandTarget)} theme={theme} onClick={() => setExpandTarget(!expandTarget)}>
                                <ExpandMoreIcon />
                            </ ExpandMore>}
                        </StyledAllTgtWordsWrapper>
                    </StyledSrcTgtWordWrapper>
                    <SupplementalInfo>
                        {/* We do not want it to flash between 0 and total */}
                        <StyledTotal>{loading === false && message('Form.Msg.XReturn', { total })}</StyledTotal>
                        <FormControlLabel control={<CheckBox checked={isHighlightKeyword} onChange={handleCheckboxChange} />} label={message('Form.Msg.HighlightWord')} />
                    </SupplementalInfo>
                    <StyledEntriesWrapper className='entry-wrapper'>
                        {entries.map((entry: Entry, index: number) => (
                            <PhraseRow key={`${entry.source}_${index}`} className='entry-row'>
                                <ProBadge
                                    tabIndustryId={industryId}
                                    srcWord={entry.source}
                                    industries={entry.industries}
                                    userDictTypeIds={getUserDictTypeIds(user?.dicttypeids)}
                                    locale={locale}
                                    isPro={isProPlus}
                                    isLoggedIn={+(user?.id ?? "") > 0}
                                    message={message}
                                />
                                <SrcPhrase isPro={isProPlus} highlight={isHighlightKeyword}>
                                    {highlightKeyword(entry.source, srcWord)}
                                </SrcPhrase>
                                <StyledTgtPhraseWrapper>
                                    {(entry.targetHtmls || [])
                                        .map((targetHtml, idx) =>
                                            <TgtPhrase
                                                key={`${entry.targets?.[idx]}-${idx}`}
                                                isPro={isProPlus}
                                                content={`${entry.source}   ${entry.targets?.[idx]}`}
                                                highlight={isHighlightKeyword}
                                            >
                                                {targetHtml}
                                            </TgtPhrase>
                                        )
                                    }
                                </StyledTgtPhraseWrapper>
                            </PhraseRow>
                        ))}
                    </StyledEntriesWrapper>
                    {isStaticEnv && <RecentUpdates />}
                </ResultInner>
            </ResultWrapper>
        </div >)
}

export default SearchResult;