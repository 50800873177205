import { getLocaledAbsolutePath, LocalStorageKey, Path } from "../constants";
import { getFromLocalStore } from "./local-storage";

const locale = getFromLocalStore(LocalStorageKey.LOCALE);

export const navigateToMain = () => {
    window.location.href = getLocaledAbsolutePath(Path.MAIN, locale);
}

export const navigateToLogin = () => {
    window.location.href = getLocaledAbsolutePath(Path.LOGIN, locale);
}
