import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import { TranslationMessage, useTranslatedMessage } from "../../../hooks/use-translated-message";
import Template from "../Template";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent, PaymentIntentResult, SetupIntent, SetupIntentResult } from "@stripe/stripe-js";
import { useUserInfoContext } from "../../../hooks/use-user-info-context";
import { stripePromise } from "../../../stripeConstant";
import { useParams, useSearchParams } from 'react-router-dom';
import { getPaidDollarAmountLabel } from "../../../utils/calculate-price";
import { useUserAPI } from "../../../hooks/use-user-api";
import AccountCard from "../../user-center/account/AccountCard";
import useNavigate from "../../../hooks/use-navigate";
import { LoadingPage } from "../../LoadingIndicator";
import { DictOrderFull } from "../../../types";
import { useDictOrderAPI } from "../../../hooks/use-dict-order-api";
import { getFromLocalStore } from "../../../utils/local-storage";
import { LocalStorageKey } from "../../../constants";

// See the following link for explanation of payment status
// https://docs.stripe.com/billing/subscriptions/overview
const getStatusMessage = (message: TranslationMessage, status: PaymentIntent.Status, isFuture: boolean) => {
    let key = '';
    switch (status) {
        case 'succeeded':
            key = isFuture ? 'Form.Msg.SetupSuccess' : 'Order.Checkout.PaymentSuccess';
            break;
        case 'processing':
            key = 'Order.Checkout.PaymentProcessing';
            break;
        case 'canceled':
            key = 'Order.Checkout.PaymentCanceled';
            break;
        case 'requires_action':
            key = 'Order.Checkout.PaymentRequiresAction';
            break;
        case 'requires_capture':
            key = 'Order.Checkout.PaymentRequiresCapture';
            break;
        case 'requires_confirmation':
            key = 'Order.Checkout.PaymentRequiresCapture';
            break;
        case 'requires_payment_method':
            key = 'Order.Checkout.PaymentRequiresPaymentMethod';
            break;
        default:
            break;
    }

    return key ? message(key) : '';
}

const PaymentSuccess = () => {
    const message = useTranslatedMessage();
    // user can be falsey since we allow unregistered user submitting orders
    const { user } = useUserInfoContext();
    const stripe = useStripe();
    const { refreshUser } = useUserAPI();
    const { fetchOrders } = useDictOrderAPI();
    const [searchParams] = useSearchParams();
    const [statusTitle, setStatusTitle] = useState('');
    const [messages, setMessages] = useState('');
    const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
    const { navigateToMain, navigateToAccount, navigateToRegister } = useNavigate();
    const [activeOrders, setActiveOrders] = useState<DictOrderFull[]>([]);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";

    const params = useParams();

    const isFuture = params?.isFuture === "1";
    const orderId = +(params?.orderId ?? 0);
    const orderNo = params?.orderNo;
    const userId = user?.id ?? "";

    const [stripeLoading, setStripeLoading] = useState(false);

    const currOrder = activeOrders.find(order => order.orderId === orderId);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        if (isFuture) {
            const clientSecret = searchParams.get('setup_intent_client_secret') ?? "";
            if (!clientSecret) return;
            setStripeLoading(true);
            stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }: SetupIntentResult) => {
                setStripeLoading(false);
                if (!setupIntent) return;
                const {
                    status
                }: SetupIntent = setupIntent;

                setStatusTitle(getStatusMessage(message, status, true));
                if (status === 'succeeded') {
                    setIsSuccess(true);
                    setMessages(message('Order.Checkout.SetupAutoRenewSuccessContent'));

                    // Make sure orders are fetched after successful payment, so information is up-to-date
                    fetchOrders({
                        userId,
                        isOnlyActive: true,
                        orderId
                    }, setOrdersLoading, setActiveOrders);
                }
            })

        } else {
            const clientSecret = searchParams.get('payment_intent_client_secret') ?? "";
            if (!clientSecret) return;

            setStripeLoading(true);
            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: PaymentIntentResult) => {
                setStripeLoading(false);
                if (!paymentIntent) return;
                const {
                    status, amount, currency
                } = paymentIntent;
                setStatusTitle(getStatusMessage(message, status, false));
                if (status === 'succeeded') {
                    setIsSuccess(true);
                    refreshUser();
                    setMessages(message('Order.Checkout.PaymentSuccessContent', { amount: getPaidDollarAmountLabel(amount, currency) }));

                    // Make sure orders are fetched after successful payment, so information is up-to-date
                    fetchOrders({
                        userId,
                        isOnlyActive: true,
                        orderId
                    }, setOrdersLoading, setActiveOrders);
                }
            })
        }

    }, [stripe, searchParams, message, refreshUser, isFuture, fetchOrders, orderId, userId]);

    return (
        <Template title={statusTitle} noBreadcrumb>
            {(stripeLoading || ordersLoading)
                ? <LoadingPage />
                : <Box>
                    <Box sx={{ mb: 1, mt: 2 }}>
                        {messages}
                    </Box>
                    {isSuccess === true && currOrder &&
                        <AccountCard
                            key={currOrder.orderId}
                            order={currOrder}
                            stripeUserId={user?.stripeId}
                            readonly
                            showDetails
                        />
                    }
                    {isSuccess === true && <Stack direction={'row'} justifyContent={'center'}>
                        {user || Boolean(currOrder?.userId)
                            ? <>
                                <Button color="primary"
                                    sx={{ margin: '24px 18px' }}
                                    variant="contained"
                                    onClick={() => navigateToMain({ hardRefresh: true, industry: industryInCache })}
                                >
                                    {message('Form.Button.ReturnHome')}
                                </Button>
                                <Button color="primary"
                                    sx={{ margin: '24px 18px' }}
                                    variant="contained"
                                    onClick={() => navigateToAccount(true)}>
                                    {message('Order.Button.GoToAccount')}
                                </Button>
                            </>
                            : <Button color="primary"
                                sx={{ margin: '24px 18px' }}
                                variant="contained"
                                onClick={() => navigateToRegister({
                                    email: currOrder?.userEmail,
                                    orderNo,
                                    orderId,
                                    isActivate: true
                                })}>
                                {message('Form.Auth.SignUpAndActivate')}
                            </Button>
                        }
                    </Stack>}
                </Box>
            }
        </Template>
    )
}

const PaymentSuccessWrapper = () => {
    return (<Elements stripe={stripePromise}>
        <PaymentSuccess />
    </Elements>)
}

export default PaymentSuccessWrapper;