import React, { useEffect, useState, SyntheticEvent, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PaymentIntentResult, SetupIntentResult, StripeElementsOptions } from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
    PaymentElementProps,
} from '@stripe/react-stripe-js';
import LoadingButton from "../../LoadingButton";
import { stripePromise } from '../../../stripeConstant';
import { TranslationMessage } from '../../../hooks/use-translated-message';
import { DictOrderFull } from '../../../types';
import { getRoundedPriceAmount } from '../../../utils/calculate-price';
import { Color } from '../../../constants';
import { usePaymentAPI } from '../../../hooks/use-payment-api';

interface CheckoutDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    orderFull: DictOrderFull;
    message: TranslationMessage;
    paymentSuccessUrl: string;
    isFuture?: boolean;
    isResumePay?: boolean;
}

interface CheckoutFormProps {
    message: TranslationMessage;
    errorMessage: string | null;
    setErrorMessage: (message: string | null) => void;
    clientSecret: string;
    orderFull: DictOrderFull;
    paymentSuccessUrl: string;
    createSubscription: (orderInfo: DictOrderFull) => Promise<string>;
    isFuture?: boolean;
}

const getBillingDetailFromOrder = (orderFull: DictOrderFull) => (
    {
        name: orderFull.userName,
        email: orderFull.userEmail,
        phone: orderFull.userPhone,
        address: {
            country: orderFull.billingAddressCountry,
            postal_code: orderFull.billingAddressZipcode,
            state: orderFull.billingAddressState,
            city: orderFull.billingAddressCity,
            line1: orderFull.billingAddressLine1,
            line2: orderFull.billingAddressLine2
        }
    }
);

// TODO: Optimize return url for subscription and auto activation and refresh
const CheckoutForm = ({ createSubscription, orderFull, message, errorMessage, setErrorMessage, clientSecret, paymentSuccessUrl, isFuture = false }: CheckoutFormProps) => {
    const stripe = useStripe();
    const elements = useElements();

    const [paying, setPaying] = useState(false);

    const paymentElementOptions: PaymentElementProps['options'] = {
        business: { name: "Cross Language LLC" },
        fields: {
            billingDetails: 'never'
        },
        defaultValues: {
            billingDetails: getBillingDetailFromOrder(orderFull)
        }
    }

    // retrieve payment intent for one-time payment
    useEffect(() => {
        if (!stripe) {
            return;
        }

        // for immediate subscription, this clientSecret is falsey
        // client secret for future subscription or future one-time payment is stored here from setup intent
        if (!clientSecret) {
            return;
        }

        if (isFuture) {
            console.log('retrieving setup intent');
            stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }: SetupIntentResult) => {
                switch (setupIntent?.status ?? "") {
                    case "succeeded":
                        break;
                    case "processing":
                        break;
                    case "requires_payment_method":
                        break;
                    default:
                        setErrorMessage(message('Order.Checkout.PaymentMethodError'));
                        break;
                }
            });
        } else {
            console.log('retrieving payment intent');
            stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }: PaymentIntentResult) => {
                switch (paymentIntent?.status ?? "") {
                    case "succeeded":
                        break;
                    case "processing":
                        break;
                    case "requires_payment_method":
                        break;
                    default:
                        setErrorMessage(message('Order.Checkout.PaymentMethodError'));
                        break;
                }
            });
        }
    }, [stripe, clientSecret, isFuture, setErrorMessage, message]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        try {
            setPaying(true);

            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();
            if (submitError) {
                // Show error to your customer
                setErrorMessage(submitError.message ?? null);
                return;
            }

            let result = null;

            if (isFuture) {
                console.log('confirming setup');
                result = await stripe?.confirmSetup({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: paymentSuccessUrl,
                        payment_method_data: {
                            billing_details: getBillingDetailFromOrder(orderFull)
                        }
                    },
                })
                    .then(function (result) {
                        if (result.error) {
                            // Inform the customer that there was an error.
                        }
                    });
            } else {
                if (orderFull.isAutoRenew) {
                    console.log('confirming subscription');
                    const subscriptionSecret = await createSubscription(orderFull);
                    result = await stripe?.confirmPayment({
                        elements,
                        clientSecret: subscriptionSecret,
                        confirmParams: {
                            return_url: paymentSuccessUrl,
                            payment_method_data: {
                                billing_details: getBillingDetailFromOrder(orderFull)
                            }
                        },
                    });
                } else {
                    console.log('confirming payment');
                    result = await stripe?.confirmPayment({
                        elements,
                        clientSecret,
                        confirmParams: {
                            return_url: paymentSuccessUrl,
                            payment_method_data: {
                                billing_details: getBillingDetailFromOrder(orderFull)
                            }
                        },
                    });
                }
            }

            const error = result?.error;

            if (!error) return;

            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (error.type === "card_error" || error.type === "validation_error") {
                setErrorMessage(error.message ?? null);
            } else {
                setErrorMessage(message('Order.Checkout.PaymentMethodError'));
            }
        } catch {
        } finally {
            setPaying(false);
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                // cannot disable button when there is error from server
                // user needs to try again
                disabled={!stripe || !elements || paying}
                loading={paying}
            >
                {message('Order.Pay')}
            </LoadingButton>

            {errorMessage && <div id="payment-message">{errorMessage}</div>}
        </form>
    )
}

const PayDialog = ({ message, open, setOpen, orderFull, paymentSuccessUrl, isFuture = false, isResumePay = false }: CheckoutDialogProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [clientSecret, setClientSecret] = useState("");
    const stripeFetchNoRef = useRef<{ oneTime: number, subscription: number }>({ oneTime: 0, subscription: 0 });
    const { fetchPaymentIntent, createPaymentIntent, fetchConfig, createSubscription, createSetupIntent } = usePaymentAPI();

    // orderFull is guranteed to be truethy, since it is awaited from the Checkout component
    // Make sure no unecessary reloads happen for stripe communication
    useEffect(() => {
        if (isResumePay) {
            fetchPaymentIntent(orderFull)
                .then((secret) => setClientSecret(secret));
            return;
        }
        if (stripeFetchNoRef.current.oneTime >= 1 && stripeFetchNoRef.current.subscription >= 1) return;

        if (isFuture) {
            // Assuming using setup intent for future subscription and one-time payment both fit the following scenario
            if (stripeFetchNoRef.current.subscription === 0 || stripeFetchNoRef.current.oneTime === 0) {
                console.log('creating setup intent');
                createSetupIntent(orderFull).then((secret) => {
                    setClientSecret(secret);
                });
                stripeFetchNoRef.current.subscription++;
                stripeFetchNoRef.current.oneTime++;
            }
            return;
        }

        if (orderFull.isAutoRenew === true && stripeFetchNoRef.current.subscription === 0) {
            console.log('fetching subscription config');
            fetchConfig();
            stripeFetchNoRef.current.subscription++;
        } else if (orderFull.isAutoRenew === false && stripeFetchNoRef.current.oneTime === 0) {
            console.log('creating payment intent');
            createPaymentIntent(orderFull)
                .then((secret) => setClientSecret(secret));
            stripeFetchNoRef.current.oneTime++;
        }
    }, [orderFull, fetchPaymentIntent, createPaymentIntent, createSetupIntent, fetchConfig, isFuture, isResumePay])

    const handleClose = (event: SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
            setErrorMessage(null);
            setClientSecret("");
        }
    };

    const elementsOptions: StripeElementsOptions = {
        mode: orderFull.isAutoRenew ? 'subscription' as const : 'payment' as const,
        // Stripe accepts cents amount, minimum usd amount is 50 cents
        amount: Math.round(getRoundedPriceAmount(orderFull.totalCharge) * 100),
        currency: 'usd',
        appearance: {
            variables: {
                colorPrimary: '#0570de',
                colorBackground: '#ffffff',
                colorText: '#30313d',
                colorDanger: Color.ERROR,
                colorDangerText: Color.ERROR,
                fontFamily: 'Ideal Sans, system-ui, sans-serif',
                spacingUnit: '2px',
                borderRadius: '4px',
            },
            rules: {
                ".Input": {
                    padding: '12.5px 14px',
                    margin: '4px 0',
                    borderColor: 'rgba(133, 133, 133, 0.5)',
                    transition: 'none'
                },
                ".Input:focus": {
                    boxShadow: 'none',
                    borderColor: 'rgb(118, 118, 118)',
                    borderWidth: '2px',
                    padding: '11.5px 13px'
                },
                ".Input:hover": {
                    borderColor: 'rgb(118, 118, 118)'
                }
            }
        }
    };

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>{message('Order.PaymentInfo')}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>
                <Elements stripe={stripePromise} options={elementsOptions}>
                    {/* CheckoutForm needs to be placed inside a separate component
                    inside the Elements context, required by Stripe */}
                    <CheckoutForm
                        orderFull={orderFull}
                        createSubscription={createSubscription}
                        message={message}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                        clientSecret={clientSecret}
                        paymentSuccessUrl={paymentSuccessUrl}
                        isFuture={isFuture}
                    />
                </Elements>
            </DialogContent>
        </Dialog>
    )
};

export default PayDialog;