import { useTranslatedMessage } from "../../../hooks/use-translated-message"
import Template from "../Template";
import ProductList from "./ProductList";

const ProductProDict = () => {
    const message = useTranslatedMessage();
    return (
        <Template title={message('Product.ProDict')}>
            <ProductList />
        </Template>
    )
}

export default ProductProDict;