import { DEFAULT_LOCALE } from '../translations';
import { type User, type UserHydrated, type UserWithoutEmail, type UserForRegistration, UserType, UserSetting } from '../types';

const userKeys = ['email', 'title', 'telephone', 'mobilephone', 'qq',
    'wechat', 'institution', 'industry', 'profession', 'proname', 'country',
    'province', 'city', 'usertype', 'dicttypeids'];

function isUserKey(key: any): key is keyof User {
    return userKeys.includes(key);
}

export const parseUserData = (userData: any): User => {
    const user: User = { email: '', id: '', stripeId: '' };

    // membership ?: string;
    // domains ?: string[];
    Object.entries(userData).forEach(([key, value]) => {
        if (isUserKey(key)) {
            if (key === 'usertype' || key === 'emailverified') {
                user[key] = value as number;
                // } else if (key === 'domains') {
                //     user[key] = value as string[];
            } else {
                user[key] = value as string;
            }
        }
    });

    user.nickname = userData?.username || "";
    user.gender = userData?.sex || "";
    user.name = userData?.fullname || userData?.firstname || userData.lastname || "";
    user.emailverified = userData?.emailverified || 0;
    user.id = userData?.dictuserid || "";
    user.stripeId = userData?.otherid || "";
    user.locale = userData?.locale || DEFAULT_LOCALE;
    user.usertype = userData.usertype || UserType.FREE;

    return user;
}

export const parseUserSettingData = (userSettingData: any): UserSetting => {
    const userSetting: UserSetting = { userId: 0 };
    if (!userSettingData) return userSetting;
    userSetting.userId = userSettingData.dictuserid;
    userSetting.highlightKeyword = userSettingData.highlightKeyword === 1 ? true : userSettingData.highlightKeyword === 0 ? false : undefined;
    userSetting.expandTarget = userSettingData.expandTarget === 1 ? true : userSettingData.expandTarget === 0 ? false : undefined;
    userSetting.drawerMode = userSettingData.drawerMode === 1 ? true : userSettingData.drawerMode === 0 ? false : undefined;

    return userSetting;
}

export const convertUserSettingDataToSubmit = (setting: UserSetting) => {
    const payload = {
        dictuserid: setting.userId,
        highlightKeyword: setting.highlightKeyword !== undefined ? +(setting.highlightKeyword) : undefined,
        drawerMode: setting.drawerMode === true ? 1 : setting.drawerMode === false ? 0 : -1,
        expandTarget: setting.expandTarget !== undefined ? +(setting.expandTarget) : undefined
    }
    return payload;
}

export const parseLoginResult = (userAndAuthData: any) => {
    const userData = userAndAuthData?.user;
    const authToken = userAndAuthData?.authToken;
    return {
        user: parseUserData(userData),
        authToken
    }
}

export const convertUserToSubmit = (user: UserWithoutEmail | UserForRegistration) => {
    // gender - We don't have this field currently, skip it
    // emailverified - We don't set this field normally, skip it
    // nickname - We don't have this field in DB, can be saved to username in DB.
    // name - We don't have this field in DB
    const { nickname, gender, name, emailverified, ...rest } = user;
    const userToSubmit = {
        // Fall back to name
        username: nickname || name,
        // We don't have lastname in FE currently
        fullname: name,
        ...rest,
    } as any;
    return userToSubmit;
};

export const convertUserToSubmitRegistration = (user: UserForRegistration) => {
    const userToSubmit = {
        ...convertUserToSubmit(user),
        email: user.email,
        emailverified: 0
    };

    return userToSubmit;
};

export const convertUserToSubmitProfile = (user: UserWithoutEmail) => {
    const userToSubmit = {
        ...convertUserToSubmit(user),
        dictuserid: user.id
    };

    return userToSubmit;
};

const isValueEqual = (item1: any, item2: any) => {
    const isItem1Arr = Array.isArray(item1);
    const isItem2Arr = Array.isArray(item2);
    if (isItem1Arr && isItem2Arr) {
        return item1.join(',') === item2.join(',');
    }
    return item1 === item2;

}

export const getUserChanged = (formData: FormData, user?: UserHydrated | null): UserHydrated | null => {
    if (user === undefined || user === null) {
        return null;
    }

    const userChanged: UserHydrated = {};

    Object.entries(user).forEach(([key, value]) => {
        if (formData.has(key)) {
            if (formData.get(key) !== value) {
                userChanged[key as keyof UserHydrated] = value;
            }
        }
    });


    for (const [key, value] of formData.entries()) {
        if (key in user) {
            const userItemVal = user[key as keyof UserHydrated];
            if (!isValueEqual(userItemVal, value)) {
                userChanged[key as keyof UserHydrated] = value as any;
            }
        }
    }

    return userChanged;
}

export const getUserDictTypeIds = (dictTypes?: string) => {
    const dictTypeIds = dictTypes && dictTypes.length > 0 ? dictTypes.split(",") : [];
    return dictTypeIds;
}