import { DICT_ORDER_LIST_URL, DICT_ORDER_LIST_GUEST_URL } from "../constants";
import useAxiosFetch from "./use-axios-fetch"
import { type DictOrderFull } from '../types';
import { useTranslatedMessage } from "./use-translated-message";
import { groupRefOrders, parseOrdersData } from "../utils/parse-order-data";
import { useUserInfoContext } from "./use-user-info-context";
import { useIntl } from "react-intl";

export const useFetchDictOrders = ({ isOnlyActive, isGrouped, orderId, skip }: { isOnlyActive?: boolean; isGrouped?: boolean; orderId?: number; skip?: boolean }) => {
    const { user } = useUserInfoContext();
    const userId = user?.id ?? '';
    // Need to skip fetching orders when userId is not ready.
    // Otherwise it ends up with empty userId sent in params
    const { data, loading } = useAxiosFetch<{ data: DictOrderFull[] }>(
        Boolean(orderId) ? DICT_ORDER_LIST_GUEST_URL : DICT_ORDER_LIST_URL,
        {
            method: 'GET',
            params: {
                userId,
                isOnlyActive,
                orderId
            }
            // Skip authentication when orderId is passed, which happens for unregistered users submitting orders
        }, Boolean(orderId), skip === undefined ? !Boolean(userId) && !orderId : skip);
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const ordersData = data?.data || [];
    const orders: DictOrderFull[] = parseOrdersData(ordersData, message, locale);

    return {
        orders: isGrouped ? groupRefOrders(orders) : orders,
        loading
    }
} 