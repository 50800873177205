export default `

<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "https://www.w3.org/TR/html4/strict.dtd"><html lang="en"><head><meta http-equiv=Content-Type content="text/html; charset=UTF-8"><style type="text/css" nonce="D34JpeaoRsPOSidgU1I7Pw">
body,td,div,p,a,input {font-family: arial, sans-serif;}
</style><meta http-equiv="X-UA-Compatible" content="IE=edge"><link rel="shortcut icon" href="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/gmail.ico" type="image/x-icon"><title>Gmail - Welcome to reCAPTCHA Enterprise!</title><style type="text/css" nonce="D34JpeaoRsPOSidgU1I7Pw">
body, td {font-size:13px} a:link, a:active {color:#1155CC; text-decoration:none} a:hover {text-decoration:underline; cursor: pointer} a:visited{color:##6611CC} img{border:0px} pre { white-space: pre; white-space: -moz-pre-wrap; white-space: -o-pre-wrap; white-space: pre-wrap; word-wrap: break-word; max-width: 800px; overflow: auto;} .logo { left: -7px; position: relative; }
</style><body><div class="bodycontainer"><table width=100% cellpadding=0 cellspacing=0 border=0><tr height=14px><td width=143><img src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_server_1x.png" width=143 height=59 alt="Gmail" class="logo"></td><td align=right><font size=-1 color=#777><b>Guohao Ren &lt;gloryren.io@gmail.com&gt;</b></font></td></tr></table><hr><div class="maincontent"><table width=100% cellpadding=0 cellspacing=0 border=0><tr><td><font size=+1><b>Welcome to reCAPTCHA Enterprise!</b></font><br><font size=-1 color=#777>1 message</font></td></tr></table><hr><table width=100% cellpadding=0 cellspacing=0 border=0 class="message"><tr><td><font size=-1><b>Google Cloud </b>&lt;CloudPlatform-noreply@google.com&gt;</font></td><td align=right><font size=-1>Sun, Mar 31, 2024 at 10:20 AM</font><tr><td colspan=2 style="padding-bottom: 4px;"><font size=-1 class="recipient"><div class="replyto">Reply-To: Google Cloud &lt;CloudPlatform-noreply@google.com&gt;</div><div>To: gloryren.io@gmail.com</div></font><tr><td colspan=2><table width=100% cellpadding=12 cellspacing=0 border=0><tr><td><div style="overflow: hidden;"><font size=-1><u></u>
<div style="margin:0!important;padding:0!important">
<table role="presentation" style="background-color:#80868b;background-image:url(https://ci3.googleusercontent.com/meips/ADKq_Narch54ymWk9iQY33igVMlxNpyV7g_qB-BuS_mfXh6VUa-OUUh1b0M-aafS0K9ZRiwCqrpKpIDU1gb4UKbxjr_1xm-zWyme_lhv1Nvm9Y2ElCTMvY8i=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/bg-img.png);background-size:cover;border-spacing:0;box-sizing:border-box;font:24px/32px Roboto,sans-serif;height:100%;margin:0;overflow:auto;width:100%">
<tbody>
<tr>
<td>
<table role="presentation" style="border-spacing:0;margin:0 auto;overflow:auto">
<tbody>
<tr>
<td>
<table role="presentation" cellspacing="0" border="0" cellpadding="0" style="border-spacing:0;display:block;font-size:12px;text-align:center;width:600px">
<tbody>
<tr>
<td>
<img src="https://ci3.googleusercontent.com/meips/ADKq_NY1hm-mYHFu4coqMKZQgXfw_m6R2Vg4rlCUECWENu1U9eLVFmK5Xs8YmkgR8ZgvIYcoGUXS8RmjVTM0-Q4swvtnGnG9t44jM3pQYNIeZkDPVqN79LAVWO7Vl2Mq8A1RNI-fh4A=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/logo-full-color-word.png" alt="" style="padding:10px;width:250px;margin-top:10px">
</td>
</tr>
<tr>
<td>
<table role="presentation" cellpadding="0" border="0" cellspacing="0" style="background:#fff;border-radius:20px 0;border-spacing:0;display:grid;height:fit-content;margin-bottom:50px;overflow:hidden;padding:0;padding-bottom:10px;padding-top:4px">
<tbody style="border-bottom:1px solid #e8eaed;padding:40px 30px;text-align:left">
<tr>
<td>
<h2 style="color:#091742;font:500 28px/36px &#39;Google Sans&#39;,sans-serif;font-size:27px;font-weight:900;letter-spacing:-0.04em;margin:0;margin-bottom:10px;padding:0;text-align:center">
Hi Guohao,
</h2>
</td>
</tr>
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-top:0;text-align:center">
Welcome to reCAPTCHA Enterprise! You&#39;re now part of a large
community of users from innovative startups to global
enterprises using reCAPTCHA to defend against bots, spam,
identity fraud and payment fraud. Congrats on creating your
first key!
</p>
</td>
</tr>
<tr>
<td align="center">
<table role="presentation" style="border-spacing:0;margin-top:20px">
<tbody>
<tr style="background-color:#1a73e8;border:none;border-radius:5px;box-sizing:border-box;color:#fff;display:flex;font-size:14px;font-weight:700;height:auto;letter-spacing:0.1em;margin:0;outline:0;padding:4px 8px;text-decoration:none;text-transform:uppercase;white-space:nowrap;width:auto">
<td style="direction:ltr;display:inline-block;font-size:24px;font-style:normal;font-weight:400;letter-spacing:normal;margin-right:5px;text-transform:none;white-space:nowrap;word-wrap:normal;width:20px">
<a href="https://notifications.google.com/g/p/ANiao5rVZn1ZWrOgXZkl7RvUQH7xqSMc6mmq-ZNAfiWxmSjjN2y_RdufMU4Wfrq5k52g6do5zqhuXUwI6NrNF19qn0GdGRDO7fmVUEC8yN5UMYQapuxyYda3dObZ0DeHRdCnIZMJfk-Rd1v9db-QYzLhJTBJ5p84ldgJa1Qnl-fctTX1UdvP4Uhws4FZVQ8R49bPlRIGHTcRQYfb2-xOEE3G2xVkrMBvUTE3-HQmZ90Rd49q2xnRY267cUJXzlrDCYO64In61Yp3-Zcl4UAdLLSF8ZtCbbMueA8GQ0pEmI1SfxdwA7AEa51WerSP-QeWpY1suBlKLXgz5qgIPmc" style="text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5rVZn1ZWrOgXZkl7RvUQH7xqSMc6mmq-ZNAfiWxmSjjN2y_RdufMU4Wfrq5k52g6do5zqhuXUwI6NrNF19qn0GdGRDO7fmVUEC8yN5UMYQapuxyYda3dObZ0DeHRdCnIZMJfk-Rd1v9db-QYzLhJTBJ5p84ldgJa1Qnl-fctTX1UdvP4Uhws4FZVQ8R49bPlRIGHTcRQYfb2-xOEE3G2xVkrMBvUTE3-HQmZ90Rd49q2xnRY267cUJXzlrDCYO64In61Yp3-Zcl4UAdLLSF8ZtCbbMueA8GQ0pEmI1SfxdwA7AEa51WerSP-QeWpY1suBlKLXgz5qgIPmc&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw2eLtAc_jRUJ28Q5FFKpSY0">
<img src="https://ci3.googleusercontent.com/meips/ADKq_NbAjV_h4sZ7uOZdP6grLig5Gxc4fm9VpAGQwd2aG7enoVNOT-ZEAh1HOekVPwfjZJ4YO65RAdTqKeuMBnyqWJeau9R496-WS0fvIoK5egxBx2ai_hvbjIx6gadGugVQIw=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/check_mark_white.png" alt="" style="margin:0 auto;padding-right:10px;padding-top:2px;max-width:100%"></a>
</td>
<td>
<a href="https://notifications.google.com/g/p/ANiao5rVZn1ZWrOgXZkl7RvUQH7xqSMc6mmq-ZNAfiWxmSjjN2y_RdufMU4Wfrq5k52g6do5zqhuXUwI6NrNF19qn0GdGRDO7fmVUEC8yN5UMYQapuxyYda3dObZ0DeHRdCnIZMJfk-Rd1v9db-QYzLhJTBJ5p84ldgJa1Qnl-fctTX1UdvP4Uhws4FZVQ8R49bPlRIGHTcRQYfb2-xOEE3G2xVkrMBvUTE3-HQmZ90Rd49q2xnRY267cUJXzlrDCYO64In61Yp3-Zcl4UAdLLSF8ZtCbbMueA8GQ0pEmI1SfxdwA7AEa51WerSP-QeWpY1suBlKLXgz5qgIPmc" style="text-decoration:none;color:#fff" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5rVZn1ZWrOgXZkl7RvUQH7xqSMc6mmq-ZNAfiWxmSjjN2y_RdufMU4Wfrq5k52g6do5zqhuXUwI6NrNF19qn0GdGRDO7fmVUEC8yN5UMYQapuxyYda3dObZ0DeHRdCnIZMJfk-Rd1v9db-QYzLhJTBJ5p84ldgJa1Qnl-fctTX1UdvP4Uhws4FZVQ8R49bPlRIGHTcRQYfb2-xOEE3G2xVkrMBvUTE3-HQmZ90Rd49q2xnRY267cUJXzlrDCYO64In61Yp3-Zcl4UAdLLSF8ZtCbbMueA8GQ0pEmI1SfxdwA7AEa51WerSP-QeWpY1suBlKLXgz5qgIPmc&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw2eLtAc_jRUJ28Q5FFKpSY0">Finish Setup</a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
<tbody style="border-bottom:1px solid #e8eaed;padding:40px 30px;text-align:left">
<tr>
<td>
<h2 style="color:#091742;font:500 28px/36px &#39;Google Sans&#39;,sans-serif;font-size:27px;font-weight:900;letter-spacing:-0.04em;margin:0;margin-bottom:10px;padding:0;text-align:center">
Best practices
</h2>
</td>
</tr>
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-top:0;padding-bottom:50px;text-align:center">
Learn how to protect your assets with confidence
</p>
</td>
</tr>
<tr>
<td>
<table role="presentation" style="border-spacing:0;margin:0 0 20px;padding:0 0 10px 0;width:100%">
<tbody>
<tr style="background-image:url(https://ci3.googleusercontent.com/meips/ADKq_Nb_hPCi0OPQ5XF2ebrirrI6lOAjaPFY14B9h3AOp7xYWemhK03FVQDoSlQwXFmkFZuDpRvRuxGmNpdLrUx75ovYK76mng5pyX5JF2XJWmXs4ga9bnWIBLQ=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/castle-1.png);background-position:bottom 0 right 0;background-repeat:no-repeat;background-size:80%" height="212.33px">
<td>
<table role="presentation" style="border-spacing:0;max-width:44%">
<tbody>
<tr>
<td>
<h3 style="color:#091742;font:500 28px/36px &#39;Google Sans&#39;,sans-serif;font-size:21px;font-weight:700;margin:0;padding:0;text-align:left">
Try it on a test website
</h3>
</td>
</tr>
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-top:0;padding-bottom:24px;text-align:left">
Deploy a demo website and easily integrate
reCAPTCHA in seconds.
</p>
</td>
</tr>
<tr>
<td>
<a href="https://notifications.google.com/g/p/ANiao5r2iigB1d65qEA82Gqu4P1-u-9EDNYmfdEyJhXyzhngRd5VCPiAsdgePqxr_HfEr3TOmQSd53mlEQV3vm8016hoyPefcnRQwFlFglkK6ZvXtl_l8zgidWOQMmUgm6deEM8R-LmRl3iDK3gvDVWxC16nSRjCrYyPKheF5iHYcgVW40MD_k_B8_JkkBX26TRNSzmCaxYe2JsYoZdJu8KufSHA39XuMwPM6xej2_q7nVmOGSZCabvCL7w1S_KZVlmGi-lwyuHH9csHKU8" style="color:#1a73e8;font-size:14.4px;font-weight:700;letter-spacing:0.1em;text-decoration:none;text-transform:uppercase" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5r2iigB1d65qEA82Gqu4P1-u-9EDNYmfdEyJhXyzhngRd5VCPiAsdgePqxr_HfEr3TOmQSd53mlEQV3vm8016hoyPefcnRQwFlFglkK6ZvXtl_l8zgidWOQMmUgm6deEM8R-LmRl3iDK3gvDVWxC16nSRjCrYyPKheF5iHYcgVW40MD_k_B8_JkkBX26TRNSzmCaxYe2JsYoZdJu8KufSHA39XuMwPM6xej2_q7nVmOGSZCabvCL7w1S_KZVlmGi-lwyuHH9csHKU8&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw3ItL-cTL_kDcKBwi1Esvmh">Learn more</a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td>
<table role="presentation" style="border-spacing:0;margin:0 0 20px;padding:0 0 10px 0;width:100%">
<tbody>
<tr style="background-image:url(https://ci3.googleusercontent.com/meips/ADKq_NYj9waLLTRAVD1ezMi8FJp0ubADl26TXA1UVAPsp0XYio8SO2i9X12dpLSQqMr-tOn1yER3uBgdWdn2Rt46O7B1slgUu5Y67u4JH7wyN0mqvMBcsouw=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/badbad.png);background-position:0 10;background-repeat:no-repeat;background-size:80%" height="261.09px">
<td style="vertical-align:top;width:50%">
<table role="presentation" style="border-spacing:0">
<tbody>
<tr>
<td align="right">
<table role="presentation" style="border-spacing:0;max-width:50%">
<tbody>
<tr>
<td>
<h3 style="color:#091742;font:500 28px/36px &#39;Google Sans&#39;,sans-serif;font-size:21px;font-weight:700;margin:0;padding:0;text-align:left">
Choose the right key type
</h3>
</td>
</tr>
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-top:0;padding-bottom:24px;padding-left:22px;text-align:left">
Discover the best use cases for either
challenge-based reCAPTCHA
(checkboxes), or frictionless
reCAPTCHA (scores).
</p>
</td>
</tr>
<tr>
<td align="right">
<a href="https://notifications.google.com/g/p/ANiao5pqZUKWpitdILONziE1U2rXEOuL-u5Nor05wvZ3A51nShjUTWF-eFxaM8jGGqKvAP2qdpA0yAGZ5MwVsixB8yczoh8RVtRGKNdjwQ7k7GYPdwwu9RerZZ-hE49aG3P19GX9kIlXh8CmWXePcbPXRZVeDi0qdn04JJ2HDUZez3oTqiQLq3vvhR99ZATziR5vo-y9X8ZHrl5mnI3GDgaY7mJ0U6esEhtRMgQNWqDWIZ1eevbYeK9xTRsQgzTSSfM" style="color:#1a73e8;font-size:14.4px;font-weight:700;letter-spacing:0.1em;padding-right:10px;text-align:right;text-decoration:none;text-transform:uppercase" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5pqZUKWpitdILONziE1U2rXEOuL-u5Nor05wvZ3A51nShjUTWF-eFxaM8jGGqKvAP2qdpA0yAGZ5MwVsixB8yczoh8RVtRGKNdjwQ7k7GYPdwwu9RerZZ-hE49aG3P19GX9kIlXh8CmWXePcbPXRZVeDi0qdn04JJ2HDUZez3oTqiQLq3vvhR99ZATziR5vo-y9X8ZHrl5mnI3GDgaY7mJ0U6esEhtRMgQNWqDWIZ1eevbYeK9xTRsQgzTSSfM&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw15xEI6kWTUQw3ilxd0nEHV">LEARN MORE</a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td>
<table role="presentation" style="border-radius:15px;border-spacing:0;margin-top:20px;overflow:hidden;width:100%">
<tbody>
<tr>
<td style="vertical-align:top;width:50%">
<table role="presentation" style="border-spacing:0">
<tbody>
<tr>
<td>
<table role="presentation" style="border-spacing:0;display:flex;padding:0 10px;text-align:center">
<tbody>
<tr>
<td>
<h3 style="color:#091742;font:500 28px/36px &#39;Google Sans&#39;,sans-serif;font-size:21px;font-weight:700;margin:0;padding:0;text-align:left">
Protect your sign-in
</h3>
</td>
</tr>
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-top:0;padding-bottom:24px;text-align:left">
Get insights to help prevent account
takeover and identity fraud by
protecting customers at sign-in.
</p>
</td>
</tr>
<tr>
<td>
<a href="https://notifications.google.com/g/p/ANiao5r5h0PeZa8nbzWfY5IZplfgz3WuM9Kb7oj7q9KHEK4rnfAoVVtucEJy4Ha2HoTjLlrxuhqBUd7nXPtLZIK0XkYEV20Xwy7PCAhXpIW56aLzxoXv2BDO4ztirrD1oRHxqm3KdjoZ77lce7ssOMWGUtCjYrgSbIigmHr0KbsERVQeLAD1w_fzzXjTJdv5CGijRldpbb3tFvDphD8EtsomYjrEfOBkgfnRvUA_zYhuqRSOPtM6dNIer3agqsTqjsv4ocYGvtwbG3p_pKyhAXqQoJU" style="color:#1a73e8;font-size:14.4px;font-weight:700;letter-spacing:0.1em;text-decoration:none;text-transform:uppercase" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5r5h0PeZa8nbzWfY5IZplfgz3WuM9Kb7oj7q9KHEK4rnfAoVVtucEJy4Ha2HoTjLlrxuhqBUd7nXPtLZIK0XkYEV20Xwy7PCAhXpIW56aLzxoXv2BDO4ztirrD1oRHxqm3KdjoZ77lce7ssOMWGUtCjYrgSbIigmHr0KbsERVQeLAD1w_fzzXjTJdv5CGijRldpbb3tFvDphD8EtsomYjrEfOBkgfnRvUA_zYhuqRSOPtM6dNIer3agqsTqjsv4ocYGvtwbG3p_pKyhAXqQoJU&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw1ib2b6X3Cd0wkJitQrUv0s">LEARN MORE</a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td>
<img src="https://ci3.googleusercontent.com/meips/ADKq_NY4n-c5V7GVC4zHoak4gSXcVq0-MCpulfmBt0XvVxpfWxlJd8suTT9NmDEPyM5QJwSXZO65waCbhqTcIhjmY9UMtBpwY5VpF-14m1Lbru2qb-d2sGlJ=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/shield.png" alt="" style="display:block;margin:0 auto;width:70%">
</td>
</tr>
</tbody>
</table>
</td>
<td style="vertical-align:top">
<table role="presentation" style="border-spacing:0">
<tbody>
<tr>
<td>
<table role="presentation" style="border-spacing:0;display:flex;padding:0 10px;text-align:center">
<tbody>
<tr>
<td>
<h3 style="color:#091742;font:500 28px/36px &#39;Google Sans&#39;,sans-serif;font-size:21px;font-weight:700;margin:0;padding:0;text-align:left">
Understand scores
</h3>
</td>
</tr>
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-top:0;padding-bottom:24px;text-align:left">
Interpret the risk of interactions on
your site or app, and take appropriate
actions.
</p>
</td>
</tr>
<tr>
<td>
<a href="https://notifications.google.com/g/p/ANiao5pyN5YddUix20UXbGgE2M56JNXNuMF4XngOS3fdPWiwRTj1KhOY_rhC6Hb1Dk_pOAKxm10FEmginxCpO5_DxosFjoztmHxbJeJFFWCd7cvQa63HUbE0_KuSNzpaywBLJGFUHwYGqajYOB_UAAZfAmQeUR61Q5X0VUqLVcTUygJBZcfMc24j0vzEhjwKOeClKv9G4NMyvTeOscYPR-j2mylXdczgSxe2ctS0jAAWJJVW-Ak3yBm22kb7XhiVP5seIXY7Ni4HyBQLE9wm1lwpiXHGBC7i" style="color:#1a73e8;font-size:14.4px;font-weight:700;letter-spacing:0.1em;text-decoration:none;text-transform:uppercase" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5pyN5YddUix20UXbGgE2M56JNXNuMF4XngOS3fdPWiwRTj1KhOY_rhC6Hb1Dk_pOAKxm10FEmginxCpO5_DxosFjoztmHxbJeJFFWCd7cvQa63HUbE0_KuSNzpaywBLJGFUHwYGqajYOB_UAAZfAmQeUR61Q5X0VUqLVcTUygJBZcfMc24j0vzEhjwKOeClKv9G4NMyvTeOscYPR-j2mylXdczgSxe2ctS0jAAWJJVW-Ak3yBm22kb7XhiVP5seIXY7Ni4HyBQLE9wm1lwpiXHGBC7i&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw1Zp4qxjFmIuZ1fqMpOdg2t">LEARN MORE</a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
<tr>
<td>
<img src="https://ci3.googleusercontent.com/meips/ADKq_NY2KG3DAXlDYFtSfcy0C7AMefnS9DwafHIZ8ezdnr36QvQDc_fnmRCnz2fEmYkc7bRj_NbFis8ccs_Wx5WhTayxQyzuITdkAZwIJTUvJdLHvwCQSXTXXWgziXM=s0-d-e1-ft#https://www.gstatic.com/recaptcha/notification/images/investigate.png" alt="" style="display:block;margin:0 auto;padding-top:20px;width:70%">
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
<tbody style="background:0 0;color:#80868b;padding-top:50px">
<tr>
<td>
<p style="color:#5f6368;font-size:15.84px;line-height:1.5;margin-bottom:40px;margin-top:0;padding:0 40px;text-align:center">
Learn more about
<a href="https://notifications.google.com/g/p/ANiao5peLgvX32dUbSe5x3CYbZaaTKCI6qZYwA3CDLOsD0XRRDihZClH7bWx_DJYS6oJHAIQJyF03EFEM5mF-k_JmS_e9XyzBjS4_N4c3o8htMblN3ESy8dJhG9-h8K0ZdUybSf_iXFi3kQ6yeBAKFAatAmgWYwC6N23WjF0aspfpSjoTE24KchRsa5btvm9h0mYDncB66VWuIpdbhzM2lojH6ZxvpQpplH0vbntZJapHDb1KQni7q4_dMnsAemPkZkaDum0wqT_hA" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5peLgvX32dUbSe5x3CYbZaaTKCI6qZYwA3CDLOsD0XRRDihZClH7bWx_DJYS6oJHAIQJyF03EFEM5mF-k_JmS_e9XyzBjS4_N4c3o8htMblN3ESy8dJhG9-h8K0ZdUybSf_iXFi3kQ6yeBAKFAatAmgWYwC6N23WjF0aspfpSjoTE24KchRsa5btvm9h0mYDncB66VWuIpdbhzM2lojH6ZxvpQpplH0vbntZJapHDb1KQni7q4_dMnsAemPkZkaDum0wqT_hA&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw0jcMcuFv6XnEOAKsb3LmuR">getting started with reCAPTCHA Enterprise</a>, and integrating with
<a href="https://notifications.google.com/g/p/ANiao5pwJ5gFhG941CYH2K_SV8HlwKKuVeSMVuzCthCcB5bSwvdRgn8RcP_4qAxWnDivA0wA0yq9l_VdJ_lWxV_ZKBC7UxAtZEATmzctET9OB8ZTZet1-1pSwL5eQUfpvXZfP3Hjo2-GhVQw0VFESZVuZ81UObU4J39NHUYIc6cR5nSxDRxv1CVPu8SfYSnBOGkbKxVSTq6MEqCd5Phbl6TPu8h3ii9SEXpJMy04vio82UjqWnnoEZxCCY-JUMH5i6bCtw-IR6s" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5pwJ5gFhG941CYH2K_SV8HlwKKuVeSMVuzCthCcB5bSwvdRgn8RcP_4qAxWnDivA0wA0yq9l_VdJ_lWxV_ZKBC7UxAtZEATmzctET9OB8ZTZet1-1pSwL5eQUfpvXZfP3Hjo2-GhVQw0VFESZVuZ81UObU4J39NHUYIc6cR5nSxDRxv1CVPu8SfYSnBOGkbKxVSTq6MEqCd5Phbl6TPu8h3ii9SEXpJMy04vio82UjqWnnoEZxCCY-JUMH5i6bCtw-IR6s&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw1gHYx64kXGqTzKbp8XX82K">mobile applications</a>
or the
<a href="https://notifications.google.com/g/p/ANiao5pFco6E5yDqyTsuQcQH9ksnqpwtzfnrZ0CpjMtIgVbw7_YkSuqZUNq3AT3DIX8HUHu58fJctTpUymXT1uKcWBkWTXwIXdFPIPlqM3lUGOMWY3Cg3UsAv7XFE9IQq2V3a5wmdLwLlWKoZRE3W1g8R5sJz9RErRfMc0zLwau7g-QTDIXH9zVNSJed97qFheNrjpdF4LPbFfla9Hy_Hk0HYQXICmaguyWeX735a3rnChULmG7CFeArnfoZ75PXMXKj0gY" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5pFco6E5yDqyTsuQcQH9ksnqpwtzfnrZ0CpjMtIgVbw7_YkSuqZUNq3AT3DIX8HUHu58fJctTpUymXT1uKcWBkWTXwIXdFPIPlqM3lUGOMWY3Cg3UsAv7XFE9IQq2V3a5wmdLwLlWKoZRE3W1g8R5sJz9RErRfMc0zLwau7g-QTDIXH9zVNSJed97qFheNrjpdF4LPbFfla9Hy_Hk0HYQXICmaguyWeX735a3rnChULmG7CFeArnfoZ75PXMXKj0gY&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw3hmSOvG3-UY1CwADAMwBt2">WAF layer</a>. For even more tutorials, product updates, and videos, check
out our
<a href="https://notifications.google.com/g/p/ANiao5qzorF12zP-8jMoSdmKZOauChA2WOLJca8TcOMzqHFJItAEkO7b1W04ZpGcDh13Qcv3SFifH0c1S1DoPVHx0sfvQLbkB8rIEfvLVQ4qdiDRnyg3WN5_FmCJdck-VvAzpV13KKTqU61k0JF5MQC6_TqV9dHk6NqGhJ9cceYHi-qxuicHFLE_4cMb0rNWDLAxgVf7nH1UEiOu9dAHnxauDzOocO-nQ5UWBIZF51PTPww" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://notifications.google.com/g/p/ANiao5qzorF12zP-8jMoSdmKZOauChA2WOLJca8TcOMzqHFJItAEkO7b1W04ZpGcDh13Qcv3SFifH0c1S1DoPVHx0sfvQLbkB8rIEfvLVQ4qdiDRnyg3WN5_FmCJdck-VvAzpV13KKTqU61k0JF5MQC6_TqV9dHk6NqGhJ9cceYHi-qxuicHFLE_4cMb0rNWDLAxgVf7nH1UEiOu9dAHnxauDzOocO-nQ5UWBIZF51PTPww&amp;source=gmail&amp;ust=1712012592964000&amp;usg=AOvVaw2hiuSUryjqUubiZsi7bMIK">documentation</a>.
</p>
</td>
</tr>
<tr width="100">
<td>
<p style="color:#74747b;font-size:12px;line-height:1.5;margin-bottom:40px;margin-top:0;padding:0 40px;text-align:center">
This email was sent to <a href="mailto:gloryren.io@gmail.com" target="_blank">gloryren.io@gmail.com</a> to confirm your
reCAPTCHA Enterprise registration.
</p>
</td>
</tr>
<tr>
<td valign="top" style="background-color:#fdfcfb;border-bottom-right-radius:20px;font-size:0.8em;padding:10px 30px;text-align:left;text-decoration:none!important">
<table role="presentation" width="100%" style="border-spacing:0">
<tbody>
<tr>
<td align="left" valign="center" width="50%" style="margin-left:10;text-decoration:none!important">
<img alt="" width="150" src="https://ci3.googleusercontent.com/meips/ADKq_Nb-c65Vl_IGQZL0tQktHFPzF_QL3pnGSBVLk5VFLKjLh35NYtOCXcsiEt3v-tGSo9F-MTedd1lj7wiT8j5CGH80TwWCMelT_tlIgr_Na42hxroq5PpLuh8W0znISsZcMO8OmvP1FejVkgyV64-iNp4=s0-d-e1-ft#https://www.gstatic.com/images/branding/googlelogo/2x/googlelogo_grey_tm_color_96x40dp.png" style="margin:2% 0 3% 8%">
</td>
<td align="right" valign="center" width="50%" style="color:#74747b;font-size:10px;line-height:1.5;text-decoration:none!important">
<span>© 2024 Google LLC</span><br>1600
Amphitheatre Parkway<br><a href="https://www.google.com/maps/search/1600%0D%0A++++++++++++++++++++++++++++++++++++++++++++Amphitheatre+Parkway+Mountain+View,+CA+94043+USA?entry=gmail&amp;source=g">Mountain View, CA 94043 USA</a>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<img alt="" height="1" width="3" src="https://ci3.googleusercontent.com/meips/ADKq_NZrI2Ombt9MnW7Ro65WHhSC1cHSSLfDTJ71dFeA9GKKABxtZaLWnrAWeKpDU_3cTTOTKLM78D64fKx7Z-Np48qcdJxRu18oo4Oivoi-dKoQYE1pNnQQlGLBBTV1nGjtGrYv0sozwJRlZeyyCS-6MM8GChRRAMqjt5iri1z9YuN7lczuUz9ZA3IWIaGe4TT-YW5pUBK3kRc66fGwO0YGdzZBIaUTkRQjXRsWr3b3QMf8QGYvGFX21K_WfX5eYuHds65ZKag2fOBO08SSe_bIIBr4Tcc2qwQRBPYX2SN5SKuYTBxdxrtaIJYj4g=s0-d-e1-ft#https://notifications.google.com/g/img/ANiao5qvBwfgqC2QSBiBUtQ1P35AmiBLFezSNj0tlrdV7nr18Td-lG_0gXKTsSHIZMmQRS9s1EW8DWObvzl5ixURHQwge-_nZLAOkD22Vxf64JI5NdqCA5CaCSfL7noFygGxJjLOOiLLr6GdOpNJb3zRikWz6uZ32mqT5iKt.gif"></div>
</font></div></table></table></div></div><script type="text/javascript" nonce="v-ezQbZqoKSlEQoAeXmLYQ">// <![CDATA[
document.body.onload=function(){document.body.offsetHeight;window.print()};
// ]]></script></body>
` as const;