import { ValidationResult } from '../types';
import { MAX_CHI_KEYWORD_LENGTH, MAX_EN_KEYWORD_LENGTH } from '../constants';
import { type TranslationMessage } from '../hooks/use-translated-message';

export const isChiWord = (input: string) => {
    const pattern = /[\u4e00-\u9fa5]/;
    return Boolean(pattern.test(input));
}

export const filterSpecialChars = (searchWord: string): string => {
    // Replace special characters with a space.
    // Special characters are defined to be non-Chinese characters, non-alphanumeric.
    // Underscore is also considered a special character
    let filteredSearchWord = searchWord.replace(/[^\w\u4E00-\u9FA5]|_/g, " ");

    // replace consecutive spaces with single space
    filteredSearchWord = filteredSearchWord.replace(/\s+/g, ' ');

    return filteredSearchWord;
}

export const sanitizeSearchWord = (searchWord: string): string => {
    // toLowerCase should be done on BE, in case that case information is needed for a search word
    return filterSpecialChars(searchWord.trim()).trim();
}

// TODO: Add unit tests to guard against each corner case, as well as error messages
export const validateSearchWord = (sanitizedSearchWord: string, searchWord: string, message: TranslationMessage): ValidationResult => {
    if (!sanitizedSearchWord) return { result: false, error: searchWord.trim().length === 0 ? message('Form.PlaceHolder.PleaseEnterSearch') : message('Form.Msg.CantQueryWord') };
    const maxLength = isChiWord(sanitizedSearchWord) ? MAX_CHI_KEYWORD_LENGTH : MAX_EN_KEYWORD_LENGTH;
    if (sanitizedSearchWord.length > maxLength) return { result: false, error: message('Form.Msg.WordTooLong') };
    return { result: true };
}

// TODO: Consider preprocess url such as /search/侧向呼吸￥%@%￥ to avoid failure in express decoding url
// Special characters such as # and % turn out to cause trouble in urls
// Express fails to decode param as such
export const sanitizeSearchWordForUrl = (searchWord?: string): string => {
    if (!searchWord) return "";
    return searchWord.replace(/[#%]+/g, " ").replace(/\s+/g, ' ').trim();
}