import { useState, useEffect } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useUserAPI } from '../../hooks/use-user-api';
import { useMessageContext } from '../../hooks/use-message-context';
import { type UserCredential, type FormError, FormType } from '../../types';
import PasswordField from './PasswordField';
import EmailField from './EmailField';
import RegisterLink from './RegisterLink';
import { updateFormError, validateFieldValue, validateLoginFormFields } from '../../utils/validate-field-value';
import ForgotPasswordLink from './ForgotPasswordLink';
import AuthPage from './AuthPage';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { useIntl } from 'react-intl';
import LoadingButton from '../LoadingButton';

export default function SignInForm() {
    const { loginUser, rememberMe } = useUserAPI();
    const [isChecked, setIsChecked] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formError, setFormError] = useState<FormError>({});
    const { showToastMessage } = useMessageContext();
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    // Sync the default value of isChecked from rememberMe
    // It is not working by setting it at useState(rememberMe) which loses synced value once refresh
    useEffect(() => {
        setIsChecked(checked => rememberMe)
    }, [rememberMe])

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const status = validateFieldValue({ name, value, formType: FormType.LOGIN, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message))
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const userCredential: UserCredential = {
            email: (formData.get('email') || '')?.toString(),
            password: (formData.get('password') || '').toString(),
            rememberMe: isChecked
        }

        const currFormError = validateLoginFormFields(userCredential, message);
        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            showToastMessage({ message: message('Form.Msg.PlsChangeSignInInfo'), type: 'warning' });
            return;
        }

        loginUser(userCredential, setFormSubmitting);
    };

    return (
        <AuthPage title={message('Form.Auth.SignIn')}>
            <Box component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate sx={{ mt: 1 }}>
                <EmailField enableRemember formError={formError} />
                <PasswordField formError={formError} />
                <FormControlLabel
                    control={<Checkbox value="remember"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        color="primary" />}
                    label={message('Form.Field.RememberMyChoice')}
                />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={Object.keys(formError).length > 0}
                    loading={formSubmitting}
                >
                    {message('Form.Auth.SignIn')}
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <ForgotPasswordLink message={message} locale={locale} />
                    </Grid>
                    <Grid item>
                        <RegisterLink message={message} locale={locale} />
                    </Grid>
                </Grid>
            </Box>
        </AuthPage>
    );
}