import exampleHtml1 from './forgotpass-example1';
import exampleHtml2 from './forgotpass-example2';
import exampleHtml3 from './forgotpass-example3';
import { useLocation } from "react-router-dom";

const ForgotPassEmailTemplate = () => {
    const location = useLocation();
    const exampleId = location.state?.data;
    const allExamples = [
        exampleHtml1, exampleHtml2, exampleHtml3
    ]
    const isExample = exampleId !== undefined && exampleId < allExamples.length;
    const html = isExample ? allExamples[exampleId] : '';

    const content = `
    You Forgot Password!
You've successfully registered an account with Scidict.org.
Enjoy searching in our dicti;onary that is dedicated to term phrases translation between Chinese and English
    `;

    if (isExample) {
        return (<div dangerouslySetInnerHTML={{ __html: html }} />);
    }

    return (<div>
        {content}
    </div>)
}

export default ForgotPassEmailTemplate;