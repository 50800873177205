import type { IPaymentMethod, IPaymentCard } from "../types";
import { underscoreToCamelCase } from "./parse-data";

export const parsePaymentData = (data: Object): IPaymentMethod => {
    const paymentMethod = {} as any;

    if (data) {
        Object.entries(data).forEach(([key, value]) => {
            const newKey = underscoreToCamelCase(key) as keyof IPaymentMethod;
            paymentMethod[newKey] = value;
        })
    }

    return paymentMethod;
}

export const parsePaymentCardData = (data: Object): IPaymentCard => {
    const paymentCard = {} as any;

    if (data) {
        Object.entries(data).forEach(([key, value]) => {
            const newKey = underscoreToCamelCase(key) as keyof IPaymentCard;
            paymentCard[newKey] = value;
        })
    }

    return paymentCard;
}