import { createTheme, ThemeProvider as MThemeProvider } from '@mui/material/styles';
import { ReactNode } from 'react';
import { Color, Size } from './constants';

// Mixins to vertcally extend content of a page while keeping footer at the bottom
// const VerticalExtendedPage = css`
//     display: flex;
//     flex-direction: column;
//     min-height: 100vh;
//     margin: 0;
// `;

// const MajorVerticalSection = css`
//     flex: 1;
// `;

const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const defaultTheme = createTheme({
        palette: {
            primary: {
                // main: '#374680',
                main: Color.PRIMARY_GREY,
                dark: Color.PRIMARY_BLUE_DARK,
                light: Color.PRIMARY_BLUE_LIGHT,
                contrastText: Color.PRIMARY_BLUE_CONTRAST_TEXT,
            },
            secondary: {
                main: Color.PINK_DARK
            },
            error: {
                main: Color.ERROR
            },
            warning: {
                main: Color.WARNING
            },
            success: {
                main: Color.SUCCESS
            },
            info: {
                main: Color.INFO
            }
        },
        components: {
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        minHeight: `${Size.APPBAR_HEIGHT}px !important`, // Adjust the height of AppBar
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            color: Color.PRIMARY_BLUE, // Replace with your desired hover color
                        },
                        textDecoration: 'underline',
                        color: Color.DARK_GREY
                    },
                },
            },
            MuiLinearProgress: {
                styleOverrides: {
                    barColorPrimary: {
                        backgroundColor: Color.PRIMARY_BLUE, // Replace with your desired color
                    },
                },
            }
        },
        custom: {
            background: {
                main: Color.BACKGROUND_THEME
            }
        },
        mixins: {
            // There is no need to fix position Footer in order to keep it at page bottom.
            // Vertical flex positioning does it better 
            responsiveMainWidth: ({ theme }) => ({
                width: '90%',
                [theme.breakpoints.between('sm', 'md')]: {
                    width: '85%'
                },
                [theme.breakpoints.between('md', 'lg')]: {
                    width: '75%'
                },
                [theme.breakpoints.up('lg')]: {
                    width: '65%'
                },
                margin: '0 auto'
            }),
            fullyExtendedMainHeight: {
                container: {
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    margin: '0 auto'
                },
                mainSection: {
                    flex: 1
                }
            }
        }
    });

    return (
        <MThemeProvider theme={defaultTheme}>
            {children}
        </MThemeProvider>
    )
}

export default ThemeProvider;