import styled from '@emotion/styled/macro';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { type FormError } from '../../types';
import { TranslationMessage } from '../../hooks/use-translated-message';
import { Color } from '../../constants';
import LoadingButton from '../LoadingButton';

const Row = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        margin: 5px 10px;
    }
`;

const ButtonRow = styled(Row)`
    margin: 10px 0;
    padding: 0 5px;
    > button {
        margin: 5px;
    }
`;

export interface ButtonsComponentProps {
    editing: boolean;
    formSubmitting: boolean;
    formError: FormError;
    setFormError: (error: FormError) => void;
    message: TranslationMessage;
    tabId: string;
    setTabEditing: (tab: string) => void;
    extraButton?: React.ReactNode;
    saveButtonProps?: {
        label?: string;
        backgroundColor?: string;
        inVisible?: boolean;
        onClick?: () => void;
        disabled?: boolean;
    };
}

const ButtonsComponent = ({
    message,
    editing,
    formSubmitting,
    formError,
    setFormError,
    tabId,
    setTabEditing,
    saveButtonProps,
    extraButton,
}: ButtonsComponentProps) => {
    const onCancel = () => {
        setFormError({});
        setTabEditing("");
    }
    const onEdit = () => {
        setTabEditing(tabId);
    }

    return (
        <ButtonRow>
            <Button variant={editing ? "outlined" : "contained"} onClick={editing ? onCancel : onEdit}>{editing ? message('Form.Button.Cancel') : message('Form.Button.Edit')}</Button>
            <Box display={'flex'}>
                {editing && <>
                    {extraButton}
                    {!saveButtonProps?.inVisible ? <LoadingButton
                        type={saveButtonProps?.onClick ? "button" : "submit"}
                        variant="contained"
                        sx={{
                            background: saveButtonProps?.backgroundColor ?? Color.PRIMARY_BLUE,
                            ml: 1
                        }}
                        disabled={saveButtonProps?.disabled || Object.keys(formError).length > 0}
                        loading={formSubmitting}
                        onClick={saveButtonProps?.onClick}
                    >
                        {saveButtonProps?.label ?? message('Form.Button.Save')}
                    </LoadingButton> : null}</>}
            </Box>
        </ButtonRow>
    )
}

export default ButtonsComponent;