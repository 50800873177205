import styled from '@emotion/styled/macro';
import React from 'react';
import { Link } from 'react-router-dom';
import { templates, type EmailTemplate } from './constant';

const Container = styled.div`
    margin: 20px;
    p {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
    }
    a {
        margin-right: 10px;
    }
`;

const EmailTemplateIndex = () => {
    return (
        <Container>
            <p>{`Email Templates`}</p>
            {
                templates.map(({ path, label, examples = [] }: EmailTemplate, i) => (<div key={i}>
                    <Link to={path}>{label}</Link>
                    {examples.map((examplePath, index) => <Link to={path} state={{ data: index }}>{`Example ${index + 1} `}</Link>)}
                </div>))
            }
        </Container>
    )
}

export default EmailTemplateIndex;