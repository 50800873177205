import { useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { useLoaderData, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled as mStyled } from '@mui/material/styles';
import SearchBox from './SearchBox';
import SearchResult from './SearchResult';
import SearchTabs from './SearchTabs';
import LogoBanner from '../../LogoBanner';
import { getIndustryIdByName, LocalStorageKey, Size } from '../../constants';
import { SearchDataContextProvider, useSearchDataContext } from '../../hooks/use-search-data-context';
import { getSearchSEO } from '../../utils/get-seo-data';
import { isPathsEqual, isSearchResultPath } from '../../utils/url-path';
import { Path, getLocaledAbsolutePath } from '../../constants';
import { useIntl } from 'react-intl';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { getUserDictTypeIds } from '../../utils/parse-user-data';
import { setLocalStore } from '../../utils/local-storage';

const StyledSearchHeader = styled.div<{ isOnSearchPage: boolean }>`
    height: 210px;
    margin: 20px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${(props) => props.isOnSearchPage && `margin-top: 0;`};
`;

const StyledSearchContainer = mStyled('div')(({ theme }) => ({
    ...theme.mixins.responsiveMainWidth({ theme })
}));

const SearchBoxContainer = () => {
    return (<StyledSearchContainer>
        <SearchBox />
    </StyledSearchContainer>)
}

const SearchHeader = () => {
    // when there is no param set at the main page, loader data is undefined
    // need to fallback to emptry string to avoid complaint from Input on 
    // changing input from controlled to uncontrolled, due to assigning undefined to value
    const params = useParams();
    const keyword = useLoaderData() as string || '';
    const industry = params?.industry ?? "";
    const { setKeyword } = useSearchDataContext();
    const { pathname } = useLocation();
    const { locale } = useIntl();
    const message = useTranslatedMessage();
    const { user, isProPlus, loading } = useUserInfoContext();
    const activeDictTypes = getUserDictTypeIds(user?.dicttypeids);
    const hasActiveDictTypes = activeDictTypes.length > 0;

    setLocalStore(LocalStorageKey.INDUSTRY, industry);

    // Update whatever keyword coming from url to search context
    useEffect(() => {
        setKeyword(keyword);
    }, [keyword, setKeyword]);

    if (loading) return null;

    // // Reset link to remove industry if usertype is not pro
    // if (userInfoLoading === false && !isProPlus && Boolean(params?.industry)) {
    //     return <Navigate to={getIndustrySanitizedPath(pathname)} replace />;
    // }

    const { title, description, keywords } = getSearchSEO(keyword, locale, message);
    const isOnSearchPage = isSearchResultPath(pathname);

    return <>
        {Boolean(keyword) && <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
        </Helmet>}
        <StyledSearchHeader isOnSearchPage={isOnSearchPage}>
            <LogoBanner supportPro labelWidth={Size.DICT_LABEL_BANNER_WIDTH} sx={{ ...hasActiveDictTypes ? { mb: '7px' } : {} }} />
            {hasActiveDictTypes && isProPlus && <SearchTabs values={activeDictTypes} selectedName={industry} />}
            <SearchBoxContainer />
        </StyledSearchHeader>
    </>;
};

const StyledSearchBody = styled.div`
`;

const SearchBody = () => {
    const { pathname } = useLocation();
    const params = useParams();
    const industry = params?.industry ?? "";
    const industryId = getIndustryIdByName(industry);
    const { locale } = useIntl();

    if (isPathsEqual(pathname, getLocaledAbsolutePath(Path.MAIN, locale, industryId))) {
        return null
    }

    return <StyledSearchBody>
        <SearchResult />
    </StyledSearchBody>
}

const SearchMain = () => {
    return (
        <SearchDataContextProvider>
            <SearchHeader />
            <SearchBody />
        </SearchDataContextProvider>
    )
}

export default SearchMain;