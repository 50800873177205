import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { useEffect, useState } from 'react';
import FormTextField, { FormTextFieldProps } from '../FormTextField';
import type { FormError } from '../../types';
import { useTranslatedMessage } from '../../hooks/use-translated-message';

interface EmailFieldProps {
    enableRemember?: boolean;
    disabled?: boolean;
    formError?: FormError;
    initialValue?: string;
    fullWidth?: boolean;
    variant?: FormTextFieldProps["variant"];
    readonly?: boolean;
    anchored?: boolean;
    noLabel?: boolean;
}

const EmailField = ({ noLabel, enableRemember, disabled, readonly, anchored, fullWidth, variant, formError, initialValue }: EmailFieldProps) => {
    const { rememberedEmail } = useUserInfoContext();
    const [email, setEmail] = useState<string>('');
    const message = useTranslatedMessage();

    // Sync the default value of email input from rememberedEmail 
    useEffect(() => {
        if (!enableRemember) return;
        setEmail((email: string) => rememberedEmail || "")
    }, [rememberedEmail, enableRemember]);

    // TODO: Looks like this initial value can be passed down to FormTextField! Try
    // Sync email value to its initial value when set as initialValue in property
    // If initialValue is set, we assume rememberedEmail is not set
    useEffect(() => {
        if (!initialValue) return;
        setEmail((email: string) => initialValue)
    }, [initialValue]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    return (
        <FormTextField
            name="email"
            label={message('Form.Profile.Email')}
            noLabel={noLabel}
            required
            readonly={readonly}
            anchored={anchored}
            fullWidth={fullWidth}
            autoComplete="email"
            value={email}
            disabled={disabled}
            onChange={handleOnChange}
            formError={formError}
            variant={variant}
        />
    );
}

export const StandardEmailField = (props: EmailFieldProps) => (<EmailField {...props} anchored variant="standard" />)

export const StandardReadonlyEmailField = (props: EmailFieldProps) => (<EmailField {...props} anchored readonly variant="standard" />)

export default EmailField;