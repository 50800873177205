import { useLocation } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { MenuItemObj } from '../../types';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import useNavigate from "../../hooks/use-navigate";
import { isPathsEqual } from '../../utils/url-path';

interface DrawerMenuItemProps {
    menuItem: MenuItemObj;
    drawerOpen?: boolean;
}

const DrawerMenuItem = ({ menuItem, drawerOpen }: DrawerMenuItemProps) => {
    const { navigate } = useNavigate();
    const { pathname } = useLocation();
    const message = useTranslatedMessage();
    const IconComponent = menuItem.IconComponent;
    const icon = IconComponent ? <IconComponent /> : null;

    const handleMenuClick = (menuItem: MenuItemObj) => (event: React.MouseEvent<HTMLElement>) => {
        const { path, onClick } = menuItem;
        path && navigate(path);
        onClick?.();
    }

    return (
        <Tooltip title={message(menuItem.labelKey)} placement="right">
            <ListItem key={menuItem.id} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: drawerOpen ? 'initial' : 'center',
                        px: 2.5,
                        'svg': {
                            mr: drawerOpen ? 3 : 'auto',
                        }
                    }}
                    onClick={handleMenuClick(menuItem)}
                    selected={isPathsEqual(menuItem.path ?? "", pathname)}
                >
                    {icon}
                    <ListItemText primary={message(menuItem.labelKey)} sx={{ opacity: drawerOpen ? 1 : 0 }} />
                </ListItemButton>
            </ListItem>
        </Tooltip>
    )
}

export default DrawerMenuItem;