import { COMPANY_NAME_FULL, Path, getLocaledAbsolutePath } from "../../constants";
import { useIntlContext } from "../../hooks/use-intl-context";
import { useTranslatedMessage } from "../../hooks/use-translated-message";
import Template from "./Template";
import { Paragraph as P } from './styled';
import { TextLink } from "../Link";

interface ContentProps {
    locale: string;
}

// TODO: Add terms regarding payment setup
// See https://docs.stripe.com/payments/save-during-payment
// At a minimum, ensure that your terms cover the following:
// The customer’s permission to your initiating a payment or a series of payments on their behalf
// The anticipated frequency of payments(that is, one - time or recurring)
// How the payment amount will be determined
// https://docs.stripe.com/payments/setup-intents
// TODO: (TBD) Add terms regarding automatic activation of dictionary upon login and access
// TODO: Write policy to not allow refund, but supports canceling subscription

const ContentEN = ({ locale }: ContentProps) => (
    <>
        <P>Welcome to our online dictionary product! Before you proceed with registration, please carefully read and agree to the following detailed terms and conditions:

        </P><P>1. **Introduction**

        </P><P>1.1. These terms and conditions govern your use of our online dictionary product and constitute a legally binding agreement between you and Cross Language LLC.

        </P><P>1.2. By registering for our online dictionary product, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please do not register for the service.

        </P><P>2. **User Registration**

        </P><P>2.1. To get access to some functionalities on our website, you may need to register for an account. You agree to provide accurate and complete information during the registration process. This includes your full name, email address, and any other information requested by the registration form.

        </P><P>2.2. You are responsible for maintaining the confidentiality of your account credentials and for any activity that occurs under your account. You agree not to share your account credentials with others or allow unauthorized access to your account.

        </P><P>3. **Privacy Policy**

        </P><P>3.1. Your privacy is important to us. Please review our {<TextLink to={getLocaledAbsolutePath(Path.PRIVACY_POLICY, locale)}>Privacy Policy</TextLink>} to understand how we collect, store, and use your personal information.

        </P><P>3.2. By using our online dictionary product, you consent to the collection, use, and disclosure of your personal information as described in our Privacy Policy.

        </P><P>4. **User Responsibilities**

        </P><P>4.1. You agree to use our online dictionary product in accordance with these terms and conditions and all applicable laws and regulations.

        </P><P>4.2. You agree not to use the service for any illegal or unauthorized purpose. This includes, but is not limited to, engaging in any activity that violates the rights of others, such as copyright infringement or defamation.

        </P><P>4.3. You agree not to interfere with or disrupt the operation of the service or servers or networks connected to the service.

        </P><P>5. **Prohibited Activities**

        </P><P>5.1. You agree not to engage in any of the following prohibited activities:
        </P><P>- Using the service for any illegal or unauthorized purpose.
        </P><P>- Posting or transmitting any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or otherwise objectionable.
        </P><P>- Impersonating any person or entity, or falsely stating or misrepresenting your affiliation with a person or entity.
        </P><P>- Posting or transmitting any unsolicited or unauthorized advertising, promotional materials, or spam.

        </P><P>6. **Intellectual Property Rights**

        </P><P>6.1. All content and intellectual property rights related to the service are owned by us or our licensors. You may not use, copy, reproduce, or distribute any content from the service without prior written permission.

        </P><P>6.2. You agree not to modify, adapt, translate, reverse engineer, decompile, or disassemble any part of the service.

        </P><P>7. **Termination**

        </P><P>7.1. We reserve the right to terminate or suspend your account at any time, without prior notice, for any reason, including, but not limited to, violation of these terms or inactivity.

        </P><P>7.2. Upon termination of your access to the Service, all provisions of these terms and conditions that by their nature should survive termination shall survive, including, without limitation,
            ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

        </P><P>8. **Modification of Terms**

        </P><P>8.1. We may modify these terms and conditions at any time, without prior notice. Any changes will be effective immediately upon posting on the website.

        </P><P>8.2. Your continued use of the Service following the posting of any changes to these terms and conditions constitutes acceptance of those changes.

        </P><P>9. **Limitation of Liability**

        </P><P>9.1. To the fullest extent permitted by applicable law, we shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to damages for lost profits, loss of data, or business interruption, arising out of or in any way related to your use of or inability to use the Service.

        </P><P>9.2. In no event shall our total liability to you for all damages exceed the amount paid by you, if any, for accessing or using the Service during the twelve (12) months preceding the event giving rise to the liability.

        </P><P>10. **Dispute Resolution**

        </P><P>10.1. Any dispute arising out of or relating to these terms and conditions or the use of the Service shall be governed by the laws of California, USA, without regard to its conflict of law provisions.

        </P><P>10.2. Any legal action or proceeding arising out of or relating to these terms and conditions or the use of the Service shall be brought exclusively in the courts of California, USA.

        </P><P>11. **Miscellaneous**

        </P><P>11.1. These terms and conditions constitute the entire agreement between you and Cross Language LLC regarding your use of the Service and supersede all prior and contemporaneous agreements and understandings, whether oral or written.

        </P><P>11.2. If any provision of these terms and conditions is found to be invalid or unenforceable, that provision shall be limited or eliminated to the minimum extent necessary so that these terms and conditions shall otherwise remain in full force and effect.

        </P><P>12. **Contact Information**

        </P><P>12.1. If you have any questions or concerns regarding these terms and conditions, please contact us at support@scidict.org.

        </P>
    </>
)

const ContentZH = ({ locale }: ContentProps) => (
    <>
        <P>欢迎使用我们的在线词典产品！在您继续注册之前，请仔细阅读并同意以下详细的条款与条件。
        </P><P>条款与条件

        </P><P>1. 介绍

        </P><P>1.1. 本条款与条件规定了您使用我们的在线词典产品（以下简称“服务”）时的行为规范，并构成您与{`${COMPANY_NAME_FULL}`}（以下简称“提供者”）之间的法律约束协议。

        </P><P>1.2. 在您使用服务之前，请确保您已阅读并同意本条款与条件。如果您不同意本条款的任何部分，请不要使用服务。

        </P><P>2. 用户注册

        </P><P>2.1. 要访问服务的某些功能，您可能需要注册账户。您同意在注册过程中提供准确和完整的信息，包括您的全名、邮箱地址，以及其他注册表格要求填写的信息。

        </P><P>2.2.您有责任保管好您的账户凭据，并对您的账户下发生的所有活动负责。您同意不与他人分享您的账户凭据，也不允许未经授权的人访问您的账户。

        </P><P>3. 隐私政策

        </P><P>3.1. 您的隐私对我们很重要。请查阅我们的{<TextLink to={getLocaledAbsolutePath(Path.PRIVACY_POLICY, locale)}>隐私政策</TextLink>}，了解我们如何收集、使用和披露您的个人信息。

        </P><P>3.2. 通过使用服务，您同意根据我们的隐私政策收集、使用和披露您的个人信息。

        </P><P>4. 用户责任

        </P><P>4.1. 您同意根据本条款和条件使用我们的在线词典产品，并遵守与服务相关的所有适用的法律法规。

        </P><P>4.2. 您同意不得将服务用于任何非法或未经授权的目的。这包括但不限于参与任何侵犯他人权利的行为，如侵犯版权或诽谤。

        </P><P>4.3. 您同意不得干扰或破坏服务的运行，或与服务相连的服务器或网络。

        </P><P>5. **受禁止的活动**

        </P><P>5.1.您同意不参与以下任何活动：

        </P><P>- 使用服务进行任何非法或未经授权的目的。

        </P><P>- 发布或传输任何违法、有害、威胁、辱骂、骚扰、诽谤、粗俗、淫秽或其他令人反感的内容。

        </P><P>- 冒充任何人或实体，或虚假陈述或误导他人以表明您与某人或实体的从属关系。

        </P><P>- 发布或传输任何未经请求或未经授权的广告、促销材料或垃圾邮件。

        </P><P>6. 知识产权

        </P><P>6.1. 本服务中的所有内容和知识产权归我们或我们的许可方所有。未经提供者事先书面许可，您不得修改、复制、重制、分发或传播服务中的任何内容。

        </P><P>6.2. 您同意不对本服务的任何部分进行修改、改编、翻译、反向工程、反编译或拆卸。

        </P><P>7. 终止

        </P><P>7.1. 我们保留在不做事先通知的情况下，在任何时间，由于任何原因，暂停或终止您对服务的访问权限。其中原因包括但不限于违反本条款与条件，或者账户长时间无活动。

        </P><P>7.2. 在您对服务的访问权限终止后，本条款与条件中应继续生效的所有条款，包括但不限于所有权条款、免责声明、赔偿条款和责任限制，将继续有效。

        </P><P>8. 条款修改

        </P><P>8.1. 我们保留在任何时间、任何原因下，无需事先通知，对本条款与条件进行修改的权力。我们对本条款与条件的任何更改如发布在本网站上，则立即生效。

        </P><P>8.2. 在本条款与条件的任何更新发布后，如您继续使用服务，则表示接受这些更新条款。

        </P><P>9. 责任限制

        </P><P>9.1.在适用法律允许的最大范围内，我们对因您使用或无法使用本服务而导致的任何直接、间接、附带、特殊、间断性或惩罚性损害，包括但不限于利润损失、数据丢失或业务中断，概不负责。

        </P><P>9.2. 在任何情况下，提供者对您的全部损害赔偿责任的总额不得超过您支付的金额（如果有），即在发生引起责任的事件前的十二个（12）个月内访问或使用服务的金额。

        </P><P>10. 争议解决

        </P><P>10.1. 任何因本条款与条件或使用服务而引起的争议均应根据美国加利福尼亚州的法律解决，不考虑其冲突法规。

        </P><P>10.2. 任何因本条款与条件或使用服务而引起的法律诉讼或程序应仅在美国加利福尼亚州的法院提起。

        </P><P>11. 其他事项

        </P><P>11.1. 本条款与条件构成您与提供者之间关于您使用服务的完整协议，取代所有之前和同时的书面或口头协议和理解。

        </P><P>11.2. 如果本条款与条件中的某特定条款被认定为无效或不可执行，则该特定条款应被限制或删除，但本条款与条件的其他内容应继续生效和被执行。

        </P><P>12. **联系信息**

        </P><P>12.1. 如果您对本条款与条件有任何疑问或顾虑，请通过 support@scidict.org 联系我们。

        </P>
    </>
)

const TermsAndConditions = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntlContext();

    return (<Template title={message('Navigation.TermsAndConditions')}>
        {
            (() => {
                switch (locale) {
                    case 'en':
                        return <ContentEN locale={locale} />
                    case 'zh':
                        return <ContentZH locale={locale} />
                    default:
                        return <ContentEN locale={locale} />
                }
            })()
        }
    </Template>);
}

export default TermsAndConditions;