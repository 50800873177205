import App from './App';
import AboutUs from './components/company/AboutUs';
import Product from './components/company/Product';
import SearchMain from './components/search/SearchMain';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import ForgotPasswordForm from './components/auth/ForgotPasswordForm';
import PasswordResetForm from './components/auth/PasswordResetForm';
import UserCenter from './components/user-center/UserCenter';
import Profile from './components/user-center/Profile';
import Glossary from './components/user-center/glossary/Glossary';
import Account from './components/user-center/account/Account';
import Settings from './components/user-center/Settings';
import FavoriteWords from './components/user-center/glossary/FavoriteWords';
import SearchHistory from './components/user-center/glossary/SearchHistory';
import EmailTemplateIndex from './email-template/index';
import RegistrationEmailTemplate from './email-template/registration-email-template';
import ForgotPassEmailTemplate from './email-template/forgotpass-email-template';
import ErrorPage from './components/ErrorPage';
import { sanitizeSearchWordForUrl } from './utils/sanitize-search-word';
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
    redirect
} from "react-router-dom";
import { useUserInfoContext } from './hooks/use-user-info-context';
import { useSearchHistory } from './hooks/use-search-history';
import { Path, getLocaledAbsolutePath, getRelativePath } from './constants';
import ContactUs from './components/company/ContactUs';
import TermsAndConditions from './components/company/TermsAndConditions';
import PrivacyPolicy from './components/company/PrivacyPolicy';
import ProductProDict from './components/company/product/ProductProDict';
import Checkout from './components/company/product/Checkout';
import CheckOrder from './components/company/product/CheckOrder';
import PaymentSuccess from './components/company/product/PaymenSuccess';
import { ReactNode } from 'react';
import { UserStatus } from './types';
import { Navigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

// Handles the case
// 1. when user logs out actively
// 2. when user logs out in one tab, other tabs will be synced
const AuthWrapper = ({ children }: { children: ReactNode }) => {
    const { userStatus, loading } = useUserInfoContext();
    const { locale } = useIntl();

    if (loading === false && userStatus !== UserStatus.ONLINE) {
        return <Navigate to={getLocaledAbsolutePath(Path.LOGIN, locale)} />;
    }

    return children;
};

const Router = () => {
    const { user } = useUserInfoContext();
    const { fetchSearchHistory } = useSearchHistory();
    const router = createBrowserRouter([
        {
            path: Path.PREFIX_LOCALE + Path.PREFIX_INDUSTRY + Path.SEARCH,
            loader: async ({ params }) => {
                const industry = params?.industry ?? "";
                const industrySubPath = industry === "" ? "" : `/${industry}`;
                const localeSubpath = params?.lang === 'en' ? "/en" : "";

                const pathWithLocale = `${localeSubpath}${industrySubPath}${Path.MAIN}`;
                return redirect(pathWithLocale);
            }
        },
        {
            path: Path.PREFIX_LOCALE + Path.MAIN,
            element: <App />,
            errorElement: <ErrorPage />,
            children: [
                {
                    index: true,
                    element: <SearchMain />
                },
                {
                    path: getRelativePath(Path.MAIN_INDUSTRY, Path.MAIN),
                    element: <SearchMain />,
                },
                {
                    path: getRelativePath(Path.PRODUCT, Path.MAIN),
                    element: <Product />,
                },
                {
                    path: getRelativePath(Path.PRODUCT_PRODICT, Path.MAIN),
                    element: <ProductProDict />
                },
                {
                    path: getRelativePath(Path.PRODUCT_CHECKOUT_PARAM, Path.MAIN),
                    element: <Checkout />
                },
                {
                    path: getRelativePath(Path.PRODUCT_CHECKORDER_PARAM, Path.MAIN),
                    element: <CheckOrder />
                },
                {
                    path: getRelativePath(Path.PRODUCT_PAYMENTSUCCESS_PARAM, Path.MAIN),
                    element: <PaymentSuccess />
                },
                {
                    path: getRelativePath(Path.ABOUT_US, Path.MAIN),
                    element: <AboutUs />
                },
                {
                    path: getRelativePath(Path.CONTACT_US, Path.MAIN),
                    element: <ContactUs />
                },
                {
                    path: getRelativePath(Path.TERMS_AND_CONDITIONS, Path.MAIN),
                    element: <TermsAndConditions />
                },
                {
                    path: getRelativePath(Path.PRIVACY_POLICY, Path.MAIN),
                    element: <PrivacyPolicy />
                }
            ]
        },
        {
            path: Path.PREFIX_LOCALE + Path.SEARCH_KEYWORD,
            element: <App><SearchMain /></App>,
            loader: async ({ params }): Promise<string> => {
                return await sanitizeSearchWordForUrl(params.keyword);
            }
        },
        // Support internal search for rewrite from nginx, not accessible from public
        {
            path: Path.PREFIX_LOCALE + Path.SEARCH_KEYWORD2,
            element: <App><SearchMain /></App>,
            loader: async ({ params }): Promise<string> => {
                return await sanitizeSearchWordForUrl(params.keyword);
            }
        },
        {
            path: Path.PREFIX_LOCALE + Path.LOGIN,
            element: <LoginForm />
        },
        {
            path: Path.PREFIX_LOCALE + Path.REGISTER,
            element: <RegisterForm />
        },
        {
            path: Path.PREFIX_LOCALE + Path.USER,
            element: <AuthWrapper>
                <UserCenter >
                    <Outlet />
                </UserCenter>
            </AuthWrapper>,
            children: [
                {
                    path: getRelativePath(Path.USER_PROFILE, Path.USER),
                    element: <Profile />
                },
                {
                    path: getRelativePath(Path.USER_ACCOUNT, Path.USER),
                    element: <Account />
                },
                {
                    path: getRelativePath(Path.USER_ACCOUNT_SECTION, Path.USER),
                    element: <Account />
                },
                {
                    path: getRelativePath(Path.USER_GLOSSARY, Path.USER),
                    element: <Glossary />,
                    children: [
                        {
                            index: true,
                            loader: async () => {
                                return redirect(Path.USER_GLOSSARY_HISTORY);
                            }
                        },
                        {
                            path: getRelativePath(Path.USER_GLOSSARY_FAVORITE, Path.USER_GLOSSARY),
                            element: <FavoriteWords />
                        }, {
                            index: true,
                            path: getRelativePath(Path.USER_GLOSSARY_HISTORY, Path.USER_GLOSSARY),
                            element: <SearchHistory />,
                            loader: async () => {
                                return await fetchSearchHistory(user?.id || '');
                            }
                        }]
                },
                {
                    path: getRelativePath(Path.USER_SETTINGS, Path.USER),
                    element: <Settings />
                }
            ]
        },
        {
            path: Path.PREFIX_LOCALE + Path.FORGOT,
            element: <ForgotPasswordForm />
        },
        {
            path: Path.PREFIX_LOCALE + Path.RESET,
            element: <PasswordResetForm />
        },
        {
            path: Path.TEMPLATE,
            element: <EmailTemplateIndex />
        },
        {
            path: Path.TEMPLATE_REGISTRATION,
            element: <RegistrationEmailTemplate />
        },
        {
            path: Path.TEMPLATE_FORGOTPASS,
            element: <ForgotPassEmailTemplate />
        }
    ]);

    return (<RouterProvider router={router} />);
}

export default Router;