import { useCallback, useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import CardHeader from '@mui/material/CardHeader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material/styles';
import { DictOrderFull, MenuItemObj, UserType } from '../../../types';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import HorizontalField from '../../HorizontalField';
import { getIndustryTitleById, getIconCharById, getSubscriptionDurationNameByMonthNum } from '../../../constants';
import { useIntl } from 'react-intl';
import { Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import MenuButton from '../../MenuButton';
import DictSelectDialog from '../../company/product/DictSelectDialog';
import { useDictOrderAPI } from '../../../hooks/use-dict-order-api';
import { usePaymentAPI } from '../../../hooks/use-payment-api';
import PayDialog from '../../company/product/PayDialog';
import { getPaymentSuccessPath } from '../../../utils/url-path';
import { getDollarAmountLabel } from '../../../utils/calculate-price';
import { useMessageContext } from '../../../hooks/use-message-context';

export const SpecialtyChip = ({ avatar, title }: { avatar: string; title: string; }) => {
    return (
        <Chip sx={{ mr: 1, mb: 0.5, mt: 0.5 }} avatar={<Avatar>{avatar}</Avatar>} label={title} variant="outlined" />
    );
}

interface AccountTypeProps {
    stripeUserId?: string;
    order: DictOrderFull;
    readonly?: boolean;
    showDetails?: boolean;
    showUserInfo?: boolean;
    asExternalOrder?: boolean;
}

const AccountCard = ({ stripeUserId, order: currOrder, readonly, showDetails, showUserInfo, asExternalOrder }: AccountTypeProps) => {
    const { locale } = useIntl();
    const message = useTranslatedMessage();
    const { openDialog } = useMessageContext();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const orderFullRef = useRef<DictOrderFull | null>(null);
    const [isPayDialogOpen, setIsPayDialogOpen] = useState(false);
    const isPayForAutoRenewRef = useRef(false);
    const [isConfirmCancelDialogOpen, setIsConfirmCancelDialogOpen] = useState(false);
    const isMobileScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { updateOrderDict, placeOrder } = useDictOrderAPI();
    const { cancelSubscription, createFutureSubscription } = usePaymentAPI();

    useEffect(() => {
        if (asExternalOrder !== true) return;
        orderFullRef.current = currOrder;
    }, [asExternalOrder, currOrder])

    const getAccountLabel = (userType?: number) => {
        switch (userType) {
            case UserType.FREE:
                return message('Form.Account.Level.BasicAccount');
            case UserType.UNIPRO:
                return message('Form.Account.Level.UniProAccount');
            case UserType.BIPRO:
                return message('Form.Account.Level.BiProAccount');
            case UserType.TRIPRO:
                return message('Form.Account.Level.TriProAccount');
            default:
                return message('Form.Account.Level.BasicAccount');
        }
    };

    const handleConfirmDictSelection = (industryIds: string[]) => {
        updateOrderDict({
            dictTypeIds: industryIds.join(","),
            userId: currOrder.userId.toString(),
            orderId: currOrder.orderId.toString(),
        })
    }

    const monthLabelKey = getSubscriptionDurationNameByMonthNum(+currOrder.activeMonthNum);
    const accountLabel = getAccountLabel(+currOrder.subscriptionTypeId);
    const activateDate = currOrder.activateTime !== undefined ? currOrder.activateTime.toLocaleDateString() : undefined;
    const overallExpireDate = currOrder.latestExpireDate ?? currOrder.expireTime;
    const expireDate = overallExpireDate !== undefined ? overallExpireDate.toLocaleDateString() : undefined;
    const createDate = currOrder.createTime.toLocaleDateString();

    const isOrderConfirmed = activateDate !== undefined && expireDate !== undefined;

    const activePeriod = isOrderConfirmed ? `${activateDate} - ${expireDate}` : message(monthLabelKey);
    const priceLabel = getDollarAmountLabel(currOrder.totalCharge, currOrder.currencyCode);
    const numTotalRefOrders = currOrder.refOrders?.length ?? 0;
    const autoPayDate = currOrder.latestExpireDate?.toLocaleDateString();

    const cardTitle = showDetails ? `SCIDICT${locale === 'en' ? ' ' : ''}${accountLabel}` : accountLabel;

    const handleCancelSubscription = useCallback(() =>
        cancelSubscription(currOrder.orderId, currOrder.userId.toString(), currOrder.subscriptionId ?? ''),
        [
            cancelSubscription,
            currOrder
        ]
    );

    const handleRenew = useCallback(async () => {
        const onDialogConfirm = async () => {
            isPayForAutoRenewRef.current = false;
            // New order should have activateTime equal the expireTime of the current order
            // This is determined based on non-null refOrderId and isAutoRenew = false
            const { success } = await placeOrder(
                {
                    ...currOrder,
                    isRenew: false,
                    refOrderId: currOrder.refOrderId ?? currOrder.orderId,
                    locale
                },
                () => { },
                (orderFull: DictOrderFull) => {
                    orderFullRef.current = orderFull;
                });
            if (!success) return;
            setTimeout(() => setIsPayDialogOpen(true), 1000);
        }

        openDialog({
            title: message('Form.Account.RenewSubscription'),
            content: message('Form.Account.RenewDialogContent', { product: accountLabel, duration: message(monthLabelKey), price: priceLabel }),
            onConfirm: onDialogConfirm
        })
    }, [currOrder, placeOrder, message, openDialog, accountLabel, priceLabel, monthLabelKey, locale]);

    const handleAutoRenew = useCallback(async () => {
        isPayForAutoRenewRef.current = true;
        const fetchedOrder = await createFutureSubscription(currOrder);
        orderFullRef.current = fetchedOrder;
        if (!fetchedOrder) return;
        setTimeout(() => setIsPayDialogOpen(true), 1000);
    }, [createFutureSubscription, currOrder]);

    const menuItems: MenuItemObj[] = [{
        id: 'change',
        labelKey: 'Form.Account.ChangeDictionary',
        IconComponent: EditIcon,
        onClick: () => setIsDialogOpen(true)
    },
    ...currOrder.isAutoRenew ? [
        {
            id: 'cancel',
            labelKey: 'Form.Account.CancelSubscription',
            IconComponent: CancelIcon,
            onClick: () => setIsConfirmCancelDialogOpen(true)
        }
    ] : [
        {
            id: 'renew',
            labelKey: 'Form.Account.RenewSubscription',
            IconComponent: AutorenewIcon,
            onClick: () => handleRenew()
        },
        {
            id: 'auto-renew',
            labelKey: 'Form.Account.TurnOnAutoRenew',
            IconComponent: AutorenewIcon,
            onClick: () => handleAutoRenew()
        }
    ]];

    return (
        <Card sx={{
            mt: 2,
            ':first-of-type': {
                mt: 0
            }
        }}>
            <CardHeader
                sx={{
                    '.MuiCardHeader-content': {
                        ml: 1,
                        display: 'flex',
                        alignItems: 'center',
                        '.MuiCardHeader-title': {
                            fontSize: '1rem',
                            fontWeight: 700
                        },
                        '.MuiCardHeader-subheader': {
                            fontSize: '0.9rem',
                            fontWeight: 700,
                            ml: 1
                        }
                    },
                    pb: 0
                }}
                action={
                    readonly ? null : <MenuButton
                        ariaLabel={message('Form.Account.MoreActionsAriaLabel')}
                        icon={<MoreVertIcon />}
                        items={menuItems}
                    />
                }
                title={cardTitle}
                subheader={message(monthLabelKey)}
            />
            <Grid container alignItems="center" sx={{ pl: 1, pr: 1 }}>
                <Grid item xs={12} sm={12}>
                    <List sx={{ width: '100%', pt: 2, 'li': { pt: 0 } }}>
                        {showDetails && <>
                            <ListItem>
                                <HorizontalField
                                    id={"orderno"}
                                    label={message('Order.OrderNo')}
                                    value={currOrder.orderNo}
                                />
                            </ListItem>
                            {!isOrderConfirmed && <ListItem>
                                <HorizontalField
                                    id={"create-date"}
                                    label={message('Order.CreateTime')}
                                    value={createDate}
                                />
                            </ListItem>}
                            <ListItem>
                                <HorizontalField
                                    id={"orderstate"}
                                    label={message('Order.OrderStatus')}
                                    value={currOrder.orderState}
                                />
                                {showDetails && !isOrderConfirmed && <Fab sx={{ position: 'absolute', right: '20%' }} variant="extended" color="primary" onClick={() => setIsPayDialogOpen(true)}>
                                    <AttachMoneyIcon sx={{ mr: 1 }} />
                                    {message('Order.PayNow')}
                                </Fab>}
                            </ListItem>
                        </>}
                        {currOrder.isAutoRenew && !showDetails ? <>
                            <ListItem>
                                <HorizontalField
                                    id={"active-from"}
                                    label={message('Form.Account.ActiveFrom')}
                                    value={activateDate}
                                />
                            </ListItem>
                            <ListItem>
                                <HorizontalField
                                    id={"next-autopay-date"}
                                    label={message('Form.Account.NextAutoPayDate')}
                                    value={autoPayDate}
                                />
                            </ListItem>
                            <ListItem>
                                <HorizontalField
                                    id={"next-autopay-amount"}
                                    label={message('Form.Account.NextAutoPayAmount')}
                                    value={priceLabel}
                                />
                            </ListItem>
                        </> : <ListItem>
                            <HorizontalField
                                id={"expiry"}
                                label={message('Form.Account.Expiry')}
                                value={activePeriod}
                            />
                        </ListItem>}
                        <ListItem>
                            <HorizontalField
                                id={"active-dict-product"}
                                label={message('Form.Account.SubscribedDict')}
                                sx={{
                                    alignItems: 'flex-start',
                                    '.MuiGrid-item:first-of-type': {
                                        mt: 0.5
                                    }
                                }}
                            >
                                <>
                                    {(currOrder?.dictTypeIds || [])
                                        .map((dictId) => ({ avatar: getIconCharById(dictId, locale), title: getIndustryTitleById(+dictId, locale) }))
                                        .map(({ avatar, title }) => (<SpecialtyChip key={title} avatar={avatar} title={title} />))
                                    }
                                    {!readonly && currOrder?.dictTypeIds?.length > 0 &&
                                        <Button
                                            color='success'
                                            onClick={() => setIsDialogOpen(true)}
                                        >
                                            {message('Form.Account.ChangeDictionaryAbbr')}
                                        </Button>
                                    }
                                </>
                            </HorizontalField>
                        </ListItem>
                        <ListItem>
                            <HorizontalField
                                id={"auto-renew"}
                                label={message('Form.Account.AutoRenew')}
                                value={message(currOrder.isAutoRenew ? 'General.Yes' : 'General.No')}
                            />
                        </ListItem>
                        {showDetails && <>
                            <ListItem>
                                <HorizontalField
                                    id={"total-charge"}
                                    label={message('Order.Price.TotalPrice')}
                                    value={getDollarAmountLabel(currOrder.totalCharge, currOrder.currencyCode)}
                                />
                            </ListItem>
                            {currOrder.paidAmount > 0 && <ListItem>
                                <HorizontalField
                                    id={"amount-paid"}
                                    label={message('Order.AmountPaid')}
                                    value={getDollarAmountLabel(currOrder.paidAmount, currOrder.currencyCode)}
                                />
                            </ListItem>}
                        </>}
                        {showUserInfo && <>
                            <Divider sx={{ m: 2 }} />
                            <ListItem>
                                <HorizontalField
                                    id={"user-name"}
                                    label={message('Form.Profile.Name')}
                                    value={currOrder.userName}
                                />
                            </ListItem>
                            <ListItem>
                                <HorizontalField
                                    id={"user-email"}
                                    label={message('Form.Profile.Email')}
                                    value={currOrder.userEmail}
                                />
                            </ListItem>
                        </>}
                    </List>
                </Grid>
                <DictSelectDialog
                    open={isDialogOpen}
                    setOpen={setIsDialogOpen}
                    onConfirm={handleConfirmDictSelection}
                    subscriptionType={currOrder.subscriptionTypeId}
                    defaultValue={currOrder.dictTypeIds}
                />
                <Dialog
                    open={isConfirmCancelDialogOpen}
                    onClose={() => setIsConfirmCancelDialogOpen(false)}
                >
                    <DialogTitle id="alert-dialog-title">
                        {message('Form.Account.ConfirmCancelSubscriptionDialogTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {message('Form.Account.ConfirmCancelSubscriptionDialogContent')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsConfirmCancelDialogOpen(false)}>{message('Form.Button.Cancel')}</Button>
                        <Button onClick={() => {
                            handleCancelSubscription();
                            setIsConfirmCancelDialogOpen(false);
                        }} autoFocus>
                            {message('Form.Button.Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {/* If checkoutOpen is used to guard the dialog, multiple create intent requests
                will be triggered if user close and open dialog again */}
                {orderFullRef.current && isPayDialogOpen ? <PayDialog
                    message={message}
                    orderFull={{
                        ...orderFullRef.current,
                        stripeUserId: stripeUserId ?? ''
                    }}
                    open={isPayDialogOpen}
                    setOpen={setIsPayDialogOpen}
                    // Need to bind to the current order, rather than future orderId in the payment success page
                    paymentSuccessUrl={getPaymentSuccessPath(
                        isPayForAutoRenewRef.current ? currOrder.orderNo : orderFullRef.current.orderNo,
                        isPayForAutoRenewRef.current ? currOrder.orderId : orderFullRef.current.orderId,
                        asExternalOrder ? false : isPayForAutoRenewRef.current,
                        asExternalOrder ? currOrder.isAutoRenew : isPayForAutoRenewRef.current,
                        locale)}
                    isFuture={asExternalOrder ? false : isPayForAutoRenewRef.current}
                    // Actually it rarely hits isResumePay = true, because piid is not available unless order confirmed
                    // We intend to get a new payment intent everytime user opens the pay dialog, which is fine.
                    isResumePay={asExternalOrder && Boolean(currOrder.piid)}
                /> : null}
            </Grid>
        </Card>
    )
}

export default AccountCard;