import { useState } from 'react';
import styled from '@emotion/styled/macro';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormError } from '../../types';
import { useTranslatedMessage } from '../../hooks/use-translated-message';

const InlineFormHelperText = styled.span`
    display: block;
`;

const defaultName = "password";

interface PasswordFieldProps {
    name?: string;
    label?: string;
    formError?: FormError;
}

const PasswordField = ({ name: nameDefined, label: LabelDefined, formError }: PasswordFieldProps) => {
    const [password, setPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const message = useTranslatedMessage();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const name = nameDefined || defaultName;
    const label = LabelDefined || message('Form.Field.Password');

    const errMsg = formError?.[name] || '';

    const helperTextNode = Array.isArray(errMsg) ? errMsg.map((msgItem: string) => (
        <InlineFormHelperText key={msgItem}>{msgItem}</InlineFormHelperText>
    )) : errMsg;

    return (
        <TextField
            margin="dense"
            type={showPassword ? 'text' : 'password'}
            name={name}
            id={name}
            required
            // Only auto complete existing password
            autoComplete={name === "password" ? 'current-password' : ""}
            fullWidth
            error={Boolean(errMsg)}
            // Note: To display multiple lines for helperText, alternatively, we can concatenate strings with "\n",
            // then use FormHelperTextProps={{ style: { whiteSpace: 'pre-line' } }} to help render it with css
            // This approach is cross-browser compatible and well-supported in all browsers, and is usually used to handle line breaks in long text.
            // -- based on ChatGPT
            helperText={helperTextNode}
            label={label}
            variant="outlined"
            value={password}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={togglePasswordVisibility}
                            // aria-label="toggle password visibility"
                            aria-label={message('Form.Button.ViewPassword')}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default PasswordField;
