import styled from '@emotion/styled/macro';
import { useLocation, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import useNavigate from '../../../hooks/use-navigate';
import { USER_MENU_ITEMS, GLOSSARY_LINKS, Path } from '../../../constants';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import Breadcrumbs from '../Breadcrumbs';
import { isPathsEqual } from '../../../utils/url-path';

const GlossaryContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Category = styled.div`
    margin-right: 20px;
    margin-top: 35px;
`;

const GlossaryContent = styled.div`
    flex: 1;
`;

const Glossary = () => {
    const menuItem = USER_MENU_ITEMS.find((item) => item.id === 'glossary');
    const { pathname } = useLocation();
    const { navigate } = useNavigate();
    const message = useTranslatedMessage();

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h5" gutterBottom>
                {message(menuItem?.labelKey ?? '')}
            </Typography>
            <Breadcrumbs />
            <GlossaryContainer>
                <Category>
                    <List dense component="nav">
                        {GLOSSARY_LINKS.map((item) => (
                            <ListItemButton
                                key={item.id}
                                selected={isPathsEqual(item.path ?? "", pathname)}
                                onClick={() => navigate(item.path ?? Path.MAIN)}
                            >
                                <ListItemText primary={message(item.labelKey)} />
                            </ListItemButton>
                        ))}
                    </List>
                </Category>
                <GlossaryContent>
                    <Outlet />
                </GlossaryContent>
            </GlossaryContainer>
        </Box>
    );
}

export default Glossary;