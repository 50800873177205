const html = `

<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "https://www.w3.org/TR/html4/strict.dtd"><html lang="en"><head><meta http-equiv=Content-Type content="text/html; charset=UTF-8"><style type="text/css" nonce="q5VEA1ulr6VncEZWl35BqA">
body,td,div,p,a,input {font-family: arial, sans-serif;}
</style><meta http-equiv="X-UA-Compatible" content="IE=edge"><link rel="shortcut icon" href="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/gmail.ico" type="image/x-icon"><title>Gmail - Forgot Your Password?</title><style type="text/css" nonce="q5VEA1ulr6VncEZWl35BqA">
body, td {font-size:13px} a:link, a:active {color:#1155CC; text-decoration:none} a:hover {text-decoration:underline; cursor: pointer} a:visited{color:##6611CC} img{border:0px} pre { white-space: pre; white-space: -moz-pre-wrap; white-space: -o-pre-wrap; white-space: pre-wrap; word-wrap: break-word; max-width: 800px; overflow: auto;} .logo { left: -7px; position: relative; }
</style><body><div class="bodycontainer"><table width=100% cellpadding=0 cellspacing=0 border=0><tr height=14px><td width=143><img src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_server_1x.png" width=143 height=59 alt="Gmail" class="logo"></td><td align=right><font size=-1 color=#777><b>J Z &lt;jesczhao@gmail.com&gt;</b></font></td></tr></table><hr><div class="maincontent"><table width=100% cellpadding=0 cellspacing=0 border=0><tr><td><font size=+1><b>Forgot Your Password?</b></font><br><font size=-1 color=#777>1 message</font></td></tr></table><hr><table width=100% cellpadding=0 cellspacing=0 border=0 class="message"><tr><td><font size=-1><b>Calvin Klein </b>&lt;calvinklein@ts.calvinklein.com&gt;</font></td><td align=right><font size=-1>Mon, Nov 28, 2022 at 11:03 PM</font><tr><td colspan=2 style="padding-bottom: 4px;"><font size=-1 class="recipient"><div class="replyto">Reply-To: Calvin Klein &lt;reply-fec215757c62027f-24_HTML-118225109-100028779-55777@em.calvinklein.com&gt;</div><div>To: jesczhao@gmail.com</div></font><tr><td colspan=2><table width=100% cellpadding=12 cellspacing=0 border=0><tr><td><div style="overflow: hidden;"><font size=-1>
<u></u>
<div>
<div style="display:none;max-height:0px;overflow:hidden">Password reset instructions enclosed. <table cellpadding="0" cellspacing="0" width="100%" style="min-width:100%"><tr><td>‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌
‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ ‌ </td></tr></table> </div>
<div style="font-size:0;line-height:0">
<img src="https://ci3.googleusercontent.com/meips/ADKq_NZNIPQi2R8G3_RcWpwcLgWuT4TQ0GSU9BSCg-B_kAK8sE3JhGRypquIzw9m-DWMkcmtnEZw_lHFP2L7k3g-WOjDAcQ8pzGbEPrzFfJBjvKjKWL2dOM8EgA_scs_vzMeKjNwC4pt-wAWui8sgmUJMFadt8TMVqj6rJ3uuP_gZy_2K_m9MBsRhOUd7xlFDM5DEaOgXOdYry8GdX43q-pc3MOJLVqIJEbFUXTMQcfcs2ytT0cWNkTDQ9TlLhup3h6v8uc-Y2ei=s0-d-e1-ft#https://click.em.calvinklein.com/open.aspx?ffcb10-fec215757c62027f-fe3015717365047a7d1073-fe2d157075640675721379-ff991674-fe3215717d660678741479-fef01175726303&amp;d=100181&amp;bmt=0" width="1" height="1" alt="">
</div>
<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
<tr>
<td bgcolor="#ffffff" class="m_8564105948698798113mb-pad-l20-r20"><div align="center" class="m_8564105948698798113eml-container">
<table role="presentation" border="0" cellpadding="0" cellspacing="0" width="640" class="m_8564105948698798113mb-width-100pc" bgcolor="#ffffff">
<tr>
<td width="320" align="center" valign="top" style="font-family:ProximaNova,Arial,Sans-serif;font-size:14px;color:#000000"></td>
</tr>
<tr>
<td width="320" align="center" valign="top" style="padding-top:10px;padding-bottom:10px"><a href="https://click.em.calvinklein.com/?qs=a47dfe436e3f0d2331b6aa67f54cc4fdfa97b0105ac6eae35f93680f2eb81d8ce3ccb267ce5b0983e8ad7c3be214ee877d0694806380a6227ed99d66576d3bf2a6bcdb9411450143" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3Da47dfe436e3f0d2331b6aa67f54cc4fdfa97b0105ac6eae35f93680f2eb81d8ce3ccb267ce5b0983e8ad7c3be214ee877d0694806380a6227ed99d66576d3bf2a6bcdb9411450143&amp;source=gmail&amp;ust=1712010468025000&amp;usg=AOvVaw04R96q7S5c93usbcXDuhD6"><img src="https://ci3.googleusercontent.com/meips/ADKq_NbsV0qqunewJn95SizaZESWnNAukubaNP1EXRaVzZdEHDYDi1KQ4-Lm9CShNIU0WNHCZ_82j0I4DYLjeH9zOkRWr5CI6wNBMHfbDX1u57bgMY5N4QK4E-BTKVZI4yFnfGmNOTNxWFYeccpvtpxnDix2Nf5kaJJ3CfQBVnIzID6v=s0-d-e1-ft#https://image.em.calvinklein.com/lib/fe2d157075640675721379/m/12/CalvinKlein2020_Master_logo_black_RGB.jpg" width="150" height="auto" border="0" alt="Calvin Klein"></a></td>
</tr>
<tr>
<td width="320" align="center" valign="top" style="padding-bottom:15px"><table>
<tr>
<td><a href="https://click.em.calvinklein.com/?qs=9abd952ed6d361bdfce74c75cf510861c515443e1af52686698fe7550983d7cc5012a23709d5d1baacced8c5f8c701d148a4374127009ed3e6bba1ea5ce260942b342addf3b5874e" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3D9abd952ed6d361bdfce74c75cf510861c515443e1af52686698fe7550983d7cc5012a23709d5d1baacced8c5f8c701d148a4374127009ed3e6bba1ea5ce260942b342addf3b5874e&amp;source=gmail&amp;ust=1712010468025000&amp;usg=AOvVaw2hemjS2oMbuwVseiraPA92"><img src="https://ci3.googleusercontent.com/meips/ADKq_NZxN6QLBlvi5k4rcwSpKzyNAhiLGUntnliYv0Kv0l88Bp8DFqAblBNbkl9syHef8jNwUoX8t2rFWUQeHWTW8MjfeJ_nrRD2TdrnRUH5-E5vHBKCHtryv6M85nnPW0s--5fc=s0-d-e1-ft#https://image.em.calvinklein.com/lib/fe2d157075640675721379/m/40/Women2x.jpg" width="69" height="28" border="0" alt="Women"></a></td>
<td><a href="https://click.em.calvinklein.com/?qs=97f74e7ba89571ae2f47b2e1e37efd96386d8c18d21587156686bad4dcd6e8c6c440dce1a9e35cb7d1190f9ea4ab2e7b270c20a14eb41b16ab4a44a9397b1d1ad67af477669cd86e" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3D97f74e7ba89571ae2f47b2e1e37efd96386d8c18d21587156686bad4dcd6e8c6c440dce1a9e35cb7d1190f9ea4ab2e7b270c20a14eb41b16ab4a44a9397b1d1ad67af477669cd86e&amp;source=gmail&amp;ust=1712010468025000&amp;usg=AOvVaw11jTClKoZoZHL23Jv7CxrX"><img src="https://ci3.googleusercontent.com/meips/ADKq_NaDpnD3a31HFpAj2ZZWCyS8XP-aPieWe3oAQ0KkpVfGT_1CXVLpGpg00Fz4AH3sWCVTgq5CZVUL3NINR8VZodhoTF3JYJIx55gIHuxKXDSkz8G65Fm6AQKhJUil5FGFQA=s0-d-e1-ft#https://image.em.calvinklein.com/lib/fe2d157075640675721379/m/40/Men2x.jpg" width="57" height="28" border="0" alt="Men"></a></td>
<td><a href="https://click.em.calvinklein.com/?qs=1b4d237f91b742a8473fd4e97ff9fb4d67598b3bbec6235beb17db904d1851474b55a1b6f6eb98b87e1aa52f7c2e8f0a21f852cdb1784baaae3c8c44034a008ccf2dfe38065e4435" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3D1b4d237f91b742a8473fd4e97ff9fb4d67598b3bbec6235beb17db904d1851474b55a1b6f6eb98b87e1aa52f7c2e8f0a21f852cdb1784baaae3c8c44034a008ccf2dfe38065e4435&amp;source=gmail&amp;ust=1712010468025000&amp;usg=AOvVaw1TPQG9NyaHWs71folyTMv1"><img src="https://ci3.googleusercontent.com/meips/ADKq_NaCqZrZpXaMXBRAOpElOeUydbnioxmnsGdNHMU6qgYNCRJlbTa_RMZm3-T65jmRusKrm9FUuyPI6dJAZ3hrazPbTUvIOIRl_5lH87aXNmdsZFzTnxPQNVHCi7TTSqeajSc=s0-d-e1-ft#https://image.em.calvinklein.com/lib/fe2d157075640675721379/m/40/Kids2x.jpg" width="56" height="28" border="0" alt="Kids"></a></td>
<td><a href="https://click.em.calvinklein.com/?qs=b6319f0a9646771d08d02161c757222eb0ce7e8a739c89ede8e520c59a454de5fca63c8beb5bd548a2540ae1cf0ba0a9fbf2e76b54a9d1ab2883dd6b46ad858e723aa056cc9f29ba" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3Db6319f0a9646771d08d02161c757222eb0ce7e8a739c89ede8e520c59a454de5fca63c8beb5bd548a2540ae1cf0ba0a9fbf2e76b54a9d1ab2883dd6b46ad858e723aa056cc9f29ba&amp;source=gmail&amp;ust=1712010468026000&amp;usg=AOvVaw0CcgqhC27zmEqbpLvgCeTI"><img src="https://ci3.googleusercontent.com/meips/ADKq_NZAmVrtudpNV1XY9zfQMNuocCLiFxEoFI_c0Jr1F2zh414apcaGwRVn3D9l2CiQKD2fenTQBCiw7AaK5BmOJcBZE8HctYNPa6RPqlpgsqzZkPXp-oZcOZ1lspTxC8binJc=s0-d-e1-ft#https://image.em.calvinklein.com/lib/fe2d157075640675721379/m/40/Sale2x.jpg" width="44" height="28" border="0" alt="Sale"></a></td>
</tr>
</table></td>
</tr>
<tr>
<td width="320" align="center" valign="top"><img alt="" src="https://ci3.googleusercontent.com/meips/ADKq_NYnItlrTaUQt2_jYLuGaSXaqWZQyXjnkasu46uESBDybZNb3Vgi0sdbPzvMDwoyMRcWT6vsyO_LZwMNWEJtnU4PQe-7q3dKMfDjRhYOYEO_FcRyM3WKzFjxJPUq7Vsv2_vsE8MbNBnbQZaMumXkUWFoQ62tg4HWTPw8tsIq=s0-d-e1-ft#http://image.em.calvinklein.com/lib/fe2d157075640675721379/m/1/6d5b3286-5add-4001-a2c6-8fd5e4590a00.png" width="614" height="2" border="0" class="m_8564105948698798113mb-img-scale-ht2px"></td>
</tr>
<tr>
<td width="640" align="center" valign="top" bgcolor="#FFFFFF"><table role="presentation" border="0" cellpadding="0" cellspacing="0" width="100%">
<tr>
<td align="center" valign="top" height="30" style="line-height:30px;font-size:30px"> </td>
</tr>
<tr>
<td align="center" valign="top" style="font-family:ArialMT,Arial,Sans-serif;line-height:25px;font-size:18px;padding:0px 7px 0px 7px;color:#000000"><span style="font-size:23px;font-weight:bold;line-height:25px">FORGOT YOUR PASSWORD?</span></td>
</tr>
<tr>
<td align="center" valign="top" height="40" style="line-height:40px;font-size:40px"> </td>
</tr>
<tr>
<td align="center" valign="top" style="font-family:ArialMT,Arial,Sans-serif;line-height:25px;font-size:18px;padding:0px 7px 0px 7px;color:#000000">No problem. Click on the button below to reset your password. This request will expire after one hour. </td>
</tr>
</table></td>
</tr>
<tr>
<td align="center" valign="top" height="32" style="line-height:32px;font-size:32px"> </td>
</tr>
<tr>
<td align="center"><table cellpadding="0" cellspacing="0" border="0" width="100%">
<tr>
<td align="center"><table cellpadding="0" cellspacing="0" width="185" border="0" style="border-collapse:separate!important">
<tr>
<td style="background-color:#ffffff;color:#000000;font-size:17px;padding:10px 10px;border-radius:1px;font-family:Arial,Helvetica,sans-serif;text-align:center;border:solid 1px #000000"><a href="https://click.em.calvinklein.com/?qs=852489ebe240bdc56a2037397dd34c364dfc12954eec534ffb8b678918fe2025c59de7f220a18bf76d7ed324c4a413cc1b418941f6837f4a9f0f9e7fc0b4cf477d3e0c4250ca5828" style="color:#000000;text-decoration:none" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3D852489ebe240bdc56a2037397dd34c364dfc12954eec534ffb8b678918fe2025c59de7f220a18bf76d7ed324c4a413cc1b418941f6837f4a9f0f9e7fc0b4cf477d3e0c4250ca5828&amp;source=gmail&amp;ust=1712010468026000&amp;usg=AOvVaw0Q86gy3suIlb7175Jg9LnF">RESET PASSWORD</a></td>
</tr>
</table></td>
</tr>
</table></td>
</tr>
<tr>
<td align="center" valign="top" height="45" style="line-height:45px;font-size:45px"> </td>
</tr>
<tr>
<td width="640" align="center" valign="top" bgcolor="#FFFFFF" style="font-family:ArialMT,Arial,Sans-serif;line-height:25px;font-size:18px;padding:0px 7px 0px 7px;color:#000000"> If you did not request a password reset, you can safely ignore this email. </td>
</tr>
<tr>
<td align="center" valign="top" height="70" style="line-height:70px;font-size:70px"> </td>
</tr>
<tr>
<td width="320" align="center" valign="top" style="border-bottom:3px solid #000000"></td>
</tr>
<td><div style="display:none"></div>
<tr>
<td align="center" valign="top" height="23" style="line-height:23px;font-size:23px"> </td>
</tr>
<tr>
<td width="320" align="center" valign="top" style="font-family:Arial;font-size:13px;color:#999999!important;line-height:25px;text-align:left;padding:0px 35px 30px 35px" class="m_8564105948698798113legal">
If you have any questions, please visit our <a href="https://click.em.calvinklein.com/?qs=7b4e751e591a919a2c14fde2244d72d72b0853ee3e38cf92395ea12264081b6b5c3d5cdfdbecbb883ba3bb4ff24155846f7e2ebbc142bff08f6f5246676edea2dcd24944f44a3429" style="font-family:Arial;font-size:13px;color:#999999!important;text-decoration:underline" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3D7b4e751e591a919a2c14fde2244d72d72b0853ee3e38cf92395ea12264081b6b5c3d5cdfdbecbb883ba3bb4ff24155846f7e2ebbc142bff08f6f5246676edea2dcd24944f44a3429&amp;source=gmail&amp;ust=1712010468026000&amp;usg=AOvVaw3iNLT1JChPR6z1BYjdupfW">Customer Service</a> center or call us at <a style="font-family:Arial;font-size:13px;color:#999999!important;text-decoration:none">866-513-0513</a>.<br>
Thanks again for shopping at <a href="https://click.em.calvinklein.com/?qs=bd94c4d38fdf0f55858f6208b23ab5e7b7421feea87f8f4387098bed9a3454e0be72a60669b93cf934a400930a40f67b37b89088cfa328753787bfc66a2744142c0abeda54ab6a00" style="font-family:Arial;font-size:13px;color:#999999!important;text-decoration:underline" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://click.em.calvinklein.com/?qs%3Dbd94c4d38fdf0f55858f6208b23ab5e7b7421feea87f8f4387098bed9a3454e0be72a60669b93cf934a400930a40f67b37b89088cfa328753787bfc66a2744142c0abeda54ab6a00&amp;source=gmail&amp;ust=1712010468026000&amp;usg=AOvVaw1k1dB9Hc5LKzRL-W8GOUwh">calvinklein.us</a>.<br> <br>
</td>
</tr>
</td></table>
</div></td>
</tr>
</table>
</div>
</font></div></table></table></div></div><script type="text/javascript" nonce="QLbEJ0hl5YpRB1F5jBq8ew">// <![CDATA[
document.body.onload=function(){document.body.offsetHeight;window.print()};
// ]]></script></body>
`;

export default html;