import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';
import { useUserAPI } from '../../hooks/use-user-api';
import { useMessageContext } from '../../hooks/use-message-context';
import { type UserCredential, type FormError, FormType } from '../../types';
import PasswordField from './PasswordField';
import EmailField from './EmailField';
import RegisterLink from './RegisterLink';
import { updateFormError, validateFieldValue, validateResetPasswordFormFields } from '../../utils/validate-field-value';
import ForgotPasswordLink from './ForgotPasswordLink';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { useIntl } from 'react-intl';
import AuthPage from './AuthPage';
import LoadingButton from '../LoadingButton';

export default function PasswordResetForm() {
    const { resetPassword } = useUserAPI();
    const [formError, setFormError] = useState<FormError>({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const { showToastMessage } = useMessageContext();
    const [params] = useSearchParams();
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    // user email & token parsed from query params
    // fall back to empty object to avoid console error
    const user = Object.fromEntries(params || {});

    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const valueRef = name === 'password2' ? (event.currentTarget.elements.namedItem("password") as HTMLInputElement).value : undefined;
        const status = validateFieldValue({ name, value, valueRef, formType: FormType.RESET_PASS, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message))
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const userCredential: UserCredential = {
            password: (formData.get('password') || '').toString(),
            password2: (formData.get('password2') || '').toString(),
            token: user.token,
            // This field is not necessary to submit form
            // It is passed in just to fit the type UserCredential
            email: user.email
        }

        const currFormError = validateResetPasswordFormFields(userCredential, message);
        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            const message = currFormError["password"] || currFormError["token"];
            showToastMessage({ message: Array.isArray(message) ? message.join('\n') : message, type: 'warning' });
            return;
        }

        resetPassword(userCredential, setFormSubmitting);
    };

    return (
        <AuthPage title={message('Form.Auth.ForgotPassword')}>
            <Box component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate sx={{ mt: 1 }}>
                <EmailField disabled initialValue={user.email} />
                <PasswordField label={message('Form.Field.NewPassword')} formError={formError} />
                <PasswordField name="password2" label={message('Form.Field.ConfirmNewPassword')} formError={formError} />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={Object.keys(formError).length > 0}
                    loading={formSubmitting}
                >
                    {message('Form.Button.ConfirmChangePassword')}
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <ForgotPasswordLink message={message} locale={locale} />
                    </Grid>
                    <Grid item>
                        <RegisterLink message={message} locale={locale} />
                    </Grid>
                </Grid>
            </Box>
        </AuthPage>
    );
}