import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { Size, Path, getLocaledAbsolutePath } from './constants';
import { useTranslatedMessage } from './hooks/use-translated-message';
import logo from './logo.png';
import { useIntl } from 'react-intl';

const StyledButton = styled(Button)`
    img {
        height: auto;
        display: block; /* removes bottom space/line */
    }
    min-width: ${Size.DRAWER_WIDTH_SHRINKED}px;
    min-height: ${Size.DRAWER_WIDTH_SHRINKED}px;
`;

interface LogoButtonProps {
    size?: number;
    tooltipPosition?: TooltipProps['placement'];
}

const LogoButton = (props: LogoButtonProps) => {
    const message = useTranslatedMessage();
    const size = props.size || Size.APPBAR_LOGO_WIDTH;
    const placement = props.tooltipPosition;
    const title = message('Navigation.BackHome');
    const { locale } = useIntl();

    return (
        <Tooltip title={title} placement={placement}>
            <Link to={getLocaledAbsolutePath(Path.MAIN, locale)}>
                <StyledButton sx={{ padding: '0 !important' }}>
                    <img src={logo} height={size} width={size} alt={title} />
                </StyledButton>
            </Link>
        </Tooltip>

    )
}

export default LogoButton;