import styled from '@emotion/styled/macro';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useUserAPI } from '../../hooks/use-user-api';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { convertUserToSubmitProfile, getUserChanged } from '../../utils/parse-user-data';
import { updateFormError, validateFieldValue, validateProfileFormFields } from '../../utils/validate-field-value';
import { useMessageContext } from '../../hooks/use-message-context';
import { TranslationMessage, useTranslatedMessage } from '../../hooks/use-translated-message';
import { type UserHydrated, type FormError, FormType, User } from '../../types';
import { StandardReadonlyEmailField as EmailField } from '../auth/EmailField';
import { StandardTextField, StandardReadonlyTextField } from '../FormTextField';
import Breadcrumbs from './Breadcrumbs';
import Accordion from '../Accordion';
import { StandardCountrySelect, StandardReadonlyCountrySelect } from '../CountrySelect';
import { USER_MENU_ITEMS } from '../../constants';
import { type CountryType } from '../../constants/country';
import { StandardPhoneField, StandardReadonlyPhoneField } from '../PhoneField';
import ButtonsComponent from './ButtonsComponent';
import { useRefreshCurrentUser } from '../../hooks/use-refresh-current-user';

const Row = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        margin: 5px 10px;
    }
`;

interface FieldsProps {
    editing: boolean;
    message: TranslationMessage;
    user?: User | null;
    formError: FormError;
    setCountryCode?: (code: string | null) => void;
}

const PersonalInformationFields = ({ editing, message, user, formError }: FieldsProps) => {
    const TextField = editing ? StandardTextField : StandardReadonlyTextField;
    return (
        <>
            <Row>
                <TextField
                    name="name"
                    label={message('Form.Profile.Name')}
                    autoComplete='name'
                    initialValue={user?.name}
                    formError={formError}
                />
                <TextField
                    name="nickname"
                    label={message('Form.Profile.Nickname')}
                    autoComplete='nickname'
                    initialValue={user?.nickname}
                    formError={formError}
                />
            </Row>
            <Row>
                <TextField
                    name="institution"
                    label={message('Form.Profile.Institution')}
                    autoComplete='organization'
                    initialValue={user?.institution}
                    formError={formError}
                />
                {/* <TextField
                            name="department"
                            label="部门"
                            initialValue={user?.department}
                            formError={formError}
                        /> */}
                <TextField
                    name="profession"
                    label={message('Form.Profile.Profession')}
                    initialValue={user?.profession}
                    formError={formError}
                />
            </Row>
            <Row>
                <TextField
                    name="industry"
                    label={message('Form.Profile.Industry')}
                    initialValue={user?.industry}
                    formError={formError}
                />
                <TextField
                    name="proname"
                    label={message('Form.Profile.Specialization')}
                    initialValue={user?.proname}
                    formError={formError}
                />
            </Row>
        </>
    )
}

const TelephoneFields = ({ editing, user, formError }: FieldsProps) => {
    const PhoneField = editing ? StandardPhoneField : StandardReadonlyPhoneField;
    return (
        <Row>
            <PhoneField
                name="mobilephone"
                initialValue={user?.mobilephone}
                formError={formError}
                country={user?.country ?? ""}
            />
            <PhoneField
                name="telephone"
                initialValue={user?.telephone}
                formError={formError}
                country={user?.country ?? ""}
            />
        </Row>
    )
}

const AddressFields = ({ editing, message, user, formError, setCountryCode }: FieldsProps) => {
    const TextField = editing ? StandardTextField : StandardReadonlyTextField;
    const CountrySelect = editing ? StandardCountrySelect : StandardReadonlyCountrySelect;
    const handleOnCountryChange = useCallback((country: CountryType | null) => {
        if (!setCountryCode || !country?.code) return;
        setCountryCode(country.code);
    }, [setCountryCode]);

    return (<>
        <Row>
            <CountrySelect formError={formError} initialValue={user?.country as any} onChange={handleOnCountryChange} />
            <TextField
                name="province"
                label={message('Form.Profile.State')}
                autoComplete='address-level1'
                initialValue={user?.province}
                formError={formError}
            />
            <TextField
                name="city"
                label={message('Form.Profile.City')}
                autoComplete='address-level2'
                initialValue={user?.city}
                formError={formError}
            />
        </Row>
    </>)
}

const Profile = () => {
    const [tabEditing, setTabEditing] = useState<string>("");
    const { user } = useUserInfoContext();
    const { updateProfile } = useUserAPI();
    const [formError, setFormError] = useState<FormError>({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [countryCode, setCountryCode] = useState<string | null>(null);
    const { showToastMessage } = useMessageContext();
    const message = useTranslatedMessage();

    useRefreshCurrentUser();

    // initialize country code when loading user
    useEffect(() => {
        if (!user?.country) return;
        if (user.country === countryCode) return;
        setCountryCode(user.country);
    }, [user])

    const menuItem = USER_MENU_ITEMS.find((item) => item.id === 'profile');

    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const status = validateFieldValue({ name, value, formType: FormType.PROFILE, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message));
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        // Override country label with country code
        countryCode && formData.set("country", countryCode);

        const userToSubmit: UserHydrated | null = getUserChanged(formData, user);

        if (userToSubmit === null) {
            showToastMessage({ message: message('Form.Msg.NoPersonalInfoToEdit'), type: 'warning' });
            return;
        }

        if (!(user?.id)) {
            showToastMessage({ message: message('Form.Msg.CurrentUserNonExist'), type: 'warning' });
            return;
        }

        const currFormError = validateProfileFormFields(userToSubmit, message);
        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            showToastMessage({ message: message('Form.Msg.PlsChangePersonalInfo'), type: 'warning' });
            return;
        }

        updateProfile(convertUserToSubmitProfile({
            ...userToSubmit,
            id: user.id || ''
        }), setFormSubmitting, () => setTabEditing(""));
    };

    const accordionItems = [{
        id: 'personal-information',
        label: message('Form.Profile.Title')
    }, {
        id: 'email',
        label: message('Form.Profile.Email')
    },
    {
        id: 'phone',
        label: message('Form.Profile.Phone')
    },
    {
        id: 'address',
        label: message('General.Address')
    }];

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h5" gutterBottom>
                {message(menuItem?.labelKey ?? '')}
            </Typography>
            <Breadcrumbs />
            <form onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate>
                <Accordion isSingleExpand={false} items={accordionItems} selectedIds={["personal-information", "email", "phone", "address"]}>
                    {/* <Box component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate> */}
                    <Box>
                        <PersonalInformationFields
                            editing={tabEditing === "personal-information"}
                            formError={formError}
                            user={user}
                            message={message}
                        />
                        <ButtonsComponent
                            editing={tabEditing === "personal-information"}
                            formSubmitting={formSubmitting}
                            formError={formError}
                            setFormError={setFormError}
                            message={message}
                            tabId="personal-information"
                            setTabEditing={setTabEditing}
                        />
                    </Box>
                    <Box>
                        <Row>
                            {/* TODO: Consider not setting name to EmailField so that it skips validation */}
                            <EmailField formError={formError} initialValue={user?.email} />
                        </Row>
                    </Box>
                    <Box>
                        <TelephoneFields
                            editing={tabEditing === "phone"}
                            formError={formError}
                            user={user}
                            message={message}
                        />
                        <ButtonsComponent
                            editing={tabEditing === "phone"}
                            formSubmitting={formSubmitting}
                            formError={formError}
                            setFormError={setFormError}
                            message={message}
                            tabId="phone"
                            setTabEditing={setTabEditing}
                        />
                    </Box>
                    <Box>
                        <AddressFields
                            editing={tabEditing === "address"}
                            formError={formError}
                            user={user}
                            message={message}
                            setCountryCode={setCountryCode}
                        />
                        <ButtonsComponent
                            editing={tabEditing === "address"}
                            formSubmitting={formSubmitting}
                            formError={formError}
                            setFormError={setFormError}
                            message={message}
                            tabId="address"
                            setTabEditing={setTabEditing}
                        />
                    </Box>
                </Accordion>
            </form>

        </Box>
    )
}

export default Profile;