import React, { useEffect } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import useNavigate from '../../hooks/use-navigate';
import { useSearchDataContext } from '../../hooks/use-search-data-context';
import useDictQuery from '../../hooks/use-dict-query';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { getSearchUrl } from '../../utils/get-search-url';
import { sanitizeSearchWordForUrl } from '../../utils/sanitize-search-word';
import { getIndustryIdByName } from '../../constants';

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    width: '100%',
    paddingLeft: `1em`
  },
}));

const SearchBox = () => {
  const paramKeyword = useLoaderData() as string;
  const { industryId: tabIndustryId, keyword, setKeyword, loading, setLoading } = useSearchDataContext();
  const { searchDict } = useDictQuery();
  const params = useParams();
  const industryName = params?.industry ?? "";
  const industryId = tabIndustryId || getIndustryIdByName(industryName) || "";
  const { navigateWithIndustry } = useNavigate();
  const message = useTranslatedMessage();
  const searchUrl = getSearchUrl(sanitizeSearchWordForUrl(keyword));

  const handleOnClick = () => {
    navigateWithIndustry(searchUrl, industryId);
  }

  // TODO: Need rate limiting to prevent attacks
  // Initiate searching the keyword coming from url parameter on load
  useEffect(() => {
    if (!paramKeyword) return;
    setLoading(true);
    searchDict(paramKeyword, industryId);
  }, [paramKeyword, searchDict, industryId, setLoading]);

  const handleEnter: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      navigateWithIndustry(searchUrl, industryId);
    }
  }

  return <SearchWrapper sx={{
    borderWidth: '3px', padding: '8px',
    borderRadius: '55px', background: 'rgba(50, 50, 50, 0.30)'
  }}>
    <StyledInputBase
      sx={{ background: 'rgba(50, 50, 50, 0.60)', borderRadius: '40px', color: 'white' }}
      placeholder={message('Form.PlaceHolder.PleaseEnterSearch')}
      inputProps={{ 'aria-label': message('Form.PlaceHolder.PleaseEnterSearch'), className: 'search-box-input' }}
      endAdornment={
        <InputAdornment position="end">
          <IconButton className='search-button' aria-label="search" color="primary" disabled={loading} onClick={handleOnClick}>
            {/* TODO: Make the icon button square */}
            <SearchIcon sx={{ color: 'rgba(200,200,200,0.5)', borderRadius: 0 }} />
          </IconButton>
        </InputAdornment>
      }
      value={keyword}
      onKeyDown={handleEnter}
      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setKeyword(e?.target?.value || '')}
    />
  </SearchWrapper>;
}

export default SearchBox;