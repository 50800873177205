import styled from '@emotion/styled/macro';
import 'react-phone-number-input/style.css'
import PhoneInput, { Country } from 'react-phone-number-input';
import { AsYouType } from 'libphonenumber-js';
import enLabels from 'react-phone-number-input/locale/en.json';
import zhLabels from 'react-phone-number-input/locale/zh.json'
import { useRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import LanguageIcon from '@mui/icons-material/LanguageOutlined';
import PhoneIcon from '@mui/icons-material/Phone';
import { StandardTextField, StandardReadonlyTextField, FormTextFieldProps } from './FormTextField';
import type { FormError } from '../types';
import { useTranslatedMessage } from '../hooks/use-translated-message';
import { FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';

const PhoneInternationalIcon = () => {
    return (<Box display="flex" sx={{ transform: 'scale(0.8) translate(-5px,-3px)' }}>
        <PhoneIcon />
        <LanguageIcon sx={{ transform: 'translateX(-14px)', color: 'rgba(200,200,200,0.8)', zIndex: -10 }} />
    </Box>)
}

const formatPhoneNumber = (value: string, country: Country) => {
    value = value.trim().replaceAll(" ", "");

    const isInternational = value.startsWith("+") || !country;
    const asYouType = isInternational ? new AsYouType() : new AsYouType(country);
    asYouType.input(value);
    const formatted = asYouType.getNumber()?.number;
    return formatted;
}

interface PhoneFieldProps {
    name: string;
    country?: string | null;
    initialCountry?: string | null;
    disabled?: boolean;
    formError?: FormError;
    initialValue?: string;
    fullWidth?: boolean;
    variant?: FormTextFieldProps["variant"];
    readonly?: boolean;
    anchored?: boolean;
    noLabel?: boolean;
    required?: boolean;
}

// TODO: This style prevents the country select from being clicked by mouse.
// However, it does not prevent keyboard actions.
const CountryDisabledPhoneInput = styled(PhoneInput)`
    .PhoneInputCountry {
        pointer-events: none;
    }
`

const PhoneField = ({ noLabel, country, initialCountry, name, disabled, readonly, required, anchored, fullWidth, variant, formError, initialValue }: PhoneFieldProps) => {
    // TODO: How to validate E164Number
    const [value, setValue] = useState<string | undefined>("");
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const [isFocused, setIsFocused] = useState(false);
    const inputRef = useRef<any>(null);
    const errMsg = formError?.[name] || '';
    const TextField = !readonly ? StandardTextField : StandardReadonlyTextField;
    // TODO: To be removed. We should always disable selecting a country from CountrySelect
    // const PhoneInputComponent = Boolean(country) ? CountryDisabledPhoneInput : PhoneInput;

    useEffect(() => {
        if (!initialValue) return;
        setValue(formatPhoneNumber(initialValue, initialCountry as Country));
    }, [initialValue, initialCountry]);

    const handleOnChange = (value: any) => {
        setValue(value);
    }

    const getLabel = (name: string) => {
        let label = "";
        switch (name) {
            case "telephone":
                label = message('Form.Profile.Phone');
                break;
            case "mobilephone":
                label = message('Form.Profile.Mobile');
                break;
            default:
                label = message('Form.Profile.Phone');
                break;
        }

        return label;
    }

    const isOutlined = variant !== 'standard';

    return (
        <FormControl
            fullWidth={fullWidth ?? true}
            required={required}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            sx={{
                ...isOutlined ? {
                    border: '1px solid rgb(133, 133, 133)',
                    borderWidth: isFocused ? '2px' : '1px',
                    borderColor: isFocused ? 'rgb(118, 118, 118)' : 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '4px',
                    margin: '8px 0 4px 0',
                    padding: '8px 16px',
                    ".MuiInput-underline::before": {
                        borderBottom: 'none'
                    },
                    "&:focus": {
                        borderColor: 'rgb(118, 118, 118)',
                        borderWidth: '2px',
                        ".MuiInput-underline::before": {
                            borderBottom: 'none !important'
                        }
                    },
                    "&:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderBottom: 'none !important'
                    },
                    "&:hover": {
                        borderColor: 'rgba(0, 0, 0, 0.87)',
                        ".MuiInput-underline::before": {
                            borderBottom: 'none !important'
                        }
                    }

                } : {}
            }}>
            {!noLabel && <FormLabel id={name} sx={{
                fontSize: '11.75px'
                // transform is an exact correspondance with FormTextField, but it results in extra white spaces
                // transform: 'translate(-28px, 0) scale(0.75)'
            }}>{getLabel(name)}</FormLabel>}
            <CountryDisabledPhoneInput
                ref={inputRef}
                aria-labelledby={name}
                name={name}
                readOnly={readonly}
                autoComplete='tel'
                disabled={disabled}
                international
                internationalIcon={PhoneInternationalIcon}
                countryCallingCodeEditable={false}
                country={country}
                defaultCountry={initialCountry as Country}
                //focusInputOnCountrySelection={!Boolean(country)}
                // focusInputOnCountrySelection={false}
                value={value as any}
                labels={locale === "en" ? enLabels : zhLabels}
                onChange={handleOnChange}
                inputComponent={TextField}
            // autoFocus={false}
            />
            {errMsg && <FormHelperText error>
                {errMsg}
            </FormHelperText>}
        </FormControl>
    );
}

export const StandardPhoneField = (props: PhoneFieldProps) => (<PhoneField {...props} anchored variant="standard" />)

export const StandardReadonlyPhoneField = (props: PhoneFieldProps) => (<PhoneField {...props} anchored readonly variant="standard" />)

export default PhoneField;