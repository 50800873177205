import Fab from '@mui/material/Fab';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import useNavigate from '../../../hooks/use-navigate';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';

const FreeAccountCard = () => {
    const { navigateToProductProdict } = useNavigate();
    const message = useTranslatedMessage();

    return (
        <Fab variant="extended" onClick={navigateToProductProdict}>
            <KeyboardDoubleArrowUpIcon sx={{ mr: 1 }} />
            {message('Form.Account.UpgradeToPro')}
        </Fab>
    );
}

export default FreeAccountCard;