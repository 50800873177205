import Button, { ButtonProps } from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Color } from '../constants';

const LoadingButton = ({ loading, disabled, children, ...restProps }: ButtonProps & { loading: boolean }) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <Button
                disabled={disabled || loading}
                {...restProps}
            >
                {children}
            </Button>
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                        color: Color.PRIMARY_BLUE,
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </Box>
    );
}

export default LoadingButton;