import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { UserInfoContextProvider } from './hooks/use-user-info-context';
import { MessageContextProvider } from './hooks/use-message-context';
import ThemeProvider from './ThemeProvider';
import Router from './Router';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { IntlContextProvider } from './hooks/use-intl-context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <IntlContextProvider>
        <HelmetProvider>
          <MessageContextProvider>
            <UserInfoContextProvider>
              <Router />
            </UserInfoContextProvider>
          </MessageContextProvider>
        </HelmetProvider>
      </IntlContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
