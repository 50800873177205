import * as React from 'react';
import { styled as mStyled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { TableVirtuoso, TableComponents } from 'react-virtuoso';
import { type ObjData, type ColumnData, DataType } from '../../types';
import { Color } from '../../constants';
import { useTranslatedMessage } from '../../hooks/use-translated-message';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string | Date },
    b: { [key in Key]: number | string | Date },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const StyledTableHead = mStyled(TableRow)(({ theme }) => ({
    'th,td': {
        fontWeight: 'bold',
        color: Color.PRIMARY_FONT_DARK_GREY
    }
}));

const StyledTableRow = mStyled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    cursor: 'pointer'
}));

const VirtuosoTableComponents: TableComponents<ObjData> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <StyledTableRow
        {...props}
    />,
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
        <TableBody {...props} ref={ref} />
    )),
};

interface EnhancedTableProps {
    columns: ColumnData[];
    numSelected: number;
    onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    editing: boolean;
}

function HeaderComponent(props: EnhancedTableProps) {
    const message = useTranslatedMessage();
    const { columns, onSelectAllClick, order, orderBy, numSelected, rowCount, editing, onRequestSort } =
        props;
    const createSortHandler =
        (property: string) => (event: React.MouseEvent<unknown>) => {
            onRequestSort?.(event, property);
        };

    return (
        <StyledTableHead>
            {editing && <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                        'aria-label': message('Form.Field.SelectAll'),
                    }}
                />
            </TableCell>}
            {columns.map((column) => (
                <TableCell
                    key={column.id}
                    variant="head"
                    align={column.dataType === DataType.NUMERIC ? 'right' : 'left'}
                    sortDirection={orderBy === column.id ? order : false}
                    style={{ width: column.width }}
                    sx={{
                        backgroundColor: 'background.paper',
                    }}
                >
                    {Boolean(onRequestSort)
                        ? <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                            {orderBy === column.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? message('Form.Field.SortedDescendingly') : message('Form.Field.SortedAscendingly')}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                        : column.label
                    }

                </TableCell>
            ))}
        </StyledTableHead>
    );
}

interface RowContentProps {
    _index: number;
    row: ObjData;
    columns: ColumnData[];
    isSelected: (id: number) => boolean;
    handleClick: (event: React.MouseEvent<unknown>, id: number) => void;
    editing: boolean;
}

const RowContent = (props: RowContentProps) => {
    const { _index, row, columns, isSelected, handleClick, editing } = props;
    const isItemSelected = isSelected(_index);
    const onCellClick = (event: any) => handleClick(event, _index);
    return (
        <>
            {editing && <TableCell padding="checkbox" onClick={onCellClick}>
                <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                        'aria-labelledby': `enhanced-table-checkbox-${_index}`,
                    }}
                />
            </TableCell>}
            {columns.map((column) => {
                const value = row[column.id];
                let node = value as React.ReactNode;
                switch (column.dataType) {
                    case DataType.DATE:
                        node = (value as Date).toLocaleDateString();
                        break;
                    case DataType.STRING_ARR:
                        node = (value as string[]).join(", ");
                        break;
                    default:
                        node = value as React.ReactNode;
                }
                return (
                    <TableCell
                        key={column.id}
                        align={column.dataType === DataType.NUMERIC ? 'right' : 'left'}
                        onClick={onCellClick}
                        style={{ overflowWrap: 'break-word' }}
                    >
                        {node}
                    </TableCell>
                );
            })}
        </>
    );
}

export interface TableComponentProps {
    columns: ColumnData[];
    rows: ObjData[];
    selected: readonly number[];
    setSelected: (selected: readonly number[]) => void;
    editing: boolean;
    handleRowClick: (event: React.MouseEvent<unknown>, rowIndex: number) => void;
    defaultOrderBy?: {
        columnId: string;
        direction: Order;
    },
    disableSort?: boolean;
}

export default function ReactVirtualizedTable(props: TableComponentProps) {
    const { columns, defaultOrderBy, selected, setSelected, editing, handleRowClick: handleRowContentClick } = props;
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<string>('id');

    React.useEffect(() => {
        if (defaultOrderBy) {
            setOrder(defaultOrderBy.direction);
            setOrderBy(defaultOrderBy.columnId);
        }
    }, [defaultOrderBy]);

    // TODO: remove typecast
    const rows = stableSort(props.rows as { [x: string]: string | number | Date; }[], getComparator(order, orderBy));

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: string,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            // TODO: remove type cast
            setSelected(newSelected as number[]);
            return;
        }
        setSelected([]);
    };

    const handleSelect = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleClick = editing ? handleSelect : handleRowContentClick;

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const tableHeight = (Math.min(rows.length + 1, 10)) * 58.3;

    return (
        <Paper className='virtual-table-wrapper' sx={{
            width: '100%',
            margin: '15px 0'
        }}>
            <TableVirtuoso
                style={{
                    height: tableHeight
                }}
                data={rows}
                components={VirtuosoTableComponents}
                fixedHeaderContent={() => (
                    <HeaderComponent
                        columns={columns}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={!props.disableSort ? handleRequestSort : undefined}
                        rowCount={rows.length}
                        editing={editing}
                    />
                )}
                itemContent={(_index: number, row: ObjData) => <RowContent _index={_index} row={row} columns={columns} isSelected={isSelected} handleClick={handleClick} editing={editing} />}
            />
        </Paper>
    );
}
