import { IntlProvider } from 'react-intl';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getMessages, DEFAULT_LOCALE } from '../translations';
import { LocalStorageKey } from '../constants';
import { getFromLocalStore, setLocalStore } from '../utils/local-storage';

type IntlContextValue = {
    locale: string;
    setLocale: (locale: string) => void;
}
const defaultValue: IntlContextValue = {
    locale: DEFAULT_LOCALE,
    setLocale: (locale: string) => { }
}

export const IntlContext = createContext<IntlContextValue>(defaultValue);

export const IntlContextProvider = ({ children }: { children: any }) => {
    const [locale, setLocale] = useState<string>(DEFAULT_LOCALE);

    useEffect(() => {
        const localLocale = getFromLocalStore(LocalStorageKey.LOCALE);
        setLocale(localLocale);
        window.document.documentElement.lang = localLocale;
    }, []);

    const handleSetLocale = useCallback((locale?: string | null) => {
        setLocale(locale ?? DEFAULT_LOCALE);
        setLocalStore(LocalStorageKey.LOCALE, locale ?? DEFAULT_LOCALE);
        window.document.documentElement.lang = locale ?? DEFAULT_LOCALE;
    }, []);

    const value = useMemo(() => (
        {
            locale,
            setLocale: handleSetLocale
        }
    ), [locale,
        handleSetLocale
    ]);

    return (
        <IntlContext.Provider value={value} >
            <IntlProvider locale={locale} messages={getMessages(locale)}>
                {children}
            </IntlProvider>
        </IntlContext.Provider>
    )
}

export const useIntlContext = () => useContext(IntlContext);