const html = `

<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "https://www.w3.org/TR/html4/strict.dtd"><html lang="en"><head><meta http-equiv=Content-Type content="text/html; charset=UTF-8"><style type="text/css" nonce="fhKSwhZiQs09mshHJq9xHg">
body,td,div,p,a,input {font-family: arial, sans-serif;}
</style><meta http-equiv="X-UA-Compatible" content="IE=edge"><link rel="shortcut icon" href="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/gmail.ico" type="image/x-icon"><title>Gmail - Reset password instructions</title><style type="text/css" nonce="fhKSwhZiQs09mshHJq9xHg">
body, td {font-size:13px} a:link, a:active {color:#1155CC; text-decoration:none} a:hover {text-decoration:underline; cursor: pointer} a:visited{color:##6611CC} img{border:0px} pre { white-space: pre; white-space: -moz-pre-wrap; white-space: -o-pre-wrap; white-space: pre-wrap; word-wrap: break-word; max-width: 800px; overflow: auto;} .logo { left: -7px; position: relative; }
</style><body><div class="bodycontainer"><table width=100% cellpadding=0 cellspacing=0 border=0><tr height=14px><td width=143><img src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_server_1x.png" width=143 height=59 alt="Gmail" class="logo"></td><td align=right><font size=-1 color=#777><b>J Z &lt;jesczhao@gmail.com&gt;</b></font></td></tr></table><hr><div class="maincontent"><table width=100% cellpadding=0 cellspacing=0 border=0><tr><td><font size=+1><b>Reset password instructions</b></font><br><font size=-1 color=#777>1 message</font></td></tr></table><hr><table width=100% cellpadding=0 cellspacing=0 border=0 class="message"><tr><td><font size=-1><b>BuildZoom </b>&lt;info@buildzoom.com&gt;</font></td><td align=right><font size=-1>Sat, Mar 16, 2024 at 12:09 AM</font><tr><td colspan=2 style="padding-bottom: 4px;"><font size=-1 class="recipient"><div class="replyto">Reply-To: BuildZoom &lt;info@buildzoom.com&gt;</div><div>To: jesczhao@gmail.com</div></font><tr><td colspan=2><table width=100% cellpadding=12 cellspacing=0 border=0><tr><td><div style="overflow: hidden;"><font size=-1><p>Hello <a href="mailto:jesczhao@gmail.com" target="_blank">jesczhao@gmail.com</a>!</p>
<p>Someone has requested a link to reset your password, and you can reset it through the link below.</p>
<p>
<a href="https://buildzoom.com/user/password/edit.2175536?reset_password_token=sjZwhNavMwxSzLjD74tY" target="_blank" data-saferedirecturl="https://www.google.com/url?hl=en&amp;q=https://buildzoom.com/user/password/edit.2175536?reset_password_token%3DsjZwhNavMwxSzLjD74tY&amp;source=gmail&amp;ust=1712010994182000&amp;usg=AOvVaw0y024VZ3s2EqxgBtohXZcR">Change my password</a>
</p>
<p>If you didn&#39;t request this, please let us know at <a href="mailto:info@buildzoom.com" target="_blank">info@buildzoom.com</a>.</p>
<p>Your password won&#39;t change until you access the link above and create a new one.</p>
</font></div></table></table></div></div><script type="text/javascript" nonce="82hDViWUA7TBWG-3goY0LA">// <![CDATA[
document.body.onload=function(){document.body.offsetHeight;window.print()};
// ]]></script></body>
`;

export default html;