import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TranslationMessage } from '../../../hooks/use-translated-message';
import { Product } from '../../../types';
import { Path } from '../../../constants';
import { useEffect, useState } from 'react';
import { SUBSCRIPTION_DURATIONS, getLocaledAbsolutePath } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import DictSelectDialog from './DictSelectDialog';
import { trimPathEnd } from '../../../utils/url-path';

interface ProductCardProps {
    title: string;
    description: string | React.ReactNode;
    priceIndex?: { [key: string]: Product };
    autoWidth?: boolean;
    message: TranslationMessage;
    subscriptionType?: string;
}

const ProductCard = ({ subscriptionType, autoWidth, title, description, priceIndex, message }: ProductCardProps) => {
    const [price, setPrice] = useState<number>(0);
    const [monthNo, setMonthNo] = useState<number>(1);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const navigate = useNavigate();
    const { locale } = useIntl();

    useEffect(() => {
        if (price === 0 && priceIndex) {
            setMonthNo(1);
            setPrice(priceIndex["1"].price);
        }
    }, [priceIndex, price]);

    const onPurchaseClick = (selectedDictTypes: string[]) => {
        if (!priceIndex) return;

        const product = priceIndex[monthNo];
        const productId = product.id;
        const subscriptionType = product.subscriptionType.toString();
        const basePrice = product.price;
        const dictTypes = selectedDictTypes.join(',');

        const basePath = getLocaledAbsolutePath(Path.PRODUCT_CHECKOUT, locale);
        const fullPath = trimPathEnd(`${basePath}/${productId}/${subscriptionType}/${monthNo}/${basePrice}/${dictTypes}`);
        navigate(fullPath);
    }

    return (
        <Card variant="outlined" sx={{ width: autoWidth ? '100%' : 320, display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: 1, paddingBottom: 0 }}>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Box color="text.secondary" fontSize={14}>
                    {description}
                </Box>
            </CardContent>
            {priceIndex && subscriptionType &&
                <>
                    <Grid container alignItems={'center'}>
                        <Grid item xs={9}>
                            <FormControl sx={{ pl: 4, pr: 0 }}>
                                <FormLabel sx={{ fontWeight: 500 }} id="subscription-duration">{message('Form.Account.Subscription.Duration')}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="subscription-duration"
                                    name="subscription-duration"
                                    onChange={(e: any) => {
                                        const monthNo = e.target.value;
                                        setMonthNo(monthNo);
                                        setPrice(priceIndex[monthNo].price);
                                    }}
                                    defaultValue={'1'}
                                >
                                    {SUBSCRIPTION_DURATIONS.map((duration) => (<FormControlLabel
                                        key={duration.id}
                                        value={duration.id}
                                        control={<Radio size='small' />}
                                        label={message(duration.nameKey)}
                                    />))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h5" color="text.primary">
                                {`$${price}`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <CardActions sx={{ padding: 2 }}>
                        <Button size="small">{message('Form.Account.Trial')}</Button>
                        <Button size="small" variant="contained" onClick={() => setIsDialogOpen(true)} >{message('Form.Account.Purchase')}</Button>
                    </CardActions>
                    <DictSelectDialog open={isDialogOpen} setOpen={setIsDialogOpen} onConfirm={onPurchaseClick} subscriptionType={subscriptionType} />
                </>
            }
        </Card>
    )
}

export default ProductCard;