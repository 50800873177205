import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { styled as mStyled } from '@mui/material/styles';
import styled from '@emotion/styled/macro';
import bannerImg from './dictlogo2.png';
import { useTranslatedMessage } from './hooks/use-translated-message';
import { Color, Size, Path, getLocaledAbsolutePath, getDictNameByIndustry, LocalStorageKey, getIndustryByName, isIndustryValid } from './constants';
import { useIntl } from 'react-intl';
import { getFromLocalStore } from './utils/local-storage';
import { useUserInfoContext } from './hooks/use-user-info-context';
import { isDictPath } from './utils/url-path';

interface BannerProps {
    size: number;
}

const StyledBanner = mStyled('figure')<BannerProps>(({ size }) => ({
    position: 'relative',
    width: size,
    margin: '20px auto'
}));

const StyledFigcaption = styled.figcaption`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-43%, -15%);
    display: flex;
`;

interface LogoBannerProps {
    width?: number;
    labelWidth?: number;
    sx?: SxProps;
    supportPro?: boolean;
}

const LogoBanner = ({ width = Size.MAIN_LOGO_BANNER_WIDTH, labelWidth, sx, supportPro }: LogoBannerProps) => {
    const message = useTranslatedMessage();
    const defaultWidth = Size.MAIN_LOGO_BANNER_WIDTH;
    const defaultFontSize = 1.2;
    const scaleRatio = width / defaultWidth;
    const defaultSupFontSize = 0.75;
    const currFontSize = scaleRatio * defaultFontSize;
    const currSupFontSize = scaleRatio * defaultSupFontSize;
    const { locale } = useIntl();
    const { isProPlus } = useUserInfoContext();
    const { pathname } = useLocation();
    const params = useParams();
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";
    const industry = params?.industry ?? industryInCache ?? "";

    const isOnDictPath = isDictPath(pathname, false);
    const isOnDictProPath = isDictPath(pathname, true);

    // Old Implementation
    // Add Pro label only to pro users
    // On Sign up page, the Pro label can't be shown while the user has cache in local storage
    // const showPro = (isProPlus && !isPathsEqual(pathname, Path.REGISTER)) || Boolean(industry);

    // New Implementation
    // Show Pro label for 
    // 1. Pro users on all dict pages
    // 2. Non-Pro users on pro dict pages
    const showPro = supportPro && isOnDictPath && (isProPlus || isOnDictProPath);
    const showIndustry = supportPro && Boolean(industry);

    const title = showIndustry ? getDictNameByIndustry(industry, locale).toUpperCase() : message('General.LogoText');
    // const letterSpacing = supportPro && !!labelWidth ? `${(labelWidth - title.length * currFontSize * 16) / title.length}px` : 'normal';
    // const letterSpacing = supportPro ? '1.1em' : '0.6em';
    const letterSpacing = locale === 'en' ? '0' : '0.6em';

    // Link to banner differs according to industry in local storage
    const link = supportPro ? getLocaledAbsolutePath(Path.MAIN + industry, locale) : getLocaledAbsolutePath(Path.MAIN, locale);

    return (
        <StyledBanner size={width} sx={sx}>
            <Link to={link}>
                <Button sx={{
                    '&:hover': {
                        backgroundColor: 'transparent', // Removes the hover background color
                    },
                }}>
                    <img src={bannerImg} width={width} alt={message('General.LogoAltText')} />
                </Button>
            </Link>
            <StyledFigcaption>
                <Typography
                    // variant="h6"
                    noWrap
                    component="a"
                    sx={{
                        fontWeight: 700,
                        color: Color.PRIMARY_FONT_LIGHT_GREY,
                        textDecoration: 'none',
                        fontSize: `${currFontSize}rem`,
                        letterSpacing
                    }}
                >
                    {title}
                </Typography>
            </StyledFigcaption>
            {showPro && <Typography
                noWrap
                component="sup"
                sx={{
                    position: 'absolute',
                    right: '-1em',
                    top: '1em',
                    fontSize: `${currSupFontSize}rem`,
                    fontWeight: 700,
                    color: Color.PINK_DARK,
                    textDecoration: 'none',
                    width: '1.8rem',
                    // transform: locale === 'en' && !showIndustry ? 'translate(100px, -63px)' : ''
                    // transform: 'translate(-35px, -53px)'
                    //  transform: 'translate(-150%, -200%)'
                }}
            >
                {`PRO`}
            </Typography>}
        </StyledBanner>
    )
}

export default LogoBanner;