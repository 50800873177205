import { useEffect, useState, SyntheticEvent } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled as mStyled } from '@mui/material/styles';
import { getIndustryIdByName, getSpecialties } from '../../constants';
import { useIntl } from 'react-intl';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { useSearchDataContext } from '../../hooks/use-search-data-context';
import { useLocation, useNavigate } from 'react-router';
import { getPathByIndustry } from '../../utils/get-search-url';
import { TabIndustryID } from '../../types';

const TabsBox = mStyled(Box)(({ theme }) => ({
    ...theme.mixins.responsiveMainWidth({ theme })
}));

const SearchTabs = ({ values, selectedName }: { values: string[], selectedName: string }) => {
    // tab value, "all" corresponds to empty industry
    const [value, setValue] = useState('all');
    const { locale } = useIntl();
    // industry id to be sent to api, empty industry is not to be updated to "all"
    const { setIndustryId } = useSearchDataContext();
    const specialties = getSpecialties({ locale });
    const message = useTranslatedMessage();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const visibleSpecialites = specialties.filter((specialty) => values.includes(specialty.value));

    const handleChange = (event: SyntheticEvent, newValue: string) => {
        setValue(newValue);
        setIndustryId(newValue as TabIndustryID);
        const newPath = getPathByIndustry(pathname, newValue);
        navigate(newPath);
    };
    useEffect(() => {
        const industryId = getIndustryIdByName(selectedName) ?? "";
        if (industryId === value) return;
        setIndustryId(industryId);
        setValue(industryId || "all");
    }, [selectedName, value, setIndustryId]);

    return (
        <TabsBox sx={{ width: '100%' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                textColor="secondary"
                indicatorColor="secondary"
                aria-label={message('Search.AvailableSpecialties')}
                sx={{ padding: '0 25px' }}
            >
                <Tab
                    value={"all"}
                    aria-label={message('Specialty.Label.All')}
                    label={message('Specialty.Label.All')}
                />
                {visibleSpecialites.map((specialty) => (
                    <Tab
                        key={specialty.value}
                        value={specialty.value}
                        aria-label={specialty.ariaLabel}
                        label={specialty.label}
                    />
                ))}
            </Tabs>
        </TabsBox>
    );
}

export default SearchTabs;