import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { getFromLocalStore, clearUserCache } from './local-storage';
import { LocalStorageKey } from '../constants';
import { navigateToLogin } from './navigate';

/**
 * A flexible utility function to fetch data using Axios.
 * 
 * @param url - The URL to fetch data from.
 * @param options - An object containing various options for the request.
 * @param authToken - authToken required for api call, use null to skip
 * @returns A promise that resolves with the response data.
 */
const fetchResponse = async <T = any>(
  url: string,
  options: AxiosRequestConfig = {}
): Promise<{ data: T, status: { code?: number, message?: string } }> => {
  try {
    const authToken = getFromLocalStore(LocalStorageKey.AUTH_TOKEN);
    // Default options
    const defaultOptions: AxiosRequestConfig = {
      method: 'GET', // Default HTTP method
      ...options,     // Merge user provided options
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${authToken?.jwt}`
      }
    };

    // Make the request using axios and specify the response type
    const response: AxiosResponse<T> = await axios({
      url,
      ...defaultOptions
    });

    // Return the response data
    return {
      data: response?.data,
      status: (response?.data as any)?.status
    };
  } catch (error: any) {
    const axiosError = error as AxiosError;
    const errResponse = await axiosError?.response;
    const code = errResponse?.status;
    // Handles the case when server responds 401 unauthorized due to token expiration
    if (code === 401) {
      navigateToLogin();
      clearUserCache();
    }

    return {
      data: null as T,
      status: (errResponse?.data as any)?.status
    };
  }
};

// TODO: Fix fetchResponse which breaks captcha if called 
const fetchDataNew = async <T = any>(
  url: string,
  options: AxiosRequestConfig = {}
): Promise<T> => {
  const { data } = await fetchResponse<T>(url, options);

  return data;
}

/**
 * A flexible utility function to fetch data using Axios.
 * 
 * @param url - The URL to fetch data from.
 * @param options - An object containing various options for the request.
 * @param authToken - authToken required for api call, use null to skip
 * @returns A promise that resolves with the response data.
 */
const fetchData = async <T = any>(
  url: string,
  options: AxiosRequestConfig = {},
  forceRedirect?: boolean
): Promise<T> => {
  try {
    const authToken = getFromLocalStore(LocalStorageKey.AUTH_TOKEN);
    // Default options
    const defaultOptions: AxiosRequestConfig = {
      method: 'GET', // Default HTTP method
      ...options,     // Merge user provided options
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${authToken?.jwt}`
      }
    };

    // Make the request using axios and specify the response type
    const response: AxiosResponse<T> = await axios({
      url,
      ...defaultOptions
    });

    // Return the response data
    return response.data;
  } catch (error: any) {
    const axiosError = error as AxiosError;
    const unAuthorized = await axiosError?.response?.status;
    // Handles the case when server responds 401 unauthorized due to token expiration
    if (forceRedirect && unAuthorized === 401) {
      navigateToLogin();
      clearUserCache();
    }

    return null as T;
  }
};

// TODO: Clean up error messages coming from the server, make sure no extra information is sent to UI
const getErrorMessage = (err: AxiosError) => {
  const errMessage = (err.response?.data as any)?.status?.message || err.response?.data || '';
  return errMessage || err.message;
}

// const getErrorMessageSimple = (err: AxiosError) => {
//   const errMessage = (err.response?.data as any)?.status?.message || err.response?.data || '';
//   return errMessage || err.message;
// }

export {
  fetchData,
  fetchResponse,
  getErrorMessage,
  AxiosError
};
