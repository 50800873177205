import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import LanguageIcon from '@mui/icons-material/Language';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useIntlContext } from '../../hooks/use-intl-context';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocaledAbsolutePath } from '../../constants';
import { useUserAPI } from '../../hooks/use-user-api';
import { useUserInfoContext } from '../../hooks/use-user-info-context';

const options = [{
    value: 'en',
    label: 'EN'
}, {
    value: 'zh',
    label: '中文'
}];

const IntlSelect = () => {
    const { locale, setLocale } = useIntlContext();
    const message = useTranslatedMessage();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const { updateUserLocale } = useUserAPI();
    const { user, loading } = useUserInfoContext();

    if (loading) return null;

    const handleChange = (event: SelectChangeEvent) => {
        const newLocale = event.target.value as string;
        setLocale(newLocale);
        navigate(getLocaledAbsolutePath(pathname + search, newLocale));
        updateUserLocale(user?.id ?? "", newLocale);
    };

    return (
        <Box alignItems={'center'}>
            <LanguageIcon sx={{ verticalAlign: 'middle' }} />
            <Select
                labelId="language-select-label"
                id="language-select"
                value={locale}
                label={message('Navigation.Language')}
                onChange={handleChange}
                autoWidth={false}
                sx={{ color: 'unset', marginRight: '8px', 'fieldset': { borderColor: 'transparent' } }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
}

export default IntlSelect;