import { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AuthPage from './AuthPage';
import { useUserAPI } from '../../hooks/use-user-api';
import { useMessageContext } from '../../hooks/use-message-context';
import { updateFormError, validateFieldValue, validateForgotPasswordFormFields } from '../../utils/validate-field-value';
import { FormType, type FormError } from '../../types';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import EmailField from './EmailField';
import RegisterLink from './RegisterLink';
import LoginLink from './LoginLink';
import { useIntl } from 'react-intl';
import LoadingButton from '../LoadingButton';

export default function ForgotPasswordForm() {
    const { forgotPassword } = useUserAPI();
    const { showToastMessage } = useMessageContext();
    const [formError, setFormError] = useState<FormError>({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const status = validateFieldValue({ name, value, formType: FormType.FORGOT_PASS, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message))
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const email = (formData.get('email') || '')?.toString();

        const currFormError = validateForgotPasswordFormFields(email, message);
        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            showToastMessage({ message: message('Form.Msg.PlsChangeForgotPassInfo'), type: 'warning' });
            return;
        }

        forgotPassword(email, setFormSubmitting);
    };

    return (
        <AuthPage title={message('Form.Auth.RecoverPassword')}>
            <Box component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate sx={{ mt: 1 }}>
                <EmailField fullWidth formError={formError} />
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    disabled={Object.keys(formError).length > 0}
                    loading={formSubmitting}
                >
                    {message('Form.Button.Confirm')}
                </LoadingButton>
                <Grid container>
                    <Grid item xs>
                        <RegisterLink message={message} locale={locale} />
                    </Grid>
                    <Grid item>
                        <LoginLink message={message} locale={locale} />
                    </Grid>
                </Grid>
            </Box>
        </AuthPage>
    );
}