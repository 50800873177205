import { forwardRef, createContext, useMemo, useRef, useState, useContext, useCallback } from "react";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps, AlertColor } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DEFAULT_TOAST_HIDE_DURATION, DEFAULT_TOAST_POSITION } from '../constants';
import { ToastMessageProps, DialogProps } from '../types';
import { useTranslatedMessage } from "./use-translated-message";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type MessageContextValue = {
    showToastMessage: (props: ToastMessageProps) => void;
    openDialog: (props: DialogProps) => void;
}

const defaultValue: MessageContextValue = { showToastMessage: () => { }, openDialog: () => { } }
export const MessageContext = createContext<MessageContextValue>(defaultValue);

export const MessageContextProvider = ({ children }: any) => {
    const message = useTranslatedMessage();
    const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
    const [autoHideDuration, setAutoHideDuration] = useState<number>(DEFAULT_TOAST_HIDE_DURATION);
    const [toastMessage, setToastMessage] = useState<string>("");
    const [toastPosition, setToastPosition] = useState<SnackbarOrigin | undefined>(DEFAULT_TOAST_POSITION);
    const [alertType, setAlertType] = useState<AlertColor | undefined>();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [dialogContent, setDialogContent] = useState<string>("");
    const [cancelButtonText, setCancelButtonText] = useState<string>(message('Form.Button.Cancel'));
    const [confirmButtonText, setConfirmButtonText] = useState<string>(message('Form.Button.Confirm'));
    const eventRef = useRef<{ onDialogConfirm?: () => void; onDialogCancel?: () => void }>({});
    const hasConfirmButton = Boolean(eventRef.current?.onDialogConfirm);
    const hasCancelButton = Boolean(eventRef.current?.onDialogCancel);

    const handleCloseToast = () => {
        setIsToastOpen(false);
    };

    const showToastMessage = useCallback(({ message, hideDuration, position, type }: ToastMessageProps) => {
        setIsToastOpen(true);
        setToastMessage(message);
        setAutoHideDuration(hideDuration || DEFAULT_TOAST_HIDE_DURATION);
        setToastPosition(position || DEFAULT_TOAST_POSITION);
        setAlertType(type);
    }, []);

    const openDialog = useCallback(({
        title,
        content,
        onCancel,
        onConfirm,
        cancelButtonText,
        confirmButtonText
    }: DialogProps) => {
        // This setTimeout approach pushes the state update to the end of the call stack, 
        // allowing the current rendering process to complete before updating the state.
        setDialogTitle(title || '');
        setDialogContent(content);
        cancelButtonText && setCancelButtonText(cancelButtonText);
        confirmButtonText && setConfirmButtonText(confirmButtonText);
        // Pass callback functions using ref
        eventRef.current.onDialogCancel = onCancel;
        eventRef.current.onDialogConfirm = onConfirm;
        // Temporary way to make sure confirmation dialog is opened after all the state updates
        // TODO: Fix the issue in a more stable way
        setTimeout(() => {
            setDialogOpen(true);
        }, 300);
    }, []);

    const closeDialog = () => {
        setDialogOpen(false);
        // Reset callback events on dialog close
        eventRef.current = {};
    };

    const handleCancelDialog = () => {
        eventRef.current?.onDialogCancel?.();
        closeDialog();
    }

    const handleConfirmDialog = () => {
        eventRef.current?.onDialogConfirm?.();
        closeDialog();
    }

    const value = useMemo(() => (
        {
            showToastMessage,
            openDialog
        }
    ), [showToastMessage,
        openDialog
    ]);

    return (
        <MessageContext.Provider value={value} >
            {children}
            <Snackbar
                open={isToastOpen}
                autoHideDuration={autoHideDuration}
                onClose={handleCloseToast}
                anchorOrigin={toastPosition}
            >
                <Alert onClose={handleCloseToast} severity={alertType} sx={{ width: '100%' }}>
                    {toastMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={dialogOpen}
                onClose={closeDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                </DialogContent>
                {(hasCancelButton || hasConfirmButton) &&
                    <DialogActions>
                        {hasCancelButton &&
                            <Button onClick={handleCancelDialog}>{cancelButtonText || message('Form.Button.Cancel')}</Button>
                        }
                        {hasConfirmButton &&
                            <Button onClick={handleConfirmDialog} autoFocus>
                                {confirmButtonText || message('Form.Button.Confirm')}
                            </Button>
                        }
                    </DialogActions>
                }
            </Dialog>
        </MessageContext.Provider>
    )
}

export const useMessageContext = () => {
    const { showToastMessage, openDialog } = useContext(MessageContext);
    return {
        showToastMessage,
        openDialog
    }
}
