import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Button, { ButtonProps } from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { MenuItemObj } from '../types';
import { useTranslatedMessage } from '../hooks/use-translated-message';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

interface MenuButtonProps {
    items: MenuItemObj[];
    secondaryItems?: MenuItemObj[];
}

interface IconMenuButtonProps extends MenuButtonProps {
    icon: React.ReactNode;
    ariaLabel: string;
}

interface TextMenuButtonProps extends MenuButtonProps {
    label: string;
}

const MenuButton = (props: IconMenuButtonProps | TextMenuButtonProps) => {
    const { items, secondaryItems } = props;

    const isTextButton = 'label' in props;
    const textLabel = isTextButton ? props.label : '';
    const icon = !isTextButton ? props.icon : null;
    const ariaLabel = !isTextButton ? props.ariaLabel : '';
    const message = useTranslatedMessage()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOnItemClick = (item: MenuItemObj) => () => {
        item.onClick?.();
        handleClose();
    }

    const renderItems = (items: MenuItemObj[]) => {
        return items.map((item: MenuItemObj) => {
            const IconComponent = item.IconComponent;
            const icon = IconComponent ? <IconComponent /> : null;
            return (
                <MenuItem key={item.labelKey} onClick={handleOnItemClick(item)} disableRipple>
                    {icon}
                    {message(item.labelKey)}
                </MenuItem>
            );
        });
    }

    const ButtonComponent: React.FC<ButtonProps> = isTextButton ? Button : IconButton;

    return (
        <div>
            <ButtonComponent
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label={ariaLabel}
                onClick={handleClick}
            >
                {isTextButton ? textLabel : icon}
            </ButtonComponent>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {renderItems(items)}
                {secondaryItems && <>
                    <Divider sx={{ my: 0.5 }} />
                    {renderItems(secondaryItems)}
                </>}
            </StyledMenu>
        </div>
    );
}

export default MenuButton;
