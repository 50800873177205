import { loadStripe, StripeConstructorOptions } from '@stripe/stripe-js';
import { getFromLocalStore } from './utils/local-storage';
import { LocalStorageKey } from './constants';

// TODO: Consider reading locale from cookie when local storage isn't available
// To avoid rerender, we hard-code locale key and default locale value

const locale = getFromLocalStore(LocalStorageKey.LOCALE);

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "", { locale: locale as StripeConstructorOptions["locale"] });
