import { createContext, useCallback, useEffect, useMemo, useState, useContext, useRef } from "react";
import { type User, UserStatus, type AuthToken, UserSetting } from "../types";
import { LocalStorageKey, USER_UPDATE_SETTING_URL } from '../constants';
import { getFromLocalStore, setLocalStore } from "../utils/local-storage";
import { fetchData } from "../utils/fetch-data";
import { convertUserSettingDataToSubmit } from "../utils/parse-user-data";

type UserInfoContextValue = {
    isProPlus?: boolean;
    user?: User | null;
    setUser: (user?: User | null) => void;
    userStatus: UserStatus;
    setUserStatus: (status: UserStatus) => void;
    rememberedEmail?: string;
    setRememberedEmail: (email: string) => void;
    isHighlightKeyword?: boolean;
    setIsHighlightKeyword: (highlight: boolean, forceRedirect?: boolean) => void;
    expandTarget?: boolean;
    setExpandTarget: (expand: boolean, forceRedirect?: boolean) => void;
    showAllTarget?: boolean;
    setShowAllTarget: (show: boolean) => void;
    hideWeakMatchedTarget?: boolean;
    setHideWeakMatchedTarget: (hideWeakMatch: boolean) => void;
    syncSetting: (setting: UserSetting) => void;
    // drawerOpen: true - drawer opened to the maximum
    // drawerOpen: false - drawer opened but shrinked
    // drawerOpen: undefined - drawer closed
    drawerOpen?: boolean;
    openDrawer: (forceRedirect?: boolean) => void;
    shrinkDrawer: (forceRedirect?: boolean) => void;
    closeDrawer: (forceRedirect?: boolean) => void;
    showDrawer: (forceRedirect?: boolean) => void;
    markLoginFail: () => void;
    authToken: AuthToken | null;
    setAuthToken: (authToken: AuthToken | null) => void;
    stripePublicKey?: string;
    setStripePublicKey: (key: string) => void;
    loading: boolean;
    clearUserCache: () => void;
}
const defaultValue: UserInfoContextValue = {
    loading: true,
    authToken: null,
    userStatus: UserStatus.UNREGISTERED,
    setUser: (user?: User | null) => { },
    setUserStatus: (status: UserStatus) => { },
    setRememberedEmail: (email: string) => { },
    setIsHighlightKeyword: (highlight: boolean) => { },
    setExpandTarget: (expand: boolean) => { },
    setShowAllTarget: (show: boolean) => { },
    setHideWeakMatchedTarget: (hideWeakMatch: boolean) => { },
    syncSetting: (setting: UserSetting) => { },
    openDrawer: (forceRedirect?: boolean) => { },
    shrinkDrawer: (forceRedirect?: boolean) => { },
    showDrawer: (forceRedirect?: boolean) => { },
    closeDrawer: (forceRedirect?: boolean) => { },
    markLoginFail: () => { },
    setAuthToken: (authToken: AuthToken | null) => { },
    setStripePublicKey: () => { },
    clearUserCache: () => { }
}

export const UserInfoContext = createContext<UserInfoContextValue>(defaultValue);

export const UserInfoContextProvider = ({ children }: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    // By default user is null, not logged in
    const [user, setUser] = useState<User | null>(null);
    const [stripePublicKey, setStripePublicKey] = useState("");
    const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.UNREGISTERED);
    const [failedLoginAttempts, setFailedLoginAttempts] = useState<number>(0);
    const [rememberedEmail, setRememberedEmail] = useState<string>("");
    const [isHighlightKeyword, setIsHighlightKeyword] = useState<boolean>(false);
    const [showAllTarget, setShowAllTarget] = useState<boolean>(false);
    const [expandTarget, setExpandTarget] = useState<boolean>(false);
    const [hideWeakMatchedTarget, setHideWeakMatchTarget] = useState<boolean>(false);
    const [drawerOpen, setDrawerOpen] = useState<boolean | undefined>();
    const [authToken, setAuthToken] = useState<AuthToken | null>(null);
    // ref for drawer open status, to track user's preference of expanded or shrinked side bar.
    // undefined only at initial status.
    const drawerOpenRef = useRef<boolean | undefined>();

    const updateUserSetting = useCallback(async (setting: UserSetting, forceRedirect: boolean) => {
        if (!setting.userId) return;

        try {
            await fetchData(
                USER_UPDATE_SETTING_URL,
                {
                    method: 'POST',
                    data: convertUserSettingDataToSubmit(setting)
                },
                forceRedirect
            );
        } catch (e) { }
    }, []);

    const numericUserId = +(user?.id ?? "");

    useEffect(() => {
        const localUser = getFromLocalStore(LocalStorageKey.USER);
        const localUserStatus = getFromLocalStore(LocalStorageKey.USER_STATUS);
        const localRememberedEmail = getFromLocalStore(LocalStorageKey.REMEMBERED_EMAIL);
        const localHighlightKeyword = getFromLocalStore(LocalStorageKey.HIGHLIGHT_KEYWORD);
        const localExpandTarget = getFromLocalStore(LocalStorageKey.EXPAND_TARGET);
        const localDrawerOpen = getFromLocalStore(LocalStorageKey.DRAWER_OPEN);
        const localShowAllTarget = getFromLocalStore(LocalStorageKey.SHOW_ALL_TARGET);
        const localHideWeakMatchedTarget = getFromLocalStore(LocalStorageKey.HIDE_WEAK_MATCH_TARGET);
        const localAuthToken = getFromLocalStore(LocalStorageKey.AUTH_TOKEN);
        setUser(localUser);
        setUserStatus(localUserStatus);
        setRememberedEmail(localRememberedEmail);
        if (process.env.REACT_APP_BUILD_ENV === 'static') {
            setIsHighlightKeyword(true);
        } else {
            setIsHighlightKeyword(localHighlightKeyword);
        }

        setExpandTarget(localExpandTarget);
        setShowAllTarget(localShowAllTarget);
        setHideWeakMatchTarget(localHideWeakMatchedTarget);
        setDrawerOpen(localDrawerOpen ?? undefined);
        setAuthToken(localAuthToken);
        setLoading(false);
        drawerOpenRef.current = localDrawerOpen ?? undefined;
    }, []);

    useEffect(() => {
        // Important to prevent status to be set to undefined when context is loading which causes user status temporarily being offline
        if (loading !== false) return;
        const isUserOnline = userStatus === UserStatus.ONLINE;

        // When user status changes due to login activity, set drawer to mini version when logged in, collapse drawer when offline
        // setDrawerOpen(isUserOnline ? drawerOpenRef.current ?? false : undefined);

        // Do not set drawer status based on login status, since user can close the drawer when online
        if (!isUserOnline) {
            setDrawerOpen(undefined);
        }
    }, [userStatus, loading]);

    const handleSetUser = useCallback((user: User | null) => {
        setUser(user);
        setLocalStore(LocalStorageKey.USER, user);
    }, []);

    const handleSyncSetting = useCallback((setting: UserSetting | null) => {
        if (setting?.highlightKeyword !== undefined) {
            setIsHighlightKeyword(setting?.highlightKeyword);
            setLocalStore(LocalStorageKey.HIGHLIGHT_KEYWORD, setting?.highlightKeyword);
        }
        // Sync settings data to local drawer status, skip only for guest
        if (Boolean(setting)) {
            const open = setting?.drawerMode;
            setDrawerOpen(open);
            // Convert open to null when it is undefined
            setLocalStore(LocalStorageKey.DRAWER_OPEN, open ?? null);
            if (open !== undefined) {
                drawerOpenRef.current = open;
            }
        }
    }, []);

    const handleSetUserStatus = useCallback((status: UserStatus) => {
        setUserStatus(status);
        setLocalStore(LocalStorageKey.USER_STATUS, status);
    }, []);

    const handleSetRememberedEmail = useCallback((email: string) => {
        setRememberedEmail(email);
        setLocalStore(LocalStorageKey.REMEMBERED_EMAIL, email);
    }, []);

    const handleSetIsHighlightKeyword = useCallback((highlight: boolean, forceRedirect?: boolean) => {
        setIsHighlightKeyword(highlight);
        setLocalStore(LocalStorageKey.HIGHLIGHT_KEYWORD, highlight);
        if (numericUserId > 0) {
            updateUserSetting({
                userId: numericUserId,
                highlightKeyword: highlight
            }, Boolean(forceRedirect));
        }
    }, [updateUserSetting, numericUserId]);

    const handleSetExpandTarget = useCallback((expand: boolean, forceRedirect?: boolean) => {
        setExpandTarget(expand);
        setLocalStore(LocalStorageKey.EXPAND_TARGET, expand);
        if (numericUserId > 0) {
            updateUserSetting({
                userId: numericUserId,
                expandTarget: expand
            }, Boolean(forceRedirect));
        }
    }, [updateUserSetting, numericUserId]);

    const handleSetHideWeakMatchedTarget = useCallback((hideWeakMatch: boolean) => {
        setHideWeakMatchTarget(hideWeakMatch);
        setLocalStore(LocalStorageKey.HIDE_WEAK_MATCH_TARGET, hideWeakMatch);
        // if (numericUserId > 0) {
        //     updateUserSetting({
        //         userId: numericUserId,
        //         expandTarget: expand
        //     });
        // }
    }, []);

    const handleSetShowAllTarget = useCallback((show: boolean) => {
        setShowAllTarget(show);
        setLocalStore(LocalStorageKey.SHOW_ALL_TARGET, show);
        // Do not write to DB for now
        // if (numericUserId > 0) {
        //     updateUserSetting({
        //         userId: numericUserId,
        //         expandTarget: expand
        //     });
        // }
    }, []);

    const handleSetAuthToken = useCallback((authToken: AuthToken | null) => {
        setAuthToken(authToken);
        setLocalStore(LocalStorageKey.AUTH_TOKEN, authToken);
    }, []);

    const handleSetUserWithStatus = useCallback((currUser?: User | null) => {
        if (!currUser) {
            handleSetUser(null);
            // No context is provided to decide whether the current user status should be unregistered or loginfail
            handleSetUserStatus(UserStatus.UNREGISTERED);
            return;
        }
        // Assuming every time we update with non-null user info, the user is logged in.
        handleSetUser(currUser);
        handleSetUserStatus(UserStatus.ONLINE);
    }, [handleSetUser, handleSetUserStatus]);

    const handleSetDrawerOpen = useCallback((open?: boolean, forceRedirect?: boolean) => {
        setDrawerOpen(open);
        // Convert open to null when it is undefined
        setLocalStore(LocalStorageKey.DRAWER_OPEN, open ?? null);

        if (open !== undefined) {
            drawerOpenRef.current = open;
        }

        if (numericUserId > 0) {
            updateUserSetting({
                userId: numericUserId,
                drawerMode: open
            }, Boolean(forceRedirect));
        }
    }, [updateUserSetting, numericUserId]);

    const openDrawer = useCallback((forceRedirect?: boolean) => {
        handleSetDrawerOpen(true, forceRedirect);
    }, [handleSetDrawerOpen]);

    const shrinkDrawer = useCallback((forceRedirect?: boolean) => {
        handleSetDrawerOpen(false, forceRedirect);
    }, [handleSetDrawerOpen]);

    const showDrawer = useCallback((forceRedirect?: boolean) => {
        if (drawerOpen !== undefined) return;
        handleSetDrawerOpen(drawerOpenRef.current ?? false, forceRedirect);
    }, [handleSetDrawerOpen, drawerOpen]);

    const closeDrawer = useCallback((forceRedirect?: boolean) => {
        handleSetDrawerOpen(undefined, forceRedirect);
    }, [handleSetDrawerOpen]);

    const markLoginFail = useCallback(() => {
        setFailedLoginAttempts((attempts) => attempts + 1);
        handleSetUserStatus(UserStatus.LOGINFAILED);
        handleSetUser(null);
    }, [handleSetUserStatus, handleSetUser]);

    const clearUserCache = useCallback(() => {
        handleSetUser(null);
        handleSetUserStatus(UserStatus.OFFLINE);
        handleSetAuthToken(null);
    }, [handleSetUser, handleSetUserStatus, handleSetAuthToken]);

    const value = useMemo(() => (
        {
            loading,
            isProPlus: (user?.usertype ?? 0) > 0,
            user,
            setUser: handleSetUserWithStatus,
            userStatus,
            setUserStatus: handleSetUserStatus,
            rememberedEmail,
            setRememberedEmail: handleSetRememberedEmail,
            isHighlightKeyword,
            setIsHighlightKeyword: handleSetIsHighlightKeyword,
            expandTarget,
            setExpandTarget: handleSetExpandTarget,
            showAllTarget,
            hideWeakMatchedTarget,
            setHideWeakMatchedTarget: handleSetHideWeakMatchedTarget,
            setShowAllTarget: handleSetShowAllTarget,
            syncSetting: handleSyncSetting,
            drawerOpen,
            openDrawer,
            shrinkDrawer,
            showDrawer,
            closeDrawer,
            markLoginFail,
            authToken,
            setAuthToken: handleSetAuthToken,
            stripePublicKey,
            setStripePublicKey,
            clearUserCache
        }
    ), [loading,
        user,
        handleSetUserWithStatus,
        userStatus,
        handleSetUserStatus,
        rememberedEmail,
        handleSetRememberedEmail,
        isHighlightKeyword,
        handleSetIsHighlightKeyword,
        expandTarget,
        handleSetExpandTarget,
        hideWeakMatchedTarget,
        handleSetHideWeakMatchedTarget,
        showAllTarget,
        handleSetShowAllTarget,
        drawerOpen,
        openDrawer,
        shrinkDrawer,
        showDrawer,
        closeDrawer,
        markLoginFail,
        authToken,
        handleSetAuthToken,
        stripePublicKey,
        setStripePublicKey,
        handleSyncSetting,
        clearUserCache
    ]);

    return (
        <UserInfoContext.Provider value={value} >
            {children}
        </UserInfoContext.Provider>
    )
}

export const useUserInfoContext = () => useContext(UserInfoContext);
