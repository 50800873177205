import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserCenter from './components/user-center/UserCenter';
import './App.css';
import { getHomeSEO } from './utils/get-seo-data';
import { useUpdateLocale } from './hooks/use-update-locale';
import { useIntl } from 'react-intl';

interface AppProps {
  children?: any
}
const App = (props?: AppProps) => {
  const { locale } = useIntl();
  const params = useParams();
  const industry = params?.industry ?? "";
  const { title, description, keywords } = getHomeSEO(locale, industry);
  useUpdateLocale();

  return (
    <div className="App">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
      <UserCenter miniOnly>
        {props?.children ?? <Outlet />}
      </UserCenter>
    </div>
  );
}

export default App;
