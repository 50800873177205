import { fetchData } from "../utils/fetch-data";
import { USER_SEARCH_HISTORY_URL } from '../constants';
import { convertUTCToLocalTime } from '../utils/utc-to-local';
import { type SearchHistoryData } from '../types';

export const useSearchHistory = () => {
    const fetchSearchHistory = async (userId: string) => {
        // On loading the page, user is initially underfined, and userId falls back to ""
        // Whenever userId == "0", return []
        if (!userId || userId === "0") return [];

        try {
            const { result } = await fetchData(
                USER_SEARCH_HISTORY_URL,
                {
                    params: { user_id: userId }
                },
                true
            );

            return (result ?? []).map((his: any) => ({
                id: his.log_Id,
                keyword: his.keyword,
                numSearch: his.count,
                recentTime: convertUTCToLocalTime(his.create_date)
            } as SearchHistoryData));
        } catch (e) {
            return [];
        }
    };

    return {
        fetchSearchHistory
    }
}