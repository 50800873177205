import { DICT_NAME_FULL, COMPANY_NAME_FULL } from "../../constants";
import { useIntlContext } from "../../hooks/use-intl-context";
import { useTranslatedMessage } from "../../hooks/use-translated-message";
import Template from "./Template";
import { Paragraph } from './styled';

const ContentEN = () => (
    <>
        <Paragraph>{`
        SCIDICT is an online Chinese-English bilingual dictionary product developed by Cross Language LLC, 
        a business registered in California, United States.
        `}</Paragraph>
        <Paragraph>{`
        SCIDICT is initially developed to serve the internal need of professional translators
         in Beijing Cross Language Culture Communications Ltd. (abbr BCL Translations), which provides human 
         professional translation services for customers in specialized fields such as biomedicine, 
         geology and earth sciences, electronic engineering, chemical engineering. Since 2010, BCL Translations has
         leveraged a series of internal tools to automate human translation processes, including CSDict, 
         a Windows desktop dictionary application with client-server architecture. CSDict gradually evolves 
         with enhancement of computer-aided translation (CAT) modules to assist professional translators during 
         translation, supporting terminology analysis and translation suggestion, while also producing human-verified
         terminology phrase pairs based on feedback in the translation process.
         `}</Paragraph>
        <Paragraph>{`
        To separate the dictionary functionality out from CSDict, SCIDICT is created. The mission of 
        SCIDICT is to not only serve the internal need of BCL Translations, but also benefit all people who need 
        a fast online bilingual dictionary to query specialized terminology. SCIDICT is a responsive web-based dictionary 
        targeting at returning fuzzy query result within 1 second. It has been open to the public since 2016.
        `}</Paragraph>
        <Paragraph>{`
        Now SCIDICT is being developed and operated by Cross Language LLC, a business registered in California, United States, which is 
        devoted to technology innovation related to language translation. While SCIDICT is still a free online product at
         scidict.org, it has a pro-version at scidict.pro which provides more advanced features to better serve customized 
         need for customers in different specialized fields. 
        `}</Paragraph>
    </>
)

const ContentZH = () => (
    <>
        <Paragraph>{`
        ${DICT_NAME_FULL}是由${COMPANY_NAME_FULL}开发的一款在线中英双语词典产品。
        `}</Paragraph>
        <Paragraph>{`
        SCIDICT最初是为满足北京语际文化传播有限公司（语际翻译）专业翻译人员的内部需求而开发的。
        语际翻译为客户提供生物医学、地质和地球科学、电子工程、化学工程等专业领域的人工专业翻译服务。
        自2010年以来，语际翻译利用内部工具为人工翻译流程的自动化提供支持，CSDict则是其中的一个产品。CSDict是基于
        客户端-服务器架构的 Windows 桌面词典应用程序，后期逐渐增加了计算机辅助翻译（CAT）模块功能，
        在翻译过程中提供术语分析和专业译词提示，从而协助专业翻译人员提供高质量的翻译。
        通过这一嵌入翻译流程中的术语人工确认和验证过程，我们积累了大量高质量的专业术语对。
         `}</Paragraph>
        <Paragraph>{`
        为了将词典功能从CSDict中独立出来，我们创建了SCIDICT。SCIDICT的使命不仅是为了满足 
        语际翻译的内部需求，还致力于为公众提供一款专业的在线双语词典，以满足专业人员对术语查询的需求。
        SCIDICT是一款响应式（适用于多平台）的网络词典产品，致力于提供快速专业术语模糊查询（1秒内返回结果）。
        自2016年以来，SCIDICT已经向公众开放。
        `}</Paragraph>
        <Paragraph>{`
        目前，SCIDICT由${COMPANY_NAME_FULL}开发和运营，成立于美国加利福尼亚州，专注于语言翻译相关的技术
        创新。SCIDICT同时提供免费和付费版产品，scidict.org为免费版。scidict.pro为SCIDICT的付费专业版，提供更多高级功能支持，
        以更好地满足不同专业领域客户的定制化需求。
        `}</Paragraph>
    </>
)

const AboutUs = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntlContext();

    return (<Template title={message('Navigation.AboutUs')}>
        {
            (() => {
                switch (locale) {
                    case 'en':
                        return <ContentEN />
                    case 'zh':
                        return <ContentZH />
                    default:
                        return <ContentEN />
                }
            })()
        }
    </Template>);
}

export default AboutUs;