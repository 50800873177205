import { DICT_PRODUCT_URL } from "../constants";
import useAxiosFetch from "./use-axios-fetch"
import { Product, ProductIndex } from '../types';

export const useFetchDictProducts = () => {
    const { data, loading } = useAxiosFetch(DICT_PRODUCT_URL, undefined, true);
    const products = data?.data || [];

    const productIndex = products.reduce((acc: ProductIndex, curr: any) => {
        const subscriptionType = curr.subscription_type as string;
        if (!acc[subscriptionType]) {
            acc[subscriptionType] = {} as {
                [key: string]: Product
            };
        }

        const monthNo = curr.month_no as string;

        acc[subscriptionType][monthNo] = {
            id: curr.id,
            name: curr.name,
            dictNo: curr.dict_no,
            price: curr.price,
            monthNo: curr.month_no,
            subscriptionType: curr.subscription_type
        }

        return acc;
    }, {} as ProductIndex);

    return {
        productIndex,
        loading
    }
} 