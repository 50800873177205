import Box from '@mui/material/Box';
import TableComponent from '../TableComponent';
import { DataType, ColumnData } from '../../../types';
import { useTranslatedMessage } from "../../../hooks/use-translated-message";
import { useFetchDictOrders } from '../../../hooks/use-fetch-dict-orders';
import LoadingIndicator from '../../LoadingIndicator';

const OrderHistory = () => {
    const { orders, loading } = useFetchDictOrders({ isOnlyActive: false });
    const message = useTranslatedMessage();

    // const onRowClick = (rowIndex: number) => {
    //     const currOrder = (orders ?? [])[rowIndex];
    //     console.log('currOrder', currOrder);
    // }

    const handleRowClick = (event: React.MouseEvent<unknown>, rowIndex: number) => {
        // onRowClick(rowIndex);
    }

    const columns: ColumnData[] = [
        {
            width: 150,
            label: message('Order.OrderNo'),
            id: 'orderNo',
            dataType: DataType.STRING
        },
        {
            width: 100,
            label: message('Order.OrderStatus'),
            id: 'orderState',
            dataType: DataType.NUMERIC
        },
        {
            width: 120,
            label: message('Order.CreateTime'),
            id: 'createTime',
            dataType: DataType.DATE
        },
        {
            width: 100,
            label: message('Order.SubscriptionType'),
            id: 'subscriptionType',
            dataType: DataType.STRING
        },
        {
            width: 200,
            label: message('Form.Account.Subscription.Duration'),
            id: 'validPeriod',
            dataType: DataType.STRING
        },
        {
            width: 100,
            label: message('Form.Account.SubscribedDict'),
            id: 'dictTypes',
            dataType: DataType.STRING_ARR
        },
        {
            width: 100,
            label: message('Order.Price.TotalPrice'),
            id: 'priceLabel',
            dataType: DataType.STRING
        },
    ];

    return (<Box sx={{ margin: '0 16px' }}>
        {loading
            ? <LoadingIndicator type="circular" />
            : <TableComponent
                columns={columns}
                rows={orders}
                selected={[]}
                setSelected={() => { }}
                editing={false}
                handleRowClick={handleRowClick}
                disableSort
            />
        }

    </Box>)
}

export default OrderHistory;