import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Breadcrumbs from './Breadcrumbs';
import styled from '@emotion/styled/macro';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { USER_MENU_ITEMS } from '../../constants';
import HorizontalField from '../HorizontalField';
import { FormControlLabel } from '@mui/material';

const Row = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        margin: 5px 10px;
    }
`;

const Settings = () => {
    const {
        isHighlightKeyword = false,
        setIsHighlightKeyword,
        showAllTarget,
        setShowAllTarget,
        drawerOpen = false,
        openDrawer,
        shrinkDrawer,
        expandTarget = false,
        setExpandTarget,
        hideWeakMatchedTarget,
        setHideWeakMatchedTarget,
        isProPlus
    } = useUserInfoContext();
    const message = useTranslatedMessage();
    const menuItem = USER_MENU_ITEMS.find((item) => item.id === 'settings');

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h5" gutterBottom>
                {message(menuItem?.labelKey ?? '')}
            </Typography>
            <Breadcrumbs />
            <Box component="form">
                <Row>
                    <HorizontalField
                        label={message('Form.Msg.HighlightWord')}
                    >
                        <FormControlLabel control={<Switch checked={isHighlightKeyword} onChange={(e, checked) => setIsHighlightKeyword(checked, true)} />} label={message(isHighlightKeyword ? 'General.On' : 'General.Off')} />
                    </HorizontalField>
                </Row>
                <Row>
                    <HorizontalField
                        label={message('Settings.ExpandTarget')}
                    >
                        <FormControlLabel control={<Switch checked={!expandTarget} onChange={(e, checked) => setExpandTarget(!checked, true)} />} label={message(expandTarget ? 'General.Off' : 'General.On')} />
                    </HorizontalField>
                </Row>
                {isProPlus && <Row>
                    <HorizontalField
                        label={message('Settings.ShowAllTarget')}
                    >
                        <FormControlLabel control={<Switch checked={showAllTarget} onChange={(e, checked) => setShowAllTarget(checked)} />} label={message(showAllTarget ? 'General.On' : 'General.Off')} />
                    </HorizontalField>
                </Row>}
                {isProPlus && <Row>
                    <HorizontalField
                        label={message('Settings.HideWeakTarget')}
                    >
                        <FormControlLabel control={<Switch checked={hideWeakMatchedTarget} onChange={(e, checked) => setHideWeakMatchedTarget(checked)} />} label={message(hideWeakMatchedTarget ? 'General.On' : 'General.Off')} />
                    </HorizontalField>
                </Row>}
                {/* <Row>
                    <TextField
                        name="keyword-color"
                        label={message('Settings.KeywordColor')}
                        initialValue={user?.industry}
                        formError={formError}
                    />
                </Row> */}
                <Row>
                    <HorizontalField
                        label={message('Settings.SidebarState')}
                    >
                        <FormControlLabel control={<Switch checked={drawerOpen} onChange={(e, checked) => checked ? openDrawer(true) : shrinkDrawer(true)} />} label={message(drawerOpen ? 'Settings.Expand' : 'Settings.Fold')} />
                    </HorizontalField>
                </Row>
                {/* <Row>
                    <TextField
                        name="return-entry-count"
                        label={message('Settings.ReturnEntryCount')}
                        initialValue={user?.industry}
                        formError={formError}
                    />
                </Row> */}
            </Box>
        </Box>
    );
}

export default Settings;