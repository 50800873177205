import {
    AddressElement as StripeAddressElement,
    Elements
} from '@stripe/react-stripe-js';
import { stripePromise } from '../../../stripeConstant';
import { StripeAddressElementChangeEvent, StripeAddressElementOptions } from '@stripe/stripe-js';
import { Address, User } from '../../../types';
import { Color } from '../../../constants';
import { CountryCode } from '../../../constants/country';

interface AddressElementProps {
    user?: User | null;
    onChange: (obj: StripeAddressElementChangeEvent) => void;
    defaultAddress?: Address | null;
    country: CountryCode;
}

const AddressForm = ({ defaultAddress, user, onChange, country }: AddressElementProps) => {
    // When user is loading, pause rendering
    // However, if user is null, it's OK to render
    if (user === undefined) return null;

    const addressOptions: StripeAddressElementOptions = {
        autocomplete: { mode: 'automatic' as const },
        mode: 'billing' as const,
        fields: { phone: 'never' as const },
        // Do not allow selecting country in AddressElement,
        // We select country using CountrySelect.
        allowedCountries: [country],
        // Do not fallback all default values to empty string, so that it does not show extra fields when country is not US
        // When user is falsey, we should still preserve user input as the fallback of AddressElement
        defaultValues: {
            name: defaultAddress?.fullName ?? user?.name,
            // phone: defaultAddress?.phone ?? user?.mobilephone ?? user?.telephone,
            address: {
                line1: defaultAddress?.addressLine1,
                line2: defaultAddress?.addressLine2,
                city: defaultAddress?.city ?? user?.city,
                state: defaultAddress?.state ?? user?.province,
                country: defaultAddress?.country ?? user?.country ?? '',
                postal_code: defaultAddress?.postalCode
            }
        },
        // validation: {
        //     phone: {
        //         required: 'never',
        //     }
        // }
    };
    return (<StripeAddressElement options={addressOptions} onChange={onChange} />)
}

const AddressElement = ({ user, onChange, defaultAddress, country }: AddressElementProps) => {
    const appearance = {
        theme: 'stripe' as const,
        variables: {
            colorPrimary: '#0570de',
            colorBackground: '#ffffff',
            colorText: '#30313d',
            colorDanger: Color.ERROR,
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '2px',
            borderRadius: '4px',
        },
        rules: {
            ".Input": {
                padding: '16.5px 14px',
                margin: '12px 0',
                borderColor: 'rgba(133, 133, 133, 0.5)',
                transition: 'none'
            },
            ".Input:focus": {
                boxShadow: 'none',
                borderColor: 'rgb(118, 118, 118)',
                borderWidth: '2px',
                padding: '15.5px 13px'
            },
            ".Input:hover": {
                borderColor: 'rgb(118, 118, 118)'
            }
        }
    };
    const elementsOptions = {
        appearance
    }

    return (
        <Elements stripe={stripePromise} options={elementsOptions}>
            {/* AddressForm needs to be placed inside a separate component
                    inside the Elements context, required by Stripe */}
            <AddressForm user={user} onChange={onChange} defaultAddress={defaultAddress} country={country} />
        </Elements>
    )
}

export default AddressElement;