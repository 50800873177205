import { USER_PAYMENT_METHOD_URL } from "../constants";
import useAxiosFetch from "./use-axios-fetch"
import { useUserInfoContext } from "./use-user-info-context";
import { parsePaymentCardData, parsePaymentData } from "../utils/parse-payment-method-data";
import type { IPaymentCard, IPaymentMethod } from "../types";

export const useFetchPaymentMethods = () => {
    const { user, loading: userLoading } = useUserInfoContext();
    const userId = user?.id ?? '';

    const skip = !Boolean(userId) || userLoading;

    // Need to skip fetching orders when userId is not ready.
    // Otherwise it ends up with empty userId sent in params
    const { data: resultData, loading } = useAxiosFetch<{ paymentMethod: IPaymentMethod[]; paymentCard: IPaymentCard[]; }>(USER_PAYMENT_METHOD_URL, {
        method: 'GET',
        params: { userId }
    }, false, skip);

    const paymentCards: IPaymentCard[] = (resultData?.paymentCard ?? []).map(parsePaymentCardData);
    const paymentCardsDict = paymentCards.reduce((acc, curr: IPaymentCard) => {
        acc[curr.stripePmId] = curr;
        return acc;
    }, {} as { [key: string]: IPaymentCard })

    const paymentMethods: IPaymentMethod[] = (resultData?.paymentMethod ?? []).map((item: IPaymentMethod) => {
        const paymentMethod: IPaymentMethod = parsePaymentData(item);
        paymentMethod.card = paymentCardsDict[paymentMethod.stripePmId];
        return paymentMethod;
    }).sort((item1: IPaymentMethod, item2: IPaymentMethod) => item2.isDefault - item1.isDefault);

    return {
        paymentMethods,
        loading
    }
} 