import { ButtonLink } from '../Link';
import { TranslationMessage } from '../../hooks/use-translated-message';
import { Path, getLocaledAbsolutePath } from '../../constants';

const ForgotPasswordLink = ({ message, locale }: { message: TranslationMessage, locale: string }) => (
    <ButtonLink to={getLocaledAbsolutePath(Path.FORGOT, locale)}>
        {`${message('Form.Auth.ForgotPassword')}?`}
    </ButtonLink>
)

export default ForgotPasswordLink;