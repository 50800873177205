import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import SearchableTable from './SearchableTable';
import { DataType, type ColumnData, type FavoriteWordsData } from '../../../types';

type Sample = [string, string, string];

const FavoriteWords = () => {
    const message = useTranslatedMessage();
    const onRowClick = (rowIndex: number) => {
    }
    function createData(
        id: number,
        srcword: string,
        tgtword: string,
        keyword: string
    ): FavoriteWordsData {
        return { id, srcword, tgtword, keyword };
    }

    const columns: ColumnData[] = [
        {
            width: 200,
            label: message('Form.SourceWord'),
            id: 'srcword',
            dataType: DataType.STRING
        },
        {
            width: 200,
            label: message('Form.TargetWord'),
            id: 'tgtword',
            dataType: DataType.STRING
        },
        {
            width: 200,
            label: message('Form.SearchWord'),
            id: 'keyword',
            dataType: DataType.STRING
        }
    ];

    const rows: FavoriteWordsData[] = [];

    return <SearchableTable columns={columns} rows={rows} searchKeys={['srcword', 'tgtword']} onRowClick={onRowClick} />
}

export default FavoriteWords;