import { SxProps } from '@mui/material';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';

interface HorizontalFieldProps {
    label: string;
    id?: string;
    value?: string;
    isEdit?: boolean;
    required?: boolean;
    children?: React.ReactNode;
    sx?: SxProps;
    // Whether keeping field label and value vertically stacked
    keepStacked?: boolean;
}

const HorizontalField = ({ sx, children, label, id, value, isEdit, required, keepStacked }: HorizontalFieldProps) => {
    const hasChildren = Boolean(children);
    const labelVisible = required ? `${label} *` : label;
    return (
        <Grid container alignItems="center" sx={sx}>
            <Grid item xs={12} sm={keepStacked ? 12 : 4} sx={{ fontWeight: 500, width: '130px' }}>
                {isEdit ? (<InputLabel htmlFor={id}>{labelVisible}</InputLabel>) : labelVisible}
            </Grid>
            <Grid item xs={12} sm={keepStacked ? 12 : 8} sx={{ fontWeight: 300 }}>
                {hasChildren ? children : isEdit ?
                    (<TextField
                        id={id}
                        name={id}
                        variant="standard"
                        fullWidth
                        InputProps={{
                            sx: {
                                input: {
                                    paddingTop: 10,
                                    paddingBottom: 10
                                }
                            },
                            readOnly: true
                        }}
                        value={value}

                    />) : value}
            </Grid>
        </Grid>
    );
}

export default HorizontalField;