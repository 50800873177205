import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

interface LoadingIndicatorProps {
    type: 'circular'
}

const LoadingIndicator = ({ type }: LoadingIndicatorProps) => {
    let Component = null;
    switch (type) {
        case 'circular':
            Component = CircularProgress;
            break;
        default:
            Component = CircularProgress;
            break;
    }
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Component />
        </Box>
    )
}

export const LoadingPage = () => {
    return <Box sx={{ width: '100%', height: '100%' }}>
        <LoadingIndicator type="circular" />
    </Box>
}

export default LoadingIndicator;