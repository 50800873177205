import { useNavigate as useRouterNavigate } from 'react-router-dom';
import { getLocaledAbsolutePath, Path } from '../constants';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { buildParams } from '../utils/url-path';

const useNavigate = () => {
    const navigate = useRouterNavigate();
    const { locale } = useIntl();

    const navigateToMain = useCallback((props?: { hardRefresh?: boolean, industry?: string }) => {
        const hardRefresh = props?.hardRefresh || false;
        const industry = props?.industry || "";

        const url = getLocaledAbsolutePath(Path.MAIN + industry, locale)

        if (hardRefresh) {
            window.location.href = url;
        } else {
            navigate(url);
        }
    }, [navigate, locale]);

    const navigateToLogin = useCallback((props?: { hardRefresh?: boolean, jumpBackTo?: string }) => {
        const hardRefresh = props?.hardRefresh || false;
        const jumpBackTo = props?.jumpBackTo || '';

        let url = getLocaledAbsolutePath(Path.LOGIN, locale);
        if (jumpBackTo) {
            url += buildParams({ jumpBackTo });
        }
        if (hardRefresh) {
            window.location.href = url;
        } else {
            navigate(url);
        }
    }, [navigate, locale]);

    const navigateToRegister = useCallback(({ hardRefresh, email, orderId, orderNo, isActivate }: {
        hardRefresh?: boolean;
        email?: string;
        orderNo?: string;
        orderId?: number;
        isActivate?: boolean;
    }) => {
        const params = buildParams({ email, isActivate, orderNo, orderId });
        const targetUrl = getLocaledAbsolutePath(Path.REGISTER, locale) + params;
        if (hardRefresh) {
            window.location.href = targetUrl;
        } else {
            navigate(targetUrl);
        }

    }, [navigate, locale]);

    const navigateToAccount = useCallback((hardRefresh?: boolean) => {
        if (hardRefresh) {
            window.location.href = getLocaledAbsolutePath(Path.USER_ACCOUNT, locale);
        } else {
            navigate(getLocaledAbsolutePath(Path.USER_ACCOUNT, locale));
        }

    }, [navigate, locale]);

    const navigateToProductProdict = useCallback(() => {
        navigate(getLocaledAbsolutePath(Path.PRODUCT_PRODICT, locale));
    }, [navigate, locale]);

    const navigateWithIndustry = useCallback((searchUrl: string, industryId: string) => {
        navigate(getLocaledAbsolutePath(searchUrl, locale, industryId))
    }, [navigate, locale]);

    const navigateLocaled = useCallback((path: string) => {
        navigate(getLocaledAbsolutePath(path, locale));
    }, [navigate, locale]);

    const reload = useCallback((delay?: number) => {
        // navigate to the same route again
        setTimeout(() => navigate(0), delay ?? 1000);
    }, [navigate]);

    return {
        navigate: navigateLocaled,
        navigateToAccount,
        navigateToMain,
        navigateToLogin,
        navigateToRegister,
        navigateToProductProdict,
        navigateWithIndustry,
        reload
    }
}

export default useNavigate;