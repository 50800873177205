import * as React from 'react';
import MBreadcrumbs from '@mui/material/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import Link from '@mui/material/Link';
import { MenuItemObj } from '../../types';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { USER_MENU_ITEMS, PAGES, GLOSSARY_LINKS, FOOTER_PAGES, getLocaledAbsolutePath, Path } from '../../constants';
import { useIntl } from 'react-intl';
import { trimPathLocale } from '../../utils/url-path';

function parsePathToMenuItems(path: string): MenuItemObj[] {
    const pathsDict: { [key: string]: MenuItemObj } = {};

    [...USER_MENU_ITEMS, ...PAGES, ...FOOTER_PAGES, ...GLOSSARY_LINKS].forEach(item => {
        if (item.path) {
            pathsDict[item.path] = item;
        }
    });

    const menuItems: MenuItemObj[] = [];

    path = trimPathLocale(path);

    while (path !== "") {
        const item = pathsDict[path];
        // only add in found item!
        item && menuItems.unshift({
            id: '',
            labelKey: item.labelKey,
            IconComponent: item.IconComponent,
            path
        });
        const slashIndex = path.lastIndexOf('/');
        if (slashIndex < 0) break;
        path = path.substring(0, slashIndex);
    }

    return menuItems;
}

export default function Breadcrumbs() {
    const { pathname } = useLocation();
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const menuItems = parsePathToMenuItems(pathname);

    return (
        <MBreadcrumbs aria-label="breadcrumb" sx={{ margin: '20px 0' }}>
            <Link underline="hover" color="inherit" href={getLocaledAbsolutePath(Path.MAIN, locale)}>
                <HomeIcon sx={{ mr: 0.5 }} style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} fontSize="inherit" />
                {message('Navigation.Home')}
            </Link>
            {menuItems.map(({ path, IconComponent, labelKey, id }: MenuItemObj) => (
                <Link key={id} underline="hover" color="inherit" href={getLocaledAbsolutePath(path ?? "", locale)}  >
                    {IconComponent && <IconComponent sx={{ mr: 0.5 }} style={{ fontSize: '20px', verticalAlign: 'text-bottom' }} fontSize="inherit" />}
                    {message(labelKey)}
                </Link>
            ))}
        </MBreadcrumbs>
    );
}
