import styled from '@emotion/styled/macro';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useParams } from 'react-router-dom';
import { useUserAPI } from '../../../hooks/use-user-api';
import { useUserInfoContext } from '../../../hooks/use-user-info-context';
import { updateFormError, validateFieldValue, validateResetPasswordFormFields } from '../../../utils/validate-field-value';
import { useMessageContext } from '../../../hooks/use-message-context';
import { useRefreshCurrentUser } from '../../../hooks/use-refresh-current-user';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import { useFetchDictOrders } from '../../../hooks/use-fetch-dict-orders';
import useNavigate from '../../../hooks/use-navigate';
import { type FormError, FormType, UserCredential } from '../../../types';
import PasswordField from '../../auth/PasswordField';
import Breadcrumbs from '../Breadcrumbs';
import Accordion from '../../Accordion';
import AccountType from './AccountCard';
import OrderHistory from './OrderHistory';
import { USER_MENU_ITEMS, Path } from '../../../constants';
import PaymentMethod from './PaymentMethod';
import LoadingIndicator from '../../LoadingIndicator';
import FreeAccountCard from './FreeAccountCard';
import LoadingButton from '../../LoadingButton';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    > div {
        margin: 5px 10px;
        width: calc(50% - 20px);
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;

    > div {
        margin: 5px 10px;
    }
`;

const ButtonRow = styled(Row)`
    margin: 10px 0;
    padding: 0 5px;
    > button {
        margin: 5px;
    }
`;

const Account = () => {
    const { user, isProPlus } = useUserInfoContext();
    const { resetPassword } = useUserAPI();
    const [formError, setFormError] = useState<FormError>({});
    const [formSubmitting, setFormSubmitting] = useState(false);
    const { showToastMessage } = useMessageContext();
    const { section } = useParams();
    const [tabEditing, setTabEditing] = useState<string>("");
    const menuItem = USER_MENU_ITEMS.find((item) => item.id === 'account');
    const message = useTranslatedMessage();
    const { navigateToProductProdict } = useNavigate();
    const { orders: groupedActiveOrders, loading: activeOrdersLoading } = useFetchDictOrders({ isOnlyActive: true, isGrouped: true });

    useRefreshCurrentUser();

    const handleOnFormChange = (event: React.ChangeEvent<HTMLFormElement>) => {
        const { name, value } = event.target;
        const valueRef = name === 'password2' ? (event.currentTarget.elements.namedItem("password") as HTMLInputElement).value : undefined;
        const status = validateFieldValue({ name, value, valueRef, formType: FormType.RESET_PASS, message });

        setFormError((currFormError) => updateFormError(currFormError, name, status.message))
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);

        const userCredential: UserCredential = {
            password: (formData.get('password') || '').toString(),
            password2: (formData.get('password2') || '').toString(),
            oldPassword: (formData.get('old-password') || '').toString(),
            email: user?.email ?? ""
        }

        const currFormError = validateResetPasswordFormFields(userCredential, message, true);
        setFormError(currFormError);
        if (Object.entries(currFormError).length > 0) {
            const message = currFormError["password"];
            showToastMessage({ message: Array.isArray(message) ? message.join('\n') : message, type: 'warning' });
            return;
        }

        resetPassword(userCredential, setFormSubmitting);
    };

    const accordionItems = [{
        id: 'account-type',
        label: message('Form.Account.Subscription'),
        path: Path.USER_ACCOUNT_TYPE,
        linkNode: (
            <Tooltip title={message('Form.Account.MoreProduct')}>
                <Button variant='text' onClick={navigateToProductProdict}>{message('Form.Account.MoreProductAbbr')}</Button>
            </Tooltip>
        )
    }, {
        id: 'order-history',
        label: message('Form.Account.OrderHistory'),
        path: Path.USER_ACCOUNT_TYPE
    },
    {
        id: 'payment-method',
        label: message('Form.Account.PaymentMethod'),
        path: Path.USER_ACCOUNT_TYPE
    },
    {
        id: 'change-password',
        label: message('Form.Account.ChangePassword'),
        path: Path.USER_ACCOUNT_CHANGE_PASSWORD
    }];

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant="h5" gutterBottom>
                {message(menuItem?.labelKey ?? '')}
            </Typography>
            <Breadcrumbs />
            <Accordion isSingleExpand={true} items={accordionItems} selectedIds={section ? [section] : ['account-type']}>
                <Box>
                    {isProPlus
                        ? activeOrdersLoading !== false ? <LoadingIndicator type='circular' /> : groupedActiveOrders.map((order) => (
                            <AccountType key={order.orderId} order={order} stripeUserId={user?.stripeId} />
                        ))
                        : <FreeAccountCard />
                    }
                </Box>
                <Box>
                    <OrderHistory />
                </Box>
                <Box>
                    <PaymentMethod
                        tabEditing={tabEditing}
                        setTabEditing={setTabEditing}
                        formError={formError}
                        setFormError={setFormError}
                        message={message} />
                </Box>
                <Box component="form" onSubmit={handleSubmit} onChange={handleOnFormChange} noValidate>
                    <Container>
                        <PasswordField name="old-password" label={message('Form.Field.PleaseEnterCurrentPassword')} formError={formError} />
                    </Container>
                    <Container>
                        <PasswordField label={message('Form.Field.PleaseEnterNewPassword')} formError={formError} />
                        <PasswordField name="password2" label={message('Form.Field.PleaseConfirmNewPassword')} formError={formError} />
                    </Container>
                    <ButtonRow>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            disabled={Object.keys(formError).length > 0}
                            loading={formSubmitting}
                        >
                            {message('Form.Button.Save')}
                        </LoadingButton>
                    </ButtonRow>
                </Box>
            </Accordion>
        </Box>
    )
}

export default Account;