import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LocalePathPattern } from "../constants";
import { useIntlContext } from "./use-intl-context";

export const useUpdateLocale = () => {
    const { pathname } = useLocation();
    const { locale, setLocale } = useIntlContext();
    const lang = pathname.match(LocalePathPattern.EN) ? 'en' : 'zh';

    useEffect(() => {
        if (lang === locale) return;
        setLocale(lang);
    }, [lang, locale, setLocale]);
}