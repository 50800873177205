import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '../user-center/Breadcrumbs';

const Template = ({ title, children, noBreadcrumb }: { title: string, children: any, noBreadcrumb?: boolean }) => {
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, textAlign: 'left' }}>
            <Typography variant="h5" gutterBottom sx={{}}>
                {title}
            </Typography>
            {!noBreadcrumb && <Breadcrumbs />}
            {children}
        </Box>
    )
}

export default Template;