import { EnrichedTextMatchItem, TextMatchItem, type Entry, type RawEntry, type SearchResult } from '../types';
import { getNodeFromParts, highlightKeyword, highlightKeywords } from './highlight-content';
import { getKeywordMatchPattern, getPrimaryKeywordMatchPattern, getSecondaryKeywordMatchPattern, getSyntheticMatchScore, getTgtWords, sanitizeWords } from './word-processing-utils';

const enhanceEntry = ({ entry, srcWord, tgtWords, primaryPattern, secondaryPattern }: {
    entry: RawEntry;
    srcWord: string;
    tgtWords: string[];
    primaryPattern: string;
    secondaryPattern: string;
}) => {
    const allPrimaryMatchedKeywords = new Set<string>();
    const allSecondaryMatchedKeywords = new Set<string>();
    const targetHtmls: JSX.Element[] = [];
    const targets = sanitizeWords(entry.target);
    const targetMatchItems: TextMatchItem[] = [];
    const matchMeasures = [
        { pattern: primaryPattern, score: 5 },
        { pattern: secondaryPattern, score: 3 }
    ]
    targets.forEach(targetPhrase => {
        // TODO: sanitize target words more thoroughly from BE.
        // Now we are only removing single alphabet from matching target phrase
        // tgtWords = filterTgtWords(tgtWords);
        const [enhancedTgtPhrase, targetPhraseMatchScore, [primaryMatchedKeywords, secondaryMatchedKeywords]] = highlightKeywords({
            text: targetPhrase,
            keywords: tgtWords,
            matchMeasures
        });
        targetHtmls.push(enhancedTgtPhrase);
        targetMatchItems.push({
            text: targetPhrase,
            isMatched: targetPhraseMatchScore > 0,
            matchScore: targetPhraseMatchScore
        });
        primaryMatchedKeywords.forEach((word) => allPrimaryMatchedKeywords.add(word));
        secondaryMatchedKeywords.forEach((word) => allSecondaryMatchedKeywords.add(word));
    });

    const sourceHtml = highlightKeyword(entry.source, srcWord);
    const matchScore = getSyntheticMatchScore(targetMatchItems);

    return {
        targets,
        sourceHtml,
        targetHtmls,
        matchScore,
        isMatched: matchScore > 0,
        primaryMatchedKeywords: allPrimaryMatchedKeywords,
        secondaryMatchedKeywords: allSecondaryMatchedKeywords
    };
}

export const parseResultData = ({ data, isPro, showAllTarget, hideWeakMatchTarget }: {
    data: SearchResult;
    isPro: boolean;
    showAllTarget: boolean;
    hideWeakMatchTarget: boolean;
}): {
    srcWords: string[];
    tgtWords: EnrichedTextMatchItem[];
    minVisibleTgtWords: EnrichedTextMatchItem[];
    total: number;
    entries: Entry[];
} => {
    if (data === null) {
        return { srcWords: [], tgtWords: [], minVisibleTgtWords: [], total: 0, entries: [] };
    }

    const { total, keyword: srcWord, targets, entries: rawEntries } = data;


    const srcWords: string[] = [srcWord];
    const tgtWords: string[] = getTgtWords(targets ?? []);

    const sortedTgtWords = [...tgtWords];
    // It is important to sort tgtWords by length to match longest words first, 
    // so that longer words are not missing a match only because its shorter version
    // has been matched.
    sortedTgtWords.sort((a, b) => b.length - a.length);

    const primaryPattern = getPrimaryKeywordMatchPattern(sortedTgtWords);
    const secondaryPattern = getSecondaryKeywordMatchPattern(sortedTgtWords);
    // set of all matched keywords, lower-cased
    const allPrimaryMatchedTgtWords = new Set<string>();
    const allSecondaryMatchedTgtWords = new Set<string>();

    const entries = rawEntries.map((entry) => {
        const { targets, sourceHtml, targetHtmls, isMatched, matchScore, primaryMatchedKeywords, secondaryMatchedKeywords } = enhanceEntry({
            entry,
            srcWord,
            tgtWords: sortedTgtWords,
            primaryPattern,
            secondaryPattern
        });
        // TODO: ToLowerCase might be done earlier. Think about case-sensitiveness in matching
        // Add matched keywords from entries for later comparison with target words
        primaryMatchedKeywords.forEach(keyword => allPrimaryMatchedTgtWords.add(keyword.toLowerCase()));
        secondaryMatchedKeywords.forEach(keyword => allSecondaryMatchedTgtWords.add(keyword.toLowerCase()));
        return ({
            ...entry,
            sourceHtml,
            targets,
            targetHtmls,
            isMatched,
            matchScore
        });
    });

    // sort matched phrases
    if (isPro) {
        entries.sort((entry1, entry2) => entry2.matchScore - entry1.matchScore);
    }

    const allKeywords = [...allPrimaryMatchedTgtWords, ...allSecondaryMatchedTgtWords];

    const tgtWordMatchPattern = getKeywordMatchPattern(allKeywords);

    let tgtWordItems: EnrichedTextMatchItem[] = tgtWords
        .map((word: string) => {
            const isPrimaryMatched = allPrimaryMatchedTgtWords.has(word.toLowerCase());
            if (isPrimaryMatched) {
                return {
                    text: word,
                    html: getNodeFromParts([{
                        text: word,
                        isMatched: true,
                        matchScore: 5,
                    }]),
                    isMatched: true,
                    matchScore: 1
                }
            }
            const [node, matchScore] = highlightKeywords({
                text: word,
                keywords: allKeywords,
                matchMeasures: [{ pattern: tgtWordMatchPattern, score: 3 }]
            });
            return {
                text: word,
                isMatched: matchScore > 0,
                html: node,
                matchScore
            }
        });
    
    // show only full/partial matched target words or all based on settings, default to false
    if (!showAllTarget) {
        tgtWordItems = tgtWordItems.filter(item => item.isMatched);
    }

    // show partial matched target words only to pro users, unless hideWeakMatchTarget is set to true by pro user
    if (!isPro) {
        tgtWordItems = tgtWordItems.filter(item => item.matchScore === 1);
    }
    
    if (isPro) {
        // for pro users, sort target words by their match score
        tgtWordItems.sort((word1, word2) => (word2.matchScore ?? 0) - (word1.matchScore ?? 0));
    } else {
        // This might not be necessary since common users can't see more words now
        tgtWordItems.sort((word1, word2) => +((word2.matchScore ?? 0) === 1) - +((word1.matchScore ?? 0) === 1));
    }

    const minVisibleNum = 10;

    const fulMatchedTgtWords = tgtWordItems.filter((word) => word.isMatched && word.matchScore === 1);
    const minVisibleTgtWords = fulMatchedTgtWords.length >= minVisibleNum || hideWeakMatchTarget || !isPro
        ? fulMatchedTgtWords
        : tgtWordItems.length >= minVisibleNum
            ? tgtWordItems.slice(0, minVisibleNum)
            : tgtWordItems;
    
    return { srcWords, tgtWords: tgtWordItems, minVisibleTgtWords, total: total, entries };
}

// public static string GetSCIDictResultHtmlVIP(string word, ref string tgtwordshtml, string specialtyinfo, ref string info, string IP, string browsername, string browserversion, ref bool isen)
// {
//     word = word.Trim();

//     if (word == "")
//     {
//         tgtwordshtml = "";
//         info = "";
//         return "";
//     }

//     DateTime dtStart = DateTime.Now;
//     string resulthtml = "";
//     info = "Time Consumption：<br/>";
//     List<DataTable> dtPhraseResultList = SearchPhraseAutoList(word, 0, specialtyinfo, ref isen);
//     time1 = Math.Round(DateTime.Now.Subtract(dtStart).TotalSeconds, 4);  //millisecond
//     info += "For data：" + time1.ToString() + " s<br/>";
//     dtStart = DateTime.Now;
//     DataTable dtPhraseResult = null;
//     DataTable dtTgtWord = null;

//     string tgtword = "";
//     tgtwordshtml = "";
//     List<string> colorsrckeywordlist = new List<string>();
//     List<string> colortgtkeywordlist = new List<string>();
//     List<string> coloredtgtkeywordlist = new List<string>();
//     StringBuilder tgtwordhtml0 = new StringBuilder();

//     if (dtPhraseResultList != null && dtPhraseResultList.Count > 0)
//     {
//         dtPhraseResult = dtPhraseResultList[0];
//         if (dtPhraseResultList.Count > 1)
//         {
//             dtTgtWord = dtPhraseResultList[1];
//         }
//     }
//     if (dtTgtWord != null && dtTgtWord.Rows.Count > 0)
//     {
//         int autoid = 0;
//         if (dtTgtWord.Rows.Count == 1)
//         {
//             tgtword = dtTgtWord.Rows[0]["contenttrans"].ToString();
//             autoid = Convert.ToInt32(dtTgtWord.Rows[0]["autoid"].ToString());
//             if (tgtword != "")
//             {
//                 colortgtkeywordlist = tgtword.Split(new string[] { "; " }, StringSplitOptions.RemoveEmptyEntries).ToList();
//             }
//             tgtwordhtml0.Append(tgtword);
//         }
//         else if (dtTgtWord.Rows.Count > 1)
//         {
//             for (int j = 0; j < dtTgtWord.Rows.Count; j++)
//             {
//                 tgtword = dtTgtWord.Rows[j]["contenttrans"].ToString();
//                 if (dtTgtWord.Rows[j]["content"].ToString().Length == word.Length)
//                 {
//                     autoid = Convert.ToInt32(dtTgtWord.Rows[0]["autoid"].ToString());
//                 }
//                 if (tgtword != "")
//                 {
//                     colortgtkeywordlist.AddRange(tgtword.Split(new string[] { "; " }, StringSplitOptions.RemoveEmptyEntries));
//                 }
//                 if (tgtwordhtml0.Length > 0)
//                 {
//                     tgtwordhtml0.Append("; ");
//                 }
//                 tgtwordhtml0.Append(tgtword);
//             }
//             colortgtkeywordlist = colortgtkeywordlist.Distinct().ToList();
//         }

//         if (autoid > 0)
//         {
//             if (dtPhraseResult != null && dtPhraseResult.Rows.Count > 0) //排除dtPhraseResult中的精确匹配结果
//             {
//                 for (int i = 0; i < dtPhraseResult.Rows.Count; i++)
//                 {
//                     if (Convert.ToInt32(dtPhraseResult.Rows[i]["autoid"].ToString()) == autoid)
//                     {
//                         dtPhraseResult.Rows.RemoveAt(i);
//                         break;
//                     }
//                 }
//             }
//         }
//     }

//     if (!IsEn(word))
//     {
//         colorsrckeywordlist.Add(word);
//     }
//     else
//     {
//         if (!word.Contains(" "))
//         {
//             colorsrckeywordlist.Add(word);
//         }
//         else
//         {
//             colorsrckeywordlist.AddRange(word.Split(new char[] { ' ' }, StringSplitOptions.RemoveEmptyEntries));
//         }
//     }

//     if (dtTgtWord != null && dtTgtWord.Rows.Count > 0)
//     {
//         for (int j = 0; j < dtTgtWord.Rows.Count; j++)
//         {
//             colorsrckeywordlist.Add(dtTgtWord.Rows[j]["content"].ToString());
//         }
//     }
//     colorsrckeywordlist = colorsrckeywordlist.Distinct().ToList();

//     DateTime dt1 = DateTime.Now;
//     StringBuilder result = new StringBuilder();
//     List<int> srckeywordstarts = new List<int>();
//     List<int> srckeywordlengths = new List<int>();
//     List<int> tgtkeywordstarts = new List<int>();
//     List<int> tgtkeywordlengths = new List<int>();
//     StringBuilder srcphrasehtml = new StringBuilder();
//     StringBuilder tgtphrasehtml = new StringBuilder();
//     List<int> htmllabelindexes = new List<int>();

//     if (dtPhraseResult != null && dtPhraseResult.Rows.Count > 0)
//     {
//         List<string> tempcoloredwords = new List<string>();
//         for (int i = 0; i < dtPhraseResult.Rows.Count; i++)
//         {
//             srckeywordstarts = new List<int>();
//             srckeywordlengths = new List<int>();
//             tgtkeywordstarts = new List<int>();
//             tgtkeywordlengths = new List<int>();

//             srcphrasehtml.Length = 0;
//             srcphrasehtml.Append(dtPhraseResult.Rows[i]["content"].ToString());
//             tgtphrasehtml.Length = 0;
//             tgtphrasehtml.Append(dtPhraseResult.Rows[i]["contenttrans"].ToString());
//             GetStrMatchCount(colorsrckeywordlist, srcphrasehtml.ToString(), ref srckeywordstarts, ref srckeywordlengths, true);
//             GetStrMatchCount(colortgtkeywordlist, tgtphrasehtml.ToString(), ref tgtkeywordstarts, ref tgtkeywordlengths, ref tempcoloredwords, true);
//             coloredtgtkeywordlist.AddRange(tempcoloredwords);


//             htmllabelindexes = new List<int>();
//             AddHtmlLabel(srcphrasehtml.ToString(), ref srcphrasehtml, ref htmllabelindexes, srckeywordstarts, srckeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//             UpdateHtmlLabel(ref srcphrasehtml);

//             htmllabelindexes = new List<int>();
//             AddHtmlLabel(tgtphrasehtml.ToString(), ref tgtphrasehtml, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//             UpdateHtmlLabel(ref tgtphrasehtml);

//             result.Append("<p>      " + "<font color=\"" + "darkgreen" + "\">" + GetSpecialtyInfo(dtPhraseResult.Rows[i]["industryinfo"].ToString()) + "</font>");
//             result.Append("    " + srcphrasehtml.ToString());
//             result.Append("  ");
//             result.Append(tgtphrasehtml.ToString() + "</p>\n");
//             result.Append("<p> </p>");
//         }
//     }
//     else
//     {
//         //AppClass.ConnectDict50Server(false);
//     }

//     if (dtTgtWord != null && dtTgtWord.Rows.Count > 0 && coloredtgtkeywordlist.Count > 0)
//     {
//         coloredtgtkeywordlist = coloredtgtkeywordlist.Distinct().ToList();
//         GetStrMatchCount(coloredtgtkeywordlist, tgtwordhtml0.ToString(), ref tgtkeywordstarts, ref tgtkeywordlengths, true);

//         htmllabelindexes = new List<int>();
//         AddHtmlLabel(tgtwordhtml0.ToString(), ref tgtwordhtml0, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//         UpdateHtmlLabel(ref tgtwordhtml0);
//         tgtwordshtml = tgtwordhtml0.ToString();
//     }

//     clsCommon.searchword = word;

//     time2 = Math.Round(DateTime.Now.Subtract(dtStart).TotalSeconds, 4);
//     info += "For display：" + time2.ToString() + " s<br/>";
//     //if (result.Length > 0)
//     //{
//     //    rtxtDictResult.SelectAll();
//     //    rtxtDictResult.SelectionFont = rtffont;
//     //    //rtxtDictResult.SelectionFont = new System.Drawing.Font("Georgia", 9, System.Drawing.FontStyle.Regular);
//     //    rtxtDictResult.SelectionColor = System.Drawing.SystemColors.WindowText;
//     //    rtxtDictResult.SelectionHangingIndent = 24;
//     //    //rtxtDictResult.SelectionIndent = 24;
//     //    rtxtDictResult.SelectionLength = 0;


//     //    //rtxtDictResult.Rtf = ColorKeyword(rtxtDictResult.Rtf, new string[]{word}.ToList(), "red");
//     //    resultrtf = rtxtDictResult.Rtf;
//     //    colorkeywordlist.Add(word);
//     //    resultrtf = ColorKeyword(resultrtf, colorkeywordlist, "red");
//     //}
//     //else
//     //{
//     //    resultrtf = "";
//     //}

//     resulthtml = result.ToString();
//     result.Length = 0;

//     let resultno = 0;
//     if (dtPhraseResult != null)
//     {
//         resultno = dtPhraseResult.Rows.Count;
//     }
//     let industryid = 0;
//     if (specialtyinfo.Length > 0)
//     {
//         industryid = Convert.ToInt32(specialtyinfo);
//     }

//     //AddSearchRecord(word, resultno, industryid, time1.ToString(), time2.ToString(), DateTime.Now.ToString(), IP, browsername, browserversion);

//     return resulthtml;
// }

// public static string GetSCIDictResultHtmlVIPClassified(string word, ref string tgtwordshtml, string specialtyinfo, ref string info, string IP, string browsername, string browserversion, ref bool isen)
// {
//     word = word.Trim();
//     if (word == "")
//     {
//         tgtwordshtml = "";
//         info = "";
//         return "";
//     }

//     DateTime dtStart = DateTime.Now;
//     string resulthtml = "";
//     info = "Time Consumption：<br/>";
//     //info = "Time Consumption：<br/>";
//     List<DataTable> dtPhraseResultList = SearchPhraseAutoList(word, 0, specialtyinfo, ref isen);
//     time1 = Math.Round(DateTime.Now.Subtract(dtStart).TotalSeconds, 4);  //millisecond
//     info += "For data：" + time1.ToString() + " s<br/>";
//     dtStart = DateTime.Now;
//     DataTable dtPhraseResult = null;
//     DataTable dtTgtWord = null;

//     string tgtword = "";
//     tgtwordshtml = "";
//     List<string> colorsrckeywordlist = new List<string>();
//     List<string> colortgtkeywordlist = new List<string>();
//     List<string> coloredtgtkeywordlist = new List<string>();
//     StringBuilder tgtwordhtml0 = new StringBuilder();
//     StringBuilder tgtwordhtml1 = new StringBuilder();
//     List<int> relatedsrcwordstarts = new List<int>();
//     List<int> relatedsrcwordlengths = new List<int>();
//     List<string> relatedsrcwords = new List<string>();

//     if (dtPhraseResultList != null && dtPhraseResultList.Count > 0)
//     {
//         dtPhraseResult = dtPhraseResultList[0];
//         if (dtPhraseResultList.Count > 1)
//         {
//             dtTgtWord = dtPhraseResultList[1];
//         }
//     }
//     if (dtTgtWord != null && dtTgtWord.Rows.Count > 0)
//     {
//         int autoid = 0;

//         if (dtTgtWord.Rows.Count > 0)
//         {
//             for (int j = 0; j < dtTgtWord.Rows.Count; j++)
//             {
//                 tgtword = dtTgtWord.Rows[j]["contenttrans"].ToString();
//                 if (dtTgtWord.Rows[j]["content"].ToString().Length == word.Length)
//                 {
//                     autoid = Convert.ToInt32(dtTgtWord.Rows[j]["autoid"].ToString());
//                     tgtwordhtml0.Append(tgtword);
//                 }

//                 if (tgtword != "")
//                 {
//                     colortgtkeywordlist.AddRange(tgtword.Split(new string[] { "; " }, StringSplitOptions.RemoveEmptyEntries));
//                 }
//             }
//             colortgtkeywordlist = colortgtkeywordlist.Distinct().ToList();

//             for (int j = 0; j < colortgtkeywordlist.Count; j++)
//             {
//                 if (colortgtkeywordlist[j].Length == 1 && IsEn(colortgtkeywordlist[j]))
//                 {
//                     colortgtkeywordlist.RemoveAt(j);
//                 }
//             }
//         }

//         //if (dtTgtWord.Rows.Count == 1)
//         //{
//         //    tgtword = dtTgtWord.Rows[0]["contenttrans"].ToString();
//         //    autoid = Convert.ToInt32(dtTgtWord.Rows[0]["autoid"].ToString());
//         //    if (tgtword != "")
//         //    {
//         //        colortgtkeywordlist = tgtword.Split(new string[] { "; " }, StringSplitOptions.RemoveEmptyEntries).ToList();
//         //    }
//         //    tgtwordhtml0.Append(tgtword);
//         //}
//         //else if (dtTgtWord.Rows.Count > 1)
//         //{
//         //    for (int j = 0; j < dtTgtWord.Rows.Count; j++)
//         //    {
//         //        tgtword = dtTgtWord.Rows[j]["contenttrans"].ToString();
//         //        if (dtTgtWord.Rows[j]["content"].ToString().Length == word.Length)
//         //        {
//         //            autoid = Convert.ToInt32(dtTgtWord.Rows[0]["autoid"].ToString());
//         //            tgtwordhtml0.Append(tgtword);
//         //        }
//         //        else
//         //        {
//         //            if (tgtwordhtml1.Length > 0)
//         //            {
//         //                tgtwordhtml1.Append("<br/>");
//         //            }

//         //            relatedsrcwordstarts.Add(tgtwordhtml1.Length);
//         //            relatedsrcwordlengths.Add(dtTgtWord.Rows[j]["content"].ToString().Length);
//         //            relatedsrcwords.Add(dtTgtWord.Rows[j]["content"].ToString());
//         //            tgtwordhtml1.Append(dtTgtWord.Rows[j]["content"].ToString());
//         //            tgtwordhtml1.Append(": ");
//         //            tgtwordhtml1.Append(tgtword);
//         //        }
//         //        if (tgtword != "")
//         //        {
//         //            colortgtkeywordlist.AddRange(tgtword.Split(new string[] { "; " }, StringSplitOptions.RemoveEmptyEntries));
//         //        }

//         //    }
//         //    colortgtkeywordlist = colortgtkeywordlist.Distinct().ToList();
//         //}

//         if (autoid > 0)
//         {
//             if (dtPhraseResult != null && dtPhraseResult.Rows.Count > 0) //排除dtPhraseResult中的精确匹配结果
//             {
//                 for (int i = 0; i < dtPhraseResult.Rows.Count; i++)
//                 {
//                     if (Convert.ToInt32(dtPhraseResult.Rows[i]["autoid"].ToString()) == autoid)
//                     {
//                         dtPhraseResult.Rows.RemoveAt(i);
//                         break;
//                     }
//                 }
//             }
//         }
//     }

//     if (!IsEn(word))
//     {
//         colorsrckeywordlist.Add(word);
//     }
//     else
//     {
//         if (!word.Contains(" "))
//         {
//             colorsrckeywordlist.Add(word);
//         }
//         else
//         {
//             colorsrckeywordlist.AddRange(word.Split(new char[] { ' ' }, StringSplitOptions.RemoveEmptyEntries));
//         }
//     }

//     if (dtTgtWord != null && dtTgtWord.Rows.Count > 0)
//     {
//         for (int j = 0; j < dtTgtWord.Rows.Count; j++)
//         {
//             colorsrckeywordlist.Add(dtTgtWord.Rows[j]["content"].ToString());
//         }
//     }
//     colorsrckeywordlist = colorsrckeywordlist.Distinct().ToList();

//     DateTime dt1 = DateTime.Now;
//     StringBuilder result = new StringBuilder();
//     StringBuilder resultother = new StringBuilder();

//     List<int> srckeywordstarts = new List<int>();
//     List<int> srckeywordlengths = new List<int>();
//     List<int> tgtkeywordstarts = new List<int>();
//     List<int> tgtkeywordlengths = new List<int>();
//     List<int> htmllabelindexes = new List<int>();

//     StringBuilder srcphrasehtml = new StringBuilder();
//     StringBuilder tgtphrasehtml = new StringBuilder();

//     string specialtystr = "";
//     string industryidstr = "";

//     StringBuilder savelink = new StringBuilder();

//     if (dtPhraseResult != null && dtPhraseResult.Rows.Count > 0)
//     {
//         DataTable dtProInfo = GetResultProInfo(dtPhraseResult);

//         for (int x = 0; x < dtProInfo.Rows.Count; x++)
//         {
//             List<int> rowindexes = clsCommon.GetListIntFromListStr(dtProInfo.Rows[x]["rowindexes"].ToString().Split(',').ToList());
//             industryidstr = dtProInfo.Rows[x]["id"].ToString();
//             specialtystr = GetSpecialtyInfo(industryidstr);
//             if (rowindexes.Count > 1)
//             {
//                 srcphrasehtml = new StringBuilder();
//                 tgtphrasehtml = new StringBuilder();
                
//                 List<string> tempcoloredwords = new List<string>();

//                 if (industryidstr != "7")
//                 {
//                     result.Append("<p>" + "<font color=\"" + "darkgreen" + "\">" + specialtystr + "</font>" + "&nbsp;&nbsp;&nbsp;<a class=\"linkproresult\" href=\"" + GetSpecialtyLink(industryidstr, word) + "\" target=\"_blank\">在" + specialtystr + "领域内搜索<b>" + word + "</b></a>" + "</p>");
//                 }
//                 else
//                 {
//                     result.Append("<p>" + "<font color=\"" + "darkgreen" + "\">" + specialtystr + "</font>" + "</p>");
//                 }
//                 result.Append("<div class=\"dictresultspecialty\">");

//                 for (int i = 0; i < rowindexes.Count; i++)
//                 {
//                     srckeywordstarts = new List<int>();
//                     srckeywordlengths = new List<int>();
//                     tgtkeywordstarts = new List<int>();
//                     tgtkeywordlengths = new List<int>();

//                     srcphrasehtml.Length = 0;
//                     srcphrasehtml.Append(dtPhraseResult.Rows[rowindexes[i]]["content"].ToString());
//                     tgtphrasehtml.Length = 0;
//                     tgtphrasehtml.Append(dtPhraseResult.Rows[rowindexes[i]]["contenttrans"].ToString());

//                     GetStrMatchCount(colorsrckeywordlist, srcphrasehtml.ToString(), ref srckeywordstarts, ref srckeywordlengths, true);
//                     GetStrMatchCount(colortgtkeywordlist, tgtphrasehtml.ToString(), ref tgtkeywordstarts, ref tgtkeywordlengths, ref tempcoloredwords, true);
//                     coloredtgtkeywordlist.AddRange(tempcoloredwords);

//                     htmllabelindexes = new List<int>();
//                     AddHtmlLabel(srcphrasehtml.ToString(), ref srcphrasehtml, ref htmllabelindexes, srckeywordstarts, srckeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//                     UpdateHtmlLabel(ref srcphrasehtml);

//                     htmllabelindexes = new List<int>();
//                     AddHtmlLabel(tgtphrasehtml.ToString(), ref tgtphrasehtml, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//                     UpdateHtmlLabel(ref tgtphrasehtml);

//                     savelink.Length = 0;
//                     savelink.Append("../users/savetofavorite.aspx?autoid=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["autoid"].ToString()));
//                     savelink.Append("&phraseid=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["phraseid"].ToString()));
//                     savelink.Append("&isen=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["isen"].ToString()));
//                     savelink.Append("&industry=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["industryinfo"].ToString()));
//                     savelink.Append("&key=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["wordunit"].ToString()));
//                     savelink.Append("&wdsrc=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["content"].ToString()));
//                     savelink.Append("&wdtgt=" + clsCommon.GetBase64FromStr(dtPhraseResult.Rows[rowindexes[i]]["contenttrans"].ToString()));
//                     //savelink中如含有加号会导致request取值时读取为空格，所以要做一下替换.Replace("+", "@")

//                     result.Append("<p>    " + srcphrasehtml.ToString());
//                     result.Append("  ");
//                     result.Append(tgtphrasehtml.ToString());
//                     result.Append("&nbsp;&nbsp;<a class=\"linkproresult\" href=\"" +  (savelink.ToString()).Replace("+", "@") + "\" target=\"_blank\">" + "收藏</a>");
//                     result.Append("&nbsp;&nbsp;<a class=\"linkproresult\" href=\"advance.aspx?word=" + dtPhraseResult.Rows[rowindexes[i]]["content"].ToString() + "\" target=\"_blank\">" + "<img style=\"vertical-align:middle;\" src=\"../styles/img/goto.ico\" alt=\"跳转到该词 Go To\"  border=\"0px\"/></a>");
//                     result.Append("</p>\n");
//                 }

//                 result.Append("</div>");
//             }
//             else if (rowindexes.Count == 1)
//             {
//                 srcphrasehtml = new StringBuilder();
//                 tgtphrasehtml = new StringBuilder();
//                 srckeywordstarts = new List<int>();
//                 srckeywordlengths = new List<int>();
//                 tgtkeywordstarts = new List<int>();
//                 tgtkeywordlengths = new List<int>();

//                 List<string> tempcoloredwords = new List<string>();

//                 srcphrasehtml.Length = 0;
//                 srcphrasehtml.Append(dtPhraseResult.Rows[rowindexes[0]]["content"].ToString());
//                 tgtphrasehtml.Length = 0;
//                 tgtphrasehtml.Append(dtPhraseResult.Rows[rowindexes[0]]["contenttrans"].ToString());

//                 GetStrMatchCount(colorsrckeywordlist, srcphrasehtml.ToString(), ref srckeywordstarts, ref srckeywordlengths, true);
//                 GetStrMatchCount(colortgtkeywordlist, tgtphrasehtml.ToString(), ref tgtkeywordstarts, ref tgtkeywordlengths, ref tempcoloredwords, true);
//                 coloredtgtkeywordlist.AddRange(tempcoloredwords);

//                 htmllabelindexes = new List<int>();
//                 AddHtmlLabel(srcphrasehtml.ToString(), ref srcphrasehtml, ref htmllabelindexes, srckeywordstarts, srckeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//                 UpdateHtmlLabel(ref srcphrasehtml);

//                 htmllabelindexes = new List<int>();
//                 AddHtmlLabel(tgtphrasehtml.ToString(), ref tgtphrasehtml, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//                 UpdateHtmlLabel(ref tgtphrasehtml);

//                 resultother.Append("<p>      " + "<font color=\"" + "darkgreen" + "\">" + specialtystr + "</font>");
//                 resultother.Append("    " + srcphrasehtml.ToString());
//                 resultother.Append("  ");
//                 resultother.Append(tgtphrasehtml.ToString() + "</p>\n");
//                 resultother.Append("<p> </p>");
//             }
//         }

//         if (resultother.Length > 0)
//         {
//             resultother.Append("</div>");
//             resultother.Insert(0, "<div class=\"dictresultspecialty\">");
//             resultother.Insert(0, "<p>" + "<font color=\"" + "darkgreen" + "\">" + "其他" + "</font>" + "</p>");

//             result.Append(resultother);
//         }
//     }
//     //else
//     //{
//     //    //AppClass.ConnectDict50Server(false);
//     //}

//     if (dtTgtWord != null && dtTgtWord.Rows.Count > 0)
//     {
//         coloredtgtkeywordlist = coloredtgtkeywordlist.Distinct().ToList();

//         if (tgtwordhtml0.Length > 0)
//         {
//             GetStrMatchCount(coloredtgtkeywordlist, tgtwordhtml0.ToString(), ref tgtkeywordstarts, ref tgtkeywordlengths, true);
//             htmllabelindexes = new List<int>();
//             AddHtmlLabel(tgtwordhtml0.ToString(), ref tgtwordhtml0, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//             UpdateHtmlLabel(ref tgtwordhtml0);
//             tgtwordshtml = "<b>" + tgtwordhtml0.ToString() + "</b>";
//         }

//         for (int i = 0; i < dtTgtWord.Rows.Count; i++)
//         {
//             if (dtTgtWord.Rows[i]["content"].ToString().Length != word.Length)
//             {
//                 if (tgtwordhtml1.Length > 0)
//                 {
//                     tgtwordhtml1.Append("<br/>");
//                 }

//                 StringBuilder tgtwordhtmltemp = new StringBuilder();
//                 tgtword = dtTgtWord.Rows[i]["contenttrans"].ToString();
//                 tgtwordhtml1.Append("<a style=\"color:#408080\" href=\"advance.aspx?word=" + dtTgtWord.Rows[i]["content"].ToString() + "\" target=\"_blank\"><b>" + dtTgtWord.Rows[i]["content"].ToString() + "</b></a>");
//                 tgtwordhtml1.Append(": ");

//                 tgtwordhtmltemp.Append(tgtword);
//                 htmllabelindexes = new List<int>();
//                 GetStrMatchCount(coloredtgtkeywordlist, tgtword, ref tgtkeywordstarts, ref tgtkeywordlengths, true);
//                 AddHtmlLabel(tgtword, ref tgtwordhtmltemp, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//                 UpdateHtmlLabel(ref tgtwordhtmltemp);

//                 tgtwordhtml1.Append(tgtwordhtmltemp);
//             }
//         }


//             //if (relatedsrcwordstarts.Count > 0)
//             //{
//             //    List<string> labelfronts = new List<string>();
//             //    for (int x = 0; x < relatedsrcwordstarts.Count; x++)
//             //    {
//             //        labelfronts.Add("<a style=\"color:#408080\" href=\"advance.aspx?word=" + relatedsrcwords[x] + "\" target=\"_blank\">");
//             //    }
//             //    AddHtmlLabel(tgtwordhtml1.ToString(), ref tgtwordhtml1, ref htmllabelindexes, relatedsrcwordstarts, relatedsrcwordlengths, labelfronts, "</a>");
//             //    UpdateHtmlLabel(ref tgtwordhtml1);
//             //}

//         //GetStrMatchCount(coloredtgtkeywordlist, tgtwordhtml1.ToString(), ref tgtkeywordstarts, ref tgtkeywordlengths, true);
//         //AddHtmlLabel(tgtwordhtml1.ToString(), ref tgtwordhtml1, ref htmllabelindexes, tgtkeywordstarts, tgtkeywordlengths, "<font color=\"" + "red" + "\">", "</font>");
//         //UpdateHtmlLabel(ref tgtwordhtml1);

//         if (tgtwordshtml.Length > 0)
//         {
//             tgtwordshtml += "<br/>";
//         }

//         tgtwordshtml += tgtwordhtml1.ToString();
//     }

//     clsCommon.searchword = word;

//     time2 = Math.Round(DateTime.Now.Subtract(dtStart).TotalSeconds, 4);
//     info += "For display：" + time2.ToString() + " s<br/>";
//     //if (result.Length > 0)
//     //{
//     //    rtxtDictResult.SelectAll();
//     //    rtxtDictResult.SelectionFont = rtffont;
//     //    //rtxtDictResult.SelectionFont = new System.Drawing.Font("Georgia", 9, System.Drawing.FontStyle.Regular);
//     //    rtxtDictResult.SelectionColor = System.Drawing.SystemColors.WindowText;
//     //    rtxtDictResult.SelectionHangingIndent = 24;
//     //    //rtxtDictResult.SelectionIndent = 24;
//     //    rtxtDictResult.SelectionLength = 0;


//     //    //rtxtDictResult.Rtf = ColorKeyword(rtxtDictResult.Rtf, new string[]{word}.ToList(), "red");
//     //    resultrtf = rtxtDictResult.Rtf;
//     //    colorkeywordlist.Add(word);
//     //    resultrtf = ColorKeyword(resultrtf, colorkeywordlist, "red");
//     //}
//     //else
//     //{
//     //    resultrtf = "";
//     //}

//     resulthtml = result.ToString();

//     int resultno = 0;
//     if (dtPhraseResult != null)
//     {
//         resultno = dtPhraseResult.Rows.Count;
//     }
//     int industryid = 0;
//     if (specialtyinfo.Length > 0)
//     {
//         industryid = Convert.ToInt32(specialtyinfo);
//     }

//     //AddSearchRecord(word, resultno, industryid, time1.ToString(), time2.ToString(), DateTime.Now.ToString(), IP, browsername, browserversion);

//     //dtPhraseResult.Rows.Clear();
//     dtPhraseResult = null;
//     //dtTgtWord.Rows.Clear();
//     dtTgtWord = null;

//     colorsrckeywordlist.Clear();
//     colorsrckeywordlist = null;
//     colortgtkeywordlist.Clear();
//     colortgtkeywordlist = null;
//     coloredtgtkeywordlist.Clear();
//     coloredtgtkeywordlist = null;
//     tgtwordhtml0.Length = 0;
//     tgtwordhtml0 = null;
//     tgtwordhtml1.Length = 0;
//     tgtwordhtml1 = null;

//     relatedsrcwordstarts.Clear();
//     relatedsrcwordstarts = null;
//     relatedsrcwordlengths.Clear();
//     relatedsrcwordlengths = null;
//     relatedsrcwords.Clear();
//     relatedsrcwords = null;

//     result.Length = 0;
//     result = null;
//     resultother.Length = 0;
//     resultother = null;
//     srckeywordstarts.Clear();
//     srckeywordstarts = null;
//     srckeywordlengths.Clear();
//     srckeywordlengths = null;
//     tgtkeywordstarts.Clear();
//     tgtkeywordstarts = null;

//     tgtkeywordlengths.Clear();
//     tgtkeywordlengths = null;
//     htmllabelindexes.Clear();
//     htmllabelindexes = null;
//     srcphrasehtml.Length = 0;
//     srcphrasehtml = null;
//     tgtphrasehtml.Length = 0;
//     tgtphrasehtml = null;
//     savelink.Length = 0;
//     savelink = null;

//     return resulthtml;
// }

// public static DataTable GetResultProInfo(DataTable dtPhraseResult)
// {
//     DataTable dtProInfo = new DataTable();
//     dtProInfo.Columns.Add("id", typeof(int));
//     dtProInfo.Columns.Add("rowindexes", typeof(string));
//     dtProInfo.Columns.Add("resultno", typeof(int));

//     string[] industryids = null;
//     List<int> industrylist = new int[]{0, 1, 2, 3, 4, 5, 6, 7, 8}.ToList();
//     List<List<int>> industryrowindexeslist = new List<List<int>>();
//     for (int i = 0; i <= 8; i++)
//     {
//         industryrowindexeslist.Add(new int[]{}.ToList());
//     }

//     if (dtPhraseResult != null)
//     {
//         for (int i = 0; i < dtPhraseResult.Rows.Count; i++)
//         {
//             industryids = null;
//             if (dtPhraseResult.Rows[i]["industryinfo"].ToString() != "")
//             {
//                 industryids = dtPhraseResult.Rows[i]["industryinfo"].ToString().Split(new char[] { ';' }, StringSplitOptions.RemoveEmptyEntries);
//                 if (industryids != null && industryids.Length > 0)
//                 {
//                     for (int j = 0; j < industryids.Length; j++)
//                     {
//                         industryrowindexeslist[Convert.ToInt32(industryids[j])].Add(i);
//                     }
//                 }
//             }
//         }

//         for (int i = 0; i <= 8; i++)
//         {
//             if (industryrowindexeslist[i].Count > 0)
//             {
//                 if (industryrowindexeslist[i].Count > 1)
//                 {
//                     industryrowindexeslist[i] = industryrowindexeslist[i].Distinct().ToList();
//                 }

//                 DataRow row = dtProInfo.NewRow();
//                 row["id"] = i;
//                 row["rowindexes"] = string.Join(",", industryrowindexeslist[i].ToArray());
//                 row["resultno"] = industryrowindexeslist[i].Count;
//                 dtProInfo.Rows.Add(row);
//             }
//         }

//         if (dtProInfo.Rows.Count > 0)
//         {
//             DataView dv = dtProInfo.DefaultView;
//             dv.Sort = "resultno desc";
//             dtProInfo = dv.ToTable();
//         }
//     }

//     return dtProInfo;
// }