import { useState } from 'react';
import { styled as mStyled } from '@mui/material/styles';
import MTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useMessageContext } from '../../hooks/use-message-context';
import { Color } from '../../constants';
import { useTranslatedMessage } from '../../hooks/use-translated-message';

const Tooltip = mStyled(({ className, ...props }: TooltipProps) => (
    <MTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.white,
        backgroundColor: Color.OFFWHITE2,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

// const Tooltip = (props: any) => {
//     const { children, ...rest } = props;

//     return (
//         <MTooltip
//             {...rest}
//             slotProps={{
//                 popper: {
//                     modifiers: [
//                         {
//                             name: 'offset',
//                             options: {
//                                 offset: [0, -5],
//                             },
//                         },
//                     ],
//                 },
//             }}
//         >
//             {children}
//         </MTooltip>)
// }

const StyledIconButton = mStyled(IconButton)({
    width: '24px', height: '24px',
    'svg': { width: 16, height: 16 }
});

const FloatingToolbar = ({ content }: { content: string }) => {
    const [savedToFav, setSavedToFav] = useState<boolean>(false);
    const [thumbedUp, setThumbedUp] = useState<boolean>(false);
    const [thumbedDown, setThumbedDown] = useState<boolean>(false);
    const { showToastMessage } = useMessageContext();
    const message = useTranslatedMessage();

    const copyToClipboard = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
            //   console.log("Text copied to clipboard");
            showToastMessage({ message: "拷贝成功�?" });
        } catch (err) {
            showToastMessage({ message: `${message('Form.Msg.FailureCopy')}: ${err}` });
            //   console.error("Failed to copy: ", err);
        }
    };

    const showFeature = false;

    return (
        <div style={{
            position: 'absolute',
            bottom: '-20px',
            left: '50%',
            transform: 'translateX(-50%)',
            display: 'flex',
            border: 'none',
            padding: '5px',
            zIndex: 1000, // Ensure it's above other elements
        }}>
            {showFeature && <Tooltip title="�?">
                <StyledIconButton onClick={() => setThumbedUp(up => !up)} >
                    {thumbedUp ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                </StyledIconButton>
            </Tooltip>}
            {showFeature && <Tooltip title="�?">
                <StyledIconButton onClick={() => setThumbedDown(down => !down)}>
                    {thumbedDown ? <ThumbDownIcon /> : <ThumbDownOffAltIcon />}
                </StyledIconButton>
            </Tooltip>}
            {/* TODO: Enable this feature after save-to-fav is done */}
            {showFeature && <Tooltip title={savedToFav ? "移除" : "收藏"}>
                <StyledIconButton onClick={() => setSavedToFav((saved) => !saved)}>
                    {savedToFav ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </StyledIconButton>
            </Tooltip>}
            {/* TODO: Enable this feature when https is implemented  */}
            {showFeature && <Tooltip title="拷贝">
                <StyledIconButton onClick={() => copyToClipboard(content)}>
                    <ContentCopyIcon />
                </StyledIconButton>
            </Tooltip>}

        </div>
    );
}

export default FloatingToolbar;
