import { MatchMeasure, TextMatchItem } from "../types";
import { getMatchScore } from "./word-processing-utils";

export function highlightKeyword(text: string, keyword: string): JSX.Element {
    if (!keyword.trim()) {
        return <>{text} </>;
    }

    const regex = new RegExp(`(${keyword})`, 'gi');
    const parts = text.split(regex);

    return (
        <>
            {
                parts.map((part, index) =>
                    regex.test(part) ? <span className="primary" key={index} >{part}</span> : part
                )
            }
        </>
    );
}

export function highlightKeywordsPipe({ parts, pattern, score }: {
    parts: TextMatchItem[];
    pattern: string;
    score: number;
}): [TextMatchItem[], Set<string>] {
    const resultParts: TextMatchItem[] = [];
    const matchedKeywords = new Set<string>();
    parts.forEach(part => {
        if (part.isMatched) {
            resultParts.push(part);
        } else {
            const [internalParts, internalMatchedKeywords] = highlightKeywordsInternal({ text: part.text, pattern, score });
            resultParts.push(...internalParts);
            internalMatchedKeywords.forEach((word) => matchedKeywords.add(word));
        }
    });

    return [resultParts, matchedKeywords];
}

export function highlightKeywordsInternal({ text, pattern, score }: {
    text: string;
    pattern: string;
    score: number;
}): [TextMatchItem[], Set<string>] {
    // Create a regex pattern to match all keywords
    const regex = new RegExp(`(${pattern})`, 'gi');
    const parts = text.split(regex);

    const matchedKeywords = new Set<string>();

    const matchParts: TextMatchItem[] = parts
        // filter out empty part!!
        .filter((part) => Boolean(part))
        .map((part) => {
            const matched = regex.test(part);
            if (matched) {
                matchedKeywords.add(part);
            }
            return {
                text: part,
                isMatched: matched,
                matchScore: score
            }
        });

    return [matchParts, matchedKeywords];
}

const getSpaceDecoratedTextNode = (text: string): JSX.Element => {
    if (text === " ") return (<>
        <span className="space"></span>
        {" "}
    </>);
    const node = (<>
        {text.startsWith(" ") && <span className="space"></span>}
        {text}
        {text.endsWith(" ") && <span className="space"></span>}
    </>);

    return node;
}

export const getNodeFromParts = (parts: TextMatchItem[], decorateSpace?: boolean): JSX.Element => {
    // Organize results as node
    const node: JSX.Element = (
        <>
            {parts.map((part, index) => {
                return part.isMatched
                    ? part.matchScore === 5
                        ? <span key={index} className="primary">{part.text}</span>
                        : <span key={index} className="secondary">{part.text}</span>
                    : <span key={index}>
                        {decorateSpace ? getSpaceDecoratedTextNode(part.text) : part.text}
                    </span>
            })}
        </>
    );

    return node;
}

export function highlightKeywords({ text, keywords, matchMeasures }: {
    text: string;
    keywords: string[];
    matchMeasures: MatchMeasure[];
}): [JSX.Element, number, Set<string>[]] {

    const matchedKeywordSetArr: Set<string>[] = [];
    // Check if keywords are empty;
    // The second half of the condition should already be guarded by filterTgtWords
    if (!keywords.length || keywords.every(keyword => !keyword.trim())) {
        return [<>{text}</>, 0, matchMeasures.map((measure) => new Set<string>())];
    }

    const initialParts: TextMatchItem[] = [{
        text,
        isMatched: false
    }];

    let finalParts: TextMatchItem[] = initialParts;

    for (const matchMeasure of matchMeasures) {
        const [parts, matchedKeywords] = highlightKeywordsPipe({ parts: finalParts, ...matchMeasure });
        finalParts = parts;
        matchedKeywordSetArr.push(matchedKeywords);
    }

    const node = getNodeFromParts(finalParts, true);

    const textMatchScore = getMatchScore(finalParts);

    return [node, textMatchScore, matchedKeywordSetArr];
}
