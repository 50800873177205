import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ProductCard from './ProductCard';
import { PRODUCT_FEATURES, SUBSCRIPTIONS } from '../../../constants';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import { useFetchDictProducts } from '../../../hooks/use-fetch-dict-products';
import Accordion from '../../Accordion';
import SectionPaper from './SectionPaper';

const ProductList = () => {
    const message = useTranslatedMessage();
    const { productIndex } = useFetchDictProducts();

    const accordionItems = SUBSCRIPTIONS.map((subscription) => ({
        id: subscription.subscriptionType,
        label: message(subscription.titleKey)
    }));

    return (
        <SectionPaper title={message('Product.ComparisonTitle')}>
            <Grid container spacing={3}>
                {PRODUCT_FEATURES.map(({ id, titleKey, descriptionKeys }) => (
                    <Grid item md={6} sm={12} key={id}>
                        <ProductCard
                            autoWidth
                            title={message(titleKey)}
                            description={
                                <>
                                    <List>
                                        {descriptionKeys.map(
                                            (descriptionKey) => (
                                                <ListItem key={descriptionKey} sx={{ pt: 0.5, pd: 0.5 }}>{message(descriptionKey)}</ListItem>
                                            ))
                                        }
                                    </List>
                                    {id === "pro" && (
                                        <Accordion isSingleExpand items={accordionItems} selectedIds={["1"]}>
                                            {SUBSCRIPTIONS.map(({ titleKey, descriptionKeys, subscriptionType }) => (<Box key={subscriptionType}>
                                                <ProductCard
                                                    autoWidth
                                                    title={message(titleKey)}
                                                    description={<List>
                                                        {descriptionKeys.map(
                                                            (descriptionKey) => (
                                                                <ListItem key={descriptionKey} sx={{ pt: 0.5, pd: 0.5 }}>{message(descriptionKey)}</ListItem>
                                                            ))
                                                        }
                                                    </List>}
                                                    message={message}
                                                    priceIndex={productIndex[subscriptionType]}
                                                    subscriptionType={subscriptionType}
                                                />
                                            </Box>))}
                                        </Accordion>
                                    )}
                                </>
                            }
                            message={message}
                        />
                    </Grid>
                ))}
            </Grid>
        </SectionPaper>
    );
}

export default ProductList;