export function underscoreToCamelCase(underscoredString: string): string {
    return underscoredString.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

export function camelToUnderscore(camelCaseString: string): string {
    return camelCaseString.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function titleCased(str: string): string {
    if (!str) return str;
    return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
}

export function formatCardNumber(brand: string, last4: string): string {
    return `${titleCased(brand)} ${(" " + (last4 ?? "")).padStart(9, "*")}`;
}