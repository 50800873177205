import * as React from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormError } from '../types';
import { Color } from '../constants';
import { CountryCode, CountryType, getCountries, getCountryTypeFromCode } from '../constants/country';
import { useTranslatedMessage } from '../hooks/use-translated-message';
import { useIntl } from 'react-intl';
import { Input, SxProps } from '@mui/material';

interface CountrySelectProps {
    formError?: FormError;
    initialValue?: CountryCode;
    fullWidth?: boolean;
    variant?: TextFieldProps["variant"];
    readonly?: boolean;
    anchored?: boolean;
    disabled?: boolean;
    required?: boolean;
    noLabel?: boolean;
    includePhone?: boolean;
    onChange?: (option: CountryType | null) => void;
    sx?: SxProps;
}

const labelSeparator = ', ';

// TODO: Consider extending FormTextField to support CountrySelect
export default function CountrySelect(props: CountrySelectProps) {
    const name = "country";
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    const [internalValue, setInternalValue] = React.useState<CountryCode | null>(null);

    const handleOnCountryChange = (event: React.SyntheticEvent<Element, Event>, value: CountryType | null) => {
        setInternalValue(value?.code ?? null);
        props.onChange?.(value);
    };

    const internalSelection = React.useMemo(
        () => getCountryTypeFromCode(internalValue, locale, props.includePhone),
        [internalValue, locale, props.includePhone]);

    const errMsg = props.formError?.[name] || '';

    // Sync email value to its initial value when set as initialValue in property
    React.useEffect(() => {
        // If initial country is empty, then do not sync 
        if (!props?.initialValue) return;
        setInternalValue(props?.initialValue)
    }, [props.initialValue]);

    return (
        <Autocomplete
            id={name}
            sx={props.sx}
            options={getCountries(locale, props.includePhone)}
            isOptionEqualToValue={(option, value) => option.code === value?.code}
            autoHighlight
            fullWidth
            getOptionLabel={(option) => Array.isArray(option.label) ? option.label.join(labelSeparator) : option.label}
            renderOption={(optionProps, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...optionProps}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt=""
                    />
                    {`${option.label}${props.includePhone ? ` + ${option.phone ?? ''}` : ''}`}
                </Box>
            )}
            value={getCountryTypeFromCode(internalValue, locale, props.includePhone)}
            onChange={handleOnCountryChange}
            autoFocus
            disabled={props.disabled || props.readonly}
            renderInput={(params) => {
                return (
                    <>
                        <TextField
                            {...params}
                            required={props.required}
                            label={props.noLabel ? '' : message('Form.Profile.Country')}
                            // TODO: Check if autoComplete should be placed as props of TextField or inputProps, same for FormTextField
                            // For explanation on autoComplete: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
                            autoComplete='country'
                            variant={props.variant}
                            sx={{
                                marginTop: 0,
                                marginBottom: 0,
                                ...props.anchored ? {
                                    'label': {
                                        transform: 'translate(0, -1.5px) scale(0.75)'
                                    }
                                } : {},
                                ...props.readonly ? {
                                    'input': {
                                        WebkitTextFillColor: `${Color.PRIMARY_FONT_DARK_GREY} !important`
                                    },
                                    '.Mui-disabled:before': {
                                        borderBottomStyle: 'none !important'
                                    }
                                } : {}
                            }}
                            margin="dense"
                            error={Boolean(errMsg)}
                            helperText={errMsg || ''}
                            inputProps={{
                                ...params.inputProps,
                                // Fix unable to delete characters in Chinese selection
                                onChange: function (evt: React.ChangeEvent<HTMLInputElement>) {
                                    // If selection is non-empty, label text changed due to user deletion, reset selection to null
                                    if (internalValue != null && internalSelection?.label !== evt.target.value) {
                                        setInternalValue(null);
                                        return;
                                    }
                                    params?.inputProps?.onChange?.(evt);
                                }
                            }}
                        />
                        {/* Use country code as the form value from this hidden field */}
                        {/* Fallback to empty string to prevent null or empty value from being fed to Input which causes error in html*/}
                        <Input
                            sx={{ display: 'none' }}
                            hidden
                            name={name}
                            value={internalValue ?? ""}
                        />
                    </>
                );
            }}
        />
    );
}

export const StandardCountrySelect = (props: CountrySelectProps) => (<CountrySelect {...props} variant="standard" anchored />)

export const StandardReadonlyCountrySelect = (props: CountrySelectProps) => (<CountrySelect {...props} anchored readonly variant="standard" />)
