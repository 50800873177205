import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef, useState, useEffect } from 'react';
import type { FormError } from '../types';
import { Color } from '../constants';

export interface FormTextFieldProps {
    name: string;
    label: string;
    noLabel?: boolean;
    autoComplete?: string;
    required?: boolean;
    disabled?: boolean;
    // unable to edit like disabled, but font color is normal
    readonly?: boolean;
    // label is anchored on top, without being transformed as placeholder text
    anchored?: boolean;
    fullWidth?: boolean;
    value?: string;
    initialValue?: string;
    // Accepting form error object, used to take error message of the current field
    formError?: FormError;
    variant?: TextFieldProps["variant"];
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormTextField = forwardRef<HTMLDivElement, TextFieldProps & FormTextFieldProps>((props, ref) => {
    const { name, label, noLabel, autoComplete, anchored, disabled, readonly, variant, fullWidth, required, value = "", initialValue = "", formError, onChange } = props;
    const [internalValue, setInternalValue] = useState<string>('');

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event.target?.value);
    };

    const hasValueManipulated = value !== undefined && onChange !== undefined;

    const errMsg = formError?.[name] || '';

    // Sync email value to its initial value when set as initialValue in property
    // If initialValue is set, we assume rememberedEmail is not set
    useEffect(() => {
        if (!initialValue) return;
        setInternalValue((val: string) => initialValue)
    }, [initialValue]);

    return (
        <TextField
            // Passing ref to TextField itself normally isn't helpful
            // ref={ref}
            inputRef={ref}
            sx={{
                ...anchored ? {
                    'label': {
                        transform: 'translate(0, -1.5px) scale(0.75)'
                    }
                } : {},
                ...readonly ? {
                    'input': {
                        WebkitTextFillColor: `${Color.PRIMARY_FONT_DARK_GREY} !important`
                    },
                    '.Mui-disabled:before': {
                        borderBottomStyle: 'none !important'
                    }
                } : {}
            }}
            margin="dense"
            required={required}
            error={Boolean(errMsg)}
            helperText={errMsg || ''}
            fullWidth={fullWidth ?? true}
            variant={variant}
            // defaultValue is fed into input only once at load time, and does not persist page refresh
            // If it is initially empty and then loaded, then only the first empty value is taken
            // defaultValue={rememberedEmail}
            value={hasValueManipulated ? value : internalValue}
            onChange={hasValueManipulated ? onChange : handleOnChange}
            id={name}
            label={noLabel ? '' : label}
            name={name}
            // TODO: Gracefully set visually readonly to TextField.
            // When setting disabled, the field is not getting value in form data
            disabled={disabled || readonly}
            // For explanation on autoComplete: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
            autoComplete={autoComplete}
            autoFocus
        />
    );
});

export const StandardTextField = forwardRef<HTMLDivElement, TextFieldProps & FormTextFieldProps>((props: FormTextFieldProps, ref) => (<FormTextField ref={ref} {...props} variant="standard" anchored />))

export const StandardReadonlyTextField = forwardRef<HTMLDivElement, TextFieldProps & FormTextFieldProps>((props: FormTextFieldProps, ref) => (<FormTextField ref={ref} {...props} anchored readonly variant="standard" />))

export default FormTextField;