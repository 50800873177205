import { Address } from "../types";
import { underscoreToCamelCase } from "./parse-data";

export const parseAddressData = (addressData: Object): Address => {
    // const address: Address = {
    //     autoId: 0,
    //     addressType: AddressType.BILLING
    // };
    const address = {} as any;

    if (addressData) {
        Object.entries(addressData).forEach(([key, value]) => {
            const addressKey = underscoreToCamelCase(key) as keyof Address;
            address[addressKey] = value;
        })
    }

    return address;
}