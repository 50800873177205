import { USER_CURRENT_URL } from "../constants";
import useAxiosFetch from "./use-axios-fetch"
import { parseUserData, parseUserSettingData } from "../utils/parse-user-data";
import { useUserInfoContext } from "./use-user-info-context";
import { useEffect } from "react";
import { User, UserSetting } from "../types";

export const useRefreshCurrentUser = () => {
    const { user, setUser, syncSetting } = useUserInfoContext();
    const userId = user?.id ?? '';
    // Need to skip fetching current user when userId is not ready.
    // Otherwise it ends up with empty userId sent in params
    const { data, loading } = useAxiosFetch<{ user: User; setting: UserSetting }>(USER_CURRENT_URL, {
        method: 'GET',
        params: { userId }
    }, false, !Boolean(userId));

    useEffect(() => {
        if (loading !== false) return;
        if (!data) return;
        const { user: userData, setting } = data;
        const fetchedUser = parseUserData(userData);
        setUser(fetchedUser);
        syncSetting(parseUserSettingData(setting));
    }, [setUser, loading, data, syncSetting]);
} 