import { useState, SyntheticEvent, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { useTranslatedMessage } from '../../../hooks/use-translated-message';
import { getSubscriptionLabelKeyById, getDictNumBySubscriptionType, getIndustryLabelById, INDUSTRIES, SUBSCRIPTION_TYPE } from '../../../constants';
import { useIntl } from 'react-intl';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(id: string, selectedIds: readonly string[], theme: Theme) {
    return {
        fontWeight:
            selectedIds.indexOf(id) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

interface DictSelectDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onConfirm: (selectedIds: string[]) => void;
    subscriptionType: string;
    defaultValue?: string[]
}

const DictSelectDialog = ({ subscriptionType, open, setOpen, onConfirm, defaultValue }: DictSelectDialogProps) => {
    const [selectedIndustryIds, setSelectedIndustryIds] = useState<string[]>([]);
    const message = useTranslatedMessage();
    const { locale } = useIntl();
    const theme = useTheme();
    const allowedDictNum = getDictNumBySubscriptionType(subscriptionType);

    useEffect(() => {
        if (subscriptionType === SUBSCRIPTION_TYPE.VipPRO && selectedIndustryIds.length === 0) {
            setSelectedIndustryIds(INDUSTRIES.map((industry) => industry.id));
        }
    }, [subscriptionType, selectedIndustryIds.length])

    useEffect(() => {
        if (!defaultValue) return;
        setSelectedIndustryIds(defaultValue);
    }, [defaultValue])

    const handleChange = (event: SelectChangeEvent<string[]>) => {
        const {
            target: { value },
        } = event;

        // On autofill we get a stringified value.
        if (typeof value === 'string') {
            setSelectedIndustryIds(value.split(','));
            return;
        }

        const allowed = value.length <= allowedDictNum;
        if (!allowed) return;

        setSelectedIndustryIds(value);
    };

    const handleClose = (event: SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const handleOnConfirm = (event: SyntheticEvent<unknown>, reason?: string) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
        onConfirm(selectedIndustryIds);
    };

    const handleOnDelete = (e: Event, value: string) => {
        const newIndustryIds = selectedIndustryIds.filter((id) => id !== value);
        setSelectedIndustryIds(newIndustryIds);
    }

    return (
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
            <DialogTitle>{message('Product.SelectDictTypes', { num: getDictNumBySubscriptionType(subscriptionType), subscription: message(getSubscriptionLabelKeyById(subscriptionType)) })}</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    <FormControl sx={{
                        m: 1, width: '100%', 'fieldset': {
                            'legend': { width: '70px' }
                        }
                    }}>
                        <InputLabel id="demo-multiple-chip-label">{message('Product.Specialty')}</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            value={selectedIndustryIds}
                            onChange={handleChange}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip
                                            key={value}
                                            label={getIndustryLabelById(+value, locale)}
                                            deleteIcon={
                                                <CancelIcon
                                                    onMouseDown={(event) => event.stopPropagation()}
                                                />
                                            }
                                            onDelete={(e) => handleOnDelete(e, value)}
                                        />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {INDUSTRIES.map((industry) => (
                                <MenuItem
                                    key={industry.id}
                                    value={industry.id}
                                    style={getStyles(industry.id, selectedIndustryIds, theme)}
                                >
                                    {locale === 'en' ? industry.labelEN : industry.labelZH}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions sx={{ pr: 4, pb: 2, pt: 0 }}>
                <Button onClick={handleClose}>{message('Form.Button.Cancel')}</Button>
                <Button variant='contained' onClick={handleOnConfirm}>{message('Form.Button.OK')}</Button>
            </DialogActions>
        </Dialog>
    );
}

export default DictSelectDialog;