import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export type TranslationMessage = (id: string, variables?: { [key: string]: string | number }) => string

export const useTranslatedMessage = () => {
    const intl = useIntl();

    const message: TranslationMessage = useCallback((id, variables) => intl.formatMessage({ id }, variables), [intl]);

    return message;
};