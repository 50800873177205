import { useFetchPaymentMethods } from "../../../hooks/use-fetch-payment-methods";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { TranslationMessage, useTranslatedMessage } from "../../../hooks/use-translated-message";
import { formatCardNumber, titleCased } from "../../../utils/parse-data";
import { StandardReadonlyTextField } from "../../FormTextField";
import type { FormError, IPaymentMethod } from "../../../types";
import ButtonsComponent from "../ButtonsComponent";
import { Color } from "../../../constants";
import { useState } from "react";
import { useUserInfoContext } from "../../../hooks/use-user-info-context";
import { useUserAPI } from "../../../hooks/use-user-api";
import useNavigate from '../../../hooks/use-navigate';

interface PaymentMethodProps {
    tabEditing: string;
    setTabEditing: (tab: string) => void;
    formError: FormError;
    setFormError: (error: FormError) => void;
    message: TranslationMessage;
}

const PaymentMethod = ({ tabEditing, setTabEditing, formError, setFormError }: PaymentMethodProps) => {
    const { paymentMethods = [] } = useFetchPaymentMethods();
    const message = useTranslatedMessage();
    const { user } = useUserInfoContext();
    const { deletePaymentMethod, setDefaultPaymentMethod } = useUserAPI();
    const TextField = StandardReadonlyTextField;
    const isEditing = tabEditing === 'payment-method';
    const [checkedPmIds, setCheckedPmIds] = useState<string[]>([]);
    const [updating, setUpdating] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);

    const hasNonDefaultMethods = paymentMethods.some(item => !item.isDefault);

    const getCardText = (paymentMethod: IPaymentMethod) => {
        if (paymentMethod.paymentChannel === 'card' && paymentMethod.card) {
            return formatCardNumber(paymentMethod.card.displayBrand, paymentMethod.card.last4)
        }

        return titleCased(paymentMethod.paymentChannel);
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const targetPmId = event.target.name;
        const checked = event.target.checked;
        let newCheckedPmIds = checkedPmIds.filter((pmId) => pmId !== targetPmId);
        if (checked) {
            newCheckedPmIds = [...newCheckedPmIds, targetPmId];
        }
        setCheckedPmIds(newCheckedPmIds);
    };

    const { reload } = useNavigate();

    const handleSetAsDefault = () => {
        if (checkedPmIds.length !== 1) return;
        setUpdating(true);
        setDefaultPaymentMethod(checkedPmIds[0], +(user?.id ?? ""), setUpdating, reload);
    }

    const handleDeletePMs = () => {
        if (checkedPmIds.length === 0) return;
        setDeleting(true);
        deletePaymentMethod(checkedPmIds, +(user?.id ?? ""), setDeleting, reload);
    }

    return (
        <Box>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {paymentMethods.map((paymentMethod, index) => (
                    <Box
                        key={paymentMethod.stripePmId}
                        display={'flex'}
                        sx={{ mb: index !== paymentMethods.length - 1 ? 2 : 0, ml: 2 }}
                    >
                        {isEditing && <Checkbox
                            disabled={Boolean(paymentMethod.isDefault)}
                            color="primary"
                            name={paymentMethod.stripePmId}
                            checked={checkedPmIds.includes(paymentMethod.stripePmId)}
                            onChange={handleCheckboxChange}
                        />}
                        <TextField
                            name={index.toString()}
                            label={paymentMethod.isDefault ? message('Form.Account.PaymentMethod.Default') : ""}
                            initialValue={getCardText(paymentMethod)}
                        />
                    </Box>
                ))
                }
            </List >
            {hasNonDefaultMethods && <ButtonsComponent
                editing={isEditing}
                formSubmitting={updating}
                formError={formError}
                setFormError={setFormError}
                message={message}
                tabId="payment-method"
                setTabEditing={setTabEditing}
                saveButtonProps={{
                    label: message('Form.Button.Delete'),
                    backgroundColor: Color.ERROR,
                    inVisible: checkedPmIds.length === 0,
                    onClick: handleDeletePMs,
                    disabled: updating
                }}
                extraButton={checkedPmIds.length === 1
                    ? <Button variant="contained" onClick={handleSetAsDefault} disabled={updating || deleting}>
                        {message('Form.Account.PaymentMethod.SetAsDefault')}
                    </Button>
                    : null}
            />}
        </Box>
    )
}

export default PaymentMethod;