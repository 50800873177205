import styled from '@emotion/styled/macro';
import { Color } from "../../constants";

export const Paragraph = styled.div`
    text-indent: 2em;
    text-align: left;

    a {
        color: ${Color.PRIMARY_BLUE_DARK};
    }
`;

export const Row = styled.div`
    margin-bottom: 1em;
`;