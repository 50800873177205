import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface SectionPaperProps {
    title: string;
    children: React.ReactNode;
}

const SectionPaper = ({ title, children }: SectionPaperProps) => {
    return <Paper sx={{ mt: 1 }}>
        <Box sx={{ p: 2 }}>
            <Typography gutterBottom variant="h6">
                {title}
            </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
            {children}
        </Box>
    </Paper>
}

export default SectionPaper;