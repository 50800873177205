import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import LogoBanner from '../../LogoBanner';
import Footer from '../Footer';
import { useUpdateLocale } from '../../hooks/use-update-locale';

interface AuthPageProps {
    title: string;
    children: React.ReactNode;
}

const AuthPage = ({ title, children }: AuthPageProps) => {
    const theme = useTheme();
    useUpdateLocale();

    return (
        <Container component="main" maxWidth="xs" sx={{
            ...theme.mixins.fullyExtendedMainHeight.container,
        }}>
            <CssBaseline />
            <Box
                sx={{
                    flex: 1,
                    ...theme.mixins.fullyExtendedMainHeight.mainSection,
                    marginTop: 4,
                    marginBottom: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <LogoBanner />
                <Typography component="h1" variant="h5">
                    {title}
                </Typography>
                {children}
            </Box>
            <Footer />
        </Container>
    )
}

export default AuthPage;