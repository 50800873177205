import * as React from 'react';
import { styled as mStyled } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Size, Color, PAGES, getLocaledAbsolutePath, INDUSTRIES } from '../../constants';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import UserMenus from './UserMenus';
import LogoButton from '../../LogoButton';
import { MenuItemObj } from '../../types';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import IntlSelect from './IntlSelect';
import { Link } from '@mui/material';
import { useIntl } from 'react-intl';

interface AppBarProps extends MuiAppBarProps {
  drawerOpen?: boolean;
}

const AppBar = mStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})<AppBarProps>(({ theme, drawerOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(drawerOpen && {
    marginLeft: Size.DRAWER_WIDTH_EXPANDED,
    width: `calc(100% - ${Size.DRAWER_WIDTH_EXPANDED}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const ResponsiveAppBar = () => {
  const { drawerOpen, loading } = useUserInfoContext();
  const { locale } = useIntl();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const message = useTranslatedMessage();

  if (loading) return null;

  const visiblePages = PAGES.filter((page) => !page.isHidden)
    .map(page => {
      if (page.id === 'product/prodict') {
        page.children = INDUSTRIES.map(industry => ({
          id: industry.name,
          label: locale === 'en' ? `${industry.dictNameEN} Dictionary` : industry.dictNameZH,
          labelKey: "",
          path: `/${industry.name}`
        }));
        return page;
      }

      return page;
    });

  const flattenedVisiblePages = visiblePages.flatMap(page => page.children ? [page, ...page.children] : [page]);

  const isStaticEnv = process.env.REACT_APP_BUILD_ENV === 'static';

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // navitation is handled by Link component
  const handleClickMenuItem = (page: MenuItemObj) => {
    handleCloseNavMenu();
  }

  const renderLink = (page: MenuItemObj) => {
    return (
      <Link href={getLocaledAbsolutePath(page.path ?? "", locale)} sx={{
        textDecoration: 'none'
      }}>
        <Button
          onClick={() => handleClickMenuItem(page)}
          sx={{
            my: 0,
            ml: 1.2,
            display: 'block',
            fontWeight: 700,
            fontSize: '1rem',
            color: Color.OFFWHITE,
            '&:hover': {
              // color: Color.BACKGROUND_THEME,
              color: Color.PINK
            },
            textTransform: 'none'
          }}
        >
          {Boolean(page.labelKey) ? message(page.labelKey) : page.label}
        </Button>
      </Link>
    )
  }

  return (
    <AppBar position="sticky" sx={{ background: Color.DARK_GREY_TRANSLUCENT }} drawerOpen={drawerOpen} >
      <Container maxWidth="xl" sx={{ paddingLeft: '0 !important' }}>
        <Toolbar disableGutters>
          {drawerOpen !== true && <LogoButton tooltipPosition='bottom' />}
          {/* Shrinked appbar layout when screen size is small */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              {flattenedVisiblePages.map((page) => (
                <Link key={page.id} sx={{ textDecoration: 'none' }} href={getLocaledAbsolutePath(page.path ?? "", locale)}>
                  <MenuItem onClick={() => handleClickMenuItem(page)} sx={{
                    fontSize: '1rem'
                  }}>
                    <Typography textAlign="center" sx={{ fontWeight: 700, color: Color.PRIMARY_FONT_DARK_GREY }}>{page.labelKey ? message(page.labelKey) : page.label}</Typography>
                  </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          {/* Expanded appbar layout when screen size is normal */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}>
            {visiblePages.map((page) => (
              <Box key={page.id} sx={{
                position: 'relative',
                ':hover': {
                  '.child-box': {
                    display: 'block'
                  }
                }
              }}>
                {renderLink(page)}
                {page.children &&
                  <Box className={`child-box`} sx={{ display: 'none', position: 'absolute', top: '40px', backgroundColor: Color.DARK_GREY_TRANSLUCENT }}>
                    <Box sx={{ marginTop: '10px' }}></Box>
                    {(page.children || []).map(childPage => (<Box key={childPage.id} sx={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                      {renderLink(childPage)}
                    </Box>))}
                    <Box sx={{ marginTop: '10px' }}></Box>
                  </Box>
                }
              </Box>
            ))}
          </Box>
          {isStaticEnv ? null : <IntlSelect />}
          <UserMenus />
        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default ResponsiveAppBar;
