import { LocalStorageKey } from "../constants"
import { DEFAULT_LOCALE } from "../translations";
import { AuthToken, User, UserStatus } from "../types"

// undefined cannot be JSON-parsed, so needs to be saved as null
interface LocalStoreKeyToValue {
    [LocalStorageKey.USER]: User | null;
    [LocalStorageKey.USER_STATUS]: UserStatus;
    [LocalStorageKey.REMEMBERED_EMAIL]: string;
    [LocalStorageKey.HIGHLIGHT_KEYWORD]: boolean;
    [LocalStorageKey.EXPAND_TARGET]: boolean;
    [LocalStorageKey.DRAWER_OPEN]: boolean | null;
    [LocalStorageKey.SHOW_ALL_TARGET]: boolean;
    [LocalStorageKey.HIDE_WEAK_MATCH_TARGET]: boolean;
    [LocalStorageKey.AUTH_TOKEN]: AuthToken | null;
    [LocalStorageKey.LOCALE]: string;
    [LocalStorageKey.INDUSTRY]: string;
}

type LocalStoreValue<K extends LocalStorageKey> = K extends keyof LocalStoreKeyToValue ? LocalStoreKeyToValue[K] : never;

const getValFromKey = (key: LocalStorageKey, fallbackVal: any): any => JSON.parse(window.localStorage.getItem(key) || JSON.stringify(fallbackVal));
const setVal = (key: LocalStorageKey, value: any): void => window.localStorage.setItem(key, JSON.stringify(value));

export function getFromLocalStore<K extends LocalStorageKey>(key: K): LocalStoreValue<K> {
    switch (key) {
        case LocalStorageKey.USER:
            return getValFromKey(key, null) as LocalStoreValue<K>;
        case LocalStorageKey.USER_STATUS:
            return getValFromKey(key, UserStatus.UNREGISTERED) as LocalStoreValue<K>;
        case LocalStorageKey.REMEMBERED_EMAIL:
            return getValFromKey(key, "") as LocalStoreValue<K>;
        case LocalStorageKey.HIGHLIGHT_KEYWORD:
            return getValFromKey(key, false) as LocalStoreValue<K>;
        case LocalStorageKey.EXPAND_TARGET:
            return getValFromKey(key, false) as LocalStoreValue<K>;
        case LocalStorageKey.DRAWER_OPEN:
            return getValFromKey(key, null) as LocalStoreValue<K>;
        case LocalStorageKey.SHOW_ALL_TARGET:
            return getValFromKey(key, false) as LocalStoreValue<K>;
        case LocalStorageKey.HIDE_WEAK_MATCH_TARGET:
            return getValFromKey(key, false) as LocalStoreValue<K>;
        case LocalStorageKey.AUTH_TOKEN:
            return getValFromKey(key, null) as LocalStoreValue<K>;
        case LocalStorageKey.LOCALE:
            return getValFromKey(key, DEFAULT_LOCALE) as LocalStoreValue<K>;
        case LocalStorageKey.INDUSTRY:
            return getValFromKey(key, null) as LocalStoreValue<K>;
        default:
            throw (new Error(`Invalid local storage key: ${key}`));
    }
}

export function setLocalStore<K extends LocalStorageKey>(key: K, value: LocalStoreValue<K>): void {
    if (key === LocalStorageKey.DRAWER_OPEN) {
        setVal(key, value ?? null);
    } else {
        setVal(key, value);
    }
}

export const clearUserCache = () => {
    setLocalStore(LocalStorageKey.USER, null);
    setLocalStore(LocalStorageKey.USER_STATUS, UserStatus.OFFLINE);
    setLocalStore(LocalStorageKey.AUTH_TOKEN, null);
}