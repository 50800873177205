import { Link } from "react-router-dom";
import { DICT_NAME_FULL, DICT_NAME_EN, Path, getLocaledAbsolutePath } from "../../constants";
import { useIntlContext } from "../../hooks/use-intl-context";
import { TranslationMessage, useTranslatedMessage } from "../../hooks/use-translated-message";
import Template from "./Template";
import { Paragraph } from './styled';

const LinkToProDict = ({ locale, message }: { locale: string, message: TranslationMessage }) => <Link to={getLocaledAbsolutePath(Path.PRODUCT_PRODICT, locale)}>{message('Product.ProDict')}</Link>

interface ContentProps {
    message: TranslationMessage;
}

const ContentZH = ({ message }: ContentProps) => (
    <>
        <Paragraph>{`
        ${DICT_NAME_FULL}是一款针对专业术语提供高速模糊查询的中英文双语词典，其目标用户是研究人员、专业从业人员、专业翻译人员、
        研究生，以及专业英语学生。
        `}</Paragraph>
        <Paragraph>{`
        ${DICT_NAME_EN}包含约五百万专业术语词条，基于先进的信息检索技术，针对词条提供高速的模糊查询。${DICT_NAME_EN}的
        词条来源于专业翻译人员查询以及网络用户提供的开放的术语表。我们对这些资源进行了筛选、分类以及处理，使其成为可供高效检索的短语词条。
        我们对搜索结果的排序基于词条和搜索关键词的匹配度以及翻译人员验证的词条可信度水平。
        `}</Paragraph>
        <Paragraph>{`
        我们针对${DICT_NAME_EN}词条的整理做了大量工作，并且当前仍在积极探索进一步提升搜索匹配质量，优化专业领域分类，开发新的功能，
        并持续提升用户体验。我们欢迎用户反馈遇到的使用问题。我们十分注重用户体验和反馈，并致力于通过持续更新提升用户体验。
        `}</Paragraph>
        <Paragraph>
            <>
                {`
            ${DICT_NAME_EN}在scidict.org是完全免费使用的产品。同时，我们在scidict.pro提供付费的专业版本，以期为有高级使用需求的
            特定用户提供更强大的功能和体验。专业版本用户可解锁针对搜索词在细分领域进行深入搜索的功能，词条质量更高，词条语境信息更全面，
            相对于免费用户，高级用户的搜索性能有更好的保证，并且享受免广告承诺，等等。详情请参考
            `}
                <LinkToProDict locale="zh" message={message} />
            </>
        </Paragraph>
        <Paragraph>{`
        ${DICT_NAME_EN}针对专业术语对提供快速的查询。我们注重术语翻译过程中语境的价值，提供术语在不同短语中的翻译结果。我们支持
        匹配关键词的突出显示功能，因此用户可以看到术语的不同使用场景，并决定哪一个译词最适用于自己的语境。用户需具备足够专业知识，
        从搜索结果中判断专业术语译词在其特定语境下的准确性和恰当性。
        `}</Paragraph>
    </>
);

const ContentEN = ({ message }: ContentProps) => (
    <>
        <Paragraph>{`
        SCIDICT is an online Chinese-English bilingual dictionary product dedicated to provide fast fuzzy query 
        service for specialized terminology. Its target audiences are researchers, industrial professionals, 
        professional translators, graduate students, as well as all specialized English learners. 
        `}</Paragraph>
        <Paragraph>{`
        SCIDICT contains about 5 million specialized term phrase pairs. It provides fast fuzzy query of entries 
        based on advanced information retrieval technology.
        The resources of SCIDICT include contributions by professional translators, free open glossaries contributed by web users.
        We screened, classified and processed the resources into searchable phrase pairs to be ready for
        efficient fuzzy query in SCIDICT. The ranking of results is based on a synthesized score of matching degree for a search word
        and confidence level based on human translator verification.
        `}</Paragraph>
        <Paragraph>{`
        We have made tremendous efforts processing existing entries in SCIDICT. There are active on-going efforts in further 
        improving matching quality, optimizing field classification, enhancement with new features, as well as a continour 
        improvement of user experience. Users are encouraged to report any issues they see in entries. We also value feedback on user experience
         and are dedicated to making continous improvement for better user experience.
        `}</Paragraph>
        <Paragraph>
            <>
                {`
        SCIDICT is completely free at scidict.org. We also provide a paid pro-version product at scidict.pro, 
        in an attempt to provide an enhanced experience for specific users with advanced requirement. Users of pro-version
         are able to unlock the feature of deep-dive a search word in a specialized field, enhanced quality and context of 
         each entry, guranteed higher performance compared to free users, ads-free gurantee, etc. See
        `}
                <LinkToProDict locale="en" message={message} />
            </>
        </Paragraph>
        <Paragraph>{`
        SCIDICT provides fast query service for phrase pair entries. We embrace the value of context in terminology 
        translation, and provide translations of a terminology in difference phrases. We support the functionality of highlighting matching keywords, 
        so users are able to see different use cases of a term and decide on which best suites their scenarios. 
        Users are expected to have sufficient knowledge to judge the accuracy and appropriateness in their specific context for term translation
        provided from the research results of SCIDICT.
        `}</Paragraph>
    </>
);

const AboutUs = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntlContext();

    return (<Template title={message('Navigation.Product')}>
        {
            (() => {
                switch (locale) {
                    case 'en':
                        return <ContentEN message={message} />
                    case 'zh':
                        return <ContentZH message={message} />
                    default:
                        return <ContentEN message={message} />
                }
            })()
        }
    </Template>);
}

export default AboutUs;