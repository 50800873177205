import { createContext, useCallback, useMemo, useState, useContext } from "react";
import { NoIndustryID, type SearchResult, type Status, type TabIndustryID } from "../types";
import { getIndustryIdByName } from "../constants";

type SearchDataContextValue = {
    keyword: string;
    setKeyword: (keyword: string) => void;
    industryId: TabIndustryID;
    setIndustryId: (industryId: TabIndustryID) => void;
    setIndustryIdByName: (name: string) => void;
    searchResult?: SearchResult | null;
    setSearchResult: (searchResult?: SearchResult) => void;
    status?: Status;
    setStatus: (status?: Status) => void;
    loading?: boolean;
    setLoading: (loading?: boolean) => void;
}
const defaultValue: SearchDataContextValue = { keyword: '', setKeyword: () => { }, industryId: NoIndustryID.EMPTY, setIndustryId: () => { }, setIndustryIdByName: () => { }, setSearchResult: () => { }, setStatus: () => { }, setLoading: () => { } }
export const SearchDataContext = createContext<SearchDataContextValue>(defaultValue);

export const SearchDataContextProvider = ({ children }: any) => {
    const [keyword, setKeyword] = useState<string>('');
    const [industryId, setIndustryId] = useState<TabIndustryID>(NoIndustryID.EMPTY);
    const [searchResult, setSearchResult] = useState<SearchResult>();
    const [status, setStatus] = useState<Status>();
    const [loading, setLoading] = useState<boolean>();
    const setIndustryIdByName = useCallback((name: string) => {
        const id = getIndustryIdByName(name);
        setIndustryId(id ?? NoIndustryID.EMPTY);
    }, []);

    const value = useMemo(() => (
        {
            keyword,
            setKeyword,
            industryId,
            setIndustryId,
            setIndustryIdByName,
            searchResult,
            setSearchResult,
            status,
            setStatus,
            loading,
            setLoading
        }
    ), [keyword,
        setKeyword,
        industryId,
        setIndustryId,
        setIndustryIdByName,
        searchResult,
        setSearchResult,
        status,
        setStatus,
        loading,
        setLoading
    ]);

    return (
        <SearchDataContext.Provider value={value} >
            {children}
        </SearchDataContext.Provider>
    )
}

export const useSearchDataContext = () => {
    const { keyword, industryId, setIndustryId, setIndustryIdByName, searchResult, setKeyword, setSearchResult, status, setStatus, loading, setLoading } = useContext(SearchDataContext);
    return {
        keyword, industryId, setIndustryId, setIndustryIdByName, searchResult, setKeyword, setSearchResult, status, setStatus, loading, setLoading
    }
}
