import styled from '@emotion/styled/macro';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import { Color } from '../../constants';

const StyledContainer = styled.section`
    text-align: left;
    margin-top: 16px;
    padding: 16px;
`;

const StyledTitle = styled.div`
    font-weight: 700;
    color: ${Color.PRIMARY_FONT_LIGHT_GREY};
    font-size: 14px;
    
`;

const StyledLink = styled.a`
    font-size: 14px;
    color: ${Color.PRIMARY_FONT_LIGHT_GREY};
    margin-right: 8px;
    :hover {
        color: ${Color.PRIMARY_BLUE_MEDIUM};
    }
`;

const RecentUpdates = () => {
    const message = useTranslatedMessage();
    const data = [{
        path: 'https://www.scidict.org/',
        keyword: 'taste'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: 'tastefull'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: 'tasty'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: 'nasty'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: 'happiness'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: '岩石'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: '石英石'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: '大理石'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: '二叠纪'
    },
    {
        path: 'https://www.scidict.org/',
        keyword: '恐龙'
    }]
    return (<StyledContainer>
        <StyledTitle>
            {message('Search.RecentUpdate')}
        </StyledTitle>
        {data.map((link) => <StyledLink className='recent-link' href={link.path}>{link.keyword}</StyledLink>)}
    </StyledContainer>)
}

export default RecentUpdates;