import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useTranslatedMessage } from "../../../hooks/use-translated-message";
import Template from "../Template";
import { useParams } from 'react-router-dom';
import AccountCard from "../../user-center/account/AccountCard";
import useNavigate from "../../../hooks/use-navigate";
import { LoadingPage } from "../../LoadingIndicator";
import { getFromLocalStore } from "../../../utils/local-storage";
import { LocalStorageKey } from "../../../constants";
import { useFetchDictOrders } from "../../../hooks/use-fetch-dict-orders";

const CheckOrder = () => {
    const message = useTranslatedMessage();
    const { navigateToMain, navigateToAccount, navigateToRegister } = useNavigate();
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";

    const params = useParams();

    const orderId = +(params?.orderId ?? 0);
    const orderNo = params?.orderNo ?? "";
    const userId = +(params?.userId ?? "");

    const getDecodedEmail = (hashedEmail: string) => {
        if (!hashedEmail) return "";
        let email = "";
        try {
            email = atob(hashedEmail);
        } catch { }

        return email;
    }

    const email = getDecodedEmail(params?.hashedEmail ?? "");

    // Skip querying an order if email is not found or orderId or orderNo is not provided
    const skip = !email || !orderId || !orderNo;

    const { orders, loading: ordersLoading } = useFetchDictOrders({ orderId, skip });
    const currOrder = orders.find(order => order.orderId === orderId);

    const isOrderFound = Boolean(currOrder)
        && currOrder?.userEmail === email
        && currOrder?.userId === userId
        && currOrder.orderNo === orderNo;

    return (
        <Template title={""} noBreadcrumb>
            {ordersLoading
                ? <LoadingPage />
                : <Box>
                    {!isOrderFound && <Box sx={{ mb: 1, mt: 2 }}>
                        {message('Order.CheckOrder.OrderNotFound')}
                    </Box>}
                    {isOrderFound && currOrder &&
                        <AccountCard
                            key={currOrder.orderId}
                            order={currOrder}
                            readonly
                            showDetails
                            showUserInfo
                            asExternalOrder
                        />
                    }
                    {isOrderFound && <Stack direction={'row'} justifyContent={'center'}>
                        {Boolean(currOrder?.userId)
                            ? <>
                                <Button color="primary"
                                    sx={{ margin: '24px 18px' }}
                                    variant="contained"
                                    onClick={() => navigateToMain({ hardRefresh: true, industry: industryInCache })}
                                >
                                    {message('Form.Button.ReturnHome')}
                                </Button>
                                <Button color="primary"
                                    sx={{ margin: '24px 18px' }}
                                    variant="contained"
                                    onClick={() => navigateToAccount(true)}>
                                    {message('Order.Button.GoToAccount')}
                                </Button>
                            </>
                            : <Button color="primary"
                                sx={{ margin: '24px 18px' }}
                                variant="contained"
                                onClick={() => navigateToRegister({
                                    email: currOrder?.userEmail,
                                    orderNo,
                                    orderId,
                                    isActivate: true
                                })}>
                                {message('Form.Auth.SignUpAndActivate')}
                            </Button>
                        }
                    </Stack>}
                </Box>
            }
        </Template>
    )
}

export default CheckOrder;