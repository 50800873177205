import { styled as mStyled } from '@mui/material/styles';
import Footer from './Footer';

const MainViewWrapper = mStyled('div')(({ theme }) => ({
    ...theme.mixins.fullyExtendedMainHeight.container
}));

const StyledMainView = mStyled('div')(({ theme }) => ({
    ...theme.mixins.fullyExtendedMainHeight.mainSection,
    width: '100%'
}));

interface MainViewProps {
    children: React.ReactNode;
}

const MainView = ({ children }: MainViewProps) => {
    return <MainViewWrapper>
        <StyledMainView>
            {children}
        </StyledMainView>
        <Footer />
    </MainViewWrapper>
}

export default MainView;


