import { getSubPathById } from "../constants";
import { concatPath } from "./url-path";

export const getSearchUrl = (keyword: string) => {
    if (!keyword) return "/";
    return `/search/${keyword}`;
}

export const replaceIndustrySubPath = (path: string, newSubPath: string) => {
    // TODO: Consolidate this logic piece into the main section below
    if (path === '/en') {
        return path + newSubPath;
    }

    // Matching a path pattern that
    // 1. starts with "/en" optionally,
    // 2. followed by "/.+" but should not be matched with "/en"
    // 3. ending with "/search/.+" optionally
    const regex = /^(\/en)?(\/(?!en\/)[^/]+)(\/search\/.*)?$/;
    if (path.match(regex)) {
        const newPath = path.replace(regex, `$1${newSubPath}$3`);
        return newPath === "" ? "/" : newPath;
    }

    return path;
}

export const getIndustrySanitizedPath = (path: string) => {
    return replaceIndustrySubPath(path, "");
}

export const getPathByIndustry = (path: string, newIndustryId: string) => {
    const newSubPath = getSubPathById(newIndustryId);
    const newPath = replaceIndustrySubPath(path, newSubPath);
    if (newPath !== path) {
        return newPath;
    }

    // If the original path has no industry
    const indexSearch = path.indexOf("/search");
    if (indexSearch >= 0) {
        const newPath = path.substring(0, indexSearch) + newSubPath + path.substring(indexSearch);
        return newPath;
    }

    // If the original path has no "/search"
    // Meaning it is a main path
    return concatPath(path, newSubPath);
}