import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import useNavigate from '../../hooks/use-navigate';
import { useUserInfoContext } from '../../hooks/use-user-info-context';
import { USER_MENU_ITEMS, Color, getLocaledAbsolutePath, Path, LocalStorageKey } from '../../constants';
import { useUserAPI } from '../../hooks/use-user-api';
import { getAvatarLetter } from '../../utils/get-avatar-letter';
import { MenuItemObj } from '../../types';
import { useTranslatedMessage } from '../../hooks/use-translated-message';
import MenuButton from '../MenuButton';
import { Link } from '@mui/material';
import { useIntl } from 'react-intl';
import { getFromLocalStore } from '../../utils/local-storage';

const UserMenus = () => {
    const { navigateToMain } = useNavigate();
    const { user, showDrawer, loading } = useUserInfoContext();
    const { logoutUser } = useUserAPI();
    const message = useTranslatedMessage();
    const { navigate } = useNavigate();
    const { locale } = useIntl();
    const industryInCache = getFromLocalStore(LocalStorageKey.INDUSTRY) || "";

    if (loading) return null;

    const handleLogout = () => {
        const email = user?.email ?? '';
        if (!email) return;
        logoutUser(email);
        navigateToMain({ industry: industryInCache });
    }

    const handleMenuClick = (menuItem: MenuItemObj) => () => {
        const { path, onClick } = menuItem;
        path && navigate(path);
        showDrawer();
        onClick?.();
    }

    const getEnhancedMenuItems = () => {
        return USER_MENU_ITEMS.map((menuItem: MenuItemObj) => {
            const onClick = menuItem.id === 'logout' ? handleLogout : handleMenuClick(menuItem);
            return { ...menuItem, onClick };
        });
    }

    if (!user) {
        return (<Box sx={{ flexGrow: 0 }}>
            <Link href={getLocaledAbsolutePath(Path.LOGIN, locale)} sx={{
                color: Color.WHITE,
                ':hover': {
                    color: Color.WHITE
                }
            }}>
                <Button
                    sx={{
                        background: 'rgba(200, 200, 200, 0.30)',
                        borderRadius: '20px'
                    }}
                    color="inherit" onClick={() => { }}
                >{message('Form.Auth.SignIn')}</Button>
            </Link>
        </Box>);
    }

    const icon = (
        <Avatar sx={{ width: 26, height: 26, background: Color.BACKGROUND_THEME }} variant='rounded'>
            {getAvatarLetter(user)}
        </Avatar>
    );

    const userMenuItems = getEnhancedMenuItems();

    return (
        <MenuButton
            items={userMenuItems}
            icon={icon}
            ariaLabel={message('Navigation.UserCenter')}
        />
    )
}

export default UserMenus;