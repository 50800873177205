import { useIntlContext } from "../../hooks/use-intl-context";
import { useTranslatedMessage } from "../../hooks/use-translated-message";
import { Paragraph, Row } from './styled';
import Template from "./Template";
import { Box, Typography } from "@mui/material";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import LogoBanner from "../../LogoBanner";

const text: { [key: string]: { [key: string]: string } } = {
    name: {
        en: 'by Cross Language LLC, a Califonia registered business',
        zh: 'Cross Language LLC 语际有限责任公司，注册于美国加州'
    }
}

const ContactUs = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntlContext();

    return (<Template title={message('Navigation.ContactUs')} >
        <>
            <Box sx={{ mb: 6 }}>
                <LogoBanner sx={{ marginLeft: '0' }} />
                <Typography sx={{ textIndent: '1rem' }}>{text.name[locale] ?? ''}</Typography>
            </Box>
            <Box sx={{ paddingLeft: '1rem' }}>
                <Paragraph>
                    <Box display={'flex'}>
                        <Box display={'flex'} width={'6rem'}><EmailIcon /><Typography sx={{ textIndent: '1rem' }}>{message('General.Email')}</Typography></Box>
                        <Box>
                            <Row>{`support@scidict.org`}</Row>
                        </Box>
                    </Box>
                </Paragraph>
                <Paragraph>
                    <Box display={'flex'}>
                        <Box display={'flex'} width={'6rem'}><PlaceIcon /><Typography sx={{ textIndent: '1rem' }}>{message('General.Address')}</Typography></Box>
                        <Box>
                            <Row>{`21163 Newport Coast Dr Suite 116`}</Row>
                            <Row>{`Newport Beach, CA 92657`}</Row>
                            <Row>{`United States`}</Row>
                        </Box>
                    </Box>
                </Paragraph>
            </Box>
        </>
    </Template>);
}

export default ContactUs;