import { useCallback } from "react";
import { useUserInfoContext } from "./use-user-info-context";
import { fetchData } from "../utils/fetch-data";
import { mapOrderFullToPayload, parseOrderData } from '../utils/parse-order-data';
import {
    FETCH_PAYMENT_INTENT_URL,
    PAYMENT_CONFIG_URL,
    PAYMENT_CREATE_SUBSCRIPTION_URL,
    PAYMENT_CREATE_FUTURE_SUBSCRIPTION_URL,
    CREATE_PAYMENT_INTENT_URL,
    PAYMENT_CANCEL_SUBSCRIPTION_URL,
    PAYMENT_CREATE_SETUP_INTENT_URL
} from "../constants";
import { DictOrderFull } from "../types";
import useNavigate from "./use-navigate";
import { useMessageContext } from "./use-message-context";
import { useTranslatedMessage } from "./use-translated-message";
import { useIntl } from "react-intl";

export const usePaymentAPI = () => {
    const { setStripePublicKey } = useUserInfoContext();
    const { reload } = useNavigate();
    const { showToastMessage } = useMessageContext();
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    const fetchPaymentIntent = useCallback(async (orderFull: DictOrderFull): Promise<string> => {
        const { result } = await fetchData(
            FETCH_PAYMENT_INTENT_URL,
            {
                method: 'POST',
                data: mapOrderFullToPayload(orderFull)
            }
        );

        const clientSecret = await result.data.clientSecret;
        return clientSecret ?? "";
    }, []);

    const createPaymentIntent = useCallback(async (orderFull: DictOrderFull): Promise<string> => {
        const { result } = await fetchData(
            CREATE_PAYMENT_INTENT_URL,
            {
                method: 'POST',
                data: mapOrderFullToPayload(orderFull)
            }
        );

        const clientSecret = await result.data.clientSecret;
        return clientSecret ?? "";
    }, []);

    const fetchConfig = useCallback(async () => {
        const { result } = await fetchData(
            PAYMENT_CONFIG_URL,
            {
                method: 'GET'
            }
        );
        setStripePublicKey(result);
    }, [setStripePublicKey]);

    const createSetupIntent = useCallback(async (orderInfo: DictOrderFull) => {
        const response = await fetchData(
            PAYMENT_CREATE_SETUP_INTENT_URL,
            {
                method: 'POST',
                data: {
                    orderId: orderInfo.orderId,
                    productId: orderInfo.productId,
                    subscriptionType: orderInfo.subscriptionTypeId,
                    monthNo: orderInfo.activeMonthNum,
                    price: orderInfo.totalCharge,
                    currencyCode: orderInfo.currencyCode,
                    stripeUserId: orderInfo.stripeUserId ?? '',
                    userId: orderInfo.userId.toString(),
                    email: orderInfo.userEmail,
                }
            }
        );
        const clientSecret = await response?.result?.clientSecret;
        return clientSecret ?? "";
    }, []);

    const createSubscription = useCallback(async (orderInfo: DictOrderFull): Promise<string> => {
        const { result } = await fetchData(
            PAYMENT_CREATE_SUBSCRIPTION_URL,
            {
                method: 'POST',
                data: {
                    orderId: orderInfo.orderId,
                    productId: orderInfo.productId,
                    subscriptionType: orderInfo.subscriptionTypeId,
                    monthNo: orderInfo.activeMonthNum,
                    price: orderInfo.totalCharge,
                    currencyCode: orderInfo.currencyCode,
                    stripeUserId: orderInfo.stripeUserId ?? '',
                    userId: orderInfo.userId.toString(),
                    email: orderInfo.userEmail,
                    isOrderUpdate: 0
                }
            }
        );

        const clientSecret = result?.clientSecret ?? "";

        return clientSecret;
    }, []);

    const createFutureSubscription = useCallback(async (orderInfo: DictOrderFull, isOrderUpdate?: boolean): Promise<DictOrderFull | null> => {
        const response = await fetchData(
            PAYMENT_CREATE_FUTURE_SUBSCRIPTION_URL,
            {
                method: 'POST',
                data: {
                    orderId: orderInfo.orderId,
                    productId: orderInfo.productId,
                    subscriptionType: orderInfo.subscriptionTypeId,
                    monthNo: orderInfo.activeMonthNum,
                    price: orderInfo.totalCharge,
                    currencyCode: orderInfo.currencyCode,
                    stripeUserId: orderInfo.stripeUserId ?? '',
                    userId: orderInfo.userId.toString(),
                    email: orderInfo.userEmail,
                    isOrderUpdate: 1
                }
            }
        );

        const fetchedOrder = await response?.result;
        return fetchedOrder ? parseOrderData(fetchedOrder, message, locale) : null;
    }, [locale, message]);

    const cancelSubscription = useCallback(async (orderId: number, userId: string, subscriptionId: string): Promise<boolean> => {
        try {
            const { result } = await fetchData(
                PAYMENT_CANCEL_SUBSCRIPTION_URL,
                {
                    method: 'POST',
                    data: {
                        orderId,
                        userId,
                        subscriptionId
                    }
                }
            );

            if (result) {
                showToastMessage({
                    type: 'success',
                    message: message('Form.Msg.SuccessCancelSubscription')
                });
                reload();
            } else {
                showToastMessage({
                    type: 'error',
                    message: message('Form.Msg.FailureCancelSubscription')
                });
            }

            return Boolean(result);
        } catch (e) {
            return false;
        }
    }, [reload, message, showToastMessage]);

    return {
        fetchPaymentIntent,
        createPaymentIntent,
        fetchConfig,
        createSubscription,
        createFutureSubscription,
        createSetupIntent,
        cancelSubscription
    }
}