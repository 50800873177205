import { Path } from '../constants';

export type EmailTemplate = {
    id: string;
    label: string;
    subject: string;
    path: string;
    examples?: string[];
}

export const templates: EmailTemplate[] = [
    {
        id: 'registration',
        label: 'Registration Confirmation',
        subject: 'Welcome to Scidict!',
        path: Path.TEMPLATE_REGISTRATION,
        examples: ['./registration-example1', './registration-example2', './registration-example3', './registration-example4', './registration-example5']
    },
    {
        id: 'forgotpass',
        label: 'Forgot Password',
        subject: 'Reset Password',
        path: Path.TEMPLATE_FORGOTPASS,
        examples: ['./forgotpass-example1', './forgotpass-example2', './forgotpass-example3']
    }
]