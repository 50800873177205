import { SnackbarOrigin } from '@mui/material/Snackbar';
import { AlertColor } from '@mui/material/Alert';
import { SvgIconProps } from '@mui/material/SvgIcon';

export type Specialty = {
    name: string;
    label?: string;
    ariaLabel: string;
    value: string;
}

export interface MatchMeasure {
    pattern: string;
    score: number
}

interface MatchInfo {
    // 0-5 scale temporarily
    matchScore?: number;
    isMatched: boolean;
}

export interface TextMatchItem extends MatchInfo {
    text: string;
}

export interface EnrichedTextMatchItem extends TextMatchItem {
    parts?: TextMatchItem[];
    html?: JSX.Element;
}

export interface RawEntry {
    source: string;
    // raw target returned from api
    target: string[];
    industries: IndustryID[];
    matchindexes?: string
}

export interface Entry extends MatchInfo, RawEntry {
    sourceHtml?: JSX.Element;
    // enhanced targets calcualted from target
    targets: string[];
    targetHtmls?: JSX.Element[];
    matchindexes?: string
}

// Match with BE status
export enum StatusCode {
    SUCCESS = 0,
    FAIL = 1
}

export type Status = {
    status: StatusCode;
    message?: string | string[] | null;
}

export type SearchResult = {
    // keyword: string,
    // status: Status,
    // result: {
    //     total: number,
    //     entries: Entry[],
    //     targets?: Entry[] | null
    // } | null
    keyword: string,
    total: number,
    entries: RawEntry[],
    targets?: RawEntry[] | null,
    matchindexes?: string | null;
}

export type ValidationResult = {
    result: boolean;
    error?: string;
}

// user without email and id
export interface UserHydrated {
    name?: string;
    nickname?: string;
    gender?: string;
    title?: string;
    telephone?: string;
    mobilephone?: string;
    qq?: string;
    wechat?: string;
    institution?: string;
    department?: string;
    industry?: string;
    profession?: string;
    proname?: string;
    country?: string;
    province?: string;
    city?: string;
    // membership?: string;
    // domains?: string[];
    usertype?: number;
    dicttypeids?: string;
    emailverified?: number;
    locale?: string;
}

export interface UserWithoutId extends UserHydrated {
    email: string;
}

export interface UserWithoutEmail extends UserHydrated {
    id: string;
}

export interface User extends UserWithoutId {
    id: string;
    stripeId: string;
}

export interface UserForRegistration extends UserWithoutId {
    password: string;
    agreementChecked: boolean;
    isHuman: boolean;
    country: string;
}

export interface UserCredential {
    email: string;
    password: string;
    password2?: string;
    oldPassword?: string;
    rememberMe?: boolean;
    token?: string;
}

export interface MenuItemObj {
    id: string;
    parentId?: string;
    isHidden?: boolean;
    labelKey: string;
    label?: string;
    IconComponent?: React.FC<SvgIconProps>,
    path?: string;
    onClick?: () => void;
    children?: MenuItemObj[];
}

// UserStatus used to track user's login status
export enum UserStatus {
    // logged in, currently active online
    ONLINE = 'online',
    // login expired/logged out, but user info is stored/cached
    OFFLINE = 'offline',
    // unregistered, never logged in, or user info is cleared
    UNREGISTERED = 'unregistered',
    // attempted login, but failed
    LOGINFAILED = 'loginfailed'
}

export enum UserType {
    FREE = 0,
    UNIPRO = 1,
    BIPRO = 2,
    TRIPRO = 3,
    VIPPRO = 4
}

export type RouteError = {
    statusText: string;
    message: string;
}

export interface ToastMessageProps {
    message: string;
    hideDuration?: number,
    position?: SnackbarOrigin,
    type?: AlertColor
}

export interface DialogProps {
    title?: string;
    content: string;
    onCancel?: () => void;
    onConfirm?: () => void;
    cancelButtonText?: string;
    confirmButtonText?: string;
}

export interface FormError {
    [key: string]: string | string[];
}

export enum FormType {
    REGISTRATION = 'registration',
    LOGIN = 'login',
    RESET_PASS = 'resetPass',
    FORGOT_PASS = 'forgotPass',
    PROFILE = 'profile',
    ORDER = 'order'
}

export interface Page {
    id: string,
    label: string,
    path: string
}

export interface AuthToken {
    jwt: string;
    expiry: Date;
}

export interface ObjData {
    [key: string]: unknown;
}

export enum DataType {
    NUMERIC = 'NUMERIC',
    STRING = 'STRING',
    STRING_ARR = 'STRING_ARR',
    DATE = 'DATE'
}

export interface ColumnData {
    id: string;
    label: string;
    dataType: DataType;
    width: number;
}

export interface FavoriteWordsData extends ObjData {
    srcword: string;
    tgtword: string;
    keyword: string;
    id: number;
}

export interface SearchHistoryData extends ObjData {
    keyword: string;
    numSearch: number;
    recentTime: Date;
    id: number;
}

export interface Product {
    id: string;
    name: string;
    dictNo: number;
    price: number;
    monthNo: number;
    subscriptionType: number;
}

export interface ProductIndex {
    [key: string]: {
        [key: string]: Product
    }
}

export interface DictOrderInfo extends ObjData {
    dictTypes: string[];
    dictTypeIds: string[]
    productId: string;
    subscriptionType: string;
    subscriptionTypeId: string;
    activeMonth: string;
    activeMonthNum: string;
    baseCharge: number;
    taxCharge: number;
    totalCharge: number;
    currencyCode: number;
    invoiceTitle: string;
    billingAddressLine1: string;
    billingAddressLine2: string;
    billingAddressLine3: string;
    billingAddressDistrict: string;
    billingAddressCity: string;
    billingAddressState: string;
    billingAddressCountry: string;
    billingAddressZipcode: string;
    userPhone: string;
    isTrial: boolean;
    isAutoRenew: boolean;
    locale: string;
    // true: allowing using a future date as activateTime
    // false: use current time as activation time, ignoring duplicates
    // undefined: to be determined, need to be asked from the server
    isRenew?: boolean;
    refOrderId?: number;
}

export interface DictOrderWithUserInfo extends DictOrderInfo {
    userId: number;
    userEmail: string;
    userName: string;
}

export interface DictOrderFull extends DictOrderWithUserInfo {
    orderId: number;
    orderNo: string;
    orderState: string;
    createTime: Date;
    paidAmount: number;
    payTime?: Date;
    activateTime?: Date;
    expireTime?: Date;
    invoiceId: string;
    subscriptionId: string;
    // stripeUserId is a FE specific field, but not included in DB table, that aims to assist data passing
    stripeUserId?: string;
    refOrders?: DictOrderFull[];
    latestExpireDate?: Date;
    priceLabel?: string;
    validPeriod?: string;
    piid?: string;
    pmid?: string;
}

export interface StripeAddress {
    elementMode: 'billing' | 'shipping';
    value: {
        name?: string;
        phone?: string;
        address?: {
            line1?: string;
            line2?: string | null;
            city?: string;
            country?: string;
            postal_code?: string;
            state?: string;
        }
    };
    empty: boolean;
    complete: boolean;
    isNewAddress: boolean;
}

export enum AddressType {
    BILLING = 'billing',
    SHIPPING = 'shipping'
}

export interface Address {
    autoId: number;
    fullName?: string;
    addressType: AddressType;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    district?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    phone?: string;
    isDefault?: boolean;
}

export interface IPaymentMethod {
    id: number;
    stripePmId: string;
    dictUserId: number;
    stripeCustomerId: string;
    email: string;
    fullName: string;
    billingAddressId: string;
    isDefault: number;
    paymentChannel: string;
    card?: IPaymentCard;
}

export interface IPaymentCard {
    pmId: number;
    stripePmId: string;
    dictUserId: number;
    stripeCustomerId: string;
    email: string;
    createTime: Date;
    cardBrand: string;
    displayBrand: string;
    country: string;
    expMonth: string;
    expYear: string;
    fingerPrint: string;
    funding: string;
    iin: string;
    issuer: string;
    last4: string;
}

export interface UserSetting {
    userId: number;
    highlightKeyword?: boolean;
    expandTarget?: boolean;
    drawerMode?: boolean;
}

export enum IndustryID {
    SCIENCE = '0',
    GEO = '1',
    BIOMED = '2',
    CHEM = '3',
    MECH = '4',
    INFO = '5',
    COMMERCE = '6',
    GENERAL = '7',
    HUMAN = '8',
}

export enum NoIndustryID {
    ALL = 'all',
    EMPTY = ''
}

export type TabIndustryID = IndustryID | NoIndustryID;