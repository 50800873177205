import { matchRoutes } from 'react-router-dom';
import { Path, getLocaledAbsolutePath, isIndustryValid } from '../constants';
import { ObjData } from '../types';

export const isSearchResultPath = (pathname: string) => {
    const regex = new RegExp(`^${Path.SEARCH}/.+`);
    return regex.test(pathname);
}

export const trimPathEnd = (path: string) => {
    while (path.endsWith('/') && path.length > 1) {
        path = path.substring(0, path.length - 1);
    }
    return path;
}

export const isPathsEqual = (path1: string, path2: string) => {
    if (path1 === path2) return true;
    path1 = trimPathEnd(path1.toLowerCase());
    path2 = trimPathEnd(path2.toLowerCase());
    return path1 === path2;
}

export const isDictPath = (pathname: string, proOnly: boolean) => {
    const routes = [
        { path: Path.LOCALED_DICT_MAIN },
        { path: Path.LOCALED_SEARCH_KEYWORD }
    ];
    const matches = matchRoutes(routes, pathname);
    if (!matches || matches.length === 0) return false;
    const { params } = matches[0];
    const hasIndustry = Boolean(params?.industry);
    const isProPath = isIndustryValid(params.industry ?? "");

    if (proOnly) {
        return isProPath;
    }

    return !hasIndustry || isProPath;
}

export const concatPath = (path1: string, path2: string) => {
    if (path1.endsWith('/')) {
        path1 = path1.substring(0, path1.length - 1);
    }
    if (path2.startsWith('/')) {
        path2 = path2.substring(1);
    }
    return path1 + '/' + path2;
}

export const trimPathLocale = (path: string) => {
    if (path.startsWith('/en/')) {
        return path.substring(3);
    }
    if (path === '/en') {
        return '/';
    }
    return path;
}

export const getStringifiedCheckoutParams = (checkoutPath: string, locale: string) => {
    const basePath = getLocaledAbsolutePath(Path.PRODUCT_CHECKOUT, locale);
    const index = checkoutPath.indexOf(basePath);
    if (index >= 0) {
        return checkoutPath.substring(index + basePath.length);
    }
    return checkoutPath;
}

export const getPaymentSuccessPathOld = (orderId: number, isFuture: boolean, isAutoRenew: boolean, checkoutPath: string, locale: string) => {
    const basePath = getLocaledAbsolutePath(Path.PRODUCT_PAYMENTSUCCESS, locale);
    const stringifiedParam = getStringifiedCheckoutParams(checkoutPath, locale);
    const otherParams = `/${isAutoRenew ? '1' : '0'}/${isFuture ? '1' : '0'}/${orderId}`;
    return window.location.origin + basePath + stringifiedParam + otherParams;
}

export const getPaymentSuccessPath = (orderNo: string, orderId: number, isFuture: boolean, isAutoRenew: boolean, locale: string) => {
    const basePath = getLocaledAbsolutePath(Path.PRODUCT_PAYMENTSUCCESS, locale);
    const params = `/${orderNo}/${orderId}/${isAutoRenew ? '1' : '0'}/${isFuture ? '1' : '0'}`;
    return window.location.origin + basePath + params;
}

// TODO: Url params need encode
export const buildParams = (paramsObj: ObjData) => {
    let params = "";
    Object.entries(paramsObj).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
            if (params.length > 0) {
                params += "&";
            }
            params += key + "=" + value
        }
    });

    if (params.length > 0) {
        params = '?' + params;
    }

    return params;
}
