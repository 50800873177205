import {
    StatusCode, FormType,
    type Status,
    type UserForRegistration,
    type FormError,
    type UserCredential,
    type UserHydrated,
    type DictOrderWithUserInfo,
    StripeAddress
} from '../types';
import { type TranslationMessage } from '../hooks/use-translated-message';

function withinVarcharLimit(input: string, VarcharLimit: number): boolean {
    const MAX_LENGTH = VarcharLimit;
    let length = 0;

    for (let char of input) {
        length += char.charCodeAt(0) <= 127 ? 1 : 2;
    }

    return length <= MAX_LENGTH;
}

const checkNumericStr = (value: string, message: TranslationMessage) => {
    const isNumericRegex = /^[0-9]+$/;
    const isNumeric = isNumericRegex.test(value);
    return isNumeric ? '' : message('Form.Msg.NotANumber');
}

const checkNonEmptyField = (value: string, message: TranslationMessage) => {
    const passed = value.length > 0;
    return passed ? '' : message('Form.Msg.EmptyField');
}

const checkMinLength = (value: string, minLength: number, message: TranslationMessage) => {
    if (value.length < minLength) {
        return message('Form.Msg.TooShort');
    }
    return '';
}

const checkMaxLength = (value: string, varcharLimit: number, message: TranslationMessage) => {
    if (!withinVarcharLimit(value, varcharLimit)) {
        return message('Form.Msg.TooLong');
    }
    return '';
}

const checkEmailFormat = (value: string, message: TranslationMessage) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const passed = emailRegex.test(value);
    return passed ? '' : message('Form.Msg.NotAValidEmail');
}

// const validatePasswordStrength = (password: string): Status => {
//     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
//     const passed = passwordRegex.test(password);
//     const result: Status = {
//         status: passed ? StatusCode.SUCCESS : StatusCode.FAIL,
//         message: passed ? '' : '不规范'
//     }
//     return result;
// };

// One line hint version
const checkPasswordStrengthOneLineHint = (password: string, message: TranslationMessage): string => {
    if (password.length < 8) {
        // return "Password must be at least 8 characters long.";
        return message('Form.Msg.Password.AtLeast8Chars');
    }
    if (!/[A-Z]/.test(password)) {
        // return "Password must contain at least one uppercase letter.";
        return message('Form.Msg.Password.AtLeastOneUpperCase');
    }
    if (!/[a-z]/.test(password)) {
        // return "Password must contain at least one lowercase letter.";
        return message('Form.Msg.Password.AtLeastOneLowerCase');
    }
    if (!/\d/.test(password)) {
        // return "Password must contain at least one number.";
        return message('Form.Msg.Password.AtLeastOneNumber');
    }
    if (!/[@$!%*?&]/.test(password)) {
        // return "Password must contain at least one special character (@, $, !, %, *, ?, &).";
        return `${message('Form.Msg.Password.AtLeastOneSpecialChar')}(@, $, !, %, *, ?, &)`
    }
    if (password.length > 20) {
        // return "Password must be at most 8 characters long.";
        return message('Form.Msg.Password.AtMost20Chars');
    }

    return "";
};

// Multiple line hint version
const checkPasswordStrength = (password: string, message: TranslationMessage): string[] => {
    const errMsgs = [];
    if (password.length < 8) {
        // return "Password must be at least 8 characters long.";
        errMsgs.push(message('Form.Msg.Password.AtLeast8Chars'));
    }
    if (!/[A-Z]/.test(password)) {
        // return "Password must contain at least one uppercase letter.";
        errMsgs.push(message('Form.Msg.Password.AtLeastOneUpperCase'));
    }
    if (!/[a-z]/.test(password)) {
        // return "Password must contain at least one lowercase letter.";
        errMsgs.push(message('Form.Msg.Password.AtLeastOneLowerCase'));
    }
    if (!/\d/.test(password)) {
        // return "Password must contain at least one number.";
        errMsgs.push(message('Form.Msg.Password.AtLeastOneNumber'));
    }
    if (!/[@$!%*?&]/.test(password)) {
        // return "Password must contain at least one special character (@, $, !, %, *, ?, &).";
        errMsgs.push(`${message('Form.Msg.Password.AtLeastOneSpecialChar')}(@, $, !, %, *, ?, &)`);
    }
    if (password.length > 20) {
        // return "Password must be at most 8 characters long.";
        errMsgs.push(message('Form.Msg.Password.AtMost20Chars'));
    }

    return errMsgs;
};

const checkPasswordConsistency = (password: string, password2: string, message: TranslationMessage): string => {
    return password === password2 ? '' : message('Form.Msg.InconsistentPasswords');
}

const checkMobilePhoneNumberFormat = (phone: string, message: TranslationMessage): string => {
    const passed = /^(\+\d{1,3}[- ]?)?\d{10,14}$/.test(phone);
    return passed ? '' : message('Form.Msg.IncorrectFormat');
}

const validateNonEmptyField = (value: string, label: string, message: TranslationMessage): Status => {
    const errMsg = checkNonEmptyField(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }
    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

export const validateAddressField = (address: StripeAddress['value'], message: TranslationMessage): Status => {
    const errMsgs = [];

    if (checkNonEmptyField(address?.name ?? '', message)) {
        errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.Name') }));
    }
    if (checkNonEmptyField(address?.address?.line1 ?? '', message)) {
        errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.StreetAddress') }));
    }
    if (checkNonEmptyField(address?.address?.city ?? '', message)) {
        errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.City') }));
    }
    if (checkNonEmptyField(address?.address?.state ?? '', message)) {
        errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.State') }));
    }
    if (checkNonEmptyField(address?.address?.country ?? '', message)) {
        errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.Country') }));
    }
    if (checkNonEmptyField(address?.address?.postal_code ?? '', message)) {
        errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.ZipCode') }));
    }
    // user phone is collected via PhoneField, skipping validation from AddressElement
    // if (checkNonEmptyField(address?.phone ?? '', message)) {
    //     errMsgs.push(message('Form.Msg.FieldIsEmpty', { field: message('Form.Profile.Phone') }));
    // }

    if (errMsgs.length > 0) {
        return {
            status: StatusCode.FAIL,
            message: errMsgs
        }
    }

    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validateEmailField = (value: string, message: TranslationMessage): Status => {
    const label = message('Form.Profile.Email');
    let errMsg = checkNonEmptyField(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkEmailFormat(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkMaxLength(value, 50, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
};

const validatePasswordField = (value: string, message: TranslationMessage): Status => {
    const label = message('Form.Field.Password');
    let errMsg = checkNonEmptyField(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    const errMsgs = checkPasswordStrength(value, message);
    if (errMsgs.length > 0) {
        return {
            status: StatusCode.FAIL,
            message: errMsgs.map((msg) => `${label}${msg}`)
        }
    }

    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validatePassword2Field = (value: string, valueRef: string, message: TranslationMessage): Status => {
    const errMsg = checkPasswordConsistency(value, valueRef, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${errMsg}`
        }
    }
    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validateNameField = (value: string, message: TranslationMessage): Status => {
    const label = message('Form.Profile.Name');
    let errMsg = checkMaxLength(value, 50, message);

    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkNonEmptyField(value, message);

    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validateMobilePhoneNumber = (value: string, message: TranslationMessage): Status => {
    const label = message('Form.Profile.Mobile');

    let errMsg = checkNonEmptyField(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkMinLength(value, 8, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkMaxLength(value, 20, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkMobilePhoneNumberFormat(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }
    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validateAgreementChecked = (value: boolean, message: TranslationMessage): Status => {
    if (!value) {
        return {
            status: StatusCode.FAIL,
            message: message('Form.Msg.PlsCheckUserAgreement')
        };
    }
    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validateIsHuman = (value: boolean, message: TranslationMessage): Status => {
    if (!value) {
        return {
            status: StatusCode.FAIL,
            message: message('Form.Msg.PlsValidateHuman')
        };
    }
    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

const validateIdField = (value: string, message: TranslationMessage): Status => {
    const label = "ID";

    let errMsg = checkNonEmptyField(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }

    errMsg = checkNumericStr(value, message);
    if (errMsg) {
        return {
            status: StatusCode.FAIL,
            message: `${label}${errMsg}`
        }
    }
    return {
        status: StatusCode.SUCCESS,
        message: ''
    }
}

export const sanitizeFieldValue = (value: string, toLowerCase?: boolean) => {
    let result = value.trim();
    if (toLowerCase) {
        result = result.toLowerCase();
    }
    return result;
}

export const sanitizePhoneValue = (value: string) => {
    const phone = sanitizeFieldValue(value, true);
    return phone.replaceAll(" ", "");
}

export const validateFieldValue = ({ name, value, valueRef, formType, message }: { name: string; value: any; valueRef?: any; formType: FormType, message: TranslationMessage }) => {
    switch (name) {
        case "id":
            return validateIdField(value, message);
        // TODO: Special validation should be added from both FE and BE to ensure uniqueness of email
        case "email":
            value = sanitizeFieldValue(value, true);
            return validateEmailField(value, message);
        case 'password':
            // Password validation for registration form only
            return formType === FormType.REGISTRATION || formType === FormType.RESET_PASS
                ? validatePasswordField(value, message)
                : validateNonEmptyField(value, message('Form.Field.Password'), message);
        case 'password2':
            // Password consistentcy validation for reset password form only
            return validatePassword2Field(value, valueRef, message);
        case 'name':
            value = sanitizeFieldValue(value);
            return validateNameField(value, message);
        case 'mobilephone':
            value = sanitizePhoneValue(value);
            return validateMobilePhoneNumber(value, message);
        case 'agreementChecked':
            return validateAgreementChecked(value, message);
        case 'isHuman':
            return validateIsHuman(value, message);
        case 'country':
            return validateNonEmptyField(value, message('Form.Profile.Country'), message);
        default:
            break;
    }
    return { status: StatusCode.SUCCESS, message: '' };
}

export const validateOrderFormFields = (order: DictOrderWithUserInfo, address: StripeAddress['value'], message: TranslationMessage) => {
    const formError: FormError = {};

    // Validate email only for unregistered user
    if (!Boolean(order.userId)) {
        const emailValidateResult = validateFieldValue({ name: "email", value: order.userEmail, formType: FormType.ORDER, message });
        if (emailValidateResult.status === StatusCode.FAIL) {
            formError["email"] = emailValidateResult.message || '';
        }
    }

    const mobilePhoneValidateResult = validateFieldValue({ name: "mobilephone", value: order.userPhone, formType: FormType.ORDER, message });
    if (mobilePhoneValidateResult.status === StatusCode.FAIL) {
        formError["mobilephone"] = mobilePhoneValidateResult.message || '';
    }

    const countryValidateResult = validateFieldValue({ name: "country", value: order.billingAddressCountry, formType: FormType.ORDER, message });
    if (countryValidateResult.status === StatusCode.FAIL) {
        formError["country"] = countryValidateResult.message || '';
    }

    const isUS = address.address?.country === 'US';

    if (isUS) {
        const addressValidationResult = validateAddressField(address, message);
        if (addressValidationResult.status === StatusCode.FAIL) {
            formError['address'] = addressValidationResult.message || '';
        }
    } else {
        const nameValidateResult = validateNonEmptyField(order.userName, message('Form.Profile.Name'), message);
        if (nameValidateResult.status === StatusCode.FAIL) {
            formError["name"] = nameValidateResult.message || '';
        }
    }

    return formError;
}

export const validateRegisterFormFields = (user: UserForRegistration, message: TranslationMessage) => {
    const formError = Object.entries(user).reduce((acc: FormError, curr: [string, any]) => {
        const [name, value] = curr;
        if (value !== undefined) {
            const currValidateResult = validateFieldValue({ name, value, formType: FormType.REGISTRATION, message });
            if (currValidateResult.status === StatusCode.FAIL) {
                acc[name] = currValidateResult.message || '';
            }
        }
        return acc;
    }, {} as FormError);

    return formError;
}

export const validateLoginFormFields = (user: UserCredential, message: TranslationMessage) => {
    const formError: FormError = {};
    const emailValidateResult = validateFieldValue({ name: "email", value: user.email, formType: FormType.LOGIN, message });
    if (emailValidateResult.status === StatusCode.FAIL) {
        formError["email"] = emailValidateResult.message || '';
    }
    const passwordValidateResult = validateNonEmptyField(user.password, message('Form.Field.Password'), message);
    if (passwordValidateResult.status === StatusCode.FAIL) {
        formError["password"] = passwordValidateResult.message || '';
    }

    return formError;
}

export const validateForgotPasswordFormFields = (email: string, message: TranslationMessage) => {
    const formError: FormError = {};
    const emailValidateResult = validateFieldValue({ name: "email", value: email, formType: FormType.FORGOT_PASS, message });
    if (emailValidateResult.status === StatusCode.FAIL) {
        formError["email"] = emailValidateResult.message || '';
    }
    return formError;
}

export const validateResetPasswordFormFields = (user: UserCredential, message: TranslationMessage, excludeToken?: boolean) => {
    const formError: FormError = {};
    const passwordValidateResult = validateFieldValue({ name: "password", value: user.password, formType: FormType.RESET_PASS, message });
    if (passwordValidateResult.status === StatusCode.FAIL) {
        formError["password"] = passwordValidateResult.message || '';
    }
    if (user.password !== user.password2) {
        formError["password2"] = message('Form.Msg.InconsistentPasswords');
    }

    if (!excludeToken) {
        const tokenValidateResult = validateNonEmptyField(user.token || "", "", message);
        if (tokenValidateResult.status === StatusCode.FAIL) {
            formError["token"] = message('Form.Msg.InvalidResetLink');
        }
    }

    return formError;
}

export const validateProfileFormFields = (user: UserHydrated, message: TranslationMessage) => {
    const formError = Object.entries(user).reduce((acc: FormError, curr: [string, any]) => {
        const [name, value] = curr;
        // Skip email address validation
        if (value !== undefined && name !== 'email') {
            const currValidateResult = validateFieldValue({ name, value, formType: FormType.PROFILE, message });
            if (currValidateResult.status === StatusCode.FAIL) {
                acc[name] = currValidateResult.message || '';
            }
        }
        return acc;
    }, {} as FormError);

    return formError;
}

export const updateFormError = (currFormError: FormError, name: string, errMsg?: string | string[] | null): FormError => {
    const newFormError = { ...currFormError, [name]: errMsg || '' };
    // Removes the field that has no essential error message
    if (!Boolean(errMsg)) {
        delete newFormError[name];
    }
    return newFormError;
}