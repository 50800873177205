export const getRoundedPriceAmount = (amount: number, numDecimal: number = 2) => {
    if (numDecimal === 0) return Math.round(amount);

    const base = 10 * numDecimal;
    return Math.round(amount * base) / base;
}

export const getRoundedPriceLabel = (amount: number) => {
    const numRegularDecimal = 2;
    const roundedAmount = getRoundedPriceAmount(amount, numRegularDecimal);
    return `$ ${roundedAmount.toFixed(numRegularDecimal).padStart(6, ' ')}`;
}

export const getRoundedTaxLabel = (amount: number) => {
    const numTaxDecimal = 2;
    const roundedAmount = getRoundedPriceAmount(amount, numTaxDecimal);
    return `$ ${roundedAmount.toFixed(numTaxDecimal).padStart(6, ' ')}`;
}

export const getUnitFromCurrency = (currency: string) => {
    if (currency === "usd") return "$";
    if (currency === "cny") return "￥";
    return "";
}

export const getUnitFromCurrencyCode = (currencyCode: number) => {
    if (currencyCode === 2) return "$";
    if (currencyCode === 1) return "￥";
    return "";
}

export const getPaidDollarAmountLabel = (centAmount: number, currency: string) => {
    const dollarAmount = centAmount / 100;
    const unit = getUnitFromCurrency(currency);
    return `${unit}${dollarAmount}`;
}

export const getDollarAmountLabel = (dollarAmount: number, currencyCode: number) => {
    const unit = getUnitFromCurrencyCode(currencyCode);
    return `${unit}${dollarAmount}`;
}


