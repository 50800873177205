import exampleHtml1 from './registration-example1';
import exampleHtml2 from './registration-example2';
import exampleHtml3 from './registration-example3';
import exampleHtml4 from './registration-example4';
import exampleHtml5 from './registration-example5';
import { useLocation } from "react-router-dom";

const RegistrationEmailTemplate = () => {
    const location = useLocation();
    const exampleId = location.state?.data;
    const allExamples = [
        exampleHtml1, exampleHtml2, exampleHtml3, exampleHtml4, exampleHtml5
    ]
    const isExample = exampleId !== undefined && exampleId < allExamples.length;
    const html = isExample ? allExamples[exampleId] : '';

    const content = `
    Welcome to Scidict!
You've successfully registered an account with Scidict.org.
Enjoy searching in our dictionary that is dedicated to term phrases translation between Chinese and English
    `

    if (isExample) {
        return (<div dangerouslySetInnerHTML={{ __html: html }} />);
    }

    return (<div>
        {content}
    </div>)
}

export default RegistrationEmailTemplate;