import MLink, { LinkProps } from '@mui/material/Link';

export const Link = ({ children, ...props }: LinkProps) => (
    <MLink
        target={'_blank'} // open in new unless overridden
        {...props}
    >
        {children}
    </MLink>
)

interface MyLinkProps {
    to: string;
    children: any;
}

export const SmallTextLink = ({ to, children }: MyLinkProps) => (
    <Link href={to} variant="body2">
        {children}
    </Link>
)

export const TextLink = ({ to, children }: MyLinkProps) => (
    <Link href={to} variant="body1" color={'rgba(0, 0, 0, 0.87)'}>
        {children}
    </Link>
)

export const ButtonLink = ({ to, children }: MyLinkProps) => (
    <Link href={to} variant="subtitle1" sx={{ fontWeight: 800 }}>
        {children}
    </Link>
)

