import { COMPANY_NAME_FULL, COMPANY_NAME_EN_FULL } from "../../constants";
import { useIntlContext } from "../../hooks/use-intl-context";
import { useTranslatedMessage } from "../../hooks/use-translated-message";
import Template from "./Template";
import { Paragraph as P } from './styled';

const ContentEN = () => (
    <>
        <P>This Privacy Policy describes how {`${COMPANY_NAME_EN_FULL}`} ("we," "us," or "our") collects, uses, stores, and shares your personal information when you use our online dictionary product (the "Service").

        </P><P>1. Information We Collect

        </P><P>1.1. Personal Information: When you register for an account or use the Service, we may collect personal information such as your name, email address, and any other information you voluntarily provide.

        </P><P>1.2. Usage Information: We may collect information about how you access and use the Service, including your IP address, browser type, device type, operating system, and interactions with the Service.

        </P><P>1.3. Cookies and Similar Technologies: We may use cookies, web beacons, and other similar technologies to collect information about your interactions with the Service and to improve your user experience.

        </P><P>2. How We Use Your Information

        </P><P>2.1. Providing the Service: We use your personal information to provide, maintain, and improve the Service, including to personalize your experience and to respond to your inquiries and requests.

        </P><P>2.2. Analytics and Research: We may use your information for analytics purposes, such as to analyze trends, track user engagement, and improve the performance of the Service.

        </P><P>2.3. Communications: We may use your email address to send you important notices, updates, and promotional materials related to the Service. You may opt-out of receiving promotional emails at any time.

        </P><P>3. How We Share Your Information

        </P><P>3.1. Service Providers: We may share your personal information with third-party service providers who assist us in providing, maintaining, and improving the Service. These service providers are obligated to use your information only as necessary to provide the services to us.

        </P><P>3.2. Legal Compliance: We may disclose your personal information if required to do so by law or if we believe that such disclosure is necessary to comply with legal obligations, protect our rights or property, or prevent fraud or abuse.

        </P><P>4. Data Retention

        </P><P>4.1. We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.

        </P><P>4.2. If you wish to delete your account or request the deletion of your personal information, please contact us at support@scidict.org.

        </P><P>5. Data Security

        </P><P>5.1. We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.

        </P><P>6. Children's Privacy

        </P><P>6.1. The Service is not directed to children under the age of 13, and we do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us at support@scidict.org.

        </P><P>7. Changes to This Privacy Policy

        </P><P>7.1. We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.

        </P><P>8. Contact Us

        </P><P>8.1. If you have any questions or concerns about this Privacy Policy, please contact us at support@scidict.org.</P>
        <P>This Privacy Policy provides a detailed overview of our data collection, usage, storage, and sharing practices. By using the Service, you acknowledge that you have read and agree to this Privacy Policy.</P>
    </>
)

const ContentZH = () => (
    <>
        <P>本隐私政策描述了当您使用我们的在线词典产品（以下简称“服务”）时，{`${COMPANY_NAME_FULL}`}（“我们”，“我们”或“我们的”）如何收集、使用、存储和分享您的个人信息。

        </P><P>1. 我们收集的信息

        </P><P>1.1. 个人信息：当您注册账户或使用服务时，我们可能会收集您的个人信息，如姓名、电子邮件地址等您自愿提供的信息。

        </P><P>1.2. 使用信息：我们可能会收集关于您如何访问和使用服务的信息，包括您的IP地址、浏览器类型、设备类型、操作系统以及与服务的互动。

        </P><P>1.3. Cookie 和类似技术：我们可能会使用 cookie、网络信标和其他类似技术来收集有关您与服务的互动以及改进您的用户体验的信息。

        </P><P>2. 我们如何使用您的信息

        </P><P>2.1. 提供服务：我们使用您的个人信息来提供、维护和改进服务，包括个性化您的体验以及回复您的询问和请求。

        </P><P>2.2. 分析和研究：我们可能会使用您的信息进行分析，如分析趋势、跟踪用户参与度以及改进服务的性能。

        </P><P>2.3. 通讯：我们可能会使用您的电子邮件地址向您发送重要通知、更新和与服务相关的促销材料。您可以随时选择不接收促销邮件。

        </P><P>3. 我们如何分享您的信息

        </P><P>3.1. 服务提供商：我们可能会与第三方服务提供商分享您的个人信息，这些服务提供商协助我们提供、维护和改进服务。这些服务提供商有义务仅根据必要使用您的信息来为我们提供服务。

        </P><P>3.2. 法律遵从：如果法律要求或我们相信有必要遵守法律义务、保护我们的权利或财产，或防止欺诈或滥用，我们可能会披露您的个人信息。

        </P><P>4. 数据保留

        </P><P>4.1. 我们将根据本隐私政策中概述的目的保留您的个人信息，除非法律要求或允许延长保留期限。

        </P><P>4.2. 如果您希望删除您的账户或要求删除您的个人信息，请联系我们：support@scidict.org。

        </P><P>5. 数据安全

        </P><P>5.1. 我们采取合理的措施保护您的个人信息免受未经授权的访问、使用或披露。然而，互联网传输或电子存储的方法没有绝对安全性，我们不能保证绝对安全。

        </P><P>6. 儿童隐私

        </P><P>6.1. 服务不针对13岁以下的儿童，我们不会故意收集13岁以下儿童的个人信息。如果您是父母或监护人，并认为您的孩子向我们提供了个人信息，请联系我们。

        </P><P>7. 隐私政策的变更

        </P><P>7.1. 我们可能会不时更新本隐私政策。我们将通过在此页面上发布新的隐私政策来通知您任何变更。建议您定期查看本隐私政策以了解任何变更。

        </P><P>8. 联系我们

        </P><P>8.1. 如果您对本隐私政策有任何疑问或疑虑，请联系我们：support@scidict.org。

        </P><P>本隐私政策提供了对我们的数据收集、使用、存储和共享做法的详细概述。通过使用服务，您确认您已阅读并同意本隐私政策。</P>
    </>
)

const PrivacyPolicy = () => {
    const message = useTranslatedMessage();
    const { locale } = useIntlContext();

    return (<Template title={message('Navigation.PrivacyPolicy')}>
        {
            (() => {
                switch (locale) {
                    case 'en':
                        return <ContentEN />
                    case 'zh':
                        return <ContentZH />
                    default:
                        return <ContentEN />
                }
            })()
        }
    </Template>);
}

export default PrivacyPolicy;