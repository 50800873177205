import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SettingsIcon from '@mui/icons-material/Settings';
import { type Specialty, type ToastMessageProps, type MenuItemObj, type ObjData, type IndustryID, NoIndustryID } from './types';

export const DICT_NAME_FULL = 'SCIDICT赛斯专业词典';
export const DICT_NAME_EN = 'SCIDICT';
export const DICT_NAME_CN = '赛斯专业词典';
export const COMPANY_NAME_FULL = '美国Cross Language LLC（语际有限责任公司）';
export const COMPANY_NAME_SHORT = 'Cross Language语际';
export const COMPANY_NAME_EN_SGORT = 'Cross Language';
export const COMPANY_NAME_EN_FULL = 'Cross Language LLC';

export const LocalePathPattern = {
    EN: /^\/en($|\/.*)/
}
export enum Path {
    PREFIX_LOCALE = '/en?',
    PREFIX_INDUSTRY = '/:industry?',
    MAIN_EN = '/en',
    MAIN_INDUSTRY = '/:industry',
    MAIN = '/',
    LOCALED_DICT_MAIN = '/en?/:industry?',
    LOCALED_SEARCH_KEYWORD = '/en?/:industry?/search/:keyword?',
    ABOUT_US = '/about-us',
    CONTACT_US = '/contact-us',
    TERMS_AND_CONDITIONS = '/terms-and-conditions',
    PRIVACY_POLICY = '/privacy-policy',
    FORGOT = '/forgot',
    LOGIN = '/login',
    PRODUCT = '/product',
    PRODUCT_PRODICT = '/product/pro-scidict',
    PRODUCT_CHECKOUT = '/product/checkout',
    // param dictTypes is needed on checkout page to show customers their initial selection
    PRODUCT_CHECKOUT_PARAM = '/product/checkout/:productId/:subscriptionType/:activeMonth/:basePrice/:dictTypes',
    PRODUCT_CHECKORDER = '/product/check-order',
    PRODUCT_CHECKORDER_PARAM = '/product/check-order/:orderNo/:orderId/:userId/:hashedEmail/',
    PRODUCT_PAYMENTSUCCESS = '/product/payment-success',
    // param dictTypes is not needed on payment success page, but we just want to append based on the checkout url
    // now we don't have to rely on checkout url, but we can retrive data from orderId
    PRODUCT_PAYMENTSUCCESS_PARAM = '/product/payment-success/:orderNo/:orderId/:isAutoRenew/:isFuture/*',
    REGISTER = '/register',
    RESET = '/reset',
    SEARCH = '/:industry?/search',
    SEARCH_KEYWORD = '/:industry?/search/:keyword',
    SEARCH_KEYWORD2 = '/:industry?/search2/:keyword',
    SEARCH_KEYWORD_TPL = '/search/keyword.tpl',
    USER = '/user',
    USER_PROFILE = '/user/profile',
    USER_ACCOUNT = '/user/account',
    USER_ACCOUNT_SECTION = '/user/account/:section',
    USER_ACCOUNT_CHANGE_PASSWORD = '/user/account/change-password',
    USER_ACCOUNT_TYPE = '/user/account/account-type',
    USER_ACCOUNT_ORDER_HISTORY = '/user/account/order-history',
    USER_GLOSSARY = '/user/glossary',
    USER_GLOSSARY_FAVORITE = '/user/glossary/favorite-words',
    USER_GLOSSARY_HISTORY = '/user/glossary/search-history',
    USER_SETTINGS = '/user/settings',
    TEMPLATE = '/template',
    TEMPLATE_REGISTRATION = '/template/registration',
    TEMPLATE_FORGOTPASS = '/template/forgotpass',
}

export const getLocaleTrimmedPath = (path: string) => {
    if (path.startsWith("/en/")) {
        return path.substring(3);
    }
    if (path === "/en") {
        return "/";
    }
    return path;
}

export const getLocaledAbsolutePath = (absPath: string, locale: string, industryId?: string) => {
    const industrySubPath = getSubPathById(industryId ?? "");
    const unLocaledPath = industrySubPath + getLocaleTrimmedPath(absPath);
    const result = locale === 'en' ? `/${locale}${unLocaledPath}` : unLocaledPath;

    return result;
}

export const getRelativePath = (absPath: string, parentAbsPath: string) => {
    const childPath = absPath.substring(parentAbsPath.length);
    return childPath.startsWith('/') ? childPath.substring(1) : childPath;
}
// TODO: Nest menu items in UI
export const PAGES: MenuItemObj[] = [
    { id: 'product', labelKey: 'Navigation.Product', path: Path.PRODUCT },
    { id: 'product/prodict', parentId: 'product', labelKey: 'Product.ProDict', path: Path.PRODUCT_PRODICT },
    { id: 'product/checkout', parentId: 'product', isHidden: true, labelKey: 'Product.Checkout', path: Path.PRODUCT_CHECKOUT },
    { id: 'aboutus', labelKey: 'Navigation.AboutUs', path: Path.ABOUT_US },
    { id: 'contactus', labelKey: 'Navigation.ContactUs', path: Path.CONTACT_US }
];

export const FOOTER_PAGES: MenuItemObj[] = [
    { id: 'termsconditions', labelKey: 'Navigation.TermsAndConditions', path: Path.TERMS_AND_CONDITIONS },
    { id: 'privacypolicy', labelKey: 'Navigation.PrivacyPolicy', path: Path.PRIVACY_POLICY },
    { id: 'contactus', labelKey: 'Navigation.ContactUs', path: Path.CONTACT_US }
    // { id: 'unsubscribe', labelKey: 'Navigation.Unsubscribe', path: Path.UNSUBSCRIBE }
]

export const USER_MENU_ITEMS: MenuItemObj[] = [
    {
        id: 'profile', IconComponent: PersonIcon, labelKey: 'Navigation.Profile', path: Path.USER_PROFILE
    },
    { id: 'account', IconComponent: ShoppingBagIcon, labelKey: 'Navigation.Account', path: Path.USER_ACCOUNT },
    { id: 'glossary', IconComponent: FavoriteIcon, labelKey: 'Navigation.Glossary', path: Path.USER_GLOSSARY },
    { id: 'settings', IconComponent: SettingsIcon, labelKey: 'Navigation.Settings', path: Path.USER_SETTINGS },
    { id: 'logout', IconComponent: LogoutIcon, labelKey: 'Navigation.Logout' }
];

export const GLOSSARY_LINKS: MenuItemObj[] = [{
    id: 'searchHistory',
    labelKey: 'Navigation.SearchHistory',
    path: Path.USER_GLOSSARY_HISTORY
}
    // TODO: Reenable favorite words after supporing from BE
    // , {
    // id: 'favorateWords',
    // labelKey: 'Navigation.FavorateWords',
    // path: Path.USER_GLOSSARY_FAVORITE
    // }
];

export enum Color {
    // PINK = '#b5458a'
    WHITE = '#fff',
    OFFWHITE = '#eee',
    OFFWHITE2 = 'rgb(242, 242, 242)',
    PINK_DARK = '#a3407d',
    PINK = '#ba4486',
    PRIMARY_FONT_LIGHT_GREY = '#424041',
    PRIMARY_FONT_DARK_GREY = '#555',
    DARK_GREY = '#515454',
    DARK_GREY_TRANSLUCENT = 'rgba(50, 50, 50, 0.7)',
    PRIMARY_FONT_BLACK = '#222',
    FONT_MATCH_COLOR = 'maroon',
    // linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(166,228,241,1) 50%, rgba(252,176,69,1) 100%);
    BACKGROUND_THEME = 'linear-gradient(90deg, rgba(139, 138, 138, 1) 0%, rgba(156, 177, 180, 1) 50%, rgba(146, 146, 148, 1) 100%)',
    PRIMARY_BLUE = '#374680',
    PRIMARY_BLUE_DARK = '#252c5b',
    PRIMARY_BLUE_MEDIUM = '#465795',
    PRIMARY_BLUE_LIGHT = '#6070a2',
    PRIMARY_BLUE_CONTRAST_TEXT = '#fff',
    PRIMARY_GREY = '#515454',
    PRIMARY_MATCH_FONT = '#6070a2',
    SECONDARY_MATCH_FONT = '#465795',
    SECONDARY_MATCH_CHECK_MARK = '#999',
    ERROR = '#803746',
    WARNING = '#807037',
    // SUCCESS = '#28576a'
    SUCCESS = '#8ba2a3',
    INFO = DARK_GREY_TRANSLUCENT
}

export enum Font {
    FONT_SIZE_TGT = '20px',
    FONT_SIZE_PHRASE = '14px',
    FONT_SIZE_LARGE = '20px',
    FONT_SIZE_MEDIUM_LARGE = '16px',
    FONT_SIZE_MEDIUM = '14px',
    FONT_SIZE_SMALL = '11px',
}

export enum Size {
    DRAWER_WIDTH_EXPANDED = 170,
    DRAWER_WIDTH_SHRINKED = 56,
    APPBAR_LOGO_WIDTH = 55,
    APPBAR_HEIGHT = 56,
    DRAWER_LOGO_WIDTH = 55,
    MAIN_LOGO_BANNER_WIDTH = 200,
    DRAWER_LOGO_BANNER_WIDTH = 150,
    DICT_LABEL_BANNER_WIDTH = 250
}

export const MAX_CHI_KEYWORD_LENGTH = 20;
export const MAX_EN_KEYWORD_LENGTH = 50;

export const DICT_SERVER_URL = process.env.REACT_APP_DICT_SERVER_URL || '';
export const DICT_SERVER_PRO_URL = process.env.REACT_APP_DICT_SERVER_PRO_URL || '';
const HOSTNAME = process.env.REACT_APP_DBACCESS_HOSTNAME || '';
export const DICT_PRODUCT_URL = HOSTNAME + process.env.REACT_APP_DICT_PRODUCT_PATH || '';
export const DICT_ORDER_PLACE_URL = HOSTNAME + process.env.REACT_APP_DICT_ORDER_PLACE_PATH || '';
export const DICT_ORDER_LIST_URL = HOSTNAME + process.env.REACT_APP_DICT_ORDER_LIST_PATH || '';
export const DICT_ORDER_LIST_GUEST_URL = HOSTNAME + process.env.REACT_APP_DICT_ORDER_LIST_GUEST_PATH || '';
export const DICT_ORDER_UPDATE_URL = HOSTNAME + process.env.REACT_APP_DICT_ORDER_UPDATE_PATH || '';
export const DICT_ORDER_UPDATE_DICT_URL = HOSTNAME + process.env.REACT_APP_DICT_ORDER_UPDATE_DICT_PATH || '';
export const USER_ADDRESS_URL = HOSTNAME + process.env.REACT_APP_USER_ADDRESS_PATH || '';
export const USER_PAYMENT_METHOD_URL = HOSTNAME + process.env.REACT_APP_USER_PAYMENT_METHOD_PATH || '';
export const USER_SET_DEFAULT_PAYMENT_METHOD_URL = HOSTNAME + process.env.REACT_APP_USER_SET_DEFAULT_PAYMENT_METHOD_PATH || '';
export const USER_DELETE_PAYMENT_METHOD_URL = HOSTNAME + process.env.REACT_APP_USER_DELETE_PAYMENT_METHOD_PATH || '';
export const USER_CURRENT_URL = HOSTNAME + process.env.REACT_APP_USER_CURRENT_PATH || '';
export const USER_LOGIN_URL = HOSTNAME + process.env.REACT_APP_USER_LOGIN_PATH || '';
export const USER_LOGOUT_URL = HOSTNAME + process.env.REACT_APP_USER_LOGOUT_PATH || '';
export const USER_REGISTER_URL = HOSTNAME + process.env.REACT_APP_USER_REGISTER_PATH || '';
export const USER_ACTIVATE_URL = HOSTNAME + process.env.REACT_APP_USER_ACTIVATE_PATH || '';
export const USER_UPDATE_PROFILE_URL = HOSTNAME + process.env.REACT_APP_USER_UPDATE_PROFILE_PATH || '';
export const USER_UPDATE_LOCALE_URL = HOSTNAME + process.env.REACT_APP_USER_UPDATE_LOCALE_PATH || '';
export const USER_UPDATE_SETTING_URL = HOSTNAME + process.env.REACT_APP_USER_UPDATE_SETTING_PATH || '';
export const USER_VALIDATE_CAPTCHA_URL = HOSTNAME + process.env.REACT_APP_USER_VALIDATE_CAPTCHA_PATH || '';
export const USER_CHECK_UNIQUENESS_URL = HOSTNAME + process.env.REACT_APP_USER_CHECK_UNIQUENESS_PATH || '';
export const USER_FORGOT_PASSWORD_URL = HOSTNAME + process.env.REACT_APP_USER_FORGOT_PASSWORD_PATH || '';
export const USER_RESET_PASSWORD_URL = HOSTNAME + process.env.REACT_APP_USER_RESET_PASSWORD_PATH || '';
export const USER_SEARCH_HISTORY_URL = HOSTNAME + process.env.REACT_APP_USER_SEARCH_HISTORY_PATH || '';
export const FETCH_PAYMENT_INTENT_URL = HOSTNAME + process.env.REACT_APP_FETCH_PAYMENT_INTENT_PATH || '';
export const CREATE_PAYMENT_INTENT_URL = HOSTNAME + process.env.REACT_APP_CREATE_PAYMENT_INTENT_PATH || '';
export const PAYMENT_CONFIG_URL = HOSTNAME + process.env.REACT_APP_PAYMENT_CONFIG_PATH || '';
export const PAYMENT_CREATE_SUBSCRIPTION_URL = HOSTNAME + process.env.REACT_APP_PAYMENT_CREATE_SUBSCRIPTION_PATH || '';
export const PAYMENT_CREATE_FUTURE_SUBSCRIPTION_URL = HOSTNAME + process.env.REACT_APP_PAYMENT_CREATE_FUTURE_SUBSCRIPTION_PATH || '';
export const PAYMENT_CREATE_SETUP_INTENT_URL = HOSTNAME + process.env.REACT_APP_PAYMENT_CREATE_SETUP_INTENT_PATH || '';
export const PAYMENT_CANCEL_SUBSCRIPTION_URL = HOSTNAME + process.env.REACT_APP_PAYMENT_CANCEL_SUBSCRIPTION_PATH || '';


export enum LocalStorageKey {
    USER = 'USER',
    USER_STATUS = 'USER_STATUS',
    REMEMBERED_EMAIL = 'REMEMBERED_EMAIL',
    HIGHLIGHT_KEYWORD = 'HIGHLIGHT_KEYWORD',
    EXPAND_TARGET = 'EXPAND_TARGET',
    DRAWER_OPEN = 'DRAWER_OPEN',
    SHOW_ALL_TARGET = 'SHOW_ALL_TARGET',
    HIDE_WEAK_MATCH_TARGET = 'HIDE_WEAK_MATCH_TARGET',
    AUTH_TOKEN = 'AUTH_TOKEN',
    LOCALE = 'LOCALE',
    INDUSTRY = 'INDUSTRY'
};

export const DEFAULT_TOAST_HIDE_DURATION = 3000;

export const DEFAULT_TOAST_POSITION = { vertical: 'bottom', horizontal: 'left' } as ToastMessageProps["position"];

export const INDUSTRIES = [{
    id: "0" as IndustryID,
    name: 'science',
    badgeZH: '数理',
    badgeEN: 'MPS',
    titleEN: 'MathPhys',
    titleZH: '数理科学',
    labelZH: '数理基础科学',
    labelEN: 'Math & Physical Sciences',
    dictNameZH: '数理科学专业词典',
    dictNameEN: 'Mathematics & Physics'

}, {
    id: "1" as IndustryID,
    name: 'geo',
    badgeZH: '地科',
    badgeEN: 'GSE',
    titleEN: 'GeoSci',
    titleZH: '地球科学',
    labelZH: '地球科学工程',
    labelEN: 'Geoscience Engineering',
    dictNameZH: '地球科学专业词典',
    dictNameEN: 'Geoscience'
}, {
    id: "2" as IndustryID,
    name: 'biomed',
    badgeZH: '生医',
    badgeEN: 'BPI',
    titleEN: 'BioPharm',
    titleZH: '生物医药',
    labelZH: '生物医药工业',
    labelEN: 'Biopharmaceutical Industry',
    dictNameZH: '生物医药专业词典',
    dictNameEN: 'Biopharmacy'
}, {
    id: "3" as IndustryID,
    name: 'chem',
    badgeZH: '石化',
    badgeEN: 'PCI',
    titleEN: 'PetroChem',
    titleZH: '石油化工',
    labelZH: '石油化工工业',
    labelEN: 'Petrochemical Industry',
    dictNameZH: '石油化工专业词典',
    dictNameEN: 'Petroleum Chemistry'
}, {
    id: "4" as IndustryID,
    name: 'mech',
    badgeZH: '机械',
    badgeEN: 'ME',
    titleEN: 'MechEng',
    titleZH: '机械工程',
    labelZH: '机械工程',
    labelEN: 'Mechanical Engineering',
    dictNameZH: '机械工程专业词典',
    dictNameEN: 'Mechanics'
}, {
    id: "5" as IndustryID,
    name: 'info',
    badgeZH: '电信',
    badgeEN: 'EIT',
    titleEN: 'ElecInfo',
    titleZH: '电子信息',
    labelZH: '电子信息技术',
    labelEN: 'Electronics and Information Technology',
    dictNameZH: '电子信息专业词典',
    dictNameEN: 'Computer & Electronics'
}, {
    id: "6" as IndustryID,
    name: 'economy',
    badgeZH: '经济',
    badgeEN: 'FBE',
    titleEN: 'FinEcon',
    titleZH: '金融经济',
    labelZH: '金融与商业经济',
    labelEN: 'Finance and Business Economics',
    dictNameZH: '经济金融专业词典',
    dictNameEN: 'Finance & Economics'
}, {
    id: "7" as IndustryID,
    name: 'common',
    badgeZH: '日常',
    badgeEN: 'Com',
    titleEN: 'Common',
    titleZH: '日常生活',
    labelZH: '日常生活类',
    labelEN: 'Everyday Commons',
    dictNameZH: '日常生活通用词典',
    dictNameEN: 'Common'
}, {
    id: "8" as IndustryID,
    name: 'humanity',
    badgeZH: '人文',
    badgeEN: 'HSS',
    titleEN: 'HumSoc',
    titleZH: '人文科学',
    labelZH: '人文社会科学',
    labelEN: 'Humanities and Social Sciences',
    dictNameZH: '人文社科专业词典',
    dictNameEN: 'Humanities'
}];

export const getIconCharById = (id: string, local: string) => {
    if (id === "") return "";
    const item = INDUSTRIES.find((entry) => entry.id === id);
    if (!item) return '';
    return local === 'en' ? item.titleZH.charAt(0) : item.titleEN.charAt(0);
}

export const getIndustryIdByName = (name: string) => {
    if (name === "") return NoIndustryID.EMPTY;
    return INDUSTRIES.find((entry) => entry.name === name)?.id ?? NoIndustryID.EMPTY;
}

export const getIndustryNameById = (id: string) => {
    if (id === "") return "";
    return INDUSTRIES.find((entry) => entry.id === id)?.name ?? "";
}

export const getIndustryByName = (name: string) => {
    const id = getIndustryIdByName(name);
    if (id === NoIndustryID.EMPTY) return null;
    return INDUSTRIES[+id];
}

export const isIndustryValid = (name: string) => {
    const id = getIndustryIdByName(name);
    return id !== NoIndustryID.EMPTY;
}

export const getSubPathById = (id: string) => {
    const industry = getIndustryNameById(id);
    return industry === "" ? "" : `/${industry}`;
}

export const getIndustryBadgeById = (id: number, locale: string) => {
    const item = INDUSTRIES[id];
    return locale === 'en' ? item.badgeEN : item.badgeZH;
}

export const getIndustryTitleById = (id: number, locale: string) => {
    const item = INDUSTRIES[id];
    return locale === 'en' ? item.titleEN : item.titleZH;
}

export const getIndustryLabelById = (id: number, locale: string) => {
    const item = INDUSTRIES[id];
    return locale === 'en' ? item.labelEN : item.labelZH;
}

export const getIndustryLabelByIndustry = (name: string, locale: string) => {
    const item = getIndustryByName(name);
    if (item === null) return "";
    return locale === 'en' ? item.labelEN : item.labelZH;
}

export const getDictNameByIndustry = (name: string, locale: string) => {
    const item = getIndustryByName(name);
    if (item === null) return "";
    return locale === 'en' ? item.dictNameEN : item.dictNameZH;
}

export const getSpecialties: ({ locale }: { locale: string }) => Specialty[] = ({ locale }) => {
    return INDUSTRIES.map(({ name, titleEN, titleZH, labelEN, labelZH, id }) => ({
        name,
        label: locale === 'en' ? titleEN : titleZH,
        ariaLabel: locale === 'en' ? labelEN : labelZH,
        value: id.toString()
    }))
}

export const PRODUCT_FEATURES = [{
    id: 'basic',
    titleKey: 'Form.Account.Level.BasicAccount',
    descriptionKeys: [
        'Product.ProductDes.Basic.Line1',
        'Product.ProductDes.Basic.Line2',
        'Product.ProductDes.Basic.Line3'
    ]
}, {
    id: 'pro',
    titleKey: 'Product.ProDict',
    descriptionKeys: [
        'Product.ProductDes.AllIncludedInBasic',
        'Product.ProductDes.Pro.Line1',
        'Product.ProductDes.Pro.Line2',
        // TODO: To be enabled when features are ready, add "new" flag
        // 'Product.ProductDes.Pro.Line3',
        // 'Product.ProductDes.Pro.Line4',
        // 'Product.ProductDes.Pro.Line5',
        'Product.ProductDes.Pro.Line6',
        'Product.ProductDes.Pro.Line7'
    ]
}];

export enum SUBSCRIPTION_TYPE {
    UniPRO = "1",
    BiPRO = "2",
    TriPRO = "3",
    VipPRO = "4"
}

export const SUBSCRIPTIONS = [
    {
        subscriptionType: SUBSCRIPTION_TYPE.UniPRO,
        numDict: 1,
        titleKey: 'Form.Account.Level.UniProAccount',
        descriptionKeys: [
            'Product.ProductDes.UniPro.Line1',
            'Product.ProductDes.UniPro.Line2'
        ],
    },
    {
        subscriptionType: SUBSCRIPTION_TYPE.BiPRO,
        numDict: 2,
        titleKey: 'Form.Account.Level.BiProAccount',
        descriptionKeys: [
            'Product.ProductDes.BiPro.Line1',
            'Product.ProductDes.BiPro.Line2'
        ],
    },
    {
        subscriptionType: SUBSCRIPTION_TYPE.TriPRO,
        numDict: 3,
        titleKey: 'Form.Account.Level.TriProAccount',
        descriptionKeys: [
            'Product.ProductDes.TriPro.Line1',
            'Product.ProductDes.TriPro.Line2'
        ],
    },
    {
        subscriptionType: SUBSCRIPTION_TYPE.VipPRO,
        numDict: 9,
        titleKey: 'Form.Account.Level.VipProAccount',
        descriptionKeys: [
            'Product.ProductDes.VipPro.Line1',
            'Product.ProductDes.VipPro.Line2'
        ]
    }
];

export const getSubscriptionLabelKeyById = (id: string): string => {
    return SUBSCRIPTIONS.find((item) => item.subscriptionType === id)?.titleKey ?? 'General.Placeholder'
}

export const getDictNumBySubscriptionType = (id: string): number => {
    return SUBSCRIPTIONS.find((item) => item.subscriptionType === id)?.numDict ?? 0;
}

export const ORDER_STATUSES = [
    { id: 1, nameKey: 'Order.OrderStatus.Submitted' },
    { id: 2, nameKey: 'Order.OrderStatus.Paid' },
    { id: 3, nameKey: 'Order.OrderStatus.Activated' },
    { id: 4, nameKey: 'Order.OrderStatus.Expired' },
    { id: 7, nameKey: 'Order.OrderStatus.Canceled' },
]

export const ORDER_STATUS_DICT = ORDER_STATUSES.reduce((acc: ObjData, curr) => {
    acc[curr.id] = curr.nameKey;
    return acc;
}, {});

export const getOrderStatusLabelKeyByState = (state: number): string => {
    return ORDER_STATUS_DICT[state] as string ?? 'General.Placeholder';
};

export const SUBSCRIPTION_DURATIONS = [
    { id: "1", nameKey: 'Form.Account.Subscription.OneMonth' },
    { id: "3", nameKey: 'Form.Account.Subscription.ThreeMonths' },
    { id: "6", nameKey: 'Form.Account.Subscription.SixMonths' },
    { id: "12", nameKey: 'Form.Account.Subscription.OneYear' }
];

export const getSubscriptionDurationNameByMonthNum = (num: number) => {
    return SUBSCRIPTION_DURATIONS.find((duration) => duration.id === num.toString())?.nameKey ?? 'General.Placeholder'
}
