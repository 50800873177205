import { ButtonLink } from '../Link';
import { TranslationMessage } from '../../hooks/use-translated-message';
import { Path, getLocaledAbsolutePath } from '../../constants';

const RegisterLink = ({ message, locale }: { message: TranslationMessage, locale: string }) => (
    <ButtonLink to={getLocaledAbsolutePath(Path.REGISTER, locale)}>
        {message('Form.Auth.SignUp')}
    </ButtonLink>
)

export default RegisterLink;