import { useIntl } from "react-intl";
import { useMessageContext } from "./use-message-context";
import { useTranslatedMessage } from "./use-translated-message";
import { DictOrderFull, DictOrderWithUserInfo } from "../types";
import { DICT_ORDER_LIST_GUEST_URL, DICT_ORDER_LIST_URL, DICT_ORDER_PLACE_URL, DICT_ORDER_UPDATE_DICT_URL, DICT_ORDER_UPDATE_URL } from '../constants';
import { fetchData } from "../utils/fetch-data";
import { mapOrderInfoToPayload, mapOrderFullToPayload, parseOrderData, parseOrdersData, groupRefOrders } from "../utils/parse-order-data";
import { useCallback } from "react";
import useNavigate from "./use-navigate";

export const useDictOrderAPI = () => {
    const { showToastMessage } = useMessageContext();
    const { reload } = useNavigate();
    const message = useTranslatedMessage();
    const { locale } = useIntl();

    const placeOrder = async (
        orderInfo: DictOrderWithUserInfo,
        setLoading: (loading: boolean) => void,
        setOrder: (order: DictOrderFull) => void
    ): Promise<{ success: boolean, duplicates?: string[] }> => {
        try {
            setLoading(true);
            const { result } = await fetchData(
                DICT_ORDER_PLACE_URL,
                {
                    method: 'POST',
                    data: mapOrderInfoToPayload(orderInfo)
                }
            );
            if (result.duplicates != null) {
                return { success: false, duplicates: result.duplicates };
            } else {
                const fetchedOrder = parseOrderData(result.data, message, locale);
                setOrder(fetchedOrder);
                // Placing order happens silently since it goes through Stripe, unless it is a trial
                // showToastMessage({ message: message('Form.Msg.OrderSuccess', { orderno: fetchedOrder.orderNo }), type: 'success' });
                // navigate(getLocaledAbsolutePath(Path.MAIN, locale));
                return { success: true };
            }
        } catch (e) {
            // TODO: Consider upload order placing error to server
            // showToastMessage({ message: `${message('Form.Msg.OrderFailure')}`, type: 'error', hideDuration: 5000 });
            return { success: false };
        } finally {
            setLoading(false);
        }
    }

    const fetchOrders = useCallback(async (
        { userId, isOnlyActive, orderId, isGrouped }: { userId: string; isOnlyActive?: boolean; isGrouped?: boolean; orderId?: number; },
        setLoading: (loading: boolean) => void,
        setOrders: (orders: DictOrderFull[]) => void
    ) => {
        try {
            setLoading(true);
            const { result } = await fetchData(
                Boolean(orderId) ? DICT_ORDER_LIST_GUEST_URL : DICT_ORDER_LIST_URL,
                {
                    method: 'GET',
                    params: {
                        userId,
                        isOnlyActive,
                        orderId
                    }
                }
            );
            const fetchedOrders = parseOrdersData(result.data, message, locale);
            setOrders(isGrouped ? groupRefOrders(fetchedOrders) : fetchedOrders);
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }, [message, locale]);

    const updateOrderDict = useCallback(async (payload: {
        orderId: string; userId: string; dictTypeIds: string
    }, setLoading?: (loading: boolean) => void) => {
        try {
            setLoading?.(true);
            const { result } = await fetchData(
                DICT_ORDER_UPDATE_DICT_URL,
                {
                    method: 'POST',
                    params: payload
                }
            );

            if (result) {
                reload(1500);
                showToastMessage({
                    message: message('Form.Msg.SuccessUpdateDictTypes'),
                    type: 'success'
                });
            } else {
                showToastMessage({
                    message: message('Form.Msg.FailureUpdateDictTypes'),
                    type: 'error'
                })
            }
        } catch (e) {
            showToastMessage({
                message: message('Form.Msg.FailureUpdateDictTypes'),
                type: 'error'
            })
        } finally {
            setLoading?.(false);
        }
    }, [message, reload, showToastMessage]);

    const updateOrder = useCallback(async (order: DictOrderFull, setLoading?: (loading: boolean) => void): Promise<boolean> => {
        try {
            setLoading?.(true);
            const { result } = await fetchData(
                DICT_ORDER_UPDATE_URL,
                {
                    method: 'POST',
                    data: mapOrderFullToPayload(order)
                }
            );

            return result;
        } catch (e) {
            return false;
        } finally {
            setLoading?.(false);
        }
    }, []);

    return ({
        placeOrder,
        fetchOrders,
        updateOrderDict,
        updateOrder
    })
}
